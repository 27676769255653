import { StatusParamsType } from '../../api/events-api';
import {
  ADD_CURRENT_EVENT_FILTER_BRANCH,
  ADD_CURRENT_EVENT_FILTER_DOCTOR,
  ADD_CURRENT_EVENT_FILTER_INSURANCE,
  ADD_CURRENT_EVENT_FILTER_REASON,
  ADD_CURRENT_EVENT_FILTER_STATUS,
  ADD_EVENT_SEARCH,
  CLEAN_EVENTS_FILTER,
  COLLAPSE_FILTER_EVENTS_FILTER,
  COLLAPSE_FILTER_INSURANCE_EVENTS_FILTER,
  COLLAPSE_FILTER_REASON_EVENTS_FILTER,
  COLLAPSE_FILTER_STATUS_EVENTS_FILTER,
  REMOVE_CURRENT_EVENT_FILTER_DOCTOR,
  REMOVE_CURRENT_EVENT_FILTER_INSURANCE,
  REMOVE_CURRENT_EVENT_FILTER_REASON,
  REMOVE_CURRENT_EVENT_FILTER_REASON_COLOR,
  REMOVE_CURRENT_EVENT_FILTER_STATUS,
  RESET_REDUCER_GROUP,
  SET_ACTIVE_ARROW_DATE_EVENTS,
  SET_ACTIVE_ARROW_FULL_NAME_EVENTS,
  SET_ACTIVE_ARROW_REASON_EVENTS,
  SET_ACTIVE_ARROW_STATUS_EVENTS,
  SET_CURRENT_EVENTS_QUERY_STRING,
  SET_CURRENT_SORT_PARAM_EVENTS,
  SET_EVENT_FILTER_PARAMS,
  SET_PRELOADER_EVENTS_FILTER,
  SET_SORT_BY_DATE_EVENTS,
  SET_SORT_BY_FULL_NAME_EVENTS,
  SET_SORT_BY_REASON_EVENTS,
  SET_SORT_BY_STATUS_EVENTS,
} from '../actions/constants';
import { EventsFilterInitialStateType } from './types';
import { EventsFilterActionsType } from '../actions/types';

const initialState: EventsFilterInitialStateType = {
  titleSort: [],
  currentQueryStringEventsList: null,
  currentFilterParams: {
    reason__color: [],
    insurance_type__in: [],
    reason__in: [],
    status__in: [],
    search: [],
    branch: [],
    doctor__in: [],
  },

  currentSortParams: {
    currentSortParam: '',
    isActiveFullName: false,
    isActiveReason: false,
    isActiveStatus: false,
    isActiveDate: false,

    isActiveArrowFullName: false,
    isActiveArrowReason: false,
    isActiveArrowStatus: false,
    isActiveArrowDate: false,
  },

  eventFilterParamsList: {
    search: {
      help_text: '',
      hidden: false,
      initial_value: null,
      input_type: '',
      label: '',
      required: true,
      type: '',
      widget_type: '',
    },
    full_name: { choices: {} },
    reason__color: { choices: {} },
    insurance_type__in: { choices: {} },
    reason__in: { choices: {} },
    status__in: {
      choices: {
        at_the_doctor: '',
        canceled: '',
        canceled_by_client: '',
        confirmed: '',
        did_not_wait: '',
        done: '',
        new: '',
        not_warned: '',
        waiting_room: '',
        warned: '',
      } as StatusParamsType,
    },
    branch: { choices: {} },
    doctor__in: { choices: {} },
  },
  isCollapsedFilter: true,
  isCollapsedFilterIncurance: true,
  isCollapsedFilterReason: true,
  isCollapsedFilterStatus: true,
  isActivePreloader: false,
};

export const eventsFilterReducer = (
  state: EventsFilterInitialStateType = initialState,
  action: EventsFilterActionsType,
): EventsFilterInitialStateType => {
  switch (action.type) {
    case SET_EVENT_FILTER_PARAMS: {
      return {
        ...state,
        eventFilterParamsList: action.params,
      };
    }
    case ADD_CURRENT_EVENT_FILTER_INSURANCE: {
      return {
        ...state,
        currentFilterParams: {
          ...state.currentFilterParams,
          insurance_type__in: [...state.currentFilterParams.insurance_type__in, action.params],
        },
      };
    }
    case REMOVE_CURRENT_EVENT_FILTER_INSURANCE: {
      return {
        ...state,
        currentFilterParams: {
          ...state.currentFilterParams,
          insurance_type__in: state.currentFilterParams.insurance_type__in.filter(
            (insurance) => insurance !== action.params,
          ),
        },
      };
    }
    case ADD_CURRENT_EVENT_FILTER_REASON: {
      return {
        ...state,
        currentFilterParams: {
          ...state.currentFilterParams,
          reason__in: [...state.currentFilterParams.reason__in, action.params],
        },
      };
    }
    case REMOVE_CURRENT_EVENT_FILTER_REASON: {
      return {
        ...state,
        currentFilterParams: {
          ...state.currentFilterParams,
          reason__in: state.currentFilterParams.reason__in.filter(
            (reason) => reason !== action.params,
          ),
        },
      };
    }
    case ADD_EVENT_SEARCH: {
      return {
        ...state,
        currentFilterParams: {
          ...state.currentFilterParams,
          search: [action.params],
        },
      };
    }
    case REMOVE_CURRENT_EVENT_FILTER_REASON_COLOR: {
      return {
        ...state,
        currentFilterParams: {
          ...state.currentFilterParams,
          reason__color: state.currentFilterParams.reason__color.filter(
            (reason) => reason !== action.params,
          ),
        },
      };
    }
    case ADD_CURRENT_EVENT_FILTER_STATUS: {
      return {
        ...state,
        currentFilterParams: {
          ...state.currentFilterParams,
          status__in: [...state.currentFilterParams.status__in, action.params],
        },
      };
    }
    case REMOVE_CURRENT_EVENT_FILTER_STATUS: {
      return {
        ...state,
        currentFilterParams: {
          ...state.currentFilterParams,
          status__in: state.currentFilterParams.status__in.filter(
            (status) => status !== action.params,
          ),
        },
      };
    }
    case ADD_CURRENT_EVENT_FILTER_BRANCH: {
      return {
        ...state,
        currentFilterParams: {
          ...state.currentFilterParams,
          branch: [action.params],
        },
      };
    }

    case ADD_CURRENT_EVENT_FILTER_DOCTOR: {
      return {
        ...state,
        currentFilterParams: {
          ...state.currentFilterParams,
          doctor__in: [...state.currentFilterParams.doctor__in, action.params],
        },
      };
    }
    case REMOVE_CURRENT_EVENT_FILTER_DOCTOR: {
      return {
        ...state,
        currentFilterParams: {
          ...state.currentFilterParams,
          doctor__in: state.currentFilterParams.doctor__in.filter(
            (doctor) => doctor !== action.params,
          ),
        },
      };
    }

    case COLLAPSE_FILTER_EVENTS_FILTER: {
      return {
        ...state,
        isCollapsedFilter: action.param,
      };
    }
    case COLLAPSE_FILTER_INSURANCE_EVENTS_FILTER: {
      return {
        ...state,
        isCollapsedFilterIncurance: action.param,
      };
    }
    case COLLAPSE_FILTER_REASON_EVENTS_FILTER: {
      return {
        ...state,
        isCollapsedFilterReason: action.param,
      };
    }
    case COLLAPSE_FILTER_STATUS_EVENTS_FILTER: {
      return {
        ...state,
        isCollapsedFilterStatus: action.param,
      };
    }
    case SET_PRELOADER_EVENTS_FILTER: {
      return {
        ...state,
        isActivePreloader: action.param,
      };
    }

    case CLEAN_EVENTS_FILTER: {
      return {
        ...state,
        currentFilterParams: {
          reason__color: [],
          insurance_type__in: [],
          reason__in: [],
          status__in: [],
          search: [],
          branch: [],
          doctor__in: [],
        },
      };
    }

    case SET_CURRENT_EVENTS_QUERY_STRING:
      return { ...state, currentQueryStringEventsList: action.query };

    case SET_CURRENT_SORT_PARAM_EVENTS:
      return {
        ...state,
        currentSortParams: {
          ...state.currentSortParams,
          currentSortParam: action.value,
        },
      };

    case SET_SORT_BY_FULL_NAME_EVENTS:
      return {
        ...state,
        currentSortParams: {
          ...state.currentSortParams,
          isActiveFullName: action.value,
        },
      };

    case SET_SORT_BY_REASON_EVENTS:
      return {
        ...state,
        currentSortParams: {
          ...state.currentSortParams,
          isActiveReason: action.value,
        },
      };

    case SET_SORT_BY_STATUS_EVENTS:
      return {
        ...state,
        currentSortParams: {
          ...state.currentSortParams,
          isActiveStatus: action.value,
        },
      };

    case SET_SORT_BY_DATE_EVENTS:
      return {
        ...state,
        currentSortParams: {
          ...state.currentSortParams,
          isActiveDate: action.value,
        },
      };

    case SET_ACTIVE_ARROW_FULL_NAME_EVENTS:
      return {
        ...state,
        currentSortParams: {
          ...state.currentSortParams,
          isActiveArrowFullName: action.value,
        },
      };

    case SET_ACTIVE_ARROW_REASON_EVENTS:
      return {
        ...state,
        currentSortParams: {
          ...state.currentSortParams,
          isActiveArrowReason: action.value,
        },
      };

    case SET_ACTIVE_ARROW_STATUS_EVENTS:
      return {
        ...state,
        currentSortParams: {
          ...state.currentSortParams,
          isActiveArrowStatus: action.value,
        },
      };

    case SET_ACTIVE_ARROW_DATE_EVENTS:
      return {
        ...state,
        currentSortParams: {
          ...state.currentSortParams,
          isActiveArrowDate: action.value,
        },
      };

    case RESET_REDUCER_GROUP:
      return { ...initialState };

    default:
      return state;
  }
};