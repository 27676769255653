import { AppRootStateType } from '../store';
import { BranchType, DateOfListType, ScheduleBranchType } from '../../api/branches-api';

export const getActiveWeekSelector = (state: AppRootStateType): string | null =>
  state.branches.activeCurrentWeek;

export const getCurrentBranchSelector = (state: AppRootStateType): number =>
  state.branches.currentBranch;

export const getBranchesSelector = (state: AppRootStateType): Array<BranchType> =>
  state.branches.branches;

export const getScheduleBranchSelector = (state: AppRootStateType): ScheduleBranchType =>
  state.branches.scheduleBranch;

export const getScheduleBranchDateSelector = (state: AppRootStateType): Array<DateOfListType> =>
  state.branches.scheduleBranch.date_of_lists;

export const getStartTimeWorkDayDateSelector = (state: AppRootStateType): string =>
  state.branches.scheduleBranch.day_start;

export const getEndTimeWorkDayDayDateSelector = (state: AppRootStateType): string =>
  state.branches.scheduleBranch.day_end;

export const getStataCalendarSelector = (state: AppRootStateType): boolean =>
  state.branches.isFullSchedule;

export const setPreloader = (state: AppRootStateType): boolean =>
  state.branches.isActivePreloader;
