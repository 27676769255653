import { applyMiddleware, combineReducers, createStore, Store } from 'redux';
import thunk from 'redux-thunk';
import { persistReducer, persistStore } from 'redux-persist';
import storage from 'redux-persist/lib/storage';
import {
  appReducer,
  authReducer,
  branchesReducer,
  clientsFilterReducer,
  clientsReducer,
  contactsReducer,
  employeesReducer,
  eventsFilterReducer,
  eventsReducer,
  fieldsReducer,
  holidaysReducer,
  orderReducer,
  ordersFilterReducer,
  profileReducer,
  reasonsReducer,
  registerReducer,
  scheduleWorkReducer,
  waitingRoomFilterReducer,
  waitingRoomReducer,
} from './reducers';

const rootReducer = combineReducers({
  auth: authReducer,
  app: appReducer,
  employees: employeesReducer,
  schedule: scheduleWorkReducer,
  events: eventsReducer,
  eventsFilter: eventsFilterReducer,
  profile: profileReducer,
  clients: clientsReducer,
  clientsFilter: clientsFilterReducer,
  waitingRoomFilter: waitingRoomFilterReducer,
  branches: branchesReducer,
  reasons: reasonsReducer,
  holidays: holidaysReducer,
  fields: fieldsReducer,
  waitingRoom: waitingRoomReducer,
  contacts: contactsReducer,
  register: registerReducer,
  orders: orderReducer,
  ordersFilter: ordersFilterReducer,
});

export type AppRootStateType = ReturnType<typeof rootReducer>;

//persist
const persistConfig = {
  key: 'root',
  storage,
  whitelist: [
    'app',
    'auth',
    'profile',
    'branches',
    'clientsFilter',
    'eventsFilter',
    'waitingRoomFilter',
    'ordersFilter',
  ],
  blacklist: [],
};

export const persistedReducer = persistReducer(persistConfig, rootReducer);
export const store: Store = createStore(persistedReducer, applyMiddleware(thunk));
export const persistor = persistStore(store);

// @ts-ignore
window.store = store;