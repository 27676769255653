import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { NavLink } from 'react-router-dom';
import { PATH } from '../../../routes/Routes';
import { DateListEventType } from '../../../api/branches-api';
import { EventDetailType } from '../../../api/events-api';
import TranslationKeys from '../../../assets/locales/translationKeys/translationKeys';

type EventsSortPropsType = {
  setCurrentPathForRedirect?: (event: DateListEventType | EventDetailType | null) => void;
};

export const EventsHeader: FC<EventsSortPropsType> = ({ setCurrentPathForRedirect }) => {
  const { t } = useTranslation();

  const setCurrentEventFromScheduleHandler = (): void => {
    setCurrentPathForRedirect && setCurrentPathForRedirect(null);
  };

  return (
    <>
      <div className="events__header">
        <h1 className="events__header__title">{t(TranslationKeys.event_list)}</h1>
        <div className="events__header__add">
          <NavLink
            className="button button_size-small button_position-right button_color-transparent"
            to={PATH.ADD_EVENT}
            onClick={setCurrentEventFromScheduleHandler}
          >
            + {t(TranslationKeys.event_btn_add)}
          </NavLink>
        </div>
      </div>
    </>
  );
};