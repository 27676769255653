import './DatePage.scss';
//@ts-ignore
import title from '../../../assets/img/title.svg';
//@ts-ignore
import times from '../../../assets/img/icons/times.svg';

const DatePage: React.FC = () => {
  return (
    <div className="date">
      <div className="date__header">
        <div className="date__header-info">
          <img className="date__header_info-img1" />
          <img className="date__header_info-img2" /> <br />
          <img className="date__header_info-img3" />
        </div>
      </div>
      <hr className="date__line" />
      <div className="date__title">
        <img src={title} className="date__title-img" />
      </div>
      <div className="date__content">
        <div className="date__information">
          <div className="date__information-data">
            <img src={times} className="date__information_data-img" />
            <p className="date__information_data-name">
              Your date: <span className="date__information_data-day">Thursday 26, 09:00</span>
            </p>
          </div>
          <button className="date__information-btn">Button</button>
          <div className="date__information-text">
            <h1 className="date__information_text-name">Coronavirus (Covid-19)</h1>
            <p className="date__information_text-paragraph">
              - Lorem ipsum dolor sit amet, consectetur adipiscing elit. Cras ultricies lorem quis
              velit <br /> hendrerit, eget fringilla nulla lacinia. Mauris at fringilla risus, quis
              gravida dui. Etiam <br /> rutrum nibh dui, in tempus lorem semper eget. Pellentesque
              habitant morbi tristique <br /> senectus et netus et malesuada fames ac turpis
              egestas. Nulla rhoncus condimentum <br /> fringilla. Cras tincidunt a quam vel
              ultrices. Suspendisse aliquet
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default DatePage;