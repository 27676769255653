import React, { Suspense } from 'react';
import ReactDOM from 'react-dom';
import App from './app/App';
import reportWebVitals from './reportWebVitals';
import { BrowserRouter } from 'react-router-dom';
import { Provider } from 'react-redux';
import i18n from 'i18next';
import { persistor, store } from './redux/store';
import './assets/sass/base.scss';
import { initReactI18next } from 'react-i18next';
import I18NextHttpBackend from 'i18next-http-backend';
import translationEN from '../src/assets/locales/en/translation';
import translationDE from './assets/locales/de/translation';
import translationDEV from './assets/locales/translationKeys/translationKeys';
import { PersistGate } from 'redux-persist/integration/react';

const resources = {
  en: {
    translation: translationEN,
  },
  de: {
    translation: translationDE,
  },
  dev: {
    translation: translationDEV,
  },
};

i18n
  .use(initReactI18next)
  .use(I18NextHttpBackend)
  .init({
    
    supportedLngs: ['de', 'en', 'dev'],
    lng:'de',
    resources,
    detection: {},
    react: {
      useSuspense: false,
    },
  });

ReactDOM.render(
  <Suspense fallback={'d'}>
    <BrowserRouter>
      <React.StrictMode>
        <Provider store={store}>
          <PersistGate loading={null} persistor={persistor}>
            <App />
          </PersistGate>
        </Provider>
      </React.StrictMode>
    </BrowserRouter>
  </Suspense>,
  document.getElementById('root'),
);

reportWebVitals();
