/**
 * "getQueryStringFromObject" takes "filter" argument.
 * Returns the query string.
 * @param {any} filter - object with query params
 * */
export const getQueryStringFromObject = (filter: any): string => {
  let newFilter = {...filter};
  for (let key in newFilter) {
    if (newFilter[key]?.length === 0 || newFilter[key]?.length === undefined) {
     delete  newFilter[key]
    }
  }
  return new URLSearchParams(newFilter).toString();
};