import React, { FC, memo, useCallback, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Navigate, NavLink } from 'react-router-dom';
import { PATH } from '../../routes/Routes';
import './Holidays.scss';
import { ConfirmationPopup } from '../../components/Popups/ConfirmationPopup/ConfirmationPopup';
import Preloader from '../../components/Preloaders/Preloader';
import {
  getActiveWeekSelector,
  getCurrentBranchSelector,
  getHolidaysSelector,
  getIsLoggedInSelector,
  getOpenSidebarAppSelector,
  getStatusSelector,
  getUserProfileSelector,
} from '../../redux/selectors';
import { useTranslation } from 'react-i18next';
import TranslationKeys from '../../assets/locales/translationKeys/translationKeys';
import { ArrowCollapse } from '../../components/ArrowСollapse/ArrowCollapse';
import {
  fetchBranchesListFirstRender,
  fetchHolidaysList,
  initializeAppTC,
  joinHolidayTC,
  leaveHolidayTC,
  removeHolidayTC,
} from '../../redux/middlewares';
import { setAppStatusAC } from '../../redux/actions';

export const Holidays: FC = memo(() => {
  const dispatch = useDispatch();

  const holidays = useSelector(getHolidaysSelector);
  const currentBranch = useSelector(getCurrentBranchSelector);
  const status = useSelector(getStatusSelector);
  const isLoggedIn = useSelector(getIsLoggedInSelector);
  const activeWeek = useSelector(getActiveWeekSelector);
  const user = useSelector(getUserProfileSelector);
  const isOpenSidebar = useSelector(getOpenSidebarAppSelector);

  //dialog alerts
  const [openAlertDialogForDelete, setOpenAlertDialogForDelete] = useState(false);
  const [modalDataId, setModalDataId] = useState<number>(0);

  const [collapsedPrivate, setCollapsedPrivate] = useState<boolean>(false);
  const [collapsedOfficial, setCollapsedOfficial] = useState<boolean>(false);
  const [collapsedSuggestion, setCollapsedSuggestion] = useState<boolean>(false);

  //locale
  const { t } = useTranslation();

  const escFunction = useCallback((event) => {
    if (event.key === 'Escape') {
      setOpenAlertDialogForDelete(false);
    }
  }, []);

  useEffect(() => {
    document.addEventListener('keydown', escFunction, false);

    return () => {
      document.removeEventListener('keydown', escFunction, false);
    };
  }, []);

  useEffect(() => {
    if (status === 'failed') {
      dispatch(initializeAppTC());
    }
  }, [status]);

  useEffect(() => {
    dispatch(fetchHolidaysList());
  }, [dispatch, currentBranch]);
  useEffect(() => {
    document.title = t(TranslationKeys.holidays_nav);
  }, []);

  useEffect(() => {
    if (isLoggedIn) {
      if (!activeWeek) {
        dispatch(fetchBranchesListFirstRender());
      }
    }
    return () => {
      dispatch(setAppStatusAC('idle'));
    };
  }, [dispatch]);

  const joinToHoliday = (holidayId: number): void => {
    dispatch(joinHolidayTC(holidayId));
  };

  const leaveToHoliday = (holidayId: number): void => {
    dispatch(leaveHolidayTC(holidayId));
  };

  const removeHoliday = (id: number): void => {
    dispatch(removeHolidayTC(id));
    setModalDataId(0);
  };

  const openAlertHandler = (holidayId: number): void => {
    setModalDataId(holidayId);
    setOpenAlertDialogForDelete(true);
  };

  const closeAlertHandler = (): void => {
    setOpenAlertDialogForDelete(false);
  };

  const collapsePrivateList = (): void => {
    setCollapsedPrivate(!collapsedPrivate);
  };

  const collapseOfficialList = (): void => {
    setCollapsedOfficial(!collapsedOfficial);
  };

  const collapseSuggestionList = (): void => {
    setCollapsedSuggestion(!collapsedSuggestion);
  };

  const setTimeHoliday = (start: string, end: string): number => {
    const editStart = Number(start?.slice(0, 2));
    const editEnd = Number(end?.slice(0, 2));
    return editEnd - editStart;
  };

  if (!isLoggedIn) {
    return <Navigate to={PATH.LOGIN} />;
  }

  return (
    <>
      <div className={`${isOpenSidebar ? 'holidays' : 'holidays full-width'}`}>
        <div className="holidays__header">
          <h1 className="holidays__header__title">{t(TranslationKeys.holiday_list)}</h1>
          <div className="holidays__header__add">
            {user.role !== 'doctor' ? (
              <NavLink
                className="button button_size-small button_position-left button_color-transparent"
                to={PATH.ADD_HOLIDAY}
              >
                + {t(TranslationKeys.holiday_btn_add)}
              </NavLink>
            ) : (
              ''
            )}
          </div>
        </div>

        {status === 'loading' ? (
          <Preloader />
        ) : (
          <div>
            <div onClick={collapsePrivateList} className="holidays__title-block private">
              <span className="title-holiday">{t(TranslationKeys.holiday_private)}</span>
              <ArrowCollapse
                changeStateArrow={collapsePrivateList}
                stateArrow={!collapsedPrivate}
              />
            </div>
            {collapsedPrivate && <hr className="holidays__line" />}
            <table className="holidays__table">
              <thead className="thead">
                <tr className={` ${collapsedPrivate ? 'active' : 'tr'}`}>
                  <th className="th-name">{t(TranslationKeys.name)}</th>
                  <th className="th">{t(TranslationKeys.date)}</th>
                  <th className="th">{t(TranslationKeys.shedule_time)}</th>
                  {user.role !== 'doctor' ? (
                    <th className="th">{t(TranslationKeys.options)}</th>
                  ) : (
                    ''
                  )}
                </tr>
              </thead>
              <tbody className={` ${collapsedPrivate ? 'active' : 'tbody'}`}>
                {holidays &&
                  holidays.private?.map((priv) => (
                    <tr key={priv.id} className="tr">
                      <td className="td-name">
                        <div>{priv.name}</div>
                      </td>
                      <td className="td">{priv.date}</td>
                      <td className="td">
                        {setTimeHoliday(priv?.start_time, priv?.end_time) > 20 ||
                        setTimeHoliday(priv?.start_time, priv?.end_time) === 0
                          ? `${t(TranslationKeys.all_day)}`
                          : `${priv?.start_time?.slice(0, 5)} — ${priv?.end_time?.slice(0, 5)}`}
                      </td>
                      {user.role !== 'doctor' ? (
                        <td className="td-btns">
                          <div className="btns">
                            <NavLink
                              className="button button_size-small  button_color-green"
                              to={`${PATH.HOLIDAYS}${priv.id}/`}
                            >
                              {t(TranslationKeys.edit)}
                            </NavLink>
                            <button
                              onClick={() => openAlertHandler(priv.id)}
                              className="button button_size-small  button_color-delete"
                            >
                              {t(TranslationKeys.delete)}
                            </button>
                          </div>
                        </td>
                      ) : (
                        ''
                      )}
                    </tr>
                  ))}
                {openAlertDialogForDelete && (
                  <>
                    <ConfirmationPopup
                      actionCallback={removeHoliday}
                      id={modalDataId}
                      titleQuestion={t(TranslationKeys.holiday_delete)}
                      onClickBtn={closeAlertHandler}
                      actionTitle={t(TranslationKeys.delete)}
                      cancelCallback={closeAlertHandler}
                    />
                  </>
                )}
              </tbody>
            </table>
            <div onClick={collapseOfficialList} className="holidays__title-block">
              <span className="title-holiday">{t(TranslationKeys.holiday_official)}</span>
              <ArrowCollapse
                changeStateArrow={collapseOfficialList}
                stateArrow={!collapsedOfficial}
              />
            </div>
            {collapsedOfficial && <hr className="holidays__line" />}
            <table className="holidays__table">
              <thead className="thead">
                <tr className={` ${collapsedOfficial ? 'active' : 'tr'}`}>
                  <th className="th-name">{t(TranslationKeys.name)}</th>
                  <th className="th">{t(TranslationKeys.date)}</th>
                  <th className="th">{t(TranslationKeys.shedule_time)}</th>
                  {user.role !== 'doctor' ? (
                    <th className="th">{t(TranslationKeys.options)}</th>
                  ) : (
                    ''
                  )}
                </tr>
              </thead>
              <tbody className={` ${collapsedOfficial ? 'active' : 'tbody'}`}>
                {holidays &&
                  holidays.official?.map((official) => (
                    <tr key={official.id} className="tr">
                      <td className="td-name">
                        <div>{official.name}</div>
                      </td>
                      <td className="td">{official.date}</td>
                      <td className="td">
                        {setTimeHoliday(official.start_time, official.end_time) > 20 ||
                        setTimeHoliday(official.start_time, official.end_time) === 0
                          ? `${t(TranslationKeys.all_day)}`
                          : `${official.start_time.slice(0, 5)} — ${official.end_time.slice(0, 5)}`}
                      </td>
                      {user.role !== 'doctor' ? (
                        <td className="td-btns">
                          <div className="btns">
                            <button
                              onClick={() => {
                                leaveToHoliday(official.id);
                              }}
                              className="button button_size-small  button_color-delete"
                            >
                              {t(TranslationKeys.leave)}
                            </button>
                          </div>
                        </td>
                      ) : (
                        ''
                      )}
                    </tr>
                  ))}
              </tbody>
            </table>
            {user.role !== 'doctor' ? (
              <>
                <div onClick={collapseSuggestionList} className="holidays__title-block">
                  <span className="title-holiday">{t(TranslationKeys.holiday_suggestion)}</span>
                  <ArrowCollapse
                    changeStateArrow={collapseSuggestionList}
                    stateArrow={!collapsedSuggestion}
                  />
                </div>
                {collapsedSuggestion && <hr className="holidays__line" />}
                <table className="holidays__table">
                  <thead className="thead">
                    <tr className={` ${collapsedSuggestion ? 'active' : 'tr'}`}>
                      <th className="th-name">{t(TranslationKeys.name)}</th>
                      <th className="th">{t(TranslationKeys.date)}</th>
                      <th className="th">{t(TranslationKeys.shedule_time)}</th>
                      {user.role !== 'doctor' ? (
                        <th className="th">{t(TranslationKeys.options)}</th>
                      ) : (
                        ''
                      )}
                    </tr>
                  </thead>
                  <tbody className={` ${collapsedSuggestion ? 'active' : 'tbody'}`}>
                    {holidays &&
                      holidays.suggestion?.map((suggestion) => (
                        <tr key={suggestion.id} className="tr">
                          <td className="td-name">
                            <div>{suggestion.name}</div>
                          </td>
                          <td className="td">{suggestion.date} </td>
                          <td className="td">
                            {setTimeHoliday(suggestion.start_time, suggestion.end_time) > 8 ||
                            setTimeHoliday(suggestion.start_time, suggestion.end_time) === 0
                              ? `${t(TranslationKeys.all_day)}`
                              : `${suggestion.start_time.slice(0, 5)} — ${suggestion.end_time.slice(
                                  0,
                                  5,
                                )}`}
                          </td>
                          {user.role !== 'doctor' ? (
                            <td className="td-btns">
                              <div className="btns">
                                <div
                                  onClick={() => joinToHoliday(suggestion.id)}
                                  className="button button_size-small  button_color-green"
                                >
                                  {t('join')}
                                </div>
                              </div>
                            </td>
                          ) : (
                            ''
                          )}
                        </tr>
                      ))}
                  </tbody>
                </table>
              </>
            ) : (
              ''
            )}
          </div>
        )}
      </div>
    </>
  );
});
