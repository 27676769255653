import { authAPI, LoginParamsType } from "../../api/auth-api";
import { Dispatch } from "redux";
import { setAppStatusAC, setProfileDataAC } from "../actions";
import { loadProfileDataLS, saveProfileDataLS, saveToken } from "../../utils/localStorage";
import { handleServerAppError, handleServerNetworkError } from "../../utils/error-utils";
import { setIsLoggedInAC } from "../actions";

export const loginTC = (data: LoginParamsType) => async (dispatch: Dispatch<any>) => {
  dispatch(setAppStatusAC('loading'));
  try {
    const res = await authAPI.login(data);
    dispatch(setIsLoggedInAC(true));
    saveToken(res.data.access);
    saveProfileDataLS(res.data);

    const user = await loadProfileDataLS();
    dispatch(setProfileDataAC(user));
    dispatch(setAppStatusAC('succeeded'));
  } catch (error: any) {
    if (error.message === 'Network Error') {
      handleServerNetworkError(error, dispatch);
    } else {
      handleServerAppError(error, dispatch);
    }
  }
};

export const refreshTC = () => async (dispatch: Dispatch<any>) => {
  const profileData = loadProfileDataLS();
  const refresh = await {
    refresh: profileData.refresh,
  };
  try {
    const res = await authAPI.refresh(refresh);
    saveToken(res.data.access);
  } catch (error: any) {
    if (error.message === 'Network Error') {
      handleServerNetworkError(error, dispatch);
    } else {
      handleServerAppError(error, dispatch);
    }
  }
};