import { OrderDataType, OrderType } from '../../api/order-api';
import {
  ADD_ORDER,
  CHANGE_STATUS_ORDER,
  EDIT_ORDER,
  SET_CURRENT_PAGE_ORDERS,
  SET_LIST_ORDER,
} from './constants';
import { OrderModelType } from './types';

export const setListOrderAC = (order: OrderDataType) => ({ type: SET_LIST_ORDER, order } as const);

export const addOrderAC = (model: OrderType) => ({ type: ADD_ORDER, model } as const);

export const editOrderAC = (model: OrderModelType) => ({ type: EDIT_ORDER, model } as const);

export const changeStatusOrderAC = (
  orderId: number,
  order: { status: string; status_display: string },
) => ({ type: CHANGE_STATUS_ORDER, orderId, order } as const);

export const setCurrentPageOrdersAC = (currentPage: string | null) =>
  ({ type: SET_CURRENT_PAGE_ORDERS, currentPage } as const);