import React, { FC, memo, useCallback, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import './Events.scss';
import { Navigate } from 'react-router-dom';
import { PATH } from '../../routes/Routes';
import {
  getActiveWeekSelector,
  getCurrentBranchSelector,
  getCurrentEventsSelector,
  getCurrentPagePaginationEventsSelector,
  getCurrentSortParamEventsSelector,
  getCurrEventsQueryStringAppSelector,
  getEventsSelector,
  getIsLoggedInSelector,
  getNextEventsSelector,
  getOpenSidebarAppSelector,
  getPreviousEventsSelector, getSelectedEventSelector,
  getSortStateArrowDateEventsSelector,
  getSortStateArrowFullNameEventsSelector,
  getSortStateArrowReasonEventsSelector,
  getSortStateArrowStatusEventsSelector,
  getSortStateByDateEventsSelector,
  getSortStateByFullNameEventsSelector,
  getSortStateByReasonEventsSelector,
  getSortStateByStatusEventsSelector,
  getStateModalWaitingRoom,
  getStatusSelector
} from "../../redux/selectors";

import { useStatusFilterParams } from '../../hooks/useStatusFilterParams';
import { EventsHeader } from './EventsHeader/EventsHeader';
import { EventsList } from './EventsList/EventsList';
import { useTranslation } from 'react-i18next';
import TranslationKeys from '../../assets/locales/translationKeys/translationKeys';
import { ModalAlertWaiting } from '../../components/Popups/WaitingRoom/ModalAlertWaitingRoom';
import {
  cancelOrderEventTC,
  changeStatusEventTC,
  fetchBranchesListFirstRender,
  fetchFilteredEventsListOfGeneral,
  fetchFilteredEventsParamsListTC,
  getNextEventsList,
  getPrevEventsList,
  initializeAppTC
} from "../../redux/middlewares";
import {
  changeStateModalWaitingRoomAC,
  editStateMenuAC,
  setAppStatusAC,
  setCurrentEventScheduleAC,
  setCurrentPageEventsAC,
  setCurrentPathAC,
  setCurrentSortParamEventsAC, setCurrEventsQueryStringAC, setSelectedEventAC
} from "../../redux/actions";
import { getObjectFromQueryString } from "../../utils/getObjectFromQueryString";
import { getQueryStringFromObject } from "../../utils/getQueryStringFromObject";

export const Events: FC = memo(() => {
  const dispatch = useDispatch();

  const events = useSelector(getEventsSelector);
  const previousPage = useSelector(getPreviousEventsSelector);
  const nextPage = useSelector(getNextEventsSelector);
  const countPagePagination = useSelector(getCurrentPagePaginationEventsSelector);
  const currentPage = useSelector(getCurrentEventsSelector);
  const status = useSelector(getStatusSelector);
  const isLoggedIn = useSelector(getIsLoggedInSelector);
  const activeWeek = useSelector(getActiveWeekSelector);
  const isOpenSidebar = useSelector(getOpenSidebarAppSelector);
  const stateModalWaitingRoom = useSelector(getStateModalWaitingRoom);
  const currentBranch = useSelector(getCurrentBranchSelector);

  const isActiveFullName = useSelector(getSortStateByFullNameEventsSelector);
  const isActiveReason = useSelector(getSortStateByReasonEventsSelector);
  const isActiveStatus = useSelector(getSortStateByStatusEventsSelector);
  const isActiveDate = useSelector(getSortStateByDateEventsSelector);
  const isActiveArrowFullName = useSelector(getSortStateArrowFullNameEventsSelector);
  const isActiveArrowReason = useSelector(getSortStateArrowReasonEventsSelector);
  const isActiveArrowStatus = useSelector(getSortStateArrowStatusEventsSelector);
  const isActiveArrowDate = useSelector(getSortStateArrowDateEventsSelector);
  const currentQueryString = useSelector(getCurrEventsQueryStringAppSelector);
  const currentSortParam = useSelector(getCurrentSortParamEventsSelector);
  const selectedIndex = useSelector(getSelectedEventSelector);

  const statusFilterParams = useStatusFilterParams();

  //dialog alerts
  const [openAlertDialogForCancel, setOpenAlertDialogForCancel] = useState(false);
  const [modalDataId, setModalDataId] = useState<number>(0);
  const [options, setOptions] = useState(false);

  //locale
  const { t } = useTranslation();

  const closeOnKeyPressEsc = useCallback((event) => {
    if (event.key === 'Escape') {
      dispatch(changeStateModalWaitingRoomAC(false));
      setOpenAlertDialogForCancel(false);
      setOptions(false);
    }
  }, []);

  useEffect(() => {
    document.addEventListener('keydown', closeOnKeyPressEsc, false);

    return () => {
      document.removeEventListener('keydown', closeOnKeyPressEsc, false);
    };
  }, []);

  useEffect(() => {
    if (status === 'failed') {
      dispatch(initializeAppTC());
    }
  }, [status]);
  useEffect(() => {
    document.title = t(TranslationKeys.event_header);
  }, []);

  useEffect(() => {
    if (currentBranch !== 0) {
      if (currentQueryString) {
        dispatch(fetchFilteredEventsListOfGeneral(undefined, currentQueryString));
      } else {
        dispatch(fetchFilteredEventsListOfGeneral(currentSortParam));
      }
      dispatch(fetchFilteredEventsParamsListTC());
    }
  }, [dispatch]);

  useEffect(() => {
    if (isLoggedIn) {
      if (!activeWeek) {
        dispatch(fetchBranchesListFirstRender());
      }
    }
    return () => {
      dispatch(setAppStatusAC('idle'));
    };
  }, [events]);

  useEffect(() => {
    dispatch(setCurrentPageEventsAC(null));
  }, [dispatch]);

  const cancelEvent = (id: number): void => {
    const currentEvent = events.length && events.find((ev) => ev.id === id);
    const uuid_field = currentEvent && currentEvent.uuid_field;
    if (uuid_field) {
      dispatch(cancelOrderEventTC(uuid_field));
    }
    setModalDataId(0);
  };

  const openAlertOnCancel = (eventsId: number): void => {
    setModalDataId(eventsId);
    setOpenAlertDialogForCancel(true);
  };

  const onChangeStatus = (eventId: number, currentStatus: string): void => {
    dispatch(changeStatusEventTC(eventId, currentStatus));
  };

  const closeModalAlert = (): void => {
    setOpenAlertDialogForCancel(false);
  };

  const nextList = (): void => {
    if (nextPage !== null) {
      dispatch(getNextEventsList());
    }
  };

  const prevList = (): void => {
    if (previousPage !== null) {
      dispatch(getPrevEventsList());
    }
  };

  const toFirstList = (): void => {
    const currQueryStringToObject = getObjectFromQueryString(currentQueryString);
    const currQueryStringToString = getQueryStringFromObject({
      ...currQueryStringToObject,
      page: '1',
    });
    if (currentQueryString) {
      dispatch(setCurrEventsQueryStringAC(currQueryStringToString));
      dispatch(
        fetchFilteredEventsListOfGeneral(
          undefined,
          currQueryStringToString,
        ),
      );
    } else {
      dispatch(fetchFilteredEventsListOfGeneral(currentSortParam));
    }
  };

  const toLastList = (): void => {
    const currQueryStringToObject = getObjectFromQueryString(currentQueryString);
    const currQueryStringToString = getQueryStringFromObject({
      ...currQueryStringToObject,
      page: `${countPagePagination}`,
    });
    if (currentQueryString) {
      dispatch(setCurrEventsQueryStringAC(currQueryStringToString));
      dispatch(
        fetchFilteredEventsListOfGeneral(
          undefined,
          currQueryStringToString,
        ),
      );
    } else {
      dispatch(fetchFilteredEventsListOfGeneral(currentSortParam));
    }
  };

  const openModalAlertWaitingRoom = (): void => {
    dispatch(changeStateModalWaitingRoomAC(true));
  };

  const onClickOptions = (eventId: number): void => {
    dispatch(editStateMenuAC(false));
    if (options) {
      if (eventId === selectedIndex) {
        setOptions(false);
      } else {
        setOptions(true);
      }
    } else {
      setOptions(true);
    }
  };

  const sortListByFullName = (ordering: string): void => {
    dispatch(setCurrentSortParamEventsAC(ordering));
    dispatch(fetchFilteredEventsListOfGeneral(ordering));
  };

  const sortListByReason = (ordering: string): void => {
    dispatch(setCurrentSortParamEventsAC(ordering));
    dispatch(fetchFilteredEventsListOfGeneral(ordering));
  };

  const sortListByStatus = (ordering: string): void => {
    dispatch(setCurrentSortParamEventsAC(ordering));
    dispatch(fetchFilteredEventsListOfGeneral(ordering));
  };

  const sortListByDate = (ordering: string): void => {
    dispatch(setCurrentSortParamEventsAC(ordering));
    dispatch(fetchFilteredEventsListOfGeneral(ordering));
  };

  const closeModalWaitingCallback = (): void => {
    dispatch(changeStateModalWaitingRoomAC(false));
  };

  const setCurrentPathForRedirect = (): void => {
    dispatch(setCurrentEventScheduleAC(null));
    dispatch(setCurrentPathAC(location.pathname));
  };

  const setSelectedIndex = (eventId: number | null): void => {
    dispatch(setSelectedEventAC(eventId))
  }

  if (!isLoggedIn) {
    return <Navigate to={PATH.LOGIN} />;
  }
  return (
    <>
      <div
        onClick={() => {
          setOptions(false);
        }}
        className={`${isOpenSidebar ? 'events' : 'events full-width'}`}
      >
        <EventsHeader setCurrentPathForRedirect={setCurrentPathForRedirect} />

        <EventsList
          isActiveArrowFullName={isActiveArrowFullName}
          isActiveArrowStatus={isActiveArrowStatus}
          isActiveArrowDate={isActiveArrowDate}
          isActiveArrowReason={isActiveArrowReason}
          sortListByFullNameCallback={sortListByFullName}
          sortListByStatusCallback={sortListByStatus}
          sortListByDateCallback={sortListByDate}
          isActiveFullName={isActiveFullName}
          isActiveStatus={isActiveStatus}
          isActiveDate={isActiveDate}
          isActiveReason={isActiveReason}
          sortListByReasonCallback={sortListByReason}
          events={events}
          onClickOptions={onClickOptions}
          options={options}
          selectedIndex={selectedIndex}
          setSelectedIndex={setSelectedIndex}
          onChangeStatus={onChangeStatus}
          statusFilterParams={statusFilterParams}
          openModalAlertWaitingRoom={openModalAlertWaitingRoom}
          openAlertOnCancel={openAlertOnCancel}
          openAlertDialogForCancel={openAlertDialogForCancel}
          modalDataId={modalDataId}
          closeModalAlert={closeModalAlert}
          cancelEvent={cancelEvent}
          next={nextPage}
          previous={previousPage}
          current={currentPage}
          currentPagePagination={countPagePagination}
          prevList={prevList}
          nextList={nextList}
          toFirstList={toFirstList}
          toLastList={toLastList}
          setCurrentPathForRedirect={setCurrentPathForRedirect}
        />
      </div>

      {stateModalWaitingRoom ? (
        <ModalAlertWaiting
          selectIndex={selectedIndex}
          onClickBtn={closeModalWaitingCallback}
          closeModalAlert={closeModalWaitingCallback}
          setSelectIndex={setSelectedIndex}
        />
      ) : (
        ''
      )}
    </>
  );
});
