import { instance } from './http';
import { BranchType } from './branches-api';
import { EventsLinksType } from './events-api';
import axios from 'axios';

export const clientsAPI = {
  getList() {
    return instance.get<ClientsResponseDataType>('/api/clients/');
  },

  getClient(clientId: number) {
    return instance.get<ClientType>(`/api/clients/${clientId}`);
  },

  getAppeared(clientId: number) {
    return instance.get<ClientType>(`/api/clients/${clientId}/not-appeared/`);
  },

  addClient(data: any) {
    return instance.post<ClientType>('/api/clients/create/', data);
  },

  editClient(data: any, clientId: number) {
    return instance.put<ClientType>(`/api/clients/${clientId}/`, data);
  },

  removeClient(clientId: number) {
    return instance.delete<any>(`/api/clients/${clientId}/`);
  },

  getNextList(token: string, lang: string, next: string | null) {
    return axios.get<ClientsResponseDataType>(`${next}`, {
      headers: {
        Authorization: `Bearer ${token}`,
        'Accept-Language': `${lang}`,
      },
    });
  },

  getPrevList(token: string, lang: string, prev: string | null) {
    return axios.get<ClientsResponseDataType>(`${prev}`, {
      headers: {
        Authorization: `Bearer ${token}`,
        'Accept-Language': `${lang}`,
      },
    });
  },

  getFilteredParamsClientsList(branchId: any) {
    return instance.get<ClientFilterParamsType>(`/api/clients/filter-form/?branch=${branchId}`);
  },

  getFilteredClientListOfGeneral(params: string | null) {
    return instance.get<ClientsResponseDataType>(
     `/api/clients/?${params}`);
  },

  getFilteredListOfBranches(ordering?: string[]) {
    return instance.get<ClientsResponseDataType>(`/api/clients/?branches__in=${ordering}`);
  },
};

export type ClientFilterParamsType = {
  email: {};
  full_name: {};
  insurance_type__in: { choices: {} };
  branches__in: { choices: {} };
  doctors__in: { choices: {} };
  phone: {};
  search: {};
};

export type ClientType = {
  id: number; //
  first_name: string;
  last_name: string;
  insurance_type: string;
  email: string;
  phone: string;
  blocked: boolean;
  branches: Array<BranchType>;
  not_appeared: number;
  insurance_type_display: string;
  doctors: Array<DoctorType>;
  owner: number;
  owner_display: string;
  gender: number;
  gender_display: string;
  date_birth: string;
};

export type DoctorType = {
  id: number;
  first_name: string;
  last_name: string;
  email: string;
  photo: null;
  phone: null;
  role: string;
  owner: number;
  owner_display: number;
};

export type ClientsLinksType = {
  next: string | null;
  previous: string | null;
};

export type ClientsResponseDataType = {
  links: EventsLinksType;
  count: number;
  current_page: number;
  lastPage: number;
  current: number;
  results: Array<ClientType>;
};

