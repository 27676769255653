import React, { FC, memo, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { DateListEventType, DateOfListType } from '../../../../api/branches-api';
import { logo } from '../../../../assets/img/image';
import { useStatusFilterParams } from '../../../../hooks/useStatusFilterParams';
import dayjs from 'dayjs';
import TranslationKeys from '../../../../assets/locales/translationKeys/translationKeys';

type EventSchedulePropsType = {
  events: Array<DateListEventType>;
  scheduleBranchDate: Array<DateOfListType>;
  dayIndex: number;
  timeWorkDayFinally: Array<string>;
  setDateForModalAlert: (date: string, date_of_lists: DateOfListType) => void;
  onFreeDateClick: (date: string, time: string) => void;
  onStatusChangeEventOpenAlert: (eventId: number, status: string) => void;
  onStatusChangeEvent: (eventId: number, status: string) => void;
  CURRENT_DATE: DateOfListType | undefined;
  setCurrentEventFromSchedule: (event: DateListEventType) => void
};

export const EventSchedule: FC<EventSchedulePropsType> = memo(
  ({
     events,
     onFreeDateClick,
     setDateForModalAlert,
     onStatusChangeEventOpenAlert,
     onStatusChangeEvent,
     CURRENT_DATE,
     setCurrentEventFromSchedule,
   }) => {

    //locale
    const { t } = useTranslation();

    const statusFilterParams = useStatusFilterParams();

    const [isOpenEvent, setOpenEvent] = useState<boolean>(false);
    const [currentEvent, setCurrentEvent] = useState<number>();

    const currentPositionEvent = (fromStart: string): number => {
      const pixelsConversion = 3;
      return Number(fromStart) * pixelsConversion;
    };

    const eventsInOneHour = (fromStart: string, index: number): number => {
      const filterEl = CURRENT_DATE?.events[index].start.slice(11, 13);

      return events.filter((event) => event.start.slice(11, 13) === filterEl).length;
    };

    const onEventClickSetDate = (date: string, dateOfLists: DateOfListType): void => {
      setDateForModalAlert(date, dateOfLists);
    };

    const optionalClasses = (fromStart: string, start: string, index: number): string => {
      const filterEl = CURRENT_DATE?.events[index].start.slice(11, 13);
      const fromStartArr = events.filter((event) => event.start.slice(11, 13) === filterEl);

      return fromStartArr.length > 1 ? 'day-several-events' : 'day-event';
    };

    const customDisplay = (
      fromStart: string,
      start: string,
      currentIndex: number,
      id: number,
    ): string => {
      const filterEl = CURRENT_DATE?.events[currentIndex].from_start;
      const fromStartArr = events.filter(
        (event) => Number(event.from_start) + 29 >= Number(filterEl),
      );
      const firstEvent = fromStartArr.find((el, index) => index === 0);

      return firstEvent && firstEvent.id === id ? 'inherit' : 'none';
    };

    const eventHeight = (start: string, end: string): number => {
      const heightEvent = (new Date(end).getTime() - new Date(start).getTime()) / (1000 * 60);
      return heightEvent * 3;
    };

    const onHoverEvent = (eventId: number): void => {
      setOpenEvent(true);
      setCurrentEvent(eventId);
    };

    const showEventInfo = (eventId: number, eventStart: string, eventEnd: string): boolean => {
      return isOpenEvent && eventId === currentEvent || eventHeight(eventStart, eventEnd) >= 90
    }

    const showBorder = (eventId: number, start: string, end: string, reasonColor: string): string => {
      if (isOpenEvent && eventId === currentEvent) {
        return `1px solid ${reasonColor}`
      } else {
        if (eventHeight(start, end) < 90) {
          return `1px solid ${reasonColor}38`
        } else {
          return `1px solid transparent`
        }
      }
    }

    return (
      <ul>
        {events &&
          events.map((event, index) =>
            event.start &&
            event.start.toString().slice(0, 10) ===
            CURRENT_DATE?.date.slice(0, 10) ? (
              <li
                key={event.id}
                onMouseOver={() => onHoverEvent(event.id)}
                onMouseLeave={() => setOpenEvent(false)}
                className={optionalClasses(event.from_start, event.start, index)}
                title=''
                style={{
                  display: `${customDisplay(event.from_start, event.start, index, event.id)}`,
                  height: `${isOpenEvent && event.id === currentEvent && eventHeight(event.start, event.end) < 90
                    ? 90
                    : eventHeight(event.start, event.end)}px`,
                  top: `calc(60px + ${currentPositionEvent(event.from_start)}px)`,
                  borderColor: `${event.reason_color}`,
                  background: 'white',
                  zIndex: `${isOpenEvent && event.id === currentEvent ? 3 : 1}`,
                  // overflow: `${eventHeight(event.start, event.end) < 90 ? 'hidden' : 'visible'}`,
                  borderTop: `${showBorder(event.id, event.start, event.end, event.reason_color)}`,
                  borderRight: `${showBorder(event.id, event.start, event.end, event.reason_color)}`,
                  borderBottom: `${showBorder(event.id, event.start, event.end, event.reason_color)}`,
                }}
                onClick={(e) => {
                  e.stopPropagation();
                  onEventClickSetDate(
                    CURRENT_DATE?.events[index].start,
                    CURRENT_DATE,
                  );
                  setCurrentEventFromSchedule(event)
                }}
              >
                <div
                  className='event'
                  style={{
                    display: `${customDisplay(event.from_start, event.start, index, event.id)}`,
                    height: `${isOpenEvent && event.id === currentEvent && eventHeight(event.start, event.end) < 90
                      ? 90
                      : eventHeight(event.start, event.end)}px`,
                    top: `calc(60px + ${currentPositionEvent(event.from_start)}px)`,
                    borderColor: `${event.reason_color}`,
                    background: `${event.reason_color}38`,
                    margin: '-11px',
                    borderRadius: '0px 12px 12px 0px',
                    padding: '10px',
                    zIndex: `${isOpenEvent && event.id === currentEvent ? 3 : 1}`,
                    overflow: `${eventHeight(event.start, event.end) < 90 ? 'hidden' : 'visible'}`,
                  }}
                >
                  <span className='reason'>{event.reason_display}</span>

                  {showEventInfo(event.id, event.start, event.end) &&
                    <div>
                      <div className='start'>
                        {dayjs(event?.start).format("HH:mm")}

                        {event.status === 'waiting_room' && (
                          <span>
                        <img
                          title={t(TranslationKeys.at_the_doctor)}
                          alt='At the doctor'
                          className='img-ok'
                          onClick={(e) => {
                            onStatusChangeEventOpenAlert(
                              event.id,
                              statusFilterParams.at_the_doctor,
                            );
                            e.stopPropagation();
                          }}
                          src={logo.iconOk}
                        />
                        <img
                          className='img-Close'
                          title={t(TranslationKeys.did_not_wait)}
                          alt='Did not wait'
                          onClick={(e) => {
                            onStatusChangeEvent(event.id, statusFilterParams.did_not_wait);
                            e.stopPropagation();
                          }}
                          src={logo.iconClose}
                        />
                      </span>
                        )}

                        {event.status === 'at_the_doctor' && (
                          <span
                            role='presentation'
                            className='waiting-room'
                            onClick={(e) => {
                              onStatusChangeEvent(event.id, statusFilterParams.done);
                              e.stopPropagation();
                            }}
                          >
                        {t(TranslationKeys.done)}
                      </span>
                        )}

                        {event.status === 'new' && (
                          <span
                            role='presentation'
                            className='waiting-room'
                            onClick={(e) => {
                              onStatusChangeEventOpenAlert(event.id, statusFilterParams.waiting_room);
                              e.stopPropagation();
                            }}
                          >
                        {t(TranslationKeys.waiting_header)}
                      </span>
                        )}

                        {event.status === 'done' && <span className='status'>Done</span>}

                        {event.status === 'did_not_wait' && (
                          <span className='status'>{t(TranslationKeys.did_not_wait)}</span>
                        )}
                      </div>

                      {event.status === 'waiting_room' && (
                        <span>
                  {t(TranslationKeys.waiting_period)}: {event.waiting_time}
                    </span>
                      )}

                      {event.status === 'at_the_doctor' && (
                        <span>
                  {t(TranslationKeys.waiting_period)}: {event.visit_time}
                    </span>
                      )}

                      <div className='client'>
                        {event.first_name} {event.last_name}
                      </div>

                      <div className='doctor'>
                        {event.doctor.first_name} {event.doctor.last_name}
                      </div>
                    </div>
                  }

                  {optionalClasses(event.from_start, event.start, index) ===
                    'day-several-events' && (
                      <div className='amount-events'>
                        <span className='amount-content'>
                          {`+${eventsInOneHour(event.from_start, index) - 1}`}
                        </span>
                      </div>
                    )}

                  <div
                    role='presentation'
                    className='edd-button'
                    onClick={(e) => {
                      e.stopPropagation();
                      onFreeDateClick(
                        CURRENT_DATE?.date,
                        // `${event.start.slice(11, 16)}`,
                        dayjs(event.start).format("HH:mm"),
                      );
                    }}
                  >
                    <span className='edd-button_title'>+</span>
                  </div>
                </div>
              </li>
            ) : (
              ''
            ),
          )}
      </ul>
    );
  },
);
