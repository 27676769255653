import React, { FC, useEffect, useMemo, useState } from 'react';
import './ModalAlertEditWorkSchedule.scss';
import { useDispatch, useSelector } from 'react-redux';
import { SubmitHandler, useForm } from 'react-hook-form';
import { WorkScheduleType } from '../../../api/scheduleWork-api';
import CheckboxInput from '../../Widgets/CheckboxInput';
import { TimePicker } from '../../Widgets/TimePicker';
import dayjs from 'dayjs';
import {
  getBranchWorkScheduleSelector,
  getFieldsWorkScheduleSelector,
} from '../../../redux/selectors';
import { useTranslation } from 'react-i18next';
import i18next from 'i18next';
import { ButtonClose } from '../../Widgets/ButtonClose/ButtonClose';
import { editScheduleWorkTC, fetchScheduleWork } from '../../../redux/middlewares';

export type FormDataModalType = {
  day_of_week: string;
  start_time_work: string | Date;
  end_time_work: string | Date;
  start_lunch_break: string | null | Date;
  end_lunch_break: string | null | Date;
  is_weekend: boolean;
  lunch_break: boolean;
};

type ModalAlertPropsType = {
  branchId: number;
  workScheduleId: number;
  workSchedule: Array<WorkScheduleType>;
  cancelCallback: () => void;
  onClickBtn?: () => void;
};

export const ModalAlertEditWorkSchedule: FC<ModalAlertPropsType> = ({
  branchId,
  workScheduleId,
  cancelCallback,
  onClickBtn,
}) => {
  const dispatch = useDispatch();

  const branches = useSelector(getBranchWorkScheduleSelector);
  const fields = useSelector(getFieldsWorkScheduleSelector);

  const currentBrunch = branches.filter((br) => br.id === branchId)[0].workschedule;
  const currentDay = currentBrunch.filter((sw) => sw.id === workScheduleId)[0];
  const currentDayId = currentBrunch.filter((sw) => sw.id === workScheduleId)[0].id;

  const [weekend, setWeekend] = useState<boolean>(currentDay.is_weekend);
  const [lunch, setLunch] = useState<boolean>(currentDay.lunch_break);

  const [disabledState, setDisabledState] = useState(false);

  //locale
  const { t } = useTranslation();

  let todayDate = new Date().toISOString().slice(0, 10);

  const workScheduleData = currentDay && {
    day_of_week: currentDay.day_of_week,
    start_time_work: !currentDay.start_time_work
      ? new Date(`${todayDate} 00:00`)
      : new Date(`${todayDate} ${currentDay.start_time_work}`),
    end_time_work: !currentDay.end_time_work
      ? new Date(`${todayDate} 00:00`)
      : new Date(`${todayDate} ${currentDay.end_time_work}`),
    start_lunch_break: !currentDay.start_lunch_break
      ? new Date(`${todayDate} 00:00`)
      : new Date(`${todayDate} ${currentDay.start_lunch_break}`),
    end_lunch_break: !currentDay.end_lunch_break
      ? new Date(`${todayDate} 00:00`)
      : new Date(`${todayDate} ${currentDay.end_lunch_break}`),
    is_weekend: currentDay.is_weekend && currentDay.is_weekend,
    lunch_break: currentDay.lunch_break && currentDay.lunch_break,
  };

  const onCancelHandler = (): void => {
    cancelCallback();
  };

  const disabledWeekendHandler = (): void => {
    if (currentDay.is_weekend || weekend) {
      setWeekend(false);
    }
    if (!weekend) {
      setWeekend(true);
      setDisabledState(!disabledState);
    }
  };

  const disabledLunchHandler = (): void => {
    if (currentDay.lunch_break || lunch) {
      setLunch(false);
    }
    if (!lunch) {
      setLunch(true);
    }
  };

  const { register, control, handleSubmit, reset } = useForm<FormDataModalType>({
    mode: 'onBlur',
    defaultValues: useMemo(() => {
      if (workScheduleData) {
        return workScheduleData;
      }
    }, [workScheduleData, fields]),
  });

  useEffect(() => {
    if (workScheduleData) {
      reset(workScheduleData);
    }
  }, [currentDay]);

  const onSubmit: SubmitHandler<FormDataModalType> = (data) => {
    const newData = {
      day_of_week: currentDay.day_of_week,
      start_time_work: dayjs(data.start_time_work).format('HH:mm'),
      end_time_work: dayjs(data.end_time_work).format('HH:mm'),
      start_lunch_break: dayjs(data.start_lunch_break).format('HH:mm'),
      end_lunch_break: dayjs(data.end_lunch_break).format('HH:mm'),
      is_weekend: data.is_weekend,
      lunch_break: data.lunch_break,
    };
    if (currentDay) {
      dispatch(editScheduleWorkTC(currentDayId, newData));
    }
    dispatch(fetchScheduleWork());
    cancelCallback();
  };

  const disabledLunch = !weekend && lunch ? false : true;

  const [selectedDate] = useState<any>(new Date());

  const dateNow = dayjs().format('YYYY-MM-DD');

  const startLunchDefault = new Date(`${dateNow} ${currentDay.start_lunch_break}`);
  const endWorkTimeDefault = new Date(`${dateNow} ${currentDay.end_time_work}`);

  const [selectedStartLunchTime, setSelectedStartLunchTime] = useState<any>(
    startLunchDefault || null,
  );
  const [selectedEndWorkTime, setSelectedEndWorkTime] = useState<any>(endWorkTimeDefault || null);

  const minStartTime = new Date(
    `${dayjs(`${dateNow} ${currentDay.start_time_work}`).format('YYYY-MM-DD HH:mm')}`,
  );

  const availableStartTime = (): Date => {
    const selectedEndTimeMS = selectedEndWorkTime?.getTime();
    const sum = selectedEndTimeMS - 900000;
    return new Date(sum);
  };

  const availableEndTime = (): Date => {
    const selectedStartTimeMS = selectedStartLunchTime?.getTime();
    const sum = selectedStartTimeMS + 900000;
    return new Date(sum);
  };

  return (
    <form onClick={onCancelHandler} onSubmit={handleSubmit(onSubmit)} className="edit-window">
      <div onClick={(e) => e.stopPropagation()} className="edit-window__alert">
        <div className="btn-close-block">
          <ButtonClose onClickBtn={onClickBtn} />
        </div>
        <h2 className={i18next.language === 'de' ? 'title-de' : 'title'}>{t('shedule_edit')}</h2>
        <div className="input-wrapper">
          <div className="checkbox-block">
            <span className="span">{t('weekend')}</span>
            <div className="input">
              <CheckboxInput
                state="square"
                defaultChecked={currentDay.is_weekend}
                id={`${currentDay.id}`}
                register={register}
                name="is_weekend"
                onChangeChecked={disabledWeekendHandler}
              />
            </div>
          </div>

          <div className="input-block">
            <span className="span">{t('start_alert')}</span>
            <div className="input">
              <TimePicker
                state={'timePicker'}
                disabled={weekend}
                selectedDate={selectedDate}
                control={control}
                label={fields.start_time_work.label}
                name={'start_time_work'}
                step={300}
                register={register}
              />
            </div>
          </div>
          <div className="input-block">
            <span className="span">{t('end_alert')}</span>
            <div className="input">
              <TimePicker
                state={'timePicker'}
                disabled={weekend}
                selectedDate={selectedDate}
                control={control}
                onChangeText={(e) => {
                  setSelectedEndWorkTime(e);
                }}
                label={fields.end_time_work.label}
                name={'end_time_work'}
                step={300}
                register={register}
              />
            </div>
          </div>
          <div className="checkbox-block">
            <span className="span">{t('lunch_alert')}</span>
            <div className="input">
              <CheckboxInput
                defaultChecked={currentDay.lunch_break}
                state="square"
                disabled={weekend}
                id={`${currentDay.id}`}
                register={register}
                name="lunch_break"
                onChangeChecked={disabledLunchHandler}
              />
            </div>
          </div>
          <div className="input-block">
            <span className="span">{t('start_alert')}</span>
            <div className="input">
              <TimePicker
                state={'timePicker'}
                disabled={disabledLunch}
                selectedDate={selectedDate}
                control={control}
                onChangeText={(e) => {
                  setSelectedStartLunchTime(e);
                }}
                label={fields.start_lunch_break.label}
                name={'start_lunch_break'}
                minTime={minStartTime}
                maxTime={availableStartTime()}
                step={300}
                register={register}
              />
            </div>
          </div>
          <div className="input-block">
            <span className="span">{t('end_alert')}</span>
            <div className="input">
              <TimePicker
                state={'timePicker'}
                disabled={disabledLunch}
                selectedDate={selectedDate}
                control={control}
                label={fields.end_lunch_break.label}
                name={'end_lunch_break'}
                minTime={availableEndTime()}
                maxTime={selectedEndWorkTime}
                step={300}
                register={register}
              />
            </div>
          </div>
        </div>
        <div className="btn-wrapper">
          <button
            onClick={onCancelHandler}
            type="submit"
            className="button button_size-middle button_position-right button_color-transparent"
          >
            {t('cancel')}
          </button>
          <button
            type="submit"
            className="button button_size-middle button_position-left button_color-green buttonAlert"
          >
            {t('submit')}
          </button>
        </div>
      </div>
    </form>
  );
};

