import React, { FC, useEffect, useState } from 'react';
import Textarea from '../../../components/Widgets/Textarea';
import { useTranslation } from 'react-i18next';
import TranslationKeys from '../../../assets/locales/translationKeys/translationKeys';
import { ButtonClose } from '../../../components/Widgets/ButtonClose/ButtonClose';
import { CopyScriptModalAlert } from '../CopyScriptModalAlert/CopyScriptModalAlert';

type IframeType = {
  branchId: number;
  cancelCallback: () => void;
  onClickBtn: () => void;
};

const widgetURL = process.env.REACT_APP_WIDGET_URL;

export const Iframe: FC<IframeType> = ({ branchId, cancelCallback, onClickBtn }) => {
  const [openAlertDialogForCopyScript, setOpenAlertDialogCopyScript] = useState(false);

  const copyToClipBoard = async (copyMe: string): Promise<void> => {
    try {
      await navigator.clipboard.writeText(copyMe);
      // await cancelCallback();
      await setOpenAlertDialogCopyScript(true);
    } catch (err) {}
  };

  useEffect(() => {
    let timer: ReturnType<typeof setTimeout> = setTimeout(() => {
      if (openAlertDialogForCopyScript) {
        setOpenAlertDialogCopyScript(false);
      }
    }, 1500);
    return () => clearTimeout(timer);
  }, [openAlertDialogForCopyScript]);

  const handleSelect = (e: any): void => {
    e.target.select();
  };

  const textareaContent = `<script type='text/javascript'>localStorage.setItem("branchId", ${branchId})</script>
<script defer='defer' src='${widgetURL}static/js/index.js'></script>
<link rel='stylesheet' href='${widgetURL}static/css/index.css'>
<div id='terminow-registration' class='terminow-root'></div>`;

  //locale
  const { t } = useTranslation();

  return (
    <div onClick={cancelCallback} className="branches__wrapper-iframe">
      <div className="iframe" onClick={(e) => e.stopPropagation()}>
        <div className="wrap-close-button">
          <ButtonClose onClickBtn={onClickBtn} />
        </div>
        <div>
          <Textarea
            name={'iframe'}
            onClick={handleSelect}
            readOnly
            resize="none"
            defaultValue={textareaContent}
          />

          {openAlertDialogForCopyScript && (
            <CopyScriptModalAlert isOpenModalAlert={openAlertDialogForCopyScript} />
          )}

          <button
            onClick={() => copyToClipBoard(textareaContent)}
            className="button button_size-middle button_position-left button_color-green btn-copy"
            type="submit"
          >
            {t(TranslationKeys.copy)}
          </button>
        </div>
      </div>
    </div>
  );
};
