import axios from 'axios';

const instance = axios.create({
  baseURL: process.env.REACT_APP_BASE_URL,
});

export const registerAPI = {
  addUser(data: any) {
    return instance.post<any>('/api/register/', data);
  },

  getVerificationOfEmail(user_id: number, token: string | null) {
    return instance.get(`/api/email-activate/${user_id}/${token}/`);
  },
};

//types
export type RegisterType = {
  id: number;
  organization_name: string;
  email: string;
  phone: number;
  username: string;
  password: string;
  password2: string;
  first_name: string;
  last_name: string;
};
