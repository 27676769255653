import React, { FC } from 'react';
import '../../../app/App.scss';
import { useLocation } from 'react-router-dom';
import { PATH } from '../../../routes/Routes';
import { EventsFilter } from '../../Events/EventsFilter/EventsFilter';
import { ClientsFilter } from '../../Clients/ClientsFilter/ClientsFilter';
import { ScheduleSideBar } from '../../Schedule/ScheduleSideBar/ScheduleSideBar';
import { WaitingRoomFilter } from '../../WaitingRoom/WaitingRoomFilter/WaitingRoomFilter';
import '../../../../src/app/App.scss';
import { ProfileSideBar } from '../../Profile/ProfileSideBar/ProfileSideBar';
import { OrdersFilter } from '../../Orders/OrdersFilter/OrdersFilter';

type SideBarPropsType = { isOpenSidebar: boolean };

export const SideBar: FC<SideBarPropsType> = ({ isOpenSidebar }) => {
  const location = useLocation();

  const isProfileSidebar =
    location.pathname !== PATH.SCHEDULE &&
    location.pathname !== PATH.EVENTS &&
    location.pathname !== PATH.WAITING_ROOM &&
    location.pathname !== PATH.CLIENTS;

  return (
    <div>
      <aside className={`${isOpenSidebar ? 'admin__left' : 'admin__left left-open-sidebar'}`}>
        {location.pathname === PATH.SCHEDULE && <ScheduleSideBar />}

        {location.pathname === PATH.EVENTS && <EventsFilter />}

        {location.pathname === PATH.WAITING_ROOM && <WaitingRoomFilter />}

        {location.pathname === PATH.CLIENTS && <ClientsFilter />}

        {location.pathname === PATH.ORDERS && <OrdersFilter />}

        {isProfileSidebar && <ProfileSideBar />}
      </aside>
    </div>
  );
};