import { Dispatch } from 'redux';
import {
  addClientAC, editClientAC, removeClientAC,
  setAppearedAC,
  setAppStatusAC, setClientFromPopupAC,
  setClientsListAC,
  setClientsParamsAC,
  setCurrClientsQueryStringAC, setCurrentClientAC, setCurrentClientsPageAC, setStateClientsPopupAC
} from "../actions";
import { clientsAPI } from '../../api/clients-api';
import { handleServerAppError, handleServerNetworkError } from '../../utils/error-utils';
import { AppRootStateType } from '../store';
import { getObjectFromQueryString } from '../../utils/getObjectFromQueryString';
import { getQueryStringFromObject } from '../../utils/getQueryStringFromObject';
import { ClientQueryStringType } from './types';
import { loadLang, loadToken } from '../../utils/localStorage';

export const fetchClientsList = () => async (dispatch: Dispatch<any>) => {
  dispatch(setAppStatusAC('loading'));
  try {
    let res = await clientsAPI.getList();
    dispatch(setClientsParamsAC(res.data));
    dispatch(setClientsListAC(res.data.results));
    dispatch(setAppStatusAC('succeeded'));
  } catch (error: any) {
    if (error.message === 'Network Error') {
      handleServerNetworkError(error, dispatch);
    } else {
      handleServerAppError(error, dispatch);
    }
  }
  dispatch(setAppStatusAC('idle'));
};

export const fetchAppearedTC = (clientId: number) => async (dispatch: Dispatch<any>) => {
  dispatch(setAppStatusAC('loading'));
  try {
    const res = await clientsAPI.getAppeared(clientId);
    dispatch(setAppearedAC(res.data));
    dispatch(setAppStatusAC('succeeded'));
  } catch (error: any) {
    if (error.message === 'Network Error') {
      handleServerNetworkError(error, dispatch);
    } else {
      handleServerAppError(error, dispatch);
    }
  }
  dispatch(setAppStatusAC('idle'));
};

export const addClientTC =
  (data: any) => async (dispatch: Dispatch<any>, getState: () => AppRootStateType) => {
    const isOpenClientPopup = getState().clients.openClientPopup;
    dispatch(setAppStatusAC('loading'));
    try {
      const res = await clientsAPI.addClient(data);
      dispatch(addClientAC(res.data));
      dispatch(setAppStatusAC('succeeded'));
      if (isOpenClientPopup) {
        dispatch(setClientFromPopupAC(res.data.id));
      }
      dispatch(setStateClientsPopupAC(null));
    } catch (error: any) {
      if (error.message === 'Network Error') {
        handleServerNetworkError(error, dispatch);
      } else {
        handleServerAppError(error, dispatch);
      }
    }
  };

export const editClientTC = (id: number, data: any) => async (dispatch: Dispatch<any>) => {
  dispatch(setAppStatusAC('loading'));
  try {
    const res = await clientsAPI.editClient(data, id);
    dispatch(editClientAC(res.data));
    dispatch(setAppStatusAC('succeeded'));
    dispatch(setStateClientsPopupAC(null));
  } catch (error: any) {
    if (error.message === 'Network Error') {
      handleServerNetworkError(error, dispatch);
    } else {
      handleServerAppError(error, dispatch);
    }
    dispatch(setAppStatusAC('failed'));
  }
};

export const removeClientTC = (clientId: number) => async (dispatch: Dispatch<any>) => {
  dispatch(setAppStatusAC('loading'));
  try {
    await clientsAPI.removeClient(clientId);
    dispatch(removeClientAC(clientId));
    fetchClientsList();
    dispatch(setAppStatusAC('succeeded'));
    dispatch(setStateClientsPopupAC(null));
  } catch (error: any) {
    if (error.message === 'Network Error') {
      handleServerNetworkError(error, dispatch);
    } else {
      handleServerAppError(error, dispatch);
    }
  }
};

export const getNextClientsList =
  () => async (dispatch: Dispatch<any>, getState: () => AppRootStateType) => {
    const next = getState().clients.clientsData.links.next;

    const searchParams = next && new URL(next).search;
    const objSearchParams = getObjectFromQueryString(searchParams);
    const queryString = getQueryStringFromObject(
      objSearchParams as unknown as ClientQueryStringType,
    );

    dispatch(setAppStatusAC('loading'));
    try {
      const tokenLS = await loadToken();
      const langLS = await loadLang();
      const res = await clientsAPI.getNextList(tokenLS, langLS, next);
      dispatch(setClientsParamsAC(res.data));
      dispatch(setClientsListAC(res.data.results));
      dispatch(setCurrentClientsPageAC(next));
      dispatch(setCurrClientsQueryStringAC(queryString));
      dispatch(setAppStatusAC('succeeded'));
    } catch (error: any) {
      if (error.message === 'Network Error') {
        handleServerNetworkError(error, dispatch);
      } else {
        handleServerAppError(error, dispatch);
      }
    }
    dispatch(setAppStatusAC('idle'));
  };

export const getPrevClientsList =
  () => async (dispatch: Dispatch<any>, getState: () => AppRootStateType) => {
    const prev = getState().clients.clientsData.links.previous;

    const searchParams = prev && new URL(prev).search;
    const objSearchParams = getObjectFromQueryString(searchParams);
    const queryString = getQueryStringFromObject(
      objSearchParams as unknown as ClientQueryStringType,
    );

    dispatch(setAppStatusAC('loading'));
    try {
      const tokenLS = await loadToken();
      const langLS = await loadLang();
      const res = await clientsAPI.getPrevList(tokenLS, langLS, prev);
      dispatch(setClientsParamsAC(res.data));
      dispatch(setClientsListAC(res.data.results));
      dispatch(setCurrentClientsPageAC(prev));
      dispatch(setCurrClientsQueryStringAC(queryString));
      dispatch(setAppStatusAC('succeeded'));
    } catch (error: any) {
      if (error.message === 'Network Error') {
        handleServerNetworkError(error, dispatch);
      } else {
        handleServerAppError(error, dispatch);
      }
    }
    dispatch(setAppStatusAC('idle'));
  };

export const fetchClient = (clientId: number) => async (dispatch: Dispatch<any>) => {
  dispatch(setAppStatusAC('loading'));
  try {
    let res = await clientsAPI.getClient(clientId);
    dispatch(setCurrentClientAC(res.data));
    dispatch(setAppStatusAC('succeeded'));
  } catch (error: any) {
    if (error.message === 'Network Error') {
      handleServerNetworkError(error, dispatch);
    } else {
      handleServerAppError(error, dispatch);
    }
  }
  dispatch(setAppStatusAC('idle'));
};