import React, { FC, useEffect, useState } from 'react';
import { SubmitHandler, useForm } from 'react-hook-form';
import './Register.scss';
import { useDispatch, useSelector } from 'react-redux';
import { Navigate, NavLink } from 'react-router-dom';
import { PATH } from '../../routes/Routes';
import { logo } from '../../assets/img/image';
import Inputs from '../../components/Widgets/Inputs';
import {
  getErrorStateSelector,
  getFieldsUserRegisterSelector,
  getStatusSelector,
} from '../../redux/selectors';
import { ErrorMessage } from '../../components/ErrorMessage/ErrorMessage';
import { useErrorHandler } from '../../hooks/useErrorHandler';
import { useTranslation } from 'react-i18next';
import TranslationKeys from '../../assets/locales/translationKeys/translationKeys';
import { addUserTC, fetchUserListFieldsTC } from "../../redux/middlewares";
import { setIsSendDataRegistrationAC } from '../../redux/actions';

type FormDataType = {
  organization_name: string;
  email: string;
  phone: number;
  username: string;
  password: string;
  password2: string;
  first_name: string;
  last_name: string;
};

export const Registration: FC = () => {
  const dispatch = useDispatch();
  const { t } = useTranslation();

  const errorState = useSelector(getErrorStateSelector);
  const status = useSelector(getStatusSelector);
  const fields = useSelector(getFieldsUserRegisterSelector);

  const [sendData, setSendData] = useState<boolean>(false);

  //validation
  const formDataArray = [
    'organization_name',
    'email',
    'phone',
    'username',
    'password',
    'password2',
    'first_name',
    'last_name',
  ];

  const userNameErrorHandler = useErrorHandler('username', errorState, formDataArray);
  const firstNameErrorHandler = useErrorHandler('first_name', errorState, formDataArray);
  const lastNameErrorHandler = useErrorHandler('last_name', errorState, formDataArray);
  const passwordErrorHandler = useErrorHandler('password', errorState, formDataArray);
  const password2ErrorHandler = useErrorHandler('password2', errorState, formDataArray);
  const organizationNameErrorHandler = useErrorHandler(
    'organization_name',
    errorState,
    formDataArray,
  );
  const emailErrorHandler = useErrorHandler('email', errorState, formDataArray);
  const phoneErrorHandler = useErrorHandler('phone', errorState, formDataArray);

  const reasonData = {
    organization_name: fields.organization_name,
    email: fields.email,
    phone: fields.phone,
    username: fields.username,
    password: fields.password,
    password2: fields.password2,
    first_name: fields.first_name,
    last_name: fields.last_name,
  };

  useEffect(() => {
    if (reasonData) {
    }
  }, [fields]);

  useEffect(() => {
    dispatch(fetchUserListFieldsTC());
  }, [dispatch]);
  useEffect(() => {
    document.title = t(TranslationKeys.register);
  }, []);

  useEffect(() => {
    if (status === 'failed') {
      userNameErrorHandler.setErrorCallback();
      firstNameErrorHandler.setErrorCallback();
      lastNameErrorHandler.setErrorCallback();
      passwordErrorHandler.setErrorCallback();
      password2ErrorHandler.setErrorCallback();
      organizationNameErrorHandler.setErrorCallback();
      emailErrorHandler.setErrorCallback();
      phoneErrorHandler.setErrorCallback();
    }
  }, [status]);

  const { register, handleSubmit, reset } = useForm<FormDataType>({
    mode: 'onBlur',
  });

  const onSubmit: SubmitHandler<FormDataType> = (data) => {
    const newData = {
      organization_name: data.organization_name,
      email: data.email,
      phone: data.phone,
      username: data.username,
      password: data.password,
      password2: data.password2,
      first_name: data.first_name,
      last_name: data.last_name,
    };
    dispatch(addUserTC(newData));
    dispatch(setIsSendDataRegistrationAC(true));
    setSendData(true);
  };

  if (sendData && status === 'succeeded') {
    return <Navigate to={PATH.SUCCESS_PAGE_EMAIL} />;
  }

  return (
    <>
      <div className="register">
        <NavLink to={PATH.LOGIN} className="img-wrapper">
          <img src={logo.termiLogo} className="img" alt="termiNow" />
        </NavLink>
        <div className="add-register">
          <h1 className="add-register__title">{t(TranslationKeys.register)}</h1>
          <form onSubmit={handleSubmit(onSubmit)} className="add-register__form">
            <label className="add-register__inputs-label">{t(TranslationKeys.info)}</label>
            <div className="add-register__inputs-info">
              <Inputs
                error={organizationNameErrorHandler.error}
                help_text={
                  fields.organization_name.help_text ||
                  (organizationNameErrorHandler.error &&
                    organizationNameErrorHandler.errorMessageCurrentField[1])
                }
                onClick={organizationNameErrorHandler.onFieldClick}
                state={'active'}
                register={register}
                input_type={fields.organization_name.input_type}
                name={'organization_name'}
                label={fields.organization_name.label}
                defaultValue={fields.organization_name.initial_value}
                {...reset}
              />
              <div className="input">
                <Inputs
                  error={userNameErrorHandler.error}
                  help_text={
                    fields.username.help_text ||
                    (userNameErrorHandler.error && userNameErrorHandler.errorMessageCurrentField[1])
                  }
                  onClick={userNameErrorHandler.onFieldClick}
                  state={'active'}
                  register={register}
                  input_type={'text'}
                  name={'username'}
                  label={fields.username.label}
                  defaultValue={fields.username.initial_value}
                  {...reset}
                />
              </div>
            </div>
            <label className="add-register__inputs-label">{t(TranslationKeys.user)}</label>
            <div className="add-register__inputs-setting">
              <Inputs
                error={firstNameErrorHandler.error}
                help_text={
                  fields.first_name.help_text ||
                  (firstNameErrorHandler.error && firstNameErrorHandler.errorMessageCurrentField[1])
                }
                onClick={firstNameErrorHandler.onFieldClick}
                state={'active'}
                register={register}
                input_type={'text'}
                name={'first_name'}
                label={fields.first_name.label}
                defaultValue={fields.first_name.initial_value}
                {...reset}
              />
              <Inputs
                error={lastNameErrorHandler.error}
                help_text={
                  fields.last_name.help_text ||
                  (lastNameErrorHandler.error && lastNameErrorHandler.errorMessageCurrentField[1])
                }
                onClick={lastNameErrorHandler.onFieldClick}
                state={'active'}
                register={register}
                input_type={'text'}
                name={'last_name'}
                label={fields.last_name.label}
                defaultValue={fields.last_name.initial_value}
                {...reset}
              />
              <Inputs
                error={emailErrorHandler.error}
                help_text={
                  fields.email.help_text ||
                  (emailErrorHandler.error && emailErrorHandler.errorMessageCurrentField[1])
                }
                onClick={emailErrorHandler.onFieldClick}
                state={'active'}
                register={register}
                input_type="email"
                name={'email'}
                label={fields.email.label}
                defaultValue={fields.email.initial_value}
                {...reset}
              />
              <Inputs
                error={phoneErrorHandler.error}
                help_text={
                  fields.phone.help_text ||
                  (phoneErrorHandler.error && phoneErrorHandler.errorMessageCurrentField[1])
                }
                onClick={phoneErrorHandler.onFieldClick}
                state={'active'}
                register={register}
                name={'phone'}
                label={fields.phone.label}
                defaultValue={fields.phone.initial_value}
                {...reset}
              />
            </div>
            <label className="add-register__inputs-label">{t(TranslationKeys.password)}</label>
            <div className="add-register__inputs-setting1">
              <Inputs
                error={passwordErrorHandler.error}
                help_text={
                  fields.password.help_text ||
                  (passwordErrorHandler.error && passwordErrorHandler.errorMessageCurrentField[1])
                }
                onClick={passwordErrorHandler.onFieldClick}
                state={'active'}
                register={register}
                input_type="password"
                name={'password'}
                label={fields.password.label}
                defaultValue={fields.password.initial_value}
                autoComplete="off"
                {...reset}
              />
              <Inputs
                error={password2ErrorHandler.error}
                help_text={
                  fields.password2.help_text ||
                  (password2ErrorHandler.error && password2ErrorHandler.errorMessageCurrentField[1])
                }
                onClick={password2ErrorHandler.onFieldClick}
                state={'active'}
                input_type="password"
                register={register}
                name={'password2'}
                label={fields.password2.label}
                defaultValue={fields.password2.initial_value}
                autoComplete="off"
                {...reset}
              />
            </div>
            <div className="_button-wrapper">
              <button className="button button_size-middle button_position-right button_color-green">
                {t(TranslationKeys.sign_up)}
              </button>
            </div>
            <div
              className="btn"
              style={{
                width: '740px',
                marginRight: '20px',
              }}
            >
              <NavLink
                to={`${PATH.LOGIN}`}
                className="button button_size-middle button_position-right  button_color-transparent"
                type="submit"
              >
                {t(TranslationKeys.login)}
              </NavLink>
            </div>

            <ErrorMessage status={status} errorState={errorState} />
          </form>
        </div>
      </div>
    </>
  );
};
