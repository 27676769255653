import React, { FC, useState } from 'react';
import { motion } from 'framer-motion';
import './ModalAlertEvents.scss';
import { DateListEventType } from '../../../api/branches-api';
import { useLocation } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { logo } from '../../../assets/img/image';
import { useStatusFilterParams } from '../../../hooks/useStatusFilterParams';
import { calcScheduleGrid } from '../../../utils/schedule/calcScheduleGrid';
import dayjs from 'dayjs';
import { ButtonClose } from '../../Widgets/ButtonClose/ButtonClose';
import { setCurrentPathAC } from "../../../redux/actions";

type ModalAlertPropsType = {
  date: string;
  eventsData: Array<DateListEventType>;
  cancelCallback: () => void;
  onFreeDateClick: (date: string, time: string) => void;
  onStatusChangeEventOpenAlert: (eventId: number, status: string) => void;
  onStatusChangeEvent: (eventId: number, status: string) => void;
  onClickBtn?: () => void;
  setCurrentEventFromSchedule: (event: DateListEventType | null) => void;
  openEventDetails: () => void;
};

const baseURL = process.env.REACT_APP_BASE_URL;

export const ModalAlertEvents: FC<ModalAlertPropsType> = ({
  date,
  cancelCallback,
  eventsData,
  onFreeDateClick,
  onStatusChangeEventOpenAlert,
  onStatusChangeEvent,
  onClickBtn,
  setCurrentEventFromSchedule,
  openEventDetails,
}) => {
  const location = useLocation();
  const dispatch = useDispatch();
  const statusFilterParams = useStatusFilterParams();

  const [eventsDataLocal] = useState<Array<DateListEventType>>(eventsData);

  const onCancelHandler = (): void => {
    cancelCallback();
  };

  const eventPositionInModal = (start: string): number => {
    return (180 * Number(start.slice(14, 16))) / 60;
  };

  const eventHeight = (start: string, end: string): number => {
    const heightEvent = (new Date(end).getTime() - new Date(start).getTime()) / (1000 * 60);
    return (180 * heightEvent) / 60;
  };

  const addEvent = (e: any): void => {
    e.stopPropagation();
    setCurrentEventFromSchedule(null);
    dispatch(setCurrentPathAC(location.pathname));
    onFreeDateClick(date, `${date.slice(11, 14)}00`);
  };

  const startHourGrid = eventsData.reduce((acc, currEvent) =>
    new Date(acc.start).getTime() < new Date(currEvent.start).getTime() ? acc : currEvent,
  );

  const endHourGrid = eventsData.reduce((acc, currEvent) =>
    new Date(acc.end).getTime() > new Date(currEvent.end).getTime() ? acc : currEvent,
  );

  const hourGrid = calcScheduleGrid(
    startHourGrid.start.slice(11, 16),
    endHourGrid.end.slice(11, 16),
  );

  return (
    <motion.div className={'events-window '} onMouseDown={onCancelHandler}>
      <motion.div
        className="events-window__alert"
        onMouseDown={(e) => e.stopPropagation()}
        onClick={(e) => e.stopPropagation()}
        style={{
          minWidth: `calc(${eventsDataLocal.length * 180 + 150}px)`,
        }}
      >
        <div onClick={onClickBtn} className="btn-close-block">
          <ButtonClose onClickBtn={onClickBtn}/>
        </div>
        <motion.div
          animate={{
            x: 0,
          }}
          className="title"
        >
          <span className="title__time">{dayjs(date).format("HH:00")}</span>

          <div className={'title__rightWrapper'}>
            <span className="title__rightWrapper_date">
              {dayjs(date).format("ddd, DD MMM")}
            </span>

            <div className="title__rightWrapper_edd-button" onClick={addEvent}>
              <span className="edd-button_title">+</span>
            </div>
          </div>
        </motion.div>

        <div className="grid">
          {hourGrid.timeWorkDayFinally.map((time, index) => (
            <div key={index} className="line-grid">
              <div className="time_title">{`${time.slice(0, 3)}00`}</div>
            </div>
          ))}
        </div>

        <motion.div className="events-wrapper">
          {eventsDataLocal &&
            eventsDataLocal.map((event) => (
              <div
                className="day-event"
                onClick={(e) => {
                  e.stopPropagation();
                  openEventDetails()
                  setCurrentEventFromSchedule(event);
                  onClickBtn && onClickBtn();
                }}
                key={event.id}
                style={{
                  height: `${eventHeight(event.start, event.end)}px`,
                  top: `calc(${eventPositionInModal(event.start)}px + 64px)`,
                  borderColor: `${event.reason_color}`,
                  background: `${event.reason_color}38`,
                }}
              >
                <span className="reason">{event.reason_display}</span>

                <div className="start">
                  {`${event.start.toString().slice(11, 16)} - ${event.end
                    .toString()
                    .slice(11, 16)}`}

                  {event.status === 'waiting_room' && (
                    <span>
                      <img
                        title="At the doctor"
                        alt="At the doctor"
                        className="img-ok"
                        onClick={(e) => {
                          onStatusChangeEventOpenAlert(event.id, statusFilterParams.at_the_doctor);
                          cancelCallback();
                          e.stopPropagation();
                        }}
                        src={logo.iconOk}
                      />
                      <img
                        className="img-Close"
                        title="Did not wait"
                        alt="Did not wait"
                        onClick={(e) => {
                          onStatusChangeEvent(event.id, statusFilterParams.did_not_wait);
                          cancelCallback();
                          e.stopPropagation();
                        }}
                        src={logo.iconClose}
                      />
                    </span>
                  )}

                  {event.status === 'at_the_doctor' && (
                    <span
                      className="waiting-room"
                      onClick={(e) => {
                        onStatusChangeEvent(event.id, statusFilterParams.done);
                        cancelCallback();
                        e.stopPropagation();
                      }}
                    >
                      Done
                    </span>
                  )}

                  {event.status === 'new' && (
                    <span
                      className="waiting-room"
                      onClick={(e) => {
                        onStatusChangeEventOpenAlert(event.id, statusFilterParams.waiting_room);
                        cancelCallback();
                        e.stopPropagation();
                      }}
                    >
                      Waiting room
                    </span>
                  )}

                  {event.status === 'done' && <span className="status">Done</span>}

                  {event.status === 'did_not_wait' && <span className="status">Did not wait</span>}
                </div>

                {event.status === 'waiting_room' && <span>Wartezeit: {event.waiting_time}</span>}

                {event.status === 'at_the_doctor' && <span>Wartezeit: {event.visit_time}</span>}

                <div className="client">
                  {event.first_name} {event.last_name}
                </div>

                <div className="doctor-photo" style={{ display: 'none' }}>
                  <img
                    className="photo"
                    src={event.doctor.photo ? `${baseURL}${event.doctor.photo}` : logo.notLogo}
                    alt="Doctor"
                  />
                </div>
              </div>
            ))}
        </motion.div>
      </motion.div>
    </motion.div>
  );
};

