import { Dispatch } from 'redux';
import {
  loadProfileDataLS,
  loadToken,
  saveProfileDataLS,
  saveToken,
} from '../../utils/localStorage';
import { authAPI } from '../../api/auth-api';
import { handleServerAppError, handleServerNetworkError } from '../../utils/error-utils';
import { resetReducerGroupAC, setAppStatusAC, setIsLoggedInAC, setProfileDataAC } from '../actions';

export const initializeAppTC = () => async (dispatch: Dispatch) => {
  const tokenLS = await loadToken();
  try {
    const token = await {
      token: tokenLS,
    };
    if (tokenLS !== '') {
      await authAPI.me(token);
      dispatch(setIsLoggedInAC(true));
      const user = loadProfileDataLS();
      dispatch(setProfileDataAC(user));
      dispatch(setAppStatusAC('succeeded'));
    } else {
      dispatch(setIsLoggedInAC(false));
    }
  } catch (error: any) {
    if (tokenLS !== '') {
      if (error.message === 'Network Error') {
        handleServerNetworkError(error, dispatch);
      } else {
        handleServerAppError(error, dispatch);
      }
      dispatch(setIsLoggedInAC(false));
    }
    saveToken('');
    saveProfileDataLS('');
    dispatch(resetReducerGroupAC());
  }
  dispatch(setAppStatusAC('idle'));
};