import React, { FC, memo, useState } from 'react';
import {
  DateListEventType,
  DateOfListType,
  ScheduleBranchType,
} from '../../../../api/branches-api';
import dayjs from 'dayjs';

type EventSchedulePropsType = {
  events: Array<DateListEventType>;
  scheduleBranchDate: Array<DateOfListType>;
  scheduleBranch: ScheduleBranchType;
  dayIndex: number;

  timeWorkDayFinally: Array<string>;
  setDateForModalAlert: (date: string, date_of_lists: DateOfListType) => void;
  onFreeDateClick: (date: string, time: string) => void;
  onStatusChangeEventOpenAlert: (eventId: number, status: string) => void;
  onStatusChangeEvent: (eventId: number, status: string) => void;
  calcGrid: {
    timeWorkDayFinally: Array<string>;
    currentHeightLine: (hour: number) => number;
    currentHeightLineStart: (hour: number) => number;
    fullHeight: number;
    sumHours: number;
  };
  heightHour: string;
  CURRENT_DATE: DateOfListType | undefined;
  setCurrentEventFromSchedule: (event: DateListEventType | null) => void;
  openEventDetails: () => void;
};

export const EventScheduleSmall: FC<EventSchedulePropsType> = memo(
  ({
    events,
    setDateForModalAlert,
    onFreeDateClick,
    calcGrid,
    scheduleBranch,
    heightHour,
    CURRENT_DATE,
    setCurrentEventFromSchedule,
    // openEventDetails,
  }) => {
    const [isOpenEvent, setOpenEvent] = useState<boolean>(false);
    const [currentEvent, setCurrentEvent] = useState<number>();

    const currentPositionEvent = (from_start: string): number => {
      const hoursFromStart = Number(from_start) / 60;

      return hoursFromStart;
    };

    const eventsInOneHour = (from_start: string, index: number): number => {
      const filterEl = CURRENT_DATE?.events[index]?.start.slice(11, 13);

      return events.filter((event) => event.start.slice(11, 13) === filterEl).length;
    };

    const onEventClickSetDate = (date: string, date_of_lists: DateOfListType): void => {
      setDateForModalAlert(date, date_of_lists);
    };

    const optionalClasses = (from_start: string, start: string, index: number): string => {
      const filterEl = CURRENT_DATE?.events[index]?.start.slice(11, 13);
      const fromStartArr = events.filter((event) => event.start.slice(11, 13) === filterEl);

      return fromStartArr.length > 1 ? 'day-several-events' : 'day-event';
    };

    const customDisplay = (
      from_start: string,
      start: string,
      currentIndex: number,
      id: number,
    ): string => {
      const filterEl = CURRENT_DATE?.events[currentIndex]?.from_start;
      const fromStartArr = events.filter(
        (event) => Number(event.from_start) + 29 >= Number(filterEl),
      );
      const firstEvent = fromStartArr.find((el, index) => index === 0);

      return firstEvent && firstEvent.id === id ? 'inherit' : 'none';
    };

    const eventHeight = (start: string, end: string): number => {
      const heightEvent = (new Date(end).getTime() - new Date(start).getTime()) / (1000 * 60);
      return heightEvent / 60;
    };

    const onHoverEvent = (eventId: number): void => {
      setOpenEvent(true);
      setCurrentEvent(eventId);
    };

    const dynamicEventHeight = (eventId: number, start: string, end: string): string => {
      if (isOpenEvent && eventId === currentEvent) {
        return `80px`;
      } else {
        return `calc(((${calcGrid.fullHeight}vh - 244px) / ${calcGrid.sumHours}) * ${eventHeight(
          start,
          end,
        )})`;
      }
    };

    const dynamicEventPaddingWrap = (start: string, end: string): string => {
      if (eventHeight(start, end) < 0.3) {
        return '0px 0px 0px 10px';
      } else {
        return '10px';
      }
    };

    const dynamicEventPadding = (start: string, end: string): string => {
      if (eventHeight(start, end) < 0.3) {
        return '0 0 0 10';
      } else {
        return '10';
      }
    };

    const showBorder = (eventId: number, reasonColor: string): string => {
      if (isOpenEvent && eventId === currentEvent) {
        return `1px solid ${reasonColor}`;
      } else {
        return `1px solid transparent`;
      }
    };

    const dynamicContentPadding = (eventId: number, start: string, end: string): string => {
      if (eventHeight(start, end) < 0.3) {
        if (isOpenEvent && eventId === currentEvent) {
          return '10';
        } else {
          return '0';
        }
      } else {
        return '0';
      }
    };

    const dynamicPositionEvent = (from_start: string): string => {
      if (scheduleBranch.day_start.slice(3, 5) === '00') {
        return `${heightHour} * ${currentPositionEvent(from_start)} + 60px`;
      } else {
        return `((${heightHour} * ${currentPositionEvent(from_start)}) + 60px)`;
      }
    };

    return (
      <>
        <ul>
          {events &&
            events.map((event, index) =>
              event.start &&
              event.start.toString().slice(0, 10) === CURRENT_DATE?.date.slice(0, 10) ? (
                <li
                  key={event.id}
                  onMouseOver={() => onHoverEvent(event.id)}
                  onMouseLeave={() => setOpenEvent(false)}
                  className={optionalClasses(event.from_start, event.start, index)}
                  title=""
                  style={{
                    display: `${customDisplay(event.from_start, event.start, index, event.id)}`,
                    height: `${dynamicEventHeight(event.id, event.start, event.end)}`,
                    top: `calc(${dynamicPositionEvent(event.from_start)})`,
                    borderColor: `${event.reason_color}`,
                    zIndex: `${isOpenEvent && event.id === currentEvent ? 3 : 1}`,
                    padding: `${dynamicEventPaddingWrap(event.start, event.end)}`,
                    borderTop: `${showBorder(event.id, event.reason_color)}`,
                    borderRight: `${showBorder(event.id, event.reason_color)}`,
                    borderBottom: `${showBorder(event.id, event.reason_color)}`,
                  }}
                  onClick={(e) => {
                    e.stopPropagation();
                    onEventClickSetDate(CURRENT_DATE?.events[index]?.start, CURRENT_DATE);
                    setCurrentEventFromSchedule(event);
                  }}
                >
                  <div
                    className="event"
                    style={{
                      display: `${customDisplay(event.from_start, event.start, index, event.id)}`,
                      height: `${dynamicEventHeight(event.id, event.start, event.end)}`,
                      top: `calc(${dynamicPositionEvent(event.from_start)})`,
                      borderColor: `${event.reason_color}`,
                      background: `${event.reason_color}38`,
                      zIndex: `${isOpenEvent && event.id === currentEvent ? 3 : 1}`,
                      margin: `${
                        eventHeight(event.start, event.end) < 0.3 ? '7px 0px 0px -10px' : '-11px'
                      }`,
                      padding: `${dynamicEventPadding(event.start, event.end)}px`,
                      paddingLeft: '10px',
                    }}
                  >
                    <div
                      className="reason"
                      style={{
                        marginTop: `${eventHeight(event.start, event.end) < 0.3 ? -8 : 0}px`,
                        paddingTop: `${dynamicContentPadding(event.id, event.start, event.end)}px`,
                      }}
                    >
                      {event.reason_display} {dayjs(event?.start).format("HH:mm")}
                    </div>

                    {isOpenEvent && event.id === currentEvent && (
                      <>
                        <div className="client">
                          {event.first_name} {event.last_name}
                        </div>

                        <div className="doctor">
                          {event.doctor.first_name} {event.doctor.last_name}
                        </div>
                      </>
                    )}

                    {optionalClasses(event.from_start, event.start, index) ===
                      'day-several-events' && (
                      <div
                        className="amount-events"
                        style={{
                        }}
                      >
                        <span className="amount-content">
                          {`+${eventsInOneHour(event.from_start, index) - 1}`}
                        </span>
                      </div>
                    )}

                    <div
                      className="edd-button"
                      onClick={(e) => {
                        e.stopPropagation();
                        setCurrentEventFromSchedule(null)
                        onFreeDateClick(
                          CURRENT_DATE?.date,
                          dayjs(event.start).format("HH:mm"),
                        );
                      }}
                    >
                      <span className="edd-button_title">+</span>
                    </div>
                  </div>
                </li>
              ) : (
                ''
              ),
            )}
        </ul>
      </>
    );
  },
);
