import { ClientType } from '../../api/clients-api';
import { ClientsParamsType, UpdateClientsModelType } from './types';
import {
  ADD_CLIENT,
  EDIT_CLIENT,
  REMOVE_CLIENT,
  SET_APPEARED,
  SET_CLIENT_FROM_POPUP,
  SET_CLIENTS_LIST,
  SET_CLIENTS_PARAMS,
  SET_CURRENT_CLIENT,
  SET_CURRENT_CLIENT_ID,
  SET_CURRENT_PAGE,
  SET_STATE_CLIENT_POPUP,
} from './constants';

export const setClientsParamsAC = (module: ClientsParamsType) =>
  ({ type: SET_CLIENTS_PARAMS, module } as const);
export const setClientsListAC = (clients: Array<ClientType>) =>
  ({ type: SET_CLIENTS_LIST, clients } as const);
export const addClientAC = (model: ClientType) => ({ type: ADD_CLIENT, model } as const);
export const editClientAC = (model: UpdateClientsModelType) =>
  ({ type: EDIT_CLIENT, model } as const);
export const removeClientAC = (id: number) => ({ type: REMOVE_CLIENT, id } as const);
export const setAppearedAC = (model: ClientType) => ({ type: SET_APPEARED, model } as const);
export const setCurrentClientsPageAC = (page: string | null) =>
  ({ type: SET_CURRENT_PAGE, page } as const);
export const setCurrentClientIdAC = (clientId: number | null) =>
  ({ type: SET_CURRENT_CLIENT_ID, clientId } as const);
export const setCurrentClientAC = (client: ClientType | null) =>
  ({ type: SET_CURRENT_CLIENT, client } as const);
export const setStateClientsPopupAC = (isOpen: string | null) =>
  ({ type: SET_STATE_CLIENT_POPUP, isOpen } as const);
export const setClientFromPopupAC = (client: number | null) =>
  ({ type: SET_CLIENT_FROM_POPUP, client } as const);