/* eslint-disable */

import dayjs from 'dayjs';

/**
 * "calcScheduleGrid" takes "startTimeWorkDay" and "endTimeWorkDay" arguments.
 * Returns an object with an array of working hours "timeWorkDayFinally", a function that returns
 * the height of the hour and a function that returns the height of the first hour.
 * @param {string} startTimeWorkDay - start time of the working day
 * @param {string} endTimeWorkDay - end time of the working day
 * */
export const calcScheduleGrid = (startTimeWorkDay: string, endTimeWorkDay: string) => {
  // calculation of the grid depending on the start time of work and the end time of work
  const startTimeNumber = Number(startTimeWorkDay.slice(0, 2));
  const endTimeNumber = Number(endTimeWorkDay.slice(0, 2));

  const timeWorkDefault = [startTimeNumber];
  for (let i: any = startTimeNumber + 1; i <= endTimeNumber; i = i + 1) {
    timeWorkDefault.push(i);
  }

  const timeWorkDayString = timeWorkDefault.map((hour) =>
    hour < 10 ? `0${hour.toString()}:00` : `${hour}:00`,
  );
  const timeWorkDayEditStart = timeWorkDayString.map((hour, index) =>
    index === 0 ? startTimeWorkDay.slice(0, 5) : hour,
  );

  const timeWorkDay = timeWorkDayEditStart.map((hour) =>
    hour === timeWorkDayString[timeWorkDayString.length - 1] ? endTimeWorkDay.slice(0, 5) : hour,
  );

  const isLastHourRound = timeWorkDay[timeWorkDay.length - 1].slice(3, 5) === '00';

  const timeWorkDayFinally = isLastHourRound
    ? timeWorkDay.filter((element, index) => index < timeWorkDay.length - 1)
    : timeWorkDay;

  // sum of working hours
  const now = dayjs().format('YYYY-MM-DD');
  const end = new Date(`${now} ${endTimeWorkDay}`).getTime();
  const start = new Date(`${now} ${startTimeWorkDay}`).getTime();

  const sumHours = (end - start) / 3600000;

  // hour line height
  const fullHeight = 180;
  const fullTime = 60;
  const currentHeightLine = (minutes: number): number => {
    return minutes === 0 ? fullHeight : (fullHeight * minutes) / fullTime;
  };

  const currentHeightLineStart = (minutes: number): number => {
    return minutes === 0 ? fullHeight : (fullHeight * (fullTime - minutes)) / fullTime;
  };

  return {
    timeWorkDayFinally,
    currentHeightLine,
    currentHeightLineStart,
    sumHours,
  };
};