import React, { FC, useRef, useState } from 'react';
import { Controller } from 'react-hook-form';
import 'react-datepicker/dist/react-datepicker.css';
import DatePicker from 'react-datepicker';
import InputsWidgets from './InputsWidgets';
import './ReactDatePicker.scss';
import dayjs from 'dayjs';

type ReactSelectType = {
  error?: string | null;
  help_text?: string | null;
  label?: string;
  placeholder?: string;
  control: any;
  onClick?: () => void;
  onChange?: () => void;
  onFocus?: () => void;
  onInputClick?: () => void;
  name: string;
  id?: string;
  defaultValue?: any;
  onChangeText?: (value: string) => any;
  selectedDate: any;
  register?: any;
  input_type?: any;
  maxLenght?: any;
};

export const ReactDatePickerHoliday: FC<ReactSelectType> = ({
  control,
  onClick,
  error,
  defaultValue,
  help_text,
  label,
  name,
  maxLenght,
}) => {
  const componentRef = useRef();

  const [calendarIsOpen, setCalendarIsOpen] = useState(false);

  const onChangeHandler = (value: string, onChange: (...event: any[]) => void): void => {
    const date = new Date(value);
    if (dayjs(date).isValid() && date.getFullYear() >= 1940) {
      onChange(date);
    } else {
    }
  };

  const onClickHandler = (): void => {
    onClick && onClick();
    setCalendarIsOpen(!calendarIsOpen);
  };

  const changeStateCalendar = (): void => {
    setCalendarIsOpen(!calendarIsOpen);
  };

  const minDate = (): Date => new Date("1940-01-01");

  return (
    <div ref={componentRef as any} className="datePicker">
      <Controller
        name={name}
        control={control}
        render={({ field: { value, onChange } }) => (
          <DatePicker
            name={name}
            id={`id_${name}`}
            onChange={onChange}
            dateFormat="yyyy-MM-dd"
            minDate={minDate()}
            selected={value}
            open={calendarIsOpen}
            calendarStartDay={1}
            showYearDropdown
            yearDropdownItemNumber={100}
            scrollableYearDropdown
            onClickOutside={changeStateCalendar}
            onSelect={changeStateCalendar}
            popperPlacement={'bottom-end'}
            customInput={
              <InputsWidgets
                help_text={help_text}
                input_type={'date'}
                error={error}
                state={'datePicker'}
                name={name}
                maxLength={maxLenght}
                label={label}
                onInputClick={onClickHandler}
                defaultValue={defaultValue}
                onChangeText={(text) => {
                  onChangeHandler(text, onChange);
                }}
              />
            }
          />
        )}
      />
    </div>
  );
};
