import { AuthActionsType } from "../actions/types";
import { IS_LOGGED_IN, RESET_REDUCER_GROUP } from "../actions/constants";

const initialState = {
  isLoggedIn: false,
};

type InitialStateType = typeof initialState;

export const authReducer = (
  state: InitialStateType = initialState,
  action: AuthActionsType,
): InitialStateType => {
  switch (action.type) {
    case IS_LOGGED_IN:
      return { ...state, isLoggedIn: action.value };
    case RESET_REDUCER_GROUP:
      return { ...initialState };
    default:
      return state;
  }
};