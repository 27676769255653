import { Dispatch } from 'redux';
import { AppRootStateType } from '../store';
import {
  addCurrentDoctorFilterWaitingAC,
  addCurrentReasonColorFilterWaitingAC,
  addCurrentReasonFilterWaitingAC,
  addWaitingSearchParamAC,
  removeCurrentDoctorFilterWaitingAC,
  removeCurrentReasonColorFilterWaitingAC,
  removeCurrentReasonFilterWaitingAC,
  setAppStatusAC,
  setCurrWaitingRoomQueryStringAC,
  setListOfBranchFilterWaitingAC, setListWaitingRoomAC
} from "../actions";
import { waitingRoomAPI } from '../../api/waitingRoom-api';
import { handleServerAppError, handleServerNetworkError } from '../../utils/error-utils';
import { getQueryStringFromObject } from '../../utils/getQueryStringFromObject';
import { convertObjectToArray } from '../../utils/convertObjectToArray';
import { WaitingRoomQueryStringType } from './types';

export const fetchFilteredWaitingRoomParamsListTC =
  () => async (dispatch: Dispatch<any>, getState: () => AppRootStateType) => {
    const branchId = getState().branches.currentBranch;
    dispatch(setAppStatusAC('loading'));
    try {
      let res = await waitingRoomAPI.getFilteredListOfBranch(branchId);
      dispatch(setListOfBranchFilterWaitingAC(res.data));
      dispatch(setAppStatusAC('succeeded'));
    } catch (error: any) {
      if (error.message === 'Network Error') {
        handleServerNetworkError(error, dispatch);
      } else {
        handleServerAppError(error, dispatch);
      }
    }
  };

export const fetchFilteredWaitingListOfGeneral =
  (query?: string) => async (dispatch: Dispatch<any>, getState: () => AppRootStateType) => {
    const branch = getState().branches.currentBranch;
    const reason__in = getState().waitingRoomFilter.currentFilterParams.reason__in;
    const color = getState().waitingRoomFilter.currentFilterParams.color;
    const doctor__in = getState().waitingRoomFilter.currentFilterParams.doctor__in;
    const search = getState().waitingRoomFilter.currentFilterParams.search;

    // query string
    const currentQueryParams = getState().waitingRoomFilter.currentQueryStringWaitingRoomList;
    const newQueryParams = getQueryStringFromObject({
      branch,
      reason__in,
      color,
      doctor__in,
      search,
    } as unknown as WaitingRoomQueryStringType);

    const queryParams = query ? currentQueryParams : newQueryParams;

    dispatch(setAppStatusAC('loading'));
    try {
      const res = await waitingRoomAPI.getFilteredListOfGeneral(branch, queryParams);
      dispatch(setListWaitingRoomAC(res.data, branch));
      dispatch(setCurrWaitingRoomQueryStringAC(queryParams));
      dispatch(setAppStatusAC('succeeded'));
    } catch (error: any) {
      if (error.message === 'Network Error') {
        handleServerNetworkError(error, dispatch);
      } else {
        handleServerAppError(error, dispatch);
      }
    }
    dispatch(setAppStatusAC('idle'));
  };

export const setFilteredOfDoctorTC =
  (currentDoctor: string) => async (dispatch: Dispatch<any>, getState: () => AppRootStateType) => {
    const currentDoctorChecked = getState().waitingRoomFilter.currentFilterParams.doctor__in.find(
      (status) => status === currentDoctor,
    );
    try {
      if (currentDoctorChecked) {
        dispatch(removeCurrentDoctorFilterWaitingAC(currentDoctor));
      } else {
        dispatch(addCurrentDoctorFilterWaitingAC(currentDoctor));
      }
      dispatch(fetchFilteredWaitingListOfGeneral());
    } catch (error: any) {
      if (error.message === 'Network Error') {
        handleServerNetworkError(error, dispatch);
      } else {
        handleServerAppError(error, dispatch);
      }
    }
  };

export const setFilteredWaitingOfReasonTC =
  (currentReason: string) => async (dispatch: Dispatch<any>, getState: () => AppRootStateType) => {
    const currentReasonChecked = getState().waitingRoomFilter.currentFilterParams.reason__in.find(
      (reason) => reason === currentReason,
    );
    const currentColor = convertObjectToArray(
      getState().waitingRoomFilter.waitingRoomFilterParamsList.color.choices,
    ).find((color) => color.name === currentReason)?.params;
    try {
      if (currentReasonChecked) {
        dispatch(removeCurrentReasonFilterWaitingAC(currentReason));
        dispatch(removeCurrentReasonColorFilterWaitingAC(currentColor));
      } else {
        dispatch(addCurrentReasonFilterWaitingAC(currentReason));
        dispatch(addCurrentReasonColorFilterWaitingAC(currentColor));
      }
      dispatch(fetchFilteredWaitingListOfGeneral());
    } catch (error: any) {
      if (error.message === 'Network Error') {
        handleServerNetworkError(error, dispatch);
      } else {
        handleServerAppError(error, dispatch);
      }
    }
  };
export const setFilteredWaitingSearchTC = (search: string) => async (dispatch: Dispatch<any>) => {
  try {
    dispatch(addWaitingSearchParamAC(search));
    dispatch(fetchFilteredWaitingListOfGeneral());
  } catch (error: any) {
    if (error.message === 'Network Error') {
      handleServerNetworkError(error, dispatch);
    } else {
      handleServerAppError(error, dispatch);
    }
  }
};