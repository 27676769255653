import React, { FC, memo, useCallback, useEffect, useMemo, useRef, useState } from 'react';
import './Branches.scss';
import { Navigate, NavLink } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { PATH } from '../../routes/Routes';
import { ConfirmationPopup } from '../../components/Popups/ConfirmationPopup/ConfirmationPopup';
import Preloader from '../../components/Preloaders/Preloader';
import { Iframe } from './Iframe/Iframe';
import {
  getBranchesSelector,
  getIsLoggedInSelector,
  getOpenSidebarAppSelector,
  getStatusSelector,
  getUserProfileSelector,
} from '../../redux/selectors';
import { useTranslation } from 'react-i18next';
import { logo } from '../../assets/img/image';
import { ActionMenuBranches } from './ActionMenuBranches/ActionMenuBranches';
import { setPreloader } from '../../redux/selectors/BranchesSelectors';
import PreloaderEvent from '../../components/Preloaders/EventsPreload/PreloadEvents';
import TranslationKeys from '../../assets/locales/translationKeys/translationKeys';
import { ViewDoctors } from '../../components/ComponentsForLists/ViewDoctors/ViewDoctors';
import { fetchBranchesList, initializeAppTC, removeBranchTC } from "../../redux/middlewares";
import { editStateMenuAC } from "../../redux/actions";

export const Branches: FC = memo(() => {
  const dispatch = useDispatch();

  const branches = useSelector(getBranchesSelector);
  const isLoggedIn = useSelector(getIsLoggedInSelector);
  const status = useSelector(getStatusSelector);
  const user = useSelector(getUserProfileSelector);
  const isOpenSidebar = useSelector(getOpenSidebarAppSelector);
  const preloader = useSelector(setPreloader);

  //dialog alerts
  const [openAlertDialogForDelete, setOpenAlertDialogForDelete] = useState(false);
  const [openAlertDialogForIframe, setOpenAlertDialogForIframe] = useState(false);
  const [options, setOptions] = useState(false);
  const [load, setLoad] = useState(false);
  const [modalDataId, setModalDataId] = useState<number>(0);
  const [selectedIndex, setSelectedIndex] = useState(0);

  //locale
  const { t } = useTranslation();

  const escFunction = useCallback((event) => {
    if (event.key === 'Escape') {
      setOpenAlertDialogForDelete(false);
      setOpenAlertDialogForIframe(false);
    }
  }, []);

  useEffect(() => {
    document.addEventListener('keydown', escFunction, false);

    return () => {
      document.removeEventListener('keydown', escFunction, false);
    };
  }, []);

  const componentRef = useRef();

  useMemo(() => {
    const handleClick = (e: any): void => {
      if (componentRef && componentRef.current) {
        const ref: any = componentRef.current;
        if (!ref.contains(e.target)) {
          setOptions(false);
        }
      }
    };
    document.addEventListener('click', handleClick);
    return () => document.removeEventListener('click', handleClick);
  }, [dispatch]);

  useEffect(() => {
    if (status === 'failed') {
      dispatch(initializeAppTC());
    }
  }, [status]);

  useEffect(() => {
    if (isLoggedIn) {
      dispatch(fetchBranchesList());
    }
  }, [dispatch]);

  useEffect(() => {
    document.title = t('branches_nav');
  }, []);

  useEffect(() => {
    const remove = new Image();
    remove.src = logo.trash;
    remove.onload = () => {
      setLoad(true);
    };
    const img = new Image();
    img.src = logo.edit;
    img.onload = () => {
      setLoad(true);
    };

    const iframe = new Image();
    iframe.src = logo.iframe;
    iframe.onload = () => {
      setLoad(true);
    };
  });

  const removeBranch = (id: number): void => {
    dispatch(removeBranchTC(id));
    setModalDataId(0);
  };

  const openAlertHandler = (clientId: number): void => {
    setModalDataId(clientId);
    setOpenAlertDialogForDelete(true);
  };

  const closeAlertHandler = (): void => {
    setOpenAlertDialogForDelete(false);
  };

  const openAlertHandlerIframe = (clientId: number): void => {
    setModalDataId(clientId);
    setOpenAlertDialogForIframe(true);
  };

  const closeAlertHandlerIframe = (): void => {
    setOpenAlertDialogForIframe(false);
  };

  const onClickOptions = (branchId: number): void => {
    dispatch(editStateMenuAC(false));
    if (options) {
      if (branchId === selectedIndex) {
        setOptions(false);
      } else {
        setOptions(true);
      }
    } else {
      setOptions(true);
    }
  };

  const onLoadHandler = (): void => {
    setLoad(true);
  };

  if (!isLoggedIn) {
    return <Navigate to={PATH.LOGIN} />;
  }

  return (
    <>
      <div ref={componentRef as any}></div>
      <div className={`${isOpenSidebar ? 'branches' : 'branches full-width'}`}>
        <div className="branches__header">
          <h1 className="branches__header__title">{t(TranslationKeys.branch_list)}</h1>
          <div className="branches__header__add">
            {user.role !== 'doctor' && user.role !== 'employee' ? (
              <NavLink
                className="button button_size-small button_position-left button_color-transparent"
                to={PATH.ADD_BRANCH}
              >
                + {t(TranslationKeys.branch_btn_add)}
              </NavLink>
            ) : (
              ''
            )}
          </div>
        </div>

        <div>
          <table className="branches__table">
            <thead className="thead">
              <tr className="tr">
                <th className="th">
                  <span>{t(TranslationKeys.name)}</span>
                </th>

                <th className="th">
                  <span className="th_title">{t(TranslationKeys.doctor)}</span>
                </th>

                <th className="th">
                  <span>{t(TranslationKeys.address)}</span>
                </th>
                {user.role !== 'doctor' ? (
                  <th
                    style={{
                      width: '170px',
                    }}
                    className="th"
                  >
                    <span>{t(TranslationKeys.options)}</span>
                  </th>
                ) : (
                  ''
                )}
              </tr>
            </thead>

            {branches.length === 0 && preloader ? (
              <tbody>
                <tr>
                  <td>
                    <Preloader />
                  </td>
                </tr>
              </tbody>
            ) : (
              preloader && (
                <tbody>
                  <tr>
                    <td>
                      <PreloaderEvent />
                    </td>
                  </tr>
                </tbody>
              )
            )}

            <tbody className="tbody">
              {branches.length > 0 &&
                branches.map((branch) => {
                  return (
                    <tr key={branch.id} className="tr">
                      <td className={user.role !== 'doctor' ? 'td-name' : 'td-name-doc'}>
                        {branch.name}{' '}
                      </td>

                      <td className="td td-doc">
                        <ViewDoctors client={branch} />
                      </td>

                      <td className={user.role !== 'doctor' ? 'td' : 'td-address-doc'}>
                        {`${branch.street} ${branch.street_number},`}
                        <p className="address">
                          {branch.zip_code} {branch.sity}
                        </p>
                      </td>
                      {user.role !== 'doctor' && (
                        <td
                          onClick={(e) => {
                            e.stopPropagation();
                            setSelectedIndex(branch.id);
                            onClickOptions(branch.id);
                          }}
                          className={'td td-btns'}
                        >
                          <ActionMenuBranches
                            branch={branch}
                            options={options}
                            onClickOptions={onClickOptions}
                            selectedIndex={selectedIndex}
                            setSelectedIndex={setSelectedIndex}
                            onLoadHandler={onLoadHandler}
                            load={load}
                            setOptions={setOptions}
                            openAlertHandler={openAlertHandler}
                            openAlertHandlerIframe={openAlertHandlerIframe}
                          />
                        </td>
                      )}
                    </tr>
                  );
                })}
              {openAlertDialogForDelete && (
                <>
                  <ConfirmationPopup
                    actionCallback={removeBranch}
                    id={modalDataId}
                    titleQuestion={t('branch_delete')}
                    actionTitle={t('delete')}
                    onClickBtn={closeAlertHandler}
                    cancelCallback={closeAlertHandler}
                  />
                </>
              )}
            </tbody>
          </table>
        </div>
        {/*    )} */}
        {openAlertDialogForIframe && (
          <Iframe
            onClickBtn={() => setOpenAlertDialogForIframe(false)}
            cancelCallback={closeAlertHandlerIframe}
            branchId={modalDataId}
          />
        )}
      </div>
    </>
  );
});
