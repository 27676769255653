import { EventDetailType, EventsResponseData, EventType } from '../../api/events-api';
import {
  ADD_EVENT,
  CANCEL_ORDER_EVENT,
  CHANGE_STATE_MODAL_WAITING,
  CHANGE_STATUS_EVENTS,
  EDIT_EVENT,
  REMOVE_EVENT,
  RESET_REDUCER_GROUP,
  SET_CHOSEN_DAY_SCHEDULE,
  SET_CURRENT_EVENT_DETAILS,
  SET_CURRENT_EVENT_SCHEDULE,
  SET_CURRENT_ORDERING_EVENTS,
  SET_CURRENT_PAGE_EVENTS,
  SET_EVENTS_LIST,
  SET_EVENTS_PARAMS,
  SET_SELECTED_EVENT,
} from '../actions/constants';
import { EventsInitialStateType } from './types';
import { EventsActionsType } from '../actions/types';

const initialState: EventsInitialStateType = {
  chosenDayInSchedule: null,
  currentOrdering: null,
  currentPage: null,
  currentDetails: {} as EventDetailType,
  currentEventFromSchedule: null,
  isOpenModalWaitingRoom: false,
  selectedEvent: null,
  events: {
    links: {
      next: null,
      previous: null,
    },
    count: 0,
    current_page: 0,
    lastPage: 0,
    current: 0,
    results: [] as Array<EventType>,
  } as EventsResponseData,
};

export const eventsReducer = (
  state: EventsInitialStateType = initialState,
  action: EventsActionsType,
): EventsInitialStateType => {
  switch (action.type) {
    case SET_EVENTS_LIST: {
      return {
        ...state,
        events: { ...state.events, results: action.events.map((tl) => ({ ...tl })) },
      };
    }

    case SET_EVENTS_PARAMS: {
      return { ...state, events: { ...state.events, ...action.module } };
    }

    case ADD_EVENT: {
      return {
        ...state,
        events: { ...state.events, results: [...state.events.results, action.event] },
      };
    }

    case EDIT_EVENT: {
      return {
        ...state,
        events: {
          ...state.events,
          results: state.events.results.map((e) =>
            e.id === action.model.id ? { ...e, ...action.model } : e,
          ),
        },
      };
    }

    case REMOVE_EVENT: {
      return {
        ...state,
        events: {
          ...state.events,
          results: state.events.results.filter((e) => e.id !== action.id),
        },
      };
    }

    case CHANGE_STATUS_EVENTS: {
      return {
        ...state,
        events: {
          ...state.events,
          results: state.events.results.map((e) =>
            e.id === action.eventId
              ? {
                  ...e,
                  status: action.event.status,
                  status_display: action.event.status_display,
                }
              : e,
          ),
        },
      };
    }

    case SET_CURRENT_ORDERING_EVENTS: {
      return { ...state, currentOrdering: action.ordering };
    }

    case SET_CURRENT_PAGE_EVENTS: {
      return { ...state, currentPage: action.page };
    }

    case SET_CURRENT_EVENT_DETAILS: {
      return { ...state, currentDetails: action.details };
    }

    case CANCEL_ORDER_EVENT: {
      return {
        ...state,
        events: {
          ...state.events,
          results: state.events.results.map((e) =>
            e.id === action.event.id ? { ...e, ...action.event } : e,
          ),
        },
      };
    }

    case SET_CHOSEN_DAY_SCHEDULE: {
      return { ...state, chosenDayInSchedule: action.day };
    }

    case SET_CURRENT_EVENT_SCHEDULE: {
      return { ...state, currentEventFromSchedule: action.event };
    }

    case CHANGE_STATE_MODAL_WAITING: {
      return { ...state, isOpenModalWaitingRoom: action.value };
    }

    case SET_SELECTED_EVENT: {
      return { ...state, selectedEvent: action.value };
    }

    case RESET_REDUCER_GROUP:
      return { ...initialState };

    default:
      return state;
  }
};