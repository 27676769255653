import TranslationKeys from '../translationKeys/translationKeys';

let Deutsch = { ...TranslationKeys };

Deutsch = {
  address: 'Adresse',
  advanced_setting: "Erweiterte Einstellungen",
  all_day: 'Den ganzen Tag',
  at_the_doctor: 'At the doctor',

  begin: 'Start',
  booking_success: 'Ihre Buchung ist erfolgreich',
  branch: 'Praxis',
  branches_nav: 'Standorte',
  branch_btn_add: 'Praxis hinzufügen',
  branch_delete: 'Praxis löschen?',
  branch_form_add: 'Praxis hinzufügen',
  branch_form_edit: 'Praxis bearbeiten',
  branch_list: 'Standortliste',
  branch_for_title: 'Auswahl der Praxis',

  cancel: 'Storniert',
  chose: 'wähle eine Option',
  click_conformation:
    'Klicken Sie auf den Bestätigungslink in der E-Mail, um Ihr Konto zu aktivieren',
  client_btn_add: 'Kunden hinzufügen',
  client: 'Kunde',
  client_data: 'Kundendaten',
  client_history: 'Kundenhistorie',
  client_form_add: 'Kunden hinzufügen',
  client_form_edit: 'Kunden bearbeiten',
  clients_header: 'Kunden',
  client_list: 'Kundenliste',
  client_not_appeared: 'Nicht erschienen',
  client_not_appeared_btn: 'Nicht erschienen',
  contact_delete: 'Labor löschen?',
  contact_add_btn: 'Labor hinzufügen',
  contact_form_add: 'Labor hinzufügen',
  contact_form_edit: 'Labor bearbeiten',
  contacts_nav: 'Labore',
  copy: 'Kopieren',
  copy_script_text_message: 'Skript in die Zwischenablage kopiert',
  create_acc: 'Ihr Konto wurde erstellt',
  current_branch: 'Aktuellen praxis',

  data_time: 'Datum/Zeit',
  data_time_desc: 'Datum/Zeit (absteigend)',
  date: 'Datum',
  date_birth: 'Geburtsdatum',
  de: "Deutsch",
  delete: 'Löschen',
  desc_name: 'Vorname (absteigend)',
  desc_reason: 'Besuchsgrund (absteigend)',
  details: 'Details',
  did_not_wait: 'Did not wait',
  doctor: 'Zahnarzt',
  done: 'Erledigt',
  duration: 'Termindauer',

  edit: 'Bearbeiten',
  email: 'E-Mail-Adresse',
  email_short: 'E-Mail',
  employees_info: 'Vor- / Nachname',
  employees_setting: 'Zugang / Rolle',
  employees_nav: 'Mitarbeiterinnen',
  employees_list: 'Mitarbeiter',
  employee_form_add: 'Mitarbeiter hinzufügen',
  employee_delete: 'Mitarbeiter löschen?',
  employee_form_edit: 'Mitarbeiter bearbeiten',
  employee_btn_add: 'mitarbeiter hinzufügen',
  en: 'Englisch',
  end: 'Ende',
  end_alert: 'Ende',
  event_data: 'Ereignisdaten',
  event_header: 'Termine',
  event_list: 'Liste Ihrer Termine',
  event_btn_Add: 'Termin hinzufügen',
  event_btn_add: 'Termin hinzufügen',
  event_form_add: 'Termin hinzufügen',
  event_form_edit: 'Termin edit',
  event_cancel: 'Termin stornieren?',
  event_did_not_come: 'Nicht gekommen',
  event_at_the_doctor: 'Beim Zahnarzt',
  event_did_not_wait: 'Gegangen',

  failed: 'Gescheitert',
  full_name: 'Vor- und Nachnamen',

  gender: 'Geschlecht',

  holiday_btn_add: 'Urlaubs hinzufügen',
  holiday_delete: 'Urlaub löschen?',
  holiday_form_add: 'Urlaubs hinzufügen',
  holiday_form_edit: 'Urlaub bearbeiten',
  holiday_list: 'Urlaubsliste',
  holidays_nav: 'Urlaubsplanung',
  holiday_official: 'Gesetzliche Feiertage',
  holiday_private: 'Persönliche Feiertage',
  holiday_suggestion: 'Vorschläge',
  home_btn: 'Startseite',

  iframe: "Iframe",
  info: 'Information',
  insurance: 'Versicherung',
  incurance_desc: 'Versicherung (absteigend)',

  join: 'Beitreten',

  language: 'Sprache',
  leave: 'Verlassen',
  login: 'Login',
  logout_nav: 'Abmelden',
  lunch: 'Pause',
  lunch_alert: 'Pause',

  material: "Material",

  name: 'Vorname',
  not_found: 'Leider können wir deine Seite nicht finden.',

  options: 'Options',
  order_btn_add: 'Laborauftrag hinzufügen',
  order_delete: 'Labor löschen?',
  order_download: 'Download',
  order_edit: 'Laborauftrag bearbeiten',
  order_form_add: 'Laborauftrag hinzufügen',
  orders_header: 'Labor',
  order_list: 'Auftragsliste',
  order_second_chance: 'Übersicht',

  password: 'Passwort',
  phone_number: 'Telefon',
  phone_number_short: 'Tel',
  profile_first_name: 'Vorname',
  profile_last_name: 'Nachname',
  profile_nav: 'Profil',
  profile_personal_info: 'Persönliche Informationen',

  reasonst_list: 'Liste der Gründe',
  reasons_nav: 'Besuchsgründe',
  reason: 'Besuchsgrund',
  reason_btn_add: 'Besuchsgrund hinzufügen',
  reason_form_add: 'Besuchsgrund hinzufügen',
  reason_delete: 'Besuchsgrund löschen?',
  reason_form_edit: 'Besuchsgründe bearbeiten',
  register: 'Anmelden',

  saved: 'Gesichert',
  setting: 'Einstellungen',
  // shedule_name: 'Kalender',
  shedule_name: 'Kalender',
  shedule_time: 'Zeit',
  shedule_edit: 'Kalender bearbeiten',
  sign_up: 'Registrieren',
  sort: 'Sortieren nach:',
  start: 'Start',
  start_alert: 'Start',
  status: 'Status',
  status_desc: 'Status (absteigend)',
  submit: 'Sichern',

  title: 'Titel',

  user: 'Benutzer',
  user_not_foud: 'Benutzer nicht gefunden',

  waiting_header: 'Wartezimmer',
  waiting: 'Im Warteraum',
  waiting_period: 'Wartezeit',
  weekend: 'Wochenende',
  weekend_alert: 'Wochenende',
  welcome: 'Willkommen im Wartezimmer!',
  work_shedule_nav: 'Arbeitszeiten',
  work_time: 'Arbeitszeit',

  yes: 'Ja',
};

export default Deutsch;
