/* eslint-disable */
import dayjs from 'dayjs';

/**
 * "calcScheduleGridSmall" takes "startTimeWorkDay" and "endTimeWorkDay" arguments.
 * Returns an object with an array of working hours "timeWorkDayFinally", a function that returns
 * the height of the hour,a function that returns the height of the first hour,
 * hour height "fullHeight" and a sum of working hours "sumHours".
 * @param {string} startTimeWorkDay - start time of the working day
 * @param {string} endTimeWorkDay - end time of the working day
 * */
export const calcScheduleGridSmall = (startTimeWorkDay: string, endTimeWorkDay: string) => {
  // calculation of the grid depending on the start time of work and the end time of work
  const startTimeNumber = Number(startTimeWorkDay.slice(0, 2));
  const endTimeNumber = Number(endTimeWorkDay.slice(0, 2));

  const timeWorkDefault = [startTimeNumber];
  for (let i: any = startTimeNumber + 1; i <= endTimeNumber; i = i + 1) {
    timeWorkDefault.push(i);
  }

  const timeWorkDayString = timeWorkDefault.map((hour) =>
    hour < 10 ? `0${hour.toString()}:00` : `${hour}:00`,
  );
  const timeWorkDayEditStart = timeWorkDayString.map((hour, index) =>
    index === 0 ? startTimeWorkDay.slice(0, 5) : hour,
  );

  const timeWorkDay = timeWorkDayEditStart.map((hour) =>
    hour === timeWorkDayString[timeWorkDayString.length - 1] ? endTimeWorkDay.slice(0, 5) : hour,
  );

  const isLastHourRound = timeWorkDay[timeWorkDay.length - 1].slice(3, 5) === '00';

  const timeWorkDayFinally = isLastHourRound
    ? timeWorkDay.filter((element, index) => index < timeWorkDay.length - 1)
    : timeWorkDay;

  // sum of working hours
  const now = dayjs().format('YYYY-MM-DD');
  const end = new Date(`${now} ${endTimeWorkDay}`).getTime();
  const start = new Date(`${now} ${startTimeWorkDay}`).getTime();

  const sumHours = (end - start) / 3600000;

  const fullHeight = 100;
  const fullTime = 60;

  const currentHeightLine = (minutes: number): number =>
    minutes === 0 ? fullHeight : (fullHeight * minutes) / fullTime;

  const currentHeightLineStart = (minutes: number): number => fullHeight;
  // minutes === 0 ? fullHeight : (fullHeight * (fullTime - minutes)) / (fullTime / 1.25);

  const dynamicHeightFirstHour = (hour: string): string => {
    const minutes = Number(hour.slice(3, 5))
    if (minutes !== 0) {
      return `((${currentHeightLineStart(
        minutes,
      )}vh - 251px) / ${sumHours}) * ((60 - ${minutes}) / 60)`;
    } else {
      return `((${currentHeightLineStart(
        minutes,
      )}vh - 251px) / ${sumHours})`;
    }
  };

  return {
    timeWorkDayFinally,
    currentHeightLine,
    currentHeightLineStart,
    fullHeight,
    sumHours,
    dynamicHeightFirstHour,
  };
};
