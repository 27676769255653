import { Dispatch } from 'redux';
import { AppRootStateType } from '../store';
import {
  addHolidayAC,
  editHolidayAC,
  joinHolidaysAC,
  leaveHolidaysAC,
  removeHolidayAC,
  setAppStatusAC,
  setListHolidaysAC,
} from '../actions';
import { holidaysAPI } from '../../api/holidays-api';
import { handleServerAppError, handleServerNetworkError } from '../../utils/error-utils';

export const fetchHolidaysList =
  () => async (dispatch: Dispatch<any>, getState: () => AppRootStateType) => {
    const currentBranch = getState().branches.currentBranch;
    dispatch(setAppStatusAC('loading'));
    try {
      const res = await holidaysAPI.getList(currentBranch);
      dispatch(setListHolidaysAC(res.data));
      dispatch(setAppStatusAC('succeeded'));
    } catch (error: any) {
      if (error.message === 'Network Error') {
        handleServerNetworkError(error, dispatch);
      } else {
        handleServerAppError(error, dispatch);
      }
    }
    dispatch(setAppStatusAC('idle'));
  };

export const joinHolidayTC =
  (holidayId: number) => async (dispatch: Dispatch<any>, getState: () => AppRootStateType) => {
    const currentBranch = getState().branches.currentBranch;
    try {
      const res = await holidaysAPI.joinToHoliday(holidayId, currentBranch);
      dispatch(joinHolidaysAC(res.data));
      dispatch(setAppStatusAC('succeeded'));
    } catch (error: any) {
      if (error.message === 'Network Error') {
        handleServerNetworkError(error, dispatch);
      } else {
        handleServerAppError(error, dispatch);
      }
    }
    dispatch(setAppStatusAC('idle'));
  };

export const leaveHolidayTC =
  (holidayId: number) => async (dispatch: Dispatch<any>, getState: () => AppRootStateType) => {
    const currentBranchId = getState().branches.currentBranch;
    try {
      await holidaysAPI.leaveHoliday(holidayId, currentBranchId);
      dispatch(leaveHolidaysAC(holidayId));
      dispatch(setAppStatusAC('succeeded'));
    } catch (error: any) {
      if (error.message === 'Network Error') {
        handleServerNetworkError(error, dispatch);
      } else {
        handleServerAppError(error, dispatch);
      }
    }
    dispatch(setAppStatusAC('idle'));
  };

export const addHolidayTC = (data: any) => async (dispatch: Dispatch<any>) => {
  dispatch(setAppStatusAC('loading'));
  try {
    let res = await holidaysAPI.addHoliday(data);
    await dispatch(addHolidayAC(res.data));
    await dispatch(setAppStatusAC('succeeded'));
  } catch (error: any) {
    if (error.message === 'Network Error') {
      handleServerNetworkError(error, dispatch);
    } else {
      handleServerAppError(error, dispatch);
    }
  }
};

export const editHolidayTC = (id: number, data: any) => async (dispatch: Dispatch<any>) => {
  dispatch(setAppStatusAC('loading'));
  try {
    let res = await holidaysAPI.editHoliday(data, id);
    dispatch(editHolidayAC(res.data));
    dispatch(setAppStatusAC('succeeded'));
  } catch (error: any) {
    if (error.message === 'Network Error') {
      handleServerNetworkError(error, dispatch);
    } else {
      handleServerAppError(error, dispatch);
    }
  }
};

export const removeHolidayTC = (holidayId: number) => async (dispatch: Dispatch<any>) => {
  dispatch(setAppStatusAC('loading'));
  try {
    await holidaysAPI.removeHoliday(holidayId);
    dispatch(removeHolidayAC(holidayId));
    dispatch(setAppStatusAC('succeeded'));
  } catch (error: any) {
    if (error.message === 'Network Error') {
      handleServerNetworkError(error, dispatch);
    } else {
      handleServerAppError(error, dispatch);
    }
  }
  dispatch(setAppStatusAC('idle'));
};