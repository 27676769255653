import React, { FC, useEffect, useMemo, useState } from 'react';
import { SubmitHandler, useForm } from 'react-hook-form';
import './FormEvent.scss';
import '../../../components/Widgets/Button/Button.scss';
import { useDispatch, useSelector } from 'react-redux';
import { Navigate, useLocation, useParams } from 'react-router-dom';
import { PATH } from '../../../routes/Routes';
import {
  defaultChoicesSelect,
  newOption,
} from '../../../components/Widgets/Select/ReactSelectStyles';
import Textarea from '../../../components/Widgets/Textarea';
import { ReactSelect } from '../../../components/Widgets/Select/ReactSelect';
import dayjs from 'dayjs';
import { ReactDateTimePicker } from '../../../components/Widgets/ReactDateTimePicker';
import { useErrorHandler } from '../../../hooks/useErrorHandler';
import {
  getChosenDaySelector,
  getClientFromPopupSelector,
  getClientsSelector,
  getCurrentBranchSelector,
  getCurrentClientIdSelector,
  getCurrentEventFromSchedule,
  getCurrentPathSelector,
  getCurrEventsQueryStringAppSelector,
  getErrorStateSelector,
  getEventsSelector,
  getFieldsEventsSelector,
  getIsLoggedInSelector,
  getOpenSidebarAppSelector,
  getStatusClientPopupSelector,
  getStatusSelector,
  getWaitingRoomSelector,
} from '../../../redux/selectors';
import { ErrorMessage } from '../../../components/ErrorMessage/ErrorMessage';
import { useTranslation } from 'react-i18next';
import TranslationKeys from '../../../assets/locales/translationKeys/translationKeys';
import { AddClientPopup } from '../../Clients/AddClientPopup/AddClientPopup';
import { logo } from '../../../assets/img/image';
import {
  setAppErrorAC,
  setAppStatusAC,
  setChosenDayInScheduleAC,
  setClientFromPopupAC,
  setCurrentClientIdAC,
  setCurrentPathAC,
  setStateClientsPopupAC,
} from '../../../redux/actions';
import {
  addEventTC,
  editEventTC,
  fetchEventsListFieldsTC,
  fetchFilteredEventsListOfGeneral,
  fetchFilteredWaitingListOfGeneral,
} from '../../../redux/middlewares';

type FormDataType = {
  reason: { value: any; label: any };
  client: { value: any; label: any };
  start: string | Date;
  message: string;
  status: { value: any; label: any };
  doctor: { value: any; label: any };
  note: string;
};

export const FormEvent: FC = () => {
  const dispatch = useDispatch();
  const params = useParams();
  const location = useLocation();

  const errorState = useSelector(getErrorStateSelector);
  const status = useSelector(getStatusSelector);
  const isLoggedIn = useSelector(getIsLoggedInSelector);
  const currentBranch = useSelector(getCurrentBranchSelector);
  const events = useSelector(getEventsSelector);
  const chosenDay = useSelector(getChosenDaySelector);
  const currentPath = useSelector(getCurrentPathSelector);
  const fields = useSelector(getFieldsEventsSelector);
  const isOpenSidebar = useSelector(getOpenSidebarAppSelector);
  const currentEventFromSchedule = useSelector(getCurrentEventFromSchedule);
  const currentClientRedux = useSelector(getCurrentClientIdSelector);
  const isOpenClientPopup = useSelector(getStatusClientPopupSelector);
  const selectedClientFromPopup = useSelector(getClientFromPopupSelector);
  const clients = useSelector(getClientsSelector);
  const currentQueryString = useSelector(getCurrEventsQueryStringAppSelector);
  const waitingRoom = useSelector(getWaitingRoomSelector);

  const [valueEvent, setValueEvent] = useState<boolean>(false);
  const [currentClientForPopup, setCurrentClientForPopup] = useState<any>(null);

  //locale
  const { t } = useTranslation();

  //for edit event
  const idUrl = params.id;
  const eventId = Number(idUrl);

  const eventsFromWaitingRoom = waitingRoom.waiting_room.find((event) => event.id === eventId);
  const eventsFromAtTheDoctor = waitingRoom.at_the_doctor.find((event) => event.id === eventId);
  const eventsWaitingRoom = eventsFromWaitingRoom || eventsFromAtTheDoctor;

  const isCurrentEventFromSchedule = currentEventFromSchedule
    ? currentEventFromSchedule
    : events.length && events.find((event) => event.id === eventId);

  const isCurrentEventFromWaitingRoom = eventsWaitingRoom
    ? eventsWaitingRoom
    : events.length && events.find((event) => event.id === eventId);

  const currentEvent = isCurrentEventFromWaitingRoom
    ? isCurrentEventFromWaitingRoom
    : isCurrentEventFromSchedule;

  const chosenDateEdit = chosenDay && `${chosenDay.date.slice(0, 10)}T${chosenDay.time}`;
  const startTimeDefaultValue = chosenDay
    ? chosenDateEdit
    : currentEvent
    ? currentEvent.start
    : fields.start.initial_value;

  const [selectedDate] = useState<any>(new Date());

  // validation
  const formDataArray = ['client', 'status', 'reason', 'start', 'message', 'doctor', 'note'];

  const statusErrorHandler = useErrorHandler('status', errorState, formDataArray);
  const reasonErrorHandler = useErrorHandler('reason', errorState, formDataArray);
  const clientErrorHandler = useErrorHandler('client', errorState, formDataArray);
  const startErrorHandler = useErrorHandler('start', errorState, formDataArray);
  const messageErrorHandler = useErrorHandler('message', errorState, formDataArray);
  const doctorErrorHandler = useErrorHandler('doctor', errorState, formDataArray);
  const noteErrorHandler = useErrorHandler('note', errorState, formDataArray);

  const choicesReason = Object.entries(fields.reason.choices).map((br) => br);
  const valueOptionReason =
    fields && Object.entries(fields.reason.choices).map((b: any) => newOption(b[0], b[1]));
  const labelOptionReason = currentEvent && currentEvent.reason.toString();

  const choicesClient = Object.entries(fields.client.choices).map((br) => br);
  const valueOptionClient =
    fields && Object.entries(fields.client.choices).map((b: any) => newOption(b[0], b[1]));
  const labelOptionClient = currentClientRedux
    ? currentClientRedux.toString()
    : currentEvent && currentEvent.client.id.toString();
  const labelOptionSelectedClient = selectedClientFromPopup && selectedClientFromPopup.toString();

  const choicesStatus = Object.entries(fields.status.choices).map((br) => br);
  const valueOptionStatus =
    fields && Object.entries(fields.status.choices).map((b: any) => newOption(b[0], b[1]));
  const labelOptionStatus = currentEvent && currentEvent.status.toString();
  const labelOptionStatusDefault = fields?.status?.initial_value?.toString();
  const labelOptionStatusForWaiting = 'waiting_room';

  const choicesDoctor = Object.entries(fields.doctor.choices).map((br) => br);
  const valueOptionDoctor =
    fields && Object.entries(fields.doctor.choices).map((b: any) => newOption(b[0], b[1]));
  const labelOptionDoctor = currentEvent && currentEvent.doctor.id?.toString();

  const eventDefaultData = currentEvent
    ? {
        client: selectedClientFromPopup
          ? defaultChoicesSelect(valueOptionClient, labelOptionSelectedClient)[0]
          : currentEvent || currentClientRedux
          ? defaultChoicesSelect(valueOptionClient, labelOptionClient)[0]
          : undefined,
        reason:
          currentEvent.reason && defaultChoicesSelect(valueOptionReason, labelOptionReason)[0],
        start: startTimeDefaultValue && new Date(startTimeDefaultValue),
        status: currentEvent && defaultChoicesSelect(valueOptionStatus, labelOptionStatus)[0],
        message: currentEvent.message,
        doctor: currentEvent && defaultChoicesSelect(valueOptionDoctor, labelOptionDoctor)[0],
        note: currentEvent.note,
      }
    : {
        client: defaultChoicesSelect(valueOptionClient, labelOptionSelectedClient)[0],
        status:
          currentPath === PATH.WAITING_ROOM
            ? defaultChoicesSelect(valueOptionStatus, labelOptionStatusForWaiting)[0]
            : defaultChoicesSelect(valueOptionStatus, labelOptionStatusDefault)[0],
      };

  useEffect(() => {
    return () => {
      dispatch(setChosenDayInScheduleAC(null));
    };
  }, [currentPath]);

  useEffect(() => {
    document.title = currentEvent
      ? t(TranslationKeys.event_form_edit)
      : t(TranslationKeys.event_form_add);
  }, []);

  useEffect(() => {
    if (isLoggedIn) {
      dispatch(fetchEventsListFieldsTC(currentBranch));

      if (currentPath === PATH.WAITING_ROOM) {
        if (currentQueryString) {
          dispatch(fetchFilteredWaitingListOfGeneral(currentQueryString));
        } else {
          dispatch(fetchFilteredWaitingListOfGeneral());
        }
      } else {
        if (currentQueryString) {
          dispatch(fetchFilteredEventsListOfGeneral(undefined, currentQueryString));
        } else {
          dispatch(fetchFilteredEventsListOfGeneral());
        }
      }
    }
  }, [dispatch, clients, currentBranch]);

  useEffect(() => {
    if (status === 'failed') {
      statusErrorHandler.setErrorCallback();
      reasonErrorHandler.setErrorCallback();
      clientErrorHandler.setErrorCallback();
      startErrorHandler.setErrorCallback();
      messageErrorHandler.setErrorCallback();
      doctorErrorHandler.setErrorCallback();
      noteErrorHandler.setErrorCallback();
    }
  }, [status]);

  const { register, handleSubmit, reset, control } = useForm<FormDataType>({
    mode: 'onBlur',
    defaultValues: useMemo(() => {
      if (eventDefaultData) {
        return eventDefaultData;
      }
    }, [eventDefaultData]),
  });

  useEffect(() => {
    if (eventDefaultData) {
      reset(eventDefaultData);
    }
  }, [currentEvent, fields]);

  useEffect(() => {
    return () => {
      dispatch(setAppStatusAC('idle'));
      dispatch(setAppErrorAC(''));
      dispatch(setClientFromPopupAC(null));
    };
  }, []);

  const openClientPopup = (): void => {
    dispatch(setCurrentClientIdAC(null));
    dispatch(setCurrentPathAC(location.pathname));
    setCurrentClientForPopup(0);
    dispatch(setStateClientsPopupAC('add'));
  };

  const openClientPopupEdit = (): void => {
    dispatch(setStateClientsPopupAC('edit'));
    if (currentClientForPopup) {
      dispatch(setCurrentClientIdAC(currentClientForPopup?.value));
    } else {
      dispatch(setCurrentClientIdAC(currentEvent && currentEvent?.client?.id));
    }
  };

  const closeClientPopup = (): void => {
    dispatch(setStateClientsPopupAC(null));
  };

  const setCurrentClient = (value: { value: string; label: string }): void => {
    setCurrentClientForPopup(value);
  };

  const isShowEdit = currentEvent && currentEvent.client ? true : !!currentClientForPopup;

  const onSubmit: SubmitHandler<FormDataType> = (data) => {
    const start = data.start
      ? dayjs(data.start).format('YYYY-MM-DDTHH:mm')
      : dayjs(startTimeDefaultValue).format('YYYY-MM-DDTHH:mm');
    const newData = {
      client: data.client && data.client.value,
      reason: data.reason && data.reason.value,
      start: start,
      status: data.status && data.status.value,
      message: data.message,
      doctor: data.doctor && data.doctor.value,
      note: data.note,
    };

    if (currentEvent) {
      dispatch(editEventTC(eventId, newData));
    } else {
      dispatch(addEventTC(newData, currentBranch));
    }
    setValueEvent(true);
  };

  if (valueEvent && status == 'succeeded') {
    if (currentPath) {
      return <Navigate to={currentPath} />;
    } else {
      return <Navigate to={PATH.EVENTS} />;
    }
  }

  if (!isLoggedIn) {
    return <Navigate to={PATH.LOGIN} />;
  }

  return (
    <>
      <div className={`${isOpenSidebar ? 'add-event' : 'add-event full-width'}`}>
        <h1 className="add-event__title">
          {currentEvent ? t(TranslationKeys.event_form_edit) : t(TranslationKeys.event_form_add)}
        </h1>
        {/*{status === 'loading' && <Preloaders />}*/}
        <form onSubmit={handleSubmit(onSubmit)} className="add-event__form">
          <label className="add-event__inputs-label">{t(TranslationKeys.client)}</label>
          <div className="add-event__inputs-info">
            <ReactSelect
              name="client"
              error={clientErrorHandler.error}
              help_text={
                fields.client.help_text ||
                (clientErrorHandler.error && clientErrorHandler.errorMessageCurrentField[1])
              }
              label={fields.client.label}
              placeholder={fields.client.label}
              onClick={clientErrorHandler.onFieldClick}
              onChangeInput={setCurrentClient}
              control={control}
              isMulti={false}
              required={fields.client.required}
              defaultValue={
                selectedClientFromPopup
                  ? defaultChoicesSelect(valueOptionClient, labelOptionSelectedClient)[0]
                  : currentEvent || currentClientRedux
                  ? defaultChoicesSelect(valueOptionClient, labelOptionClient)[0]
                  : null
              }
              options={
                choicesClient &&
                Object.entries(fields.client.choices).map((b: any) => newOption(b[0], b[1]))
              }
            />

            <div className="client-button-wrapp">
              <div className="add_client" onClick={openClientPopup}>
                <img alt="add client" src={logo.add_gray} />
              </div>

              {isShowEdit && (
                <div className="edit_client" onClick={openClientPopupEdit}>
                  <img alt="edit client" src={logo.edit} />
                </div>
              )}
            </div>
          </div>

          <label className="add-event__inputs-label">{t(TranslationKeys.info)}</label>
          <div className="add-event__inputs-info">
            <ReactSelect
              name="doctor"
              error={doctorErrorHandler.error}
              help_text={doctorErrorHandler.error && doctorErrorHandler.errorMessageCurrentField[1]}
              label={fields.doctor.label}
              placeholder={fields.doctor.label}
              onClick={doctorErrorHandler.onFieldClick}
              control={control}
              isMulti={false}
              required={fields.doctor.required}
              defaultValue={
                currentEvent && defaultChoicesSelect(valueOptionDoctor, labelOptionDoctor)[0]
              }
              options={
                choicesDoctor &&
                Object.entries(fields.doctor.choices).map((b: any) => newOption(b[0], b[1]))
              }
            />

            <ReactSelect
              name="reason"
              error={reasonErrorHandler.error}
              help_text={
                fields.reason.help_text ||
                (reasonErrorHandler.error && reasonErrorHandler.errorMessageCurrentField[1])
              }
              label={fields.reason.label}
              placeholder={fields.reason.label}
              onClick={reasonErrorHandler.onFieldClick}
              control={control}
              isMulti={false}
              required={fields.reason.required}
              defaultValue={
                currentEvent && defaultChoicesSelect(valueOptionReason, labelOptionReason)[0]
              }
              options={
                choicesReason &&
                Object.entries(fields.reason.choices).map((b: any) => newOption(b[0], b[1]))
              }
            />

            <ReactDateTimePicker
              defaultValue={startTimeDefaultValue && new Date(startTimeDefaultValue)}
              selectedDate={selectedDate}
              control={control}
              label={fields.start.label}
              onClick={startErrorHandler.onFieldClick}
              name={'start'}
              register={register}
              help_text={
                fields.start.help_text ||
                (startErrorHandler.error && startErrorHandler.errorMessageCurrentField[1])
              }
              error={startErrorHandler.error}
            />

            <ReactSelect
              name="status"
              error={statusErrorHandler.error}
              help_text={statusErrorHandler.error && statusErrorHandler.errorMessageCurrentField[1]}
              label={fields.status.label}
              placeholder={fields.status.label}
              onClick={statusErrorHandler.onFieldClick}
              control={control}
              isMulti={false}
              required={fields.status.required}
              defaultValue={
                currentEvent
                  ? defaultChoicesSelect(valueOptionStatus, labelOptionStatus)[0]
                  : defaultChoicesSelect(valueOptionStatus, labelOptionStatusDefault)[0]
              }
              options={
                choicesStatus &&
                Object.entries(fields.status.choices).map((b: any) => newOption(b[0], b[1]))
              }
            />
          </div>

          <Textarea
            error={messageErrorHandler.error}
            onClick={messageErrorHandler.onFieldClick}
            help_text={
              fields.message.help_text ||
              (messageErrorHandler.error && messageErrorHandler.errorMessageCurrentField[1])
            }
            state={'active'}
            register={register}
            name="message"
            input_type={fields.message.input_type}
            label={fields.message.label}
            defaultValue={currentEvent ? currentEvent?.message : fields.message.initial_value}
            {...reset}
          />
          <Textarea
            error={noteErrorHandler.error}
            onClick={noteErrorHandler.onFieldClick}
            help_text={
              fields.note.help_text ||
              (noteErrorHandler.error && noteErrorHandler.errorMessageCurrentField[1])
            }
            state={'active'}
            register={register}
            name="note"
            input_type={fields.note.input_type}
            label={fields.note.label}
            defaultValue={currentEvent ? currentEvent?.note : fields.note.initial_value}
            {...reset}
          />
          <div>
            <button className="button button_size-middle button_position-right button_color-black">
              {t(TranslationKeys.submit)}
            </button>
          </div>

          <ErrorMessage status={status} errorState={errorState} />
        </form>

        {isOpenClientPopup && <AddClientPopup closeClientPopup={closeClientPopup} />}
      </div>
    </>
  );
};