import React, { FC, useEffect, useMemo, useRef, useState } from 'react';
import { SubmitHandler, useForm } from 'react-hook-form';
import { useDispatch, useSelector } from 'react-redux';
import { Navigate, NavLink } from 'react-router-dom';
import './Login.scss';
import '../../components/Widgets/Button/Button.scss';
import { logo } from '../../assets/img/image';
import { PATH } from '../../routes/Routes';
import Inputs from '../../components/Widgets/Inputs';
import {
  getErrorStateSelector,
  getIsLoggedInSelector,
  getStatusSelector,
} from '../../redux/selectors';
import { ErrorMessage } from '../../components/ErrorMessage/ErrorMessage';
import { useErrorHandler } from '../../hooks/useErrorHandler';
import { LanguageMenu } from '../Main/Header/LanguageMenu/LanguageMenu';
import { useTranslation } from 'react-i18next';
import TranslationKeys from '../../assets/locales/translationKeys/translationKeys';
import { editStateMenuAC, setAppStatusAC } from "../../redux/actions";
import { loginTC } from "../../redux/middlewares";

export type FormDataType = {
  username: string;
  password: string;
  detail: string;
};

export const Login: FC = () => {
  const dispatch = useDispatch();
  const componentRef = useRef();
  const { t } = useTranslation();

  const errorState = useSelector(getErrorStateSelector);
  const status = useSelector(getStatusSelector);
  const isLoggedIn = useSelector(getIsLoggedInSelector);

  const [openMenuLang, setOpenMenuLang] = useState(false);

  //validation
  const formDataArray = ['username', 'password'];

  const userNameErrorHandler = useErrorHandler('username', errorState, formDataArray);
  const passwordErrorHandler = useErrorHandler('password', errorState, formDataArray);

  useMemo(() => {
    const handleClick = (e: any): void => {
      if (componentRef && componentRef.current) {
        const ref: any = componentRef.current;
        if (!ref.contains(e.target)) {
          dispatch(editStateMenuAC(false));
        }
      }
    };
    document.addEventListener('click', handleClick);
    return () => document.removeEventListener('click', handleClick);
  }, [dispatch]);

  useEffect(() => {
    if (status === 'failed') {
      userNameErrorHandler.setErrorCallback();
      passwordErrorHandler.setErrorCallback();
    }
  }, [status]);
  useEffect(() => {
    document.title = t(TranslationKeys.login);
  }, []);

  const { register, handleSubmit } = useForm<FormDataType>({
    mode: 'onBlur',
  });
  const onSubmit: SubmitHandler<FormDataType> = (data) => {
    dispatch(loginTC(data));
  };

  if (isLoggedIn) {
    return <Navigate to={'/'} />;
  }

  return (
    <>
      <form
        onSubmit={handleSubmit(onSubmit)}
        className='login'
        ref={componentRef as any}
        onClick={() => {
          dispatch(setAppStatusAC('idle'));
          setOpenMenuLang(false);
        }}
      >
        <NavLink to={PATH.LOGIN} className='img-wrapper'>
          <img src={logo.termiLogo} className='img' alt='termiNow' />
        </NavLink>
        <div className='wrapper'>
          <h1 className='title'>{t(TranslationKeys.register)}</h1>
          <Inputs
            error={userNameErrorHandler.error}
            help_text={userNameErrorHandler.error && userNameErrorHandler.errorMessageCurrentField[1]}
            onClick={userNameErrorHandler.onFieldClick}
            state={'active'}
            register={register}
            name={'username'}
            label={t(TranslationKeys.email)}
          />
          <Inputs
            error={passwordErrorHandler.error}
            onClick={passwordErrorHandler.onFieldClick}
            help_text={passwordErrorHandler.error && passwordErrorHandler.errorMessageCurrentField[1]}
            name='password'
            input_type='password'
            state='active'
            label={t(TranslationKeys.password)}
            register={register}
            autoComplete='off'
          />

          <ErrorMessage status={status} errorState={errorState} />

          <div>
            <NavLink
              to={`${PATH.REGISTER}`}
              className='button button_size-middle button_position-left button_color-transparent'
              type='submit'
            >
              {t(TranslationKeys.sign_up)}
            </NavLink>
            <button
              className='button button_size-middle button_position-right button_color-green'
              type='submit'
            >
              {t(TranslationKeys.login)}
            </button>
          </div>
        </div>

        <div className="language-block" onClick={(e) => {
          e.stopPropagation();
          setOpenMenuLang(!openMenuLang);
          dispatch(editStateMenuAC(false));
        }}>
          <LanguageMenu setOpenMenuLang={setOpenMenuLang} openMenuLang={openMenuLang} />
        </div>
      </form>

    </>
  );
};
