import React, { FC } from 'react';
import { Route, Routes } from 'react-router-dom';
import { Employees } from '../pages/Employees/Employees';
import { Events } from '../pages/Events/Events';
import { Schedule } from '../pages/Schedule/Schedule';
import { WaitingRoom } from '../pages/WaitingRoom/WaitingRoom';
import DatePage from '../pages/Success/DatePage/DatePage';
import { Success } from '../pages/Success/Success/Success';
import { SuccessWithImg } from '../pages/Success/SuccessWithImg/SuccessWithImg';
import { Profile } from '../pages/Profile/Profile';
import { WorkSchedule } from '../pages/WorkSchedule/WorkSchedule';
import { Clients } from '../pages/Clients/Clients';
import { Branches } from '../pages/Branches/Branches';
import { Reasons } from '../pages/Reasons/Reasons';
import { FormEmployee } from '../pages/Employees/FormEmployee/FormEmployee';
import { FormReason } from '../pages/Reasons/FormReason/FormReason';
import { FormEvent } from '../pages/Events/FormEvent/FormEvent';
import { FormClients } from '../pages/Clients/FormClients/FormClients';
import { Holidays } from '../pages/Holidays/Holidays';
import NotFound from '../pages/NotFound/NotFound';
import { FormBranch } from '../pages/Branches/FormBranch/FormBranch';
import { FormHoliday } from '../pages/Holidays/FormHoliday/FormHoliday';
import { Contacts } from '../pages/Contacts/Contacts';
import { FormContacts } from '../pages/Contacts/FormContacts/FormContacts';
import { Activation } from '../pages/Activation/Activation';
import { FormOrders } from '../pages/Orders/FormOrders/FormOrders';
import { OrderList } from '../pages/Orders/OrderList';
import { ModalAlertWaiting } from '../components/Popups/WaitingRoom/ModalAlertWaitingRoom';
import { useSelector } from 'react-redux';
import { getUserProfileSelector } from '../redux/selectors';
import { EventDetails } from '../pages/Events/EventDetails/EventDetails';
import SuccessPage from '../pages/Success/SuccessPage/SuccessPage';

export const PATH = {
  LOGIN: '/login/',
  SIGN_UP: '/signup/',
  PROFILE: '/profile/',
  ERROR_404: '/error404/',
  EMPLOYEES: '/employees/',
  REASONS: '/reasons/',
  ADD_EMPLOYEE: '/employees/create/',
  ADD_REASON: '/reasons/create/',
  EDIT_EMPLOYEE: '/employees/:id/',
  EDIT_REASON: '/reasons/:id/',
  EVENTS: '/events/',
  EDIT_EVENT: '/events/:id/',
  EVENT_DETAILS: '/events/details/:id/',
  ADD_EVENT: '/events/create',
  SCHEDULE: '/',
  WORK_SCHEDULE: '/work-schedule/',
  WAITING_ROOM: '/waiting-room/',
  CLIENTS: '/clients/',
  ADD_CLIENT: '/clients/create/',
  EDIT_CLIENT: '/clients/:id/',
  BRANCHES: '/branches/',
  ADD_BRANCH: '/branches/create/',
  EDIT_BRANCH: '/branches/:id/',
  HOLIDAYS: '/holidays/',
  ADD_HOLIDAY: '/holidays/create/',
  EDIT_HOLIDAY: '/holidays/:id/',
  SUCCESS_PAGE: '/success-page/',
  DATE: '/date/',
  SUCCESS: '/success/',
  SUCCESS_IMG: '/success-img/',
  CONTACTS: '/contacts/',
  ADD_CONTACTS: '/contacts/create/',
  EDIT_CONTACTS: '/contacts/:id/detail/',
  REGISTER: '/register/',
  // ACTIVATION: '/activation/:user_id/:confirmation_token',
  ACTIVATION: '/activation/',
  // ACTIVATION: '/email-activation/:user_id/:confirmation_token',
  SUCCESS_PAGE_EMAIL: '/success-email/',
  SUCCESS_PAGE_ERROR: '/success-error/',
  SUCCESS_PAGE_CREATE: '/success-create/',
  ORDERS: '/orders/',
  ADD_ORDER: '/orders/create/',
  EDIT_ORDER: '/orders/:id/',
  MODALWAITING: '/m-waitng/',
};

export const RoutesComponent: FC = () => {
  const user = useSelector(getUserProfileSelector);
  return (
    <>
      <Routes>
        <Route path="/" element={<Schedule />} />
        <Route path={PATH.SCHEDULE} element={<Schedule />} />
        <Route path={PATH.PROFILE} element={<Profile />} />
        {user.role === 'owner' ? (
          <>
            <Route path={PATH.EMPLOYEES} element={<Employees />} />
            <Route path={PATH.ADD_EMPLOYEE} element={<FormEmployee />} />
            <Route path={PATH.EDIT_EMPLOYEE} element={<FormEmployee />} />
          </>
        ) : (
          ''
        )}
        {user.role !== 'doctor' ? (
          <>
            <Route path={PATH.ADD_REASON} element={<FormReason />} />
            <Route path={PATH.EDIT_REASON} element={<FormReason />} />
            <Route path={PATH.ADD_HOLIDAY} element={<FormHoliday />} />
            <Route path={PATH.EDIT_HOLIDAY} element={<FormHoliday />} />
            <Route path={PATH.ADD_BRANCH} element={<FormBranch />} />
            <Route path={PATH.EDIT_BRANCH} element={<FormBranch />} />
          </>
        ) : (
          ''
        )}
        <Route path={PATH.REASONS} element={<Reasons />} />
        <Route path={PATH.EVENTS} element={<Events />} />
        <Route path={PATH.ADD_EVENT} element={<FormEvent />} />
        <Route path={PATH.EDIT_EVENT} element={<FormEvent />} />
        <Route path={PATH.EVENT_DETAILS} element={<><Events /> <EventDetails /></>} />
        <Route path={PATH.WORK_SCHEDULE} element={<WorkSchedule />} />
        <Route path={PATH.WAITING_ROOM} element={<WaitingRoom />} />
        <Route path={PATH.CLIENTS} element={<Clients />} />
        <Route path={PATH.ADD_CLIENT} element={<FormClients />} />
        <Route path={PATH.EDIT_CLIENT} element={<FormClients />} />
        <Route path={PATH.BRANCHES} element={<Branches />} />
        <Route path={PATH.HOLIDAYS} element={<Holidays />} />
        <Route path={PATH.DATE} element={<DatePage />} />
        <Route path={PATH.SUCCESS} element={<Success />} />
        <Route path={PATH.SUCCESS_IMG} element={<SuccessWithImg />} />
        <Route path={PATH.CONTACTS} element={<Contacts />} />
        <Route path={PATH.ADD_CONTACTS} element={<FormContacts />} />
        <Route path={PATH.EDIT_CONTACTS} element={<FormContacts />} />
        <Route path={PATH.ACTIVATION} element={<Activation />} />
        <Route path={PATH.ADD_ORDER} element={<FormOrders />} />
        <Route path={PATH.EDIT_ORDER} element={<FormOrders />} />
        <Route path={PATH.ORDERS} element={<OrderList />} />
        <Route path={PATH.MODALWAITING} element={<ModalAlertWaiting />} />
        <Route path={PATH.SUCCESS_PAGE_ERROR} element={<SuccessPage />} />
        <Route path={PATH.SUCCESS_PAGE_CREATE} element={<SuccessPage />} />

        <Route path="*" element={<NotFound />} />
      </Routes>
    </>
  );
};
