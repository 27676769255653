import { instance } from './http';

export const contactsAPI = {
  getList() {
    return instance.get<Array<ContactsType>>('/api/contacts/');
  },

  addContact(data: any) {
    return instance.post<ContactsType>('/api/contacts/create/', data);
  },

  editContact(data: any, contactId: number) {
    return instance.put<ContactsType>(`/api/contacts/${contactId}/detail/`, data);
  },

  removeContact(contactId: number) {
    return instance.delete<any>(`/api/contacts/${contactId}/detail/`);
  },
};

//types
export type ContactsType = {
  id: number;
  branches: any;
  title: string;
  email: string;
  phone: number;
  address: string;
};
