import React, { FC, memo } from 'react';
import { DateOfListType, ScheduleBranchType } from '../../../../api/branches-api';

type EventSchedulePropsType = {
  scheduleBranchDate: Array<DateOfListType>;
  dayIndex: number;
  scheduleBranch: ScheduleBranchType;
  CURRENT_DATE: DateOfListType | undefined;
};

export const HolidaySchedule: FC<EventSchedulePropsType> = memo(
  ({ dayIndex, scheduleBranch, CURRENT_DATE }) => {

    const fromStart = (date: string, start_time: string, day_start: string): number => {
      const editStartHoliday = new Date(`${date} ${start_time}`).getTime();
      const editStartDay = new Date(`${date} ${day_start}`).getTime();

      return (editStartHoliday - editStartDay) / 60000;
    };

    const editScheduleBranch = {
      ...scheduleBranch,
      date_of_lists: [
        ...scheduleBranch.date_of_lists.map((date) => ({
          ...date,
          holidays: date.holidays.map((hol) =>
            hol.date === date.date
              ? {
                  ...hol,
                  fromStart: String(fromStart(hol.date, hol.start_time, scheduleBranch.day_start)),
                }
              : hol,
          ),
        })),
      ],
    };

    const currentHolidays = editScheduleBranch.date_of_lists.find(
      (day) => day.date_index === dayIndex,
    )?.holidays;

    const currentPositionEvent = (from_start: string): number => {
      const pixelsConversion = 3;
      return Number(from_start) >= 0 ? Number(from_start) * pixelsConversion : 0;
    };

    const holidayHeight = (date: string, start: string, end: string): number => {
      const startMS = new Date(`${date} ${start}`).getTime();
      const endMS = new Date(`${date} ${end}`).getTime();
      const dayStartMS = new Date(`${date} ${editScheduleBranch.day_start}`).getTime();
      const dayEndMS = new Date(`${date} ${editScheduleBranch.day_end}`).getTime();
      const startCond = startMS < dayStartMS ? dayStartMS : startMS;
      const endCond = endMS > dayEndMS ? dayEndMS : endMS;
      // const heightEvent = (endCond - startMS) / (1000 * 60);
      const heightEvent = (endCond - startCond) / (1000 * 60);
      return heightEvent * 3;
    };

    const holidayTitleCount = (date: string, start: string, end: string, name: string): string => {
      const lengthName = name.length;
      const heightEvent = holidayHeight(date, start, end);
      // const titleCount = heightEvent / 180;
      const titleCount = (heightEvent ) / (lengthName);
      let finallyTitle = '';
      for (let i = 0; i < titleCount; i += 1) {
        finallyTitle += '\u00A0' + '\u00A0' + name + '\u00A0' + '\u00A0';
      }
      return finallyTitle;
    };

    return (
      <>
        <ul>
          {currentHolidays &&
            currentHolidays.map((holiday, /*index*/) =>
              holiday.date && !holiday.external &&
              holiday.date.toString().slice(0, 10) === CURRENT_DATE?.date.slice(0, 10) ? (
                <div
                  key={holiday.id}
                  className="holiday-wrap"
                  style={{
                    height: `${holidayHeight(
                      holiday.date,
                      holiday.start_time,
                      holiday.end_time,
                    )}px`,
                    top: `calc(60px + ${currentPositionEvent(holiday.fromStart)}px)`,
                  }}
                >
                  <li
                    key={holiday.id}
                    className="day-holiday"
                    title=""
                    style={{
                      height: `${holidayHeight(
                        holiday.date,
                        holiday.start_time,
                        holiday.end_time,
                      )}px`,
                    }}
                  >
                    <div
                      style={{
                        width: `${holidayHeight(
                          holiday.date,
                          holiday.start_time,
                          holiday.end_time,
                        )}px`,
                      }}
                      className="holiday-name"
                    >
                      {holidayTitleCount(
                        holiday.date,
                        holiday.start_time,
                        holiday.end_time,
                        holiday.name,
                      )}
                    </div>

                    <div
                      title={holiday.name}
                      className="holiday-el"
                      style={{
                        height: `${holidayHeight(
                          holiday.date,
                          holiday.start_time,
                          holiday.end_time,
                        )}px`,
                        top: `calc(60px + ${currentPositionEvent(holiday.fromStart)}px)`,
                      }}
                    >
                    </div>
                  </li>
                </div>
              ) : (
                ''
              ),
            )}
        </ul>
      </>
    );
  },
);
