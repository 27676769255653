import { Dispatch } from 'redux';
import { fieldsAPI } from '../../api/fields-api';
import { handleServerAppError, handleServerNetworkError } from '../../utils/error-utils';
import {
  setListFieldsBranchesAC,
  setListFieldsClientsAC,
  setListFieldsContactsAC,
  setListFieldsEmployeesAC,
  setListFieldsEventsAC,
  setListFieldsHolidayAC,
  setListFieldsOrderAC,
  setListFieldsReasonsAC,
  setListFieldsUserAC,
  setListFieldsWorkScheduleAC,
} from '../actions';

export const fetchEmployeesListFieldsTC = () => async (dispatch: Dispatch<any>) => {
  try {
    const res = await fieldsAPI.getListFieldEmployees();
    dispatch(setListFieldsEmployeesAC(res.data));
  } catch (error: any) {
    if (error.message === 'Network Error') {
      handleServerNetworkError(error, dispatch);
    } else {
      handleServerAppError(error, dispatch);
    }
  }
};

export const fetchClientsListFieldsTC = (branchId: number) => async (dispatch: Dispatch<any>) => {
  try {
    const res = await fieldsAPI.getListFieldClients(branchId);
    dispatch(setListFieldsClientsAC(res.data));
  } catch (error: any) {
    if (error.message === 'Network Error') {
      handleServerNetworkError(error, dispatch);
    } else {
      handleServerAppError(error, dispatch);
    }
  }
};

export const fetchEventsListFieldsTC = (eventId: number) => async (dispatch: Dispatch<any>) => {
  try {
    const res = await fieldsAPI.getListFieldEvents(eventId);
    dispatch(setListFieldsEventsAC(res.data));
  } catch (error: any) {
    if (error.message === 'Network Error') {
      handleServerNetworkError(error, dispatch);
    } else {
      handleServerAppError(error, dispatch);
    }
  }
};

export const fetchReasonsListFieldsTC = () => async (dispatch: Dispatch<any>) => {
  try {
    const res = await fieldsAPI.getListFieldReasons();
    dispatch(setListFieldsReasonsAC(res.data));
  } catch (error: any) {
    if (error.message === 'Network Error') {
      handleServerNetworkError(error, dispatch);
    } else {
      handleServerAppError(error, dispatch);
    }
  }
};

export const fetchWorkScheduleListFieldsTC = () => async (dispatch: Dispatch<any>) => {
  try {
    const res = await fieldsAPI.getListFieldWorkSchedule();
    dispatch(setListFieldsWorkScheduleAC(res.data));
  } catch (error: any) {
    if (error.message === 'Network Error') {
      handleServerNetworkError(error, dispatch);
    } else {
      handleServerAppError(error, dispatch);
    }
  }
};

export const fetchBranchListFieldsTC = () => async (dispatch: Dispatch<any>) => {
  try {
    const res = await fieldsAPI.getListFieldBranches();
    dispatch(setListFieldsBranchesAC(res.data));
  } catch (error: any) {
    if (error.message === 'Network Error') {
      handleServerNetworkError(error, dispatch);
    } else {
      handleServerAppError(error, dispatch);
    }
  }
};

export const fetchHolidayListFieldsTC = () => async (dispatch: Dispatch<any>) => {
  try {
    let res = await fieldsAPI.getListFieldHoliday();
    dispatch(setListFieldsHolidayAC(res.data));
  } catch (error: any) {
    if (error.message === 'Network Error') {
      handleServerNetworkError(error, dispatch);
    } else {
      handleServerAppError(error, dispatch);
    }
  }
};

export const fetchContactsListFieldsTC = () => async (dispatch: Dispatch<any>) => {
  try {
    let res = await fieldsAPI.getListFieldContacts();
    dispatch(setListFieldsContactsAC(res.data));
  } catch (error: any) {
    if (error.message === 'Network Error') {
      handleServerNetworkError(error, dispatch);
    } else {
      handleServerAppError(error, dispatch);
    }
  }
};

export const fetchUserListFieldsTC = () => async (dispatch: Dispatch<any>) => {
  try {
    let res = await fieldsAPI.getListFieldUser();
    dispatch(setListFieldsUserAC(res.data));
  } catch (error: any) {
    if (error.message === 'Network Error') {
      handleServerNetworkError(error, dispatch);
    } else {
      handleServerAppError(error, dispatch);
    }
  }
};

export const fetchOrderListFieldsTC = (branchId: number) => async (dispatch: Dispatch<any>) => {
  try {
    let res = await fieldsAPI.getListFieldOrder(branchId);
    dispatch(setListFieldsOrderAC(res.data));
  } catch (error: any) {
    if (error.message === 'Network Error') {
      handleServerNetworkError(error, dispatch);
    } else {
      handleServerAppError(error, dispatch);
    }
  }
};