import './NotFound.scss';
import { NavLink } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { getOpenSidebarAppSelector } from '../../redux/selectors';
import TranslationKeys from '../../assets/locales/translationKeys/translationKeys';

const NotFound: React.FC = () => {
  //locale
  const { t } = useTranslation();

  const isOpenSidebar = useSelector(getOpenSidebarAppSelector);

  return (
    <>
      <div className={`${isOpenSidebar ? "containerNot" : "containerNot full-width"}`}>
        <h1 className="containerNot__title">404!</h1>
        <p className="containerNot__p">{t(TranslationKeys.not_found)}</p>
        <div className="containerNot__btn">
          <NavLink to={'/'} className="btn">
            {t(TranslationKeys.home_btn)}
          </NavLink>
        </div>
      </div>
    </>
  );
};

export default NotFound;