import React, { FC, useState } from 'react';
import './Calendar.scss';
import Calendar, { Detail } from 'react-calendar';
import 'react-calendar/dist/Calendar.css';
import { logo } from '../../assets/img/image';
import dayjs from 'dayjs';
import { ButtonClose } from '../Widgets/ButtonClose/ButtonClose';

type Calendar = {
  setDate?: (date: Date) => void;
  onPrevClick?: () => void;
  onNextClick?: () => void;
  onClickBtn?: () => void;
  branchId?: number;
  setChosenWeekInCalendar?: (branchId: number, week: string) => void;
  onDayClick?: () => void;
  onDayClickCustom?: (date: string) => void;
  onTimeClick2?: (time: string) => void;
  disabled?: boolean;
  disabledCallback?: (activeStartDate: Date, date: Date, view: Detail) => boolean;
};

export const CalendarGeneral: FC<Calendar> = ({
  setDate,
  onPrevClick,
  onClickBtn,
  onNextClick,
  branchId,
  setChosenWeekInCalendar,
  onDayClick,
  onDayClickCustom,
  disabled,
  disabledCallback,
}) => {
  const [startDate, setStartDate] = useState(new Date());

  const onDateClick = (date: Date): void => {
    setStartDate(date);
    setDate && setDate(date);

    if (setChosenWeekInCalendar && branchId) {
      setChosenWeekInCalendar(branchId, dayjs(date).format('YYYY-MM-DD'));
      onDayClick && onDayClick();
    }
    onDayClickCustom && onDayClickCustom(dayjs(date).format('YYYY-MM-DD'));
  };

  return (
    <div onClick={(e) => e.stopPropagation()}>
      <div className="btn-block">
        <ButtonClose onClickBtn={onClickBtn}/>
      </div>

      <Calendar
        showFixedNumberOfWeeks={true}
        onChange={onDateClick}
        onActiveStartDateChange={({ action }) => {
          if (action === 'prev') {
            onPrevClick && onPrevClick();
          }
          if (action === 'next') {
            onNextClick && onNextClick();
          }
        }}
        tileDisabled={
          disabled && disabledCallback
            ? ({ activeStartDate, date, view }) => disabledCallback(activeStartDate, date, view)
            : () => false
        }
        value={startDate}
        nextLabel={<img src={logo.arrow_right} alt="next" />}
        prevLabel={<img src={logo.arrow_left} alt="previous" />}
        next2Label={null}
        prev2Label={null}
        locale={'en'}
      />
    </div>
  );
};