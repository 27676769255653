import React, { FC, useEffect, useMemo, useState } from 'react';
import { SubmitHandler, useForm } from 'react-hook-form';
import '../../../components/Widgets/Button/Button.scss';
import './FormsHoliday.scss';
import { useDispatch, useSelector } from 'react-redux';
import { Navigate, useParams } from 'react-router-dom';
import { PATH } from '../../../routes/Routes';
import Inputs from '../../../components/Widgets/Inputs';
import {
  defaultChoicesMultiSelect,
  newOption,
} from '../../../components/Widgets/Select/ReactSelectStyles';
import { ReactSelect } from '../../../components/Widgets/Select/ReactSelect';
import { TimePicker } from '../../../components/Widgets/TimePicker';
import dayjs from 'dayjs';
import { useErrorHandler } from '../../../hooks/useErrorHandler';
import {
  getErrorStateSelector,
  getFieldsHolidaysSelector,
  getHolidaysSelector,
  getIsLoggedInSelector,
  getOpenSidebarAppSelector,
  getStatusSelector,
} from '../../../redux/selectors';
import { ErrorMessage } from '../../../components/ErrorMessage/ErrorMessage';
import { useTranslation } from 'react-i18next';
import { ReactDatePickerHoliday } from '../../../components/Widgets/ReactDatePickerHoliday';
import CheckboxInput from '../../../components/Widgets/CheckboxInput';
import TranslationKeys from '../../../assets/locales/translationKeys/translationKeys';
import {
  addHolidayTC,
  editHolidayTC,
  fetchHolidayListFieldsTC,
  fetchHolidaysList,
} from '../../../redux/middlewares';

type FormDataType = {
  name: string;
  date: string | Date;
  start_time: string | Date;
  end_time: string | Date;
  branches: { value: any; label: any };
  all_day: boolean;
  external: boolean;
};

export const FormHoliday: FC = () => {
  const dispatch = useDispatch();

  //for edit employee
  const params = useParams();
  const idUrl = params.id;
  const holidayId = Number(idUrl);

  const holidays = useSelector(getHolidaysSelector);
  const fields = useSelector(getFieldsHolidaysSelector);
  const errorState = useSelector(getErrorStateSelector);
  const status = useSelector(getStatusSelector);
  const isLoggedIn = useSelector(getIsLoggedInSelector);
  const isOpenSidebar = useSelector(getOpenSidebarAppSelector);

  const [valueHoliday, setValueHoliday] = useState<boolean>(false);
  const [startDate] = useState<Date>(new Date());
  const [selectedDate] = useState<Date>(new Date());
  const [selectedStartTime, setSelectedStartTime] = useState<any>(null);

  const maxEndTime = new Date(`${dayjs(selectedStartTime).format('YYYY-MM-DD')} 23:59`);
  const currentHoliday = holidays && holidays?.private?.find((pr) => +pr.id === +holidayId);
  const allDayDefaultValue =
    currentHoliday?.start_time === '00:00:00' && currentHoliday?.end_time === '23:59:00';

  const [isAllDay, setAllDay] = useState<boolean>(
    !currentHoliday
      ? true
      : currentHoliday.start_time && currentHoliday.end_time
      ? false
      : allDayDefaultValue,
  );

  //validation
  const formDataArray = ['name', 'date', 'start_time', 'end_time', 'branches', 'external'];

  const nameErrorHandler = useErrorHandler('name', errorState, formDataArray);
  const dateErrorHandler = useErrorHandler('date', errorState, formDataArray);
  const startTimeErrorHandler = useErrorHandler('start_time', errorState, formDataArray);
  const endTimeErrorHandler = useErrorHandler('end_time', errorState, formDataArray);
  const branchesErrorHandler = useErrorHandler('branches', errorState, formDataArray);

  //locale
  const { t } = useTranslation();

  //for select
  const choices = Object.entries(fields.branches.choices).map((br) => br);
  const valueOption =
    fields && Object.entries(fields.branches.choices).map((b: any) => newOption(b[0], b[1]));
  const labelOption = currentHoliday && currentHoliday.branches.map((el: any) => el.toString());

  const holidayData = currentHoliday
    ? {
        name: currentHoliday.name,
        date: new Date(currentHoliday.date),
        start_time: new Date(`${currentHoliday.date} ${currentHoliday.start_time}`),
        end_time: new Date(`${currentHoliday.date} ${currentHoliday.end_time}`),
        branches: currentHoliday && defaultChoicesMultiSelect(valueOption, labelOption),
        all_day: allDayDefaultValue,
        external: currentHoliday.external,
      }
    : {
        name: fields.name.initial_value && new Date(fields.name.initial_value),
        date: fields.date.initial_value && new Date(fields.date.initial_value),
      };

  useEffect(() => {
    dispatch(fetchHolidayListFieldsTC());
    dispatch(fetchHolidaysList());
  }, [dispatch]);

  useEffect(() => {
    if (status === 'failed') {
      nameErrorHandler.setErrorCallback();
      dateErrorHandler.setErrorCallback();
      startTimeErrorHandler.setErrorCallback();
      endTimeErrorHandler.setErrorCallback();
      branchesErrorHandler.setErrorCallback();
    }
  }, [status]);

  useEffect(() => {
    if (currentHoliday?.start_time) {
      setAllDay(false);
    }
  }, [currentHoliday]);

  const { register, handleSubmit, control, reset } = useForm<FormDataType>({
    mode: 'onBlur',
    defaultValues: useMemo(() => {
      if (holidayData) {
        return holidayData;
      }
    }, [holidayData, fields]),
  });

  useEffect(() => {
    if (holidayData) {
      reset(holidayData);
    }
  }, [currentHoliday, fields]);
  useEffect(() => {
    document.title = currentHoliday
      ? t(TranslationKeys.holiday_form_edit)
      : t(TranslationKeys.holiday_form_add);
  }, []);

  const availableEndTime = (): Date => {
    const selectedStartTimeMS = selectedStartTime.getTime();
    const sum = selectedStartTimeMS + 3600000;
    return new Date(sum);
  };

  const onSubmit: SubmitHandler<FormDataType> = (data) => {
    let time_start_format = dayjs(data.start_time).format('HH:mm');
    let time_end_format = dayjs(data.end_time).format('HH:mm');

    const start_time = data.start_time ? time_start_format : '00:00';
    const end_time = data.end_time ? time_end_format : '23:59';

    const newData = {
      branches: data.branches && Object.values(data.branches).map((br: any) => br.value),
      name: data.name,
      date: dayjs(data.date).format('YYYY-MM-DD'),
      start_time: start_time,
      end_time: end_time,
      external: data.external,
    };

    if (currentHoliday) {
      dispatch(editHolidayTC(holidayId, newData));
    } else {
      dispatch(addHolidayTC(newData));
    }
    setValueHoliday(true);
  };

  if (status === 'succeeded' && valueHoliday) {
    return <Navigate to={PATH.HOLIDAYS} />;
  }

  if (!isLoggedIn) {
    return <Navigate to={PATH.LOGIN} />;
  }

  return (
    <>
      <div className={`${isOpenSidebar ? 'add-holiday' : 'add-holiday full-width'}`}>
        <h1 className="add-holiday__title">
          {currentHoliday
            ? t(TranslationKeys.holiday_form_edit)
            : t(TranslationKeys.holiday_form_add)}
        </h1>

        <div>
          <form onSubmit={handleSubmit(onSubmit)} className="add-holiday__form">
            <label className="add-holiday__inputs-label">
              {/*{fields.branches.label}*/}
              {t(TranslationKeys.branch_for_title)}
            </label>
            <div className="add-branch__inputs-setting">
              <ReactSelect
                name="branches"
                error={branchesErrorHandler.error}
                help_text={
                  branchesErrorHandler.error && branchesErrorHandler.errorMessageCurrentField[1]
                }
                label={fields.branches.label}
                placeholder={fields.branches.label}
                onClick={branchesErrorHandler.onFieldClick}
                control={control}
                isMulti={true}
                required={fields.branches.required}
                options={
                  choices &&
                  Object.entries(fields.branches.choices).map((b: any) => newOption(b[0], b[1]))
                }
              />
            </div>

            <label className="add-holiday__inputs-label">{t(TranslationKeys.details)}</label>
            <div className="add-holiday__inputs-info">
              <Inputs
                error={nameErrorHandler.error}
                help_text={
                  fields?.name?.help_text ||
                  (nameErrorHandler.error && nameErrorHandler.errorMessageCurrentField[1])
                }
                onClick={nameErrorHandler.onFieldClick}
                state={'active'}
                register={register}
                input_type={fields.name.input_type}
                name={'name'}
                label={fields.name.label}
                defaultValue={currentHoliday ? currentHoliday.name : fields.name.initial_value}
                {...reset}
              />
              <div>
                <ReactDatePickerHoliday
                  selectedDate={selectedDate}
                  control={control}
                  label={fields.date.label}
                  onClick={dateErrorHandler.onFieldClick}
                  name={'date'}
                  help_text={dateErrorHandler.error && dateErrorHandler.errorMessageCurrentField[1]}
                  error={dateErrorHandler.error}
                  register={register}
                />
              </div>
            </div>

            <CheckboxInput
              id="all_day"
              state={'square'}
              input_type="checkbox"
              name={'all_day'}
              label={t(TranslationKeys.all_day)}
              onChange={() => {
                setAllDay(!isAllDay);
              }}
              defaultChecked={isAllDay || allDayDefaultValue}
              register={register}
            />

            <div className="add-holiday__inputs-time">
              <TimePicker
                selectedDate={startDate}
                state={'timePicker'}
                control={control}
                label={fields.start_time.label}
                onClick={startTimeErrorHandler.onFieldClick}
                timeIntervals={30}
                onChangeText={(e) => {
                  setSelectedStartTime(e);
                }}
                name={'start_time'}
                defaultValue={
                  currentHoliday ? currentHoliday.start_time : fields.start_time.initial_value
                }
                disabled={isAllDay}
                help_text={
                  fields.start_time.help_text ||
                  (startTimeErrorHandler.error && startTimeErrorHandler.errorMessageCurrentField[1])
                }
                error={startTimeErrorHandler.error}
                register={register}
              />

              <TimePicker
                selectedDate={startDate}
                state={'timePicker'}
                control={control}
                label={fields.end_time.label}
                onClick={endTimeErrorHandler.onFieldClick}
                timeIntervals={30}
                name={'end_time'}
                minTime={selectedStartTime && availableEndTime()}
                maxTime={maxEndTime}
                disabled={isAllDay ? true : currentHoliday?.start_time ? false : !selectedStartTime}
                help_text={
                  fields.end_time.help_text ||
                  (endTimeErrorHandler.error && endTimeErrorHandler.errorMessageCurrentField[1])
                }
                defaultValue={
                  currentHoliday ? currentHoliday.end_time : fields.end_time.initial_value
                }
                error={endTimeErrorHandler.error}
                register={register}
              />
            </div>

            <CheckboxInput
              id="external"
              state={'square'}
              input_type={fields.external.input_type}
              name={'external'}
              label={fields.external.label}
              defaultChecked={
                currentHoliday ? currentHoliday.external : fields.external.initial_value
              }
              help_text={fields.external.help_text}
              register={register}
            />

            <div>
              <button className="button button_size-middle button_position-right button_color-black">
                {t(TranslationKeys.submit)}
              </button>
            </div>

            <ErrorMessage status={status} errorState={errorState} />
          </form>
        </div>
      </div>
    </>
  );
};
