/* eslint-disable */
import { useState } from 'react';

/**
 * "setValidationError" takes "errorState" and "formDataArray" arguments.
 * Return object with an array of error fields and an array of error fields with error messages.
 * @param {string | Object} errorState - error text or object with fields containing errors
 * @param {string[]} formDataArray - array of fields for the current form
 * */
export const setValidationError = (errorState: string | Object, formDataArray: string[]) => {
  const errorFieldKey = Object.keys(errorState);
  const matched = formDataArray.filter((el) => errorFieldKey.indexOf(el) > -1);
  const testErrorField = formDataArray.filter(
    (field) => field === matched.filter((el) => el === field)[0],
  );
  const testErrorMessage = Object.entries(errorState);
  const testErrorFieldMessage = testErrorMessage.filter(
    (mes) => mes[0] === testErrorField.filter((el) => el === mes[0])[0],
  );
  return {
    testErrorField,
    testErrorFieldMessage,
  };
};

/**
 * "useErrorHandler" takes "fieldName", "errorState" and "formDataArray" arguments.
 * Returns an object with the name of the error field, an array with the error message,
 * a callback to clear the error field, and a callback to save the error
 * @param {string} fieldName - field name
 * @param {string | Object} errorState - error text or object with fields containing errors
 * @param {string[]} formDataArray - array of fields for the current form
 * */
export const useErrorHandler = (fieldName: string, errorState: string | Object, formDataArray: string[]) => {
  const [error, setError] = useState<string | null>(null);

  const errorField = setValidationError(errorState, formDataArray).testErrorField;
  const errorFieldMessage = setValidationError(errorState, formDataArray).testErrorFieldMessage;
  const errorCurrentField = errorField.filter((el) => el === fieldName)[0];
  const errorMessageCurrentField = errorFieldMessage.filter((el) => el[0] === fieldName)[0];

  const onFieldClick = (): void => setError(null);
  const setErrorCallback = (): void => setError(errorCurrentField);

  return {
    error,
    errorMessageCurrentField,
    onFieldClick,
    setErrorCallback,
  } as UseErrorHandlerType;
};

export type UseErrorHandlerType = {
  error: string | null;
  errorMessageCurrentField: [string, string];
  onFieldClick: () => void;
  setErrorCallback: () => void;
};