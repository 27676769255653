import { HolidaysBranchType } from '../../api/holidays-api';
import { HolidaysActionsType } from '../actions/types';
import {
  ADD_HOLIDAY,
  EDIT_HOLIDAY,
  JOIN_HOLIDAY,
  LEAVE_HOLIDAY,
  REMOVE_HOLIDAY,
  SET_LIST_HOLIDAYS,
} from '../actions/constants';

const HolidaysInitialState = {} as HolidaysBranchType;

export const holidaysReducer = (
  state: HolidaysBranchType = HolidaysInitialState,
  action: HolidaysActionsType,
): HolidaysBranchType => {
  switch (action.type) {
    case SET_LIST_HOLIDAYS: {
      return action.holidays;
    }

    case JOIN_HOLIDAY: {
      return {
        ...state,
        official: [...state.official, action.officialHoliday],
        suggestion: state.suggestion.filter((el) => el.id !== action.officialHoliday.id),
      };
    }

    case LEAVE_HOLIDAY: {
      const leavedHol = state && state.official.find((el) => el.id === action.holidayId);
      if (leavedHol) {
        return {
          ...state,
          official: state.official.filter((el) => el.id !== action.holidayId),
          suggestion: [...state.suggestion, leavedHol],
        };
      } else {
        return {
          ...state,
          official: state.official.filter((el) => el.id !== action.holidayId),
        };
      }
    }

    case ADD_HOLIDAY:
      return {
        ...state,
        private: [...state.private, action.privateHoliday],
      };

    case EDIT_HOLIDAY: {
      return {
        ...state,
        private: state.private.map((pr) =>
          pr.id === action.model.id ? { ...pr, ...action.model } : pr,
        ),
      };
    }

    case REMOVE_HOLIDAY:
      return {
        ...state,
        private: state.private.filter((el) => el.id !== action.holidayId),
      };

    default:
      return state;
  }
};