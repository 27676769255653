import React, { FC, memo, useEffect, useMemo, useState } from 'react';
import './ScheduleSideBar.scss';
import { useDispatch, useSelector } from 'react-redux';
import {
  getActiveWeekSelector,
  getCurrentBranchSelector,
  getCurrentDoctorParamsEventsSelector,
  getFilterParamsEventsSelector,
  getUserProfileSelector,
} from '../../../redux/selectors';
import CheckboxInput from '../../../components/Widgets/CheckboxInput';
import { useTranslation } from 'react-i18next';
import { useLocation } from 'react-router-dom';
import { useForm } from 'react-hook-form';
import {
  defaultChoicesSelect,
  newOption,
} from '../../../components/Widgets/Select/ReactSelectStyles';
import { ReactSelectBranch } from '../../../components/Widgets/Select/ReactSelectBranch';
import cx from 'classnames';
import Collapse from '@kunukn/react-collapse';
import TranslationKeys from '../../../assets/locales/translationKeys/translationKeys';
import { setCurrentBranchesAC, setIsLoggedInAC } from "../../../redux/actions";
import {
  fetchFilteredEventsParamsListTC,
  getScheduleBranchActiveWeekTC,
  getScheduleBranchTC,
  setFilteredScheduleOfDoctorTC
} from "../../../redux/middlewares";
import { useCleanFilters } from "../../../hooks/useCleanFilters";

type EventsSideBarPropsType = {};

type FormDataType = {
  branches: { value: any; label: any };
};

export const ScheduleSideBar: FC<EventsSideBarPropsType> = memo(() => {
  const dispatch = useDispatch();
  const location = useLocation();
  const cleanFilters = useCleanFilters();

  const currentBranch = useSelector(getCurrentBranchSelector);
  const activeWeek = useSelector(getActiveWeekSelector);
  const currentDoctorParams = useSelector(getCurrentDoctorParamsEventsSelector);
  const filterParams = useSelector(getFilterParamsEventsSelector);
  const user = useSelector(getUserProfileSelector);

  const [stateDoctor, setStateDoctor] = useState<boolean>(true);
  const [openDoc, isOpenDoc] = useState(true);

  //locale
  const { t } = useTranslation();

  const valueOptionBranches =
    filterParams &&
    Object.entries(filterParams.branch.choices).map((b: any) => newOption(b[0], b[1]));
  const labelOptionBranches = currentBranch.toString();

  const choicesDoctor =
    filterParams?.doctor__in && Object.entries(filterParams?.doctor__in?.choices).map((br) => br);
  const choicesBranch =
    filterParams.branch && Object.entries(filterParams.branch.choices).map((br) => br);

  useEffect(() => {
    if (currentDoctorParams.length) {
      setStateDoctor(true);
    }
  }, []);

  useEffect(() => {
    if (currentBranch !== 0) {
      dispatch(fetchFilteredEventsParamsListTC());
    }
  }, [currentBranch]);

  const setBranch = (branchId: number): void => {
    cleanFilters();
    if (activeWeek) {
      dispatch(getScheduleBranchActiveWeekTC(branchId));
    } else {
      dispatch(getScheduleBranchTC(branchId));
    }
    dispatch(setCurrentBranchesAC(branchId));
    dispatch(setIsLoggedInAC(true));
  };

  const filterDoctor = (params: string): void => {
    dispatch(setFilteredScheduleOfDoctorTC(params));
  };

  const countDoctor = useMemo(
    () =>
      choicesDoctor.reduce(
        (count, [doctorId]) => (currentDoctorParams.includes(doctorId) ? count + 1 : count),
        0,
      ),
    [currentDoctorParams, choicesDoctor],
  );

  const { control } = useForm<FormDataType>({
    mode: 'onBlur',
  });

  return (
    <>
      <form className="form-filter">
        <div className="filter-block">
          <div className="filter-block-select">
            <ReactSelectBranch
              name="branches"
              label={t(TranslationKeys.current_branch)}
              placeholder={t(TranslationKeys.current_branch)}
              control={control}
              isMulti={false}
              defaultValue={
                currentBranch && defaultChoicesSelect(valueOptionBranches, labelOptionBranches)
              }
              onChange={(e) => setBranch(e.value)}
              options={
                choicesBranch &&
                Object.entries(filterParams.branch.choices).map((b: any) => newOption(b[0], b[1]))
              }
            />
          </div>
        </div>

        {location.pathname === '/' && user.role !== 'doctor' && (
          <div className="filter-block">
            <div
              className={cx('app__toggle', {
                'app__toggle--active': openDoc,
              })}
              onClick={() => {
                setStateDoctor(!stateDoctor);
                isOpenDoc(!openDoc);
              }}
            >
              <span className="filter-title-name">
                {t(TranslationKeys.doctor)}
                <span
                  style={{
                    marginLeft: '15px',
                    fontSize: '15px',
                    fontWeight: 'lighter',
                    color: 'rgba(45, 45, 45, 0.852)',
                  }}
                >
                  [
                  <span
                    style={{
                      color: 'black',
                    }}
                  >
                    {countDoctor}
                  </span>{' '}
                  / {Object.keys(filterParams.doctor__in.choices).length}]
                </span>
              </span>
              <div
                onClick={() => isOpenDoc(!openDoc)}
                className={openDoc ? 'cross active' : 'cross'}
              />
            </div>
            <Collapse
              isOpen={openDoc}
              className={
                'app__collapse app__collapse--gradient ' + (openDoc ? 'app__collapse--active' : '')
              }
            >
              <div className="app__content">
                {filterParams?.doctor__in &&
                  choicesDoctor.map((doctor, index) => (
                    <div
                      key={index}
                      className={!stateDoctor ? 'filter-param-none' : ' filter-param'}
                    >
                      <CheckboxInput
                        id={doctor[0]}
                        state={'square'}
                        checked={currentDoctorParams.find((cs) => cs === doctor[0]) === doctor[0]}
                        onChange={() => {
                          filterDoctor(doctor[0]);
                        }}
                        name={'status'}
                        value={doctor[0]}
                        label={doctor[1]}
                      />
                    </div>
                  ))}
              </div>
            </Collapse>
          </div>
        )}
      </form>
    </>
  );
});
