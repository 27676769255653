import React, { FC, memo, useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Navigate, NavLink, useLocation } from 'react-router-dom';
import { PATH } from '../../routes/Routes';
import './WaitingRoom.scss';
import {
  getCurrentBranchSelector,
  getCurrWaitingRoomQueryStringAppSelector,
  getEventsSelector,
  getIsLoggedInSelector,
  getOpenSidebarAppSelector,
  getSelectedEventSelector,
  getStatusSelector,
  getWaitingRoomSelector,
} from '../../redux/selectors';
import { useStatusFilterParams } from '../../hooks/useStatusFilterParams';
import { logo } from '../../assets/img/image';
import { useTranslation } from 'react-i18next';
import { setDate } from '../../utils/setDate';
import dayjs from 'dayjs';
import TranslationKeys from '../../assets/locales/translationKeys/translationKeys';
import WaitingRoomPreload from '../../components/Preloaders/WaitingRoomPreload/WaitingRoomPreload';
import {
  changeStatusEventTC,
  fetchFilteredWaitingListOfGeneral,
  fetchFilteredWaitingRoomParamsListTC,
  initializeAppTC,
} from '../../redux/middlewares';
import {
  setCurrentEventScheduleAC,
  setCurrentPathAC,
  setSelectedEventAC,
} from '../../redux/actions';

export const WaitingRoom: FC = memo(() => {
  const dispatch = useDispatch();
  const location = useLocation();
  const statusFilterParams = useStatusFilterParams();
  const componentScrollRef = useRef();

  const waitingRoom = useSelector(getWaitingRoomSelector);
  const status = useSelector(getStatusSelector);
  const isLoggedIn = useSelector(getIsLoggedInSelector);
  const events = useSelector(getEventsSelector);
  const currentBranch = useSelector(getCurrentBranchSelector);
  const isOpenSidebar = useSelector(getOpenSidebarAppSelector);
  const currentQueryString = useSelector(getCurrWaitingRoomQueryStringAppSelector);
  const selectedIndex = useSelector(getSelectedEventSelector);

  const [load, setLoad] = useState(false);

  //locale
  const { t } = useTranslation();

  useEffect(() => {
    if (status === 'failed') {
      dispatch(initializeAppTC());
    }
  }, [status]);

  useEffect(() => {
    if (currentBranch !== 0) {
      if (currentQueryString) {
        dispatch(fetchFilteredWaitingListOfGeneral(currentQueryString));
      } else {
        dispatch(fetchFilteredWaitingListOfGeneral());
      }
      dispatch(fetchFilteredWaitingRoomParamsListTC());
    }
  }, [events, currentBranch]);

  useEffect(() => {
    return () => {
      dispatch(setSelectedEventAC(null));
    };
  }, []);

  useEffect(() => {
    document.title = t(TranslationKeys.waiting_header);
  }, []);

  useEffect(() => {
    if (componentScrollRef && componentScrollRef.current) {
      const ref: any = componentScrollRef.current;
      if (ref.id === `${selectedIndex}`) {
        ref.scrollIntoView({ block: 'center', behavior: 'smooth' });
      }
    }
  }, [selectedIndex, componentScrollRef.current]);

  const handleButtonClick = (): void => {
    if (componentScrollRef && componentScrollRef.current) {
      const ref: any = componentScrollRef.current;
      ref.scrollIntoView({ block: 'center', behavior: 'smooth' });
    }
  };

  const onChangeStatus = async (eventId: number, currentStatus: string): Promise<void> => {
    await dispatch(changeStatusEventTC(eventId, currentStatus));
    await dispatch(setSelectedEventAC(eventId));
    await handleButtonClick();
  };

  const onLoadHandler = (): void => {
    setLoad(true);
  };
  const escFunction = useCallback((event) => {
    if (event.key === 'Escape') {
    }
  }, []);
  const componentRefWait = useRef();
  const componentRefAtTheDoc = useRef();

  useMemo(() => {
    const handleClick = (e: any): void => {
      if (componentRefWait && componentRefWait.current) {
        const ref: any = componentRefWait.current;
        if (!ref.contains(e.target)) {
        }
      }
      if (componentRefAtTheDoc && componentRefAtTheDoc.current) {
        const ref: any = componentRefAtTheDoc.current;
        if (!ref.contains(e.target)) {
        }
      }
    };
    document.addEventListener('click', handleClick);
    return () => document.removeEventListener('click', handleClick);
  }, [dispatch]);

  useEffect(() => {
    document.addEventListener('keydown', escFunction, false);

    return () => {
      document.removeEventListener('keydown', escFunction, false);
    };
  }, []);

  const setCurrentPathForRedirect = (): void => {
    dispatch(setCurrentEventScheduleAC(null));
    dispatch(setCurrentPathAC(location.pathname));
  };

  if (!isLoggedIn) {
    return <Navigate to={PATH.LOGIN} />;
  }

  return (
    <>
      <div className={`${isOpenSidebar ? 'waitingRoom' : 'waitingRoom full-width'}`}>
        <div className={'waitingRoom__header'}>
          <h1 className="waitingRoom__header__title">{t(TranslationKeys.waiting_header)}</h1>
          <div className="waitingRoom__header__add">
            <NavLink
              className="button button_size-small button_position-right button_color-transparent"
              to={PATH.ADD_EVENT}
              onClick={setCurrentPathForRedirect}
            >
              + {t(TranslationKeys.event_btn_add)}
            </NavLink>
          </div>
        </div>
        {status == 'loading' && <WaitingRoomPreload />}
        <div>
          <span className="title">{t(TranslationKeys.waiting)}</span>
          <table ref={componentRefWait as any} className="waitingRoom__table">
            <thead className="thead">
              <tr className="tr">
                <th className="th-name">
                  <span>{t(TranslationKeys.full_name)}</span>
                </th>
                <th className="th">
                  <span>{t(TranslationKeys.reason)}</span>
                </th>
                <th className="th">
                  <span>{t(TranslationKeys.begin)}</span>
                </th>
                <th className="th-war">
                  <span>{t(TranslationKeys.waiting_period)}</span>
                </th>
                <th className="th th-btn">
                  <span>{t(TranslationKeys.options)}</span>
                </th>
              </tr>
            </thead>
            <tbody className="tbody">
              {waitingRoom.waiting_room.map((eventWait) => (
                <tr key={eventWait.id} className="tr">
                  <td className="td-name">
                    <b>
                      {eventWait?.client?.first_name} {eventWait?.client?.last_name}
                    </b>
                    <br />
                    <span className="text">#{eventWait.id}</span>
                  </td>
                  <td
                    style={{
                      backgroundColor: `${eventWait.reason_color}38`,
                      borderColor: `${eventWait.reason_color}`,
                    }}
                    className="td-color"
                  >
                    <span className="td-colorReason">{eventWait.reason_display}</span>
                  </td>
                  <td className="td date-waiting">
                    <span>{dayjs(eventWait.start).format('DD MMM. YYYY')}</span>
                    <b className="time">{dayjs(eventWait.start).format('HH:mm')}</b>
                  </td>
                  <td className="td">{eventWait.waiting_time}</td>
                  <td className="td-btns">
                    <div className="btns">
                      <div
                        onClick={() => {
                          onChangeStatus(eventWait.id, statusFilterParams.at_the_doctor);
                        }}
                        className="wrap-img-doc"
                      >
                        <img
                          onLoad={onLoadHandler}
                          title={t(TranslationKeys.event_at_the_doctor)}
                          className={'img-doc'}
                          src={logo.doctor_icon}
                          alt="at_the_doctor"
                        />
                      </div>

                      <div
                        onClick={() =>
                          onChangeStatus(eventWait.id, statusFilterParams.did_not_wait)
                        }
                        className="wrap-img-not"
                      >
                        <img
                          onLoad={onLoadHandler}
                          title={t(TranslationKeys.event_did_not_wait)}
                          className={'img-not'}
                          src={logo.close}
                          alt="did_not_wait"
                        />
                      </div>

                      <div className={'wrap-img-edit'}>
                        <NavLink to={`${PATH.EVENTS}${eventWait.id}/`}>
                          <img
                            onLoad={onLoadHandler}
                            onClick={setCurrentPathForRedirect}
                            className="img-edit"
                            alt="edit"
                            title={t(TranslationKeys.edit)}
                            src={logo.edit}
                          />
                        </NavLink>
                      </div>
                    </div>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
          <span className="title">{t(TranslationKeys.event_at_the_doctor)}</span>
          <table ref={componentRefAtTheDoc as any} className="waitingRoom__table">
            <thead className="thead">
              <tr className="th-name">
                <th className="th">
                  <span>{t(TranslationKeys.full_name)}</span>
                </th>
                <th className="th">
                  <span>{t(TranslationKeys.reason)}</span>
                </th>
                <th className="th">
                  <span>{t(TranslationKeys.begin)}</span>
                </th>
                <th className="th-war">
                  <span>{t(TranslationKeys.waiting_period)}</span>
                </th>
                <th className="th th-btn">
                  <span>{t(TranslationKeys.options)}</span>
                </th>
              </tr>
            </thead>
            <tbody className="tbody">
              {waitingRoom.at_the_doctor.map((w) => (
                <tr
                  ref={w.id === selectedIndex ? (componentScrollRef as any) : null}
                  id={`${selectedIndex}`}
                  key={w.id}
                  className={w.id === selectedIndex ? 'tr active' : 'tr'}
                >

                  <td className="td-name">
                    <b>
                      {w.client?.first_name} {w?.client?.last_name}
                    </b>
                    <br />
                    <span>#{w.id}</span>
                  </td>
                  <td
                    style={{
                      backgroundColor: `${w.reason_color}38`,
                      borderColor: `${w.reason_color}`,
                    }}
                    className="td-color"
                  >
                    <span className="td">{w.reason_display}</span>
                  </td>
                  <td className="td date-waiting">
                    <span>
                      {`${setDate(w.start).getUTCDate()} ${setDate(w.start)
                        .toDateString()
                        .slice(4, 7)}. ${setDate(w.start).getFullYear()}`}
                    </span>
                    <b className="time">{`${w.start.slice(11, 16)} `}</b>
                  </td>
                  <td className="td">{w.visit_time}</td>
                  <td className="td-btns">
                    <div className="btns">
                      <div
                        onClick={() => onChangeStatus(w.id, statusFilterParams.done)}
                        className="wrap-img-done"
                      >
                        <img
                          onLoad={onLoadHandler}
                          title={t(TranslationKeys.done)}
                          className={load ? 'img-done' : 'img'}
                          src={logo.check}
                          alt="done"
                        />
                      </div>

                      <div className={'wrap-img-edit'}>
                        <NavLink to={`${PATH.EVENTS}${w.id}/`}>
                          <img
                            onLoad={onLoadHandler}
                            onClick={setCurrentPathForRedirect}
                            className="img-edit"
                            title={t(TranslationKeys.edit)}
                            src={logo.edit}
                          />
                        </NavLink>
                      </div>
                    </div>
                  </td>

                  <td className={w.id === selectedIndex ? 'borderDoctor' : 'borderNone'}></td>

                </tr>
              ))}
            </tbody>
          </table>
        </div>
      </div>
    </>
  );
});
