import { RegisterType } from '../../api/register-api';
import {
  ACTIVATION_REGISTRATION,
  ADD_USER_REGISTRATION,
  IS_SEND_DATA_REGISTRATION,
} from './constants';

export const addUserRegistrationAC = (model: RegisterType) =>
  ({ type: ADD_USER_REGISTRATION, model } as const);
export const activationRegistrationAC = (id: number, token: string) =>
  ({ type: ACTIVATION_REGISTRATION, id, token } as const);
export const setIsSendDataRegistrationAC = (value: boolean) =>
  ({ type: IS_SEND_DATA_REGISTRATION, value } as const);