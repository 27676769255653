import React, { FC, memo, useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Navigate, NavLink } from 'react-router-dom';
import { PATH } from '../../routes/Routes';
import './Employees.scss';
import { ConfirmationPopup } from '../../components/Popups/ConfirmationPopup/ConfirmationPopup';
import Preloader from '../../components/Preloaders/Preloader';
import {
  getEmployeesSelector,
  getIsLoggedInSelector,
  getOpenSidebarAppSelector,
  getStatusSelector,
} from '../../redux/selectors';
import { useTranslation } from 'react-i18next';
import { logo } from '../../assets/img/image';
import PreloaderEvent from '../../components/Preloaders/EventsPreload/PreloadEvents';
import { ActionMenuEmployees } from './ActionMenuEmployees/ActionMenuEmployees';
import TranslationKeys from '../../assets/locales/translationKeys/translationKeys';
import { fetchEmployeesListTC, initializeAppTC, removeEmployeeTC } from '../../redux/middlewares';
import { editStateMenuAC } from '../../redux/actions';

export const Employees: FC = memo(() => {
  const dispatch = useDispatch();

  const employees = useSelector(getEmployeesSelector);
  const status = useSelector(getStatusSelector);
  const isLoggedIn = useSelector(getIsLoggedInSelector);
  const isOpenSidebar = useSelector(getOpenSidebarAppSelector);

  //dialog alerts
  const [openAlertDialogForDelete, setOpenAlertDialogForDelete] = useState(false);
  const [options, setOptions] = useState(false);
  //const [arrow, changeArrow] = useState(false)
  const [load, setLoad] = useState(false);
  const [modalDataId, setModalDataId] = useState<number>(0);
  const [selectedIndex, setSelectedIndex] = useState(0);

  //locale
  const { t } = useTranslation();

  const escFunction = useCallback((event) => {
    if (event.key === 'Escape') {
      setOpenAlertDialogForDelete(false);
    }
  }, []);

  const componentRef = useRef();

  useMemo(() => {
    const handleClick = (e: any): void => {
      if (componentRef && componentRef.current) {
        const ref: any = componentRef.current;
        if (!ref.contains(e.target)) {
          setOptions(false);
        }
      }
    };
    document.addEventListener('click', handleClick);
    return () => document.removeEventListener('click', handleClick);
  }, [dispatch]);

  useEffect(() => {
    document.addEventListener('keydown', escFunction, false);

    return () => {
      document.removeEventListener('keydown', escFunction, false);
    };
  }, []);

  useEffect(() => {
    if (status === 'failed') {
      dispatch(initializeAppTC());
    }
  }, [status]);

  useEffect(() => {
    if (isLoggedIn) {
      dispatch(fetchEmployeesListTC());
    }
  }, [dispatch]);

  useEffect(() => {
    document.title = t(TranslationKeys.employees_nav);
  }, []);

  useEffect(() => {
    const remove = new Image();
    remove.src = logo.trash;
    remove.onload = () => {
      setLoad(true);
    };
    const img = new Image();
    img.src = logo.edit;
    img.onload = () => {
      setLoad(true);
    };
  });

  const removeEmployee = (id: number): void => {
    dispatch(removeEmployeeTC(id));
    setModalDataId(0);
  };

  const openAlertHandler = (employeeId: number): void => {
    setModalDataId(employeeId);
    setOpenAlertDialogForDelete(true);
  };
  const closeAlertHandler = (): void => {
    setOpenAlertDialogForDelete(false);
  };

  const onClickOptions = (employeeId: number): void => {
    dispatch(editStateMenuAC(false));
    if (options) {
      if (employeeId === selectedIndex) {
        setOptions(false);
      } else {
        setOptions(true);
      }
    } else {
      setOptions(true);
    }
  };

  const onLoadHandler = (): void => {
    setLoad(true);
  };

  if (!isLoggedIn) {
    return <Navigate to={PATH.LOGIN} />;
  }

  return (
    <>
      <div ref={componentRef as any}></div>
      <div className={`${isOpenSidebar ? 'employees' : 'employees full-width'}`}>
        <div className="employees__header">
          <h1 className="employees__header__title">{t(TranslationKeys.employees_list)}</h1>
          <div className="employees__header__add">
            <NavLink
              className="button button_size-small button_position-left button_color-transparent"
              to={PATH.ADD_EMPLOYEE}
            >
              + {t(TranslationKeys.employee_btn_add)}
            </NavLink>
          </div>
        </div>

        <div>
          <table className="employees__table">
            <thead className="thead">
              <tr className="tr">
                <th className="th">
                  <span>{t(TranslationKeys.name)}</span>
                </th>
                <th className="th">
                  <span>{t(TranslationKeys.login)}</span>
                </th>
                <th
                  style={{
                    width: '170px',
                  }}
                  className="th"
                >
                  <span>{t(TranslationKeys.options)}</span>
                </th>
              </tr>
            </thead>

            {employees.length === 0 && status === 'loading' ? (
              <tbody>
                <tr>
                  <td>
                    <Preloader />
                  </td>
                </tr>
              </tbody>
            ) : (
              status === 'loading' && (
                <tbody>
                  <tr>
                    <td>
                      <PreloaderEvent />
                    </td>
                  </tr>
                </tbody>
              )
            )}

            <tbody className="tbody">
              {employees.length > 0 &&
                employees.map((employee) => (
                  <tr key={employee.id} className="tr">
                    <td className="td-name">
                      <img
                        className="doc-img"
                        src={
                          employee.photo === null
                            ? logo.notLogo
                            : `${process.env.REACT_APP_BASE_URL}${employee.photo}`
                        }
                        title={`${employee.first_name} ${employee.last_name}`}
                      />
                      <div>
                        <b>
                          {employee.first_name} {employee.last_name}
                          <br />
                        </b>
                        <span className="td-role">
                          {employee?.role?.charAt(0).toUpperCase() + employee?.role?.slice(1)}
                        </span>
                      </div>
                    </td>
                    <td className="td">{employee.username}</td>
                    <td
                      className="td-btns"
                      onClick={(e) => {
                        e.stopPropagation();
                        setSelectedIndex(employee.id);
                        onClickOptions(employee.id);
                      }}
                    >
                      <ActionMenuEmployees
                        employee={employee}
                        options={options}
                        onClickOptions={onClickOptions}
                        selectedIndex={selectedIndex}
                        setSelectedIndex={setSelectedIndex}
                        onLoadHandler={onLoadHandler}
                        load={load}
                        setOptions={setOptions}
                        openAlertHandler={openAlertHandler}
                      />
                    </td>
                  </tr>
                ))}
              {openAlertDialogForDelete && (
                <>
                  <ConfirmationPopup
                    actionCallback={removeEmployee}
                    id={modalDataId}
                    titleQuestion={t(TranslationKeys.employee_delete)}
                    actionTitle={t(TranslationKeys.delete)}
                    onClickBtn={closeAlertHandler}
                    cancelCallback={closeAlertHandler}
                  />
                </>
              )}
            </tbody>
          </table>
        </div>
      </div>
    </>
  );
});

