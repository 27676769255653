import { Dispatch } from "redux";
import { addEmployeeAC, editEmployeeAC, removeEmployeesAC, setAppStatusAC, setListEmployeesAC } from "../actions";
import { employeesAPI } from "../../api/employees-api";
import { handleServerAppError, handleServerNetworkError } from "../../utils/error-utils";

export const fetchEmployeesListTC = () => async (dispatch: Dispatch<any>) => {
  dispatch(setAppStatusAC('loading'));
  try {
    let res = await employeesAPI.getList();
    dispatch(setListEmployeesAC(res.data));
    dispatch(setAppStatusAC('succeeded'));
  } catch (error: any) {
    if (error.message === 'Network Error') {
      handleServerNetworkError(error, dispatch);
    } else {
      handleServerAppError(error, dispatch);
    }
  }
  dispatch(setAppStatusAC('idle'));
};

export const addEmployeesTC = (data: any) => async (dispatch: Dispatch<any>) => {
  dispatch(setAppStatusAC('loading'));
  try {
    let res = await employeesAPI.addEmployee(data);
    dispatch(
      addEmployeeAC(
        res.data.id,
        res.data.username,
        res.data.first_name,
        res.data.last_name,
        res.data.branch_set,
        res.data.role,
        res.data.external,
      ),
    );
    dispatch(setAppStatusAC('succeeded'));
  } catch (error: any) {
    if (error.message === 'Network Error') {
      handleServerNetworkError(error, dispatch);
    } else {
      handleServerAppError(error, dispatch);
    }
  }
};

export const editEmployeeTC = (id: number, data: any) => async (dispatch: Dispatch<any>) => {
  dispatch(setAppStatusAC('loading'));
  try {
    let res = await employeesAPI.editEmployee(data, id);
    dispatch(
      editEmployeeAC(
        res.data.id,
        res.data.username,
        res.data.first_name,
        res.data.last_name,
        res.data.role,
        res.data.external,
      ),
    );
    dispatch(setAppStatusAC('succeeded'));
  } catch (error: any) {
    if (error.message === 'Network Error') {
      handleServerNetworkError(error, dispatch);
    } else {
      handleServerAppError(error, dispatch);
    }
  }
};

export const removeEmployeeTC = (employeeId: number) => async (dispatch: Dispatch<any>) => {
  dispatch(setAppStatusAC('loading'));
  try {
    await employeesAPI.removeEmployee(employeeId);
    dispatch(removeEmployeesAC(employeeId));
    dispatch(setAppStatusAC('succeeded'));
  } catch (error: any) {
    if (error.message === 'Network Error') {
      handleServerNetworkError(error, dispatch);
    } else {
      handleServerAppError(error, dispatch);
    }
  }
  dispatch(setAppStatusAC('idle'));
};
