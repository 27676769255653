import {
  ADD_CLIENT_SEARCH,
  ADD_CURRENT_CLIENT_FILTER_DOCTOR,
  ADD_CURRENT_CLIENTS_BRANCHES,
  ADD_CURRENT_CLIENTS_FILTER_INSURANCE,
  CLEAN_CLIENTS_FILTER,
  COLLAPSE_FILTER_DOCTOR,
  COLLAPSE_FILTER_INSURANCE,
  REMOVE_CURRENT_CLIENT_FILTER_DOCTOR,
  REMOVE_CURRENT_CLIENTS_FILTER_INSURANCE,
  RESET_REDUCER_GROUP,
  SET_ACTIVE_ARROW_FULL_NAME,
  SET_ACTIVE_ARROW_INSURANCE,
  SET_CLIENT_FILTER_PARAMS,
  SET_CURRENT_CLIENTS_QUERY_STRING,
  SET_CURRENT_SORT_PARAM,
  SET_PRELOADER_CLIENTS_FILTER,
  SET_SORT_BY_FULL_NAME,
  SET_SORT_BY_INSURANCE,
} from '../actions/constants';
import { ClientsFilterActionsType } from '../actions/types';
import { ClientsFilterInitialStateType } from "./types";

const initialState: ClientsFilterInitialStateType = {
  currentQueryStringClientsList: null,

  currentFilterParams: {
    insurance_type__in: [],
    branches__in: [],
    doctors__in: [],
    search: [],
  },

  currentSortParams: {
    currentSortParam: 'first_name',
    isActiveFullName: false,
    isActiveInsurance: false,
    isActiveArrowFullName: false,
    isActiveArrowInsurance: false,
  },

  clientsFilterParamsList: {
    search: {
      help_text: '',
      hidden: false,
      initial_value: null,
      input_type: '',
      label: '',
      required: true,
      type: '',
      widget_type: '',
    },
    email: {},
    full_name: {},
    insurance_type__in: { choices: {} },
    branches__in: { choices: {} },
    doctors__in: { choices: {} },
    phone: {},
  },
  isCollapseInsuranceFilter: true,
  isCollapseDoctorFilter: true,
  isActivePreloader: false,
};

export const clientsFilterReducer = (
  state: ClientsFilterInitialStateType = initialState,
  action: ClientsFilterActionsType,
): ClientsFilterInitialStateType => {
  switch (action.type) {
    case SET_CLIENT_FILTER_PARAMS: {
      return { ...state, clientsFilterParamsList: action.params };
    }
    case ADD_CURRENT_CLIENTS_FILTER_INSURANCE: {
      return {
        ...state,
        currentFilterParams: {
          ...state.currentFilterParams,
          insurance_type__in: [...state.currentFilterParams.insurance_type__in, action.params],
        },
      };
    }
    case REMOVE_CURRENT_CLIENTS_FILTER_INSURANCE: {
      return {
        ...state,
        currentFilterParams: {
          ...state.currentFilterParams,
          insurance_type__in: state.currentFilterParams.insurance_type__in.filter(
            (insurance) => insurance !== action.params,
          ),
        },
      };
    }

    case ADD_CURRENT_CLIENTS_BRANCHES: {
      return {
        ...state,
        currentFilterParams: {
          ...state.currentFilterParams,
          branches__in: [action.params],
        },
      };
    }
    case ADD_CURRENT_CLIENT_FILTER_DOCTOR: {
      return {
        ...state,
        currentFilterParams: {
          ...state.currentFilterParams,
          doctors__in: [...state.currentFilterParams.doctors__in, action.params],
        },
      };
    }
    case REMOVE_CURRENT_CLIENT_FILTER_DOCTOR: {
      return {
        ...state,
        currentFilterParams: {
          ...state.currentFilterParams,
          doctors__in: state.currentFilterParams.doctors__in.filter(
            (doctor) => doctor !== action.params,
          ),
        },
      };
    }

    case COLLAPSE_FILTER_INSURANCE: {
      return {
        ...state,
        isCollapseInsuranceFilter: action.param,
      };
    }
    case COLLAPSE_FILTER_DOCTOR: {
      return {
        ...state,
        isCollapseDoctorFilter: action.param,
      };
    }
    case SET_PRELOADER_CLIENTS_FILTER: {
      return {
        ...state,
        isActivePreloader: action.param,
      };
    }
    case ADD_CLIENT_SEARCH: {
      return {
        ...state,
        currentFilterParams: {
          ...state.currentFilterParams,
          search: [action.params],
        },
      };
    }

    case CLEAN_CLIENTS_FILTER: {
      return {
        ...state,
        currentFilterParams: {
          insurance_type__in: [],
          branches__in: [],
          doctors__in: [],
          search: [],
        },
      };
    }

    case SET_CURRENT_SORT_PARAM: {
      return {
        ...state,
        currentSortParams: {
          ...state.currentSortParams,
          currentSortParam: action.value,
        },
      };
    }

    case SET_SORT_BY_FULL_NAME: {
      return {
        ...state,
        currentSortParams: {
          ...state.currentSortParams,
          isActiveFullName: action.value,
        },
      };
    }

    case SET_SORT_BY_INSURANCE: {
      return {
        ...state,
        currentSortParams: {
          ...state.currentSortParams,
          isActiveInsurance: action.value,
        },
      };
    }

    case SET_ACTIVE_ARROW_FULL_NAME: {
      return {
        ...state,
        currentSortParams: {
          ...state.currentSortParams,
          isActiveArrowFullName: action.value,
        },
      };
    }

    case SET_ACTIVE_ARROW_INSURANCE: {
      return {
        ...state,
        currentSortParams: {
          ...state.currentSortParams,
          isActiveArrowInsurance: action.value,
        },
      };
    }

    case SET_CURRENT_CLIENTS_QUERY_STRING:
      return { ...state, currentQueryStringClientsList: action.query };

    case RESET_REDUCER_GROUP:
      return { ...initialState };

    default:
      return state;
  }
};