import React, { FC, memo, useEffect, useMemo, useRef, useState } from 'react';
import './Clients.scss';
import { Navigate, NavLink } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { PATH } from '../../routes/Routes';
import { Pagination } from '../../components/ComponentsForLists/Pagination/Pagination';
import Preloader from '../../components/Preloaders/Preloader';
import {
  getActiveWeekSelector,
  getClientsSelector,
  getCurrClientsQueryStringAppSelector,
  getCurrentBranchSelector,
  getCurrentClientsSelector,
  getCurrentPagePaginationClientsSelector,
  getCurrentSortParamSelector,
  getIsLoggedInSelector,
  getNextClientsSelector,
  getOpenSidebarAppSelector,
  getPreviousClientsSelector,
  getSortStateArrowFullNameSelector,
  getSortStateArrowInsuranceSelector,
  getSortStateByFullNameSelector,
  getSortStateByInsuranceSelector,
  getStatusSelector,
} from '../../redux/selectors';
import { useTranslation } from 'react-i18next';
import { ActionMenuClients } from './ActionMenuClients/ActionMenuClients';
import { logo } from '../../assets/img/image';
import PreloaderClient from '../../components/Preloaders/ClientsPreload/PreloadClients';
import TranslationKeys from '../../assets/locales/translationKeys/translationKeys';
import { SortByTitle } from '../../components/ComponentsForLists/SortByTitle/SortByTitle';
import { ViewDoctors } from '../../components/ComponentsForLists/ViewDoctors/ViewDoctors';
import { getObjectFromQueryString } from '../../utils/getObjectFromQueryString';
import { getQueryStringFromObject } from '../../utils/getQueryStringFromObject';
import {
  fetchAppearedTC,
  fetchBranchesListFirstRender,
  fetchFilteredClientsListOfGeneral, fetchFilteredClientsParamsListTC, getNextClientsList, getPrevClientsList,
  initializeAppTC
} from "../../redux/middlewares";
import {
  editStateMenuAC,
  setAppStatusAC,
  setCurrClientsQueryStringAC, setCurrentClientIdAC,
  setCurrentSortParamAC
} from "../../redux/actions";

export const Clients: FC = memo(() => {
  const dispatch = useDispatch();

  const clients = useSelector(getClientsSelector);
  const previousPage = useSelector(getPreviousClientsSelector);
  const nextPage = useSelector(getNextClientsSelector);
  const countPagePagination = useSelector(getCurrentPagePaginationClientsSelector);
  const currentBranch = useSelector(getCurrentBranchSelector);
  const currentPage = useSelector(getCurrentClientsSelector);
  const status = useSelector(getStatusSelector);
  const isLoggedIn = useSelector(getIsLoggedInSelector);
  const activeWeek = useSelector(getActiveWeekSelector);
  const isOpenSidebar = useSelector(getOpenSidebarAppSelector);
  const currentQueryString = useSelector(getCurrClientsQueryStringAppSelector);
  const isActiveFullName = useSelector(getSortStateByFullNameSelector);
  const isActiveInsurance = useSelector(getSortStateByInsuranceSelector);
  const isActiveArrowFullName = useSelector(getSortStateArrowFullNameSelector);
  const isActiveArrowInsurance = useSelector(getSortStateArrowInsuranceSelector);
  const currentSortParam = useSelector(getCurrentSortParamSelector);

  //dialog alerts
  const [loading, setLoading] = useState(true);

  //locale
  const { t } = useTranslation();

  const [options, setOptions] = useState(false);
  const [selectedIndex, setSelectedIndex] = useState(0);
  const [load, setLoad] = useState(false);

  const componentRef = useRef();

  useMemo(() => {
    const handleClick = (e: any): void => {
      if (componentRef && componentRef.current) {
        const ref: any = componentRef.current;
        if (!ref.contains(e.target)) {
          setOptions(false);
        }
      }
    };
    document.addEventListener('click', handleClick);
    return () => document.removeEventListener('click', handleClick);
  }, [dispatch]);

  useEffect(() => {
    document.title = t('clients_header');
  }, []);

  useEffect(() => {
    if (status === 'failed') {
      dispatch(initializeAppTC());
    }
  }, [status]);

  useEffect(() => {
    const imgAppeared = new Image();
    imgAppeared.src = logo.appeared;
    imgAppeared.onload = () => {
      setLoad(true);
    };
    const img = new Image();
    img.src = logo.edit;
    img.onload = () => {
      setLoad(true);
    };
  });

  useEffect(() => {
    if (currentBranch !== 0) {
      if (currentQueryString) {
        dispatch(
          fetchFilteredClientsListOfGeneral(
            currentBranch.toString(),
            undefined,
            currentQueryString,
          ),
        );
      } else {
        dispatch(fetchFilteredClientsListOfGeneral(currentBranch.toString(), currentSortParam));
      }
      dispatch(fetchFilteredClientsParamsListTC());
    }
  }, []);

  useEffect(() => {
    if (isLoggedIn) {
      if (!activeWeek) {
        dispatch(fetchBranchesListFirstRender());
      }
    }

    return () => {
      dispatch(setAppStatusAC('idle'));
    };
  }, [dispatch]);

  const sortListByFullName = (ordering: string): void => {
    dispatch(setCurrentSortParamAC(ordering));
    dispatch(fetchFilteredClientsListOfGeneral(currentBranch.toString(), ordering));
  };

  const sortListByInsurance = (ordering: string): void => {
    dispatch(setCurrentSortParamAC(ordering));
    dispatch(fetchFilteredClientsListOfGeneral(currentBranch.toString(), ordering));
  };

  const setNotAppeared = (clientId: number): void => {
    dispatch(fetchAppearedTC(clientId));
    if (loading) {
      setLoading(false);
    }
  };

  const onLoadHandler = (): void => {
    setLoad(true);
  };

  const nextList = (): void => {
    if (nextPage !== null) {
      dispatch(getNextClientsList());
    }
  };

  const prevList = (): void => {
    if (previousPage !== null) {
      dispatch(getPrevClientsList());
    }
  };

  const toFirstList = (): void => {
    const currQueryStringToObject = getObjectFromQueryString(currentQueryString);
    const currQueryStringToString = getQueryStringFromObject({
      ...currQueryStringToObject,
      page: '1',
    });
    if (currentQueryString) {
      dispatch(setCurrClientsQueryStringAC(currQueryStringToString));
      dispatch(
        fetchFilteredClientsListOfGeneral(
          currentBranch.toString(),
          undefined,
          currQueryStringToString,
        ),
      );
    } else {
      dispatch(fetchFilteredClientsListOfGeneral(currentBranch.toString(), currentSortParam));
    }
  };

  const toLastList = (): void => {
    const currQueryStringToObject = getObjectFromQueryString(currentQueryString);
    const currQueryStringToString = getQueryStringFromObject({
      ...currQueryStringToObject,
      page: `${countPagePagination}`,
    });
    if (currentQueryString) {
      dispatch(setCurrClientsQueryStringAC(currQueryStringToString));
      dispatch(
        fetchFilteredClientsListOfGeneral(
          currentBranch.toString(),
          undefined,
          currQueryStringToString,
        ),
      );
    } else {
      dispatch(fetchFilteredClientsListOfGeneral(currentBranch.toString(), currentSortParam));
    }
  };

  const onClickOptions = (clientId: number): void => {
    dispatch(editStateMenuAC(false));
    if (options) {
      if (clientId === selectedIndex) {
        setOptions(false);
      } else {
        setOptions(true);
      }
    } else {
      setOptions(true);
    }
  };

  const dropCurrentClient = (): void => {
    dispatch(setCurrentClientIdAC(null));
  };

  if (!isLoggedIn) {
    return <Navigate to={PATH.LOGIN} />;
  }

  return (
    <>
      <div ref={componentRef as any}></div>
      <div className={`${isOpenSidebar ? 'clients' : 'clients full-width'}`}>
        <div className="clients__header">
          <h1 className="clients__header__title">{t(TranslationKeys.client_list)}</h1>
          <div className="clients__header__add" onClick={dropCurrentClient}>
            <NavLink
              className="button button_size-small button_position-left button_color-transparent"
              to={PATH.ADD_CLIENT}
            >
              + {t(TranslationKeys.client_btn_add)}
            </NavLink>
          </div>
        </div>
        <div>
          <table className="clients__table">
            <thead className="thead">
              <tr className="tr">
                <th className="th">
                  <SortByTitle
                    isActiveSortTitle={isActiveFullName}
                    isActiveArrow={isActiveArrowFullName}
                    sortListCallback={sortListByFullName}
                    sortParam="first_name"
                    title={TranslationKeys.full_name}
                  />
                </th>
                <th className="th">
                  <SortByTitle
                    isActiveSortTitle={isActiveInsurance}
                    isActiveArrow={isActiveArrowInsurance}
                    sortListCallback={sortListByInsurance}
                    sortParam="insurance_type"
                    title={TranslationKeys.insurance}
                  />
                </th>
                <th className="th">
                  <span className="th_title">{t(TranslationKeys.doctor)}</span>
                </th>
                <th className="th">
                  <span className="th_title">
                    {t(TranslationKeys.phone_number)}
                    <br />
                    {t(TranslationKeys.email)}
                  </span>
                </th>
                <th className="th-options">
                  <span className="th_title">{t(TranslationKeys.options)}</span>
                </th>
              </tr>
            </thead>
            <tbody className="tbody">
              {clients.length === 0 && status == 'loading' ? (
                <tr>
                  <td>
                    <Preloader />
                  </td>
                </tr>
              ) : (
                status == 'loading' && (
                  <tr>
                    <td>
                      <PreloaderClient />
                    </td>
                  </tr>
                )
              )}

              {clients.length > 0 &&
                clients.map((client) => {
                  return (
                    <tr key={client.id} className="tr">
                      {client.blocked === true ? (
                        <td className="td tdBlock-name">
                          {' '}
                          {client.first_name} {client.last_name}
                          <br />
                          {client.not_appeared === 0 ? (
                            ''
                          ) : (
                            <span className="not-appeared">
                              {t(TranslationKeys.client_not_appeared)}: {client.not_appeared}
                            </span>
                          )}
                        </td>
                      ) : (
                        <td className="td td-name">
                          {' '}
                          {client.first_name} {client.last_name}
                          <br />
                          {client.not_appeared === 0 ? (
                            ''
                          ) : (
                            <span className="not-appeared">
                              {t(TranslationKeys.client_not_appeared)}: {client.not_appeared}
                            </span>
                          )}
                        </td>
                      )}
                      <td className="td">{client.insurance_type_display}</td>
                      <td className="td td-doc">
                        <ViewDoctors client={client} />
                      </td>

                      <td className="td">
                        {client.phone}
                        <br />
                        {client.email}{' '}
                      </td>

                      <td
                        onClick={(e) => {
                          e.stopPropagation();
                          setSelectedIndex(client.id);
                          onClickOptions(client.id);
                        }}
                        className={'td td-btns'}
                      >
                        <ActionMenuClients
                          client={client}
                          options={options}
                          onClickOptions={onClickOptions}
                          selectedIndex={selectedIndex}
                          setSelectedIndex={setSelectedIndex}
                          onLoadHandler={onLoadHandler}
                          load={load}
                          setOptions={setOptions}
                          setNotAppeared={setNotAppeared}
                        />
                      </td>
                    </tr>
                  );
                })}
            </tbody>
          </table>
          {(nextPage !== null || previousPage !== null) && (
            <Pagination
              previous={previousPage}
              next={nextPage}
              current={currentPage}
              currentPage={countPagePagination}
              previousCallback={prevList}
              nextCallback={nextList}
              toFirstList={toFirstList}
              toLastList={toLastList}
            />
          )}
        </div>
      </div>
    </>
  );
});