import React, { FC, memo, useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Navigate, NavLink } from 'react-router-dom';
import { PATH } from '../../routes/Routes';
import './Contacts.scss';
import { ConfirmationPopup } from '../../components/Popups/ConfirmationPopup/ConfirmationPopup';
import Preloader from '../../components/Preloaders/Preloader';
import {
  getContactsSelector,
  getIsLoggedInSelector,
  getOpenSidebarAppSelector,
  getStatusSelector,
} from '../../redux/selectors';
import { useTranslation } from 'react-i18next';
import { ActionMenuContacts } from './ActionMenuContacts/ActionMenuContacts';
import { logo } from '../../assets/img/image';
import PreloaderClient from '../../components/Preloaders/ClientsPreload/PreloadClients';
import TranslationKeys from '../../assets/locales/translationKeys/translationKeys';
import { fetchContactsList, initializeAppTC, removeContactTC } from "../../redux/middlewares";
import { editStateMenuAC } from "../../redux/actions";

export const Contacts: FC = memo(() => {
  const dispatch = useDispatch();

  const contacts = useSelector(getContactsSelector);
  const status = useSelector(getStatusSelector);
  const isLoggedIn = useSelector(getIsLoggedInSelector);
  const isOpenSidebar = useSelector(getOpenSidebarAppSelector);

  //dialog alerts
  const [openAlertDialogForDelete, setOpenAlertDialogForDelete] = useState(false);
  const [modalDataId, setModalDataId] = useState<number>(0);
  const [options, setOptions] = useState(false);
  const [load, setLoad] = useState(false);
  const [selectedIndex, setSelectedIndex] = useState(0);

  //locale
  const { t } = useTranslation();

  const escFunction = useCallback((event) => {
    if (event.key === 'Escape') {
      setOpenAlertDialogForDelete(false);
    }
  }, []);

  const componentRef = useRef();

  useMemo(() => {
    const handleClick = (e: any): void => {
      if (componentRef && componentRef.current) {
        const ref: any = componentRef.current;
        if (!ref.contains(e.target)) {
          setOptions(false);
        }
      }
    };
    document.addEventListener('click', handleClick);
    return () => document.removeEventListener('click', handleClick);
  }, [dispatch]);

  useEffect(() => {
    document.addEventListener('keydown', escFunction, false);

    return () => {
      document.removeEventListener('keydown', escFunction, false);
    };
  }, []);

  useEffect(() => {
    if (status === 'failed') {
      dispatch(initializeAppTC());
    }
  }, [status]);

  useEffect(() => {
    if (isLoggedIn) {
      dispatch(fetchContactsList());
    }
  }, [dispatch]);
  useEffect(() => {
    document.title = t('contacts_nav');
  }, []);

  useEffect(() => {
    const remove = new Image();
    remove.src = logo.trash;
    remove.onload = () => {
      setLoad(true);
    };
    const img = new Image();
    img.src = logo.edit;
    img.onload = () => {
      setLoad(true);
    };
  });

  const removeContact = (id: number): void => {
    dispatch(removeContactTC(id));
    setModalDataId(0);
  };

  const openAlertHandler = (employeeId: number): void => {
    setModalDataId(employeeId);
    setOpenAlertDialogForDelete(true);
  };
  const closeAlertHandler = (): void => {
    setOpenAlertDialogForDelete(false);
  };

  const onClickOptions = (contactId: number): void => {
    dispatch(editStateMenuAC(false));
    if (options) {
      if (contactId === selectedIndex) {
        setOptions(false);
      } else {
        setOptions(true);
      }
    } else {
      setOptions(true);
    }
  };

  const onLoadHandler = (): void => {
    setLoad(true);
  };

  if (!isLoggedIn) {
    return <Navigate to={PATH.LOGIN} />;
  }

  return (
    <>
      <div ref={componentRef as any}></div>
      <div className={`${isOpenSidebar ? 'contact' : 'contact full-width'}`}>
        <div className="contact__header">
          <h1 className="contact__header__title">{t(TranslationKeys.contacts_nav)}</h1>
          <div className="contact__header__add">
            <NavLink
              className="button button_size-small button_position-left button_color-transparent"
              to={PATH.ADD_CONTACTS}
            >
              + {t(TranslationKeys.contact_add_btn)}
            </NavLink>
          </div>
        </div>

        <div>
          <table className="contact__table">
            <thead className="thead">
              <tr className="tr">
                <th className="th">
                  <span>{t(TranslationKeys.title)}</span>
                </th>
                <th className="th">
                  <span>
                    {t(TranslationKeys.phone_number)}
                    <br />
                    {t('email')}
                  </span>
                </th>
                <th className="th">
                  <span>{t(TranslationKeys.address)}</span>
                </th>
                <th
                  style={{
                    width: '166px',
                  }}
                  className="th"
                >
                  <span>{t(TranslationKeys.options)}</span>
                </th>
              </tr>
            </thead>

            <tbody className="tbody">
              {contacts.length === 0 && status === 'loading' ? (
                <tr>
                  <td>
                    <Preloader />
                  </td>
                </tr>
              ) : (
                status === 'loading' && (
                  <tr>
                    <td>
                      <PreloaderClient />
                    </td>
                  </tr>
                )
              )}

              {contacts.length > 0 &&
                contacts.map((contact) => (
                  <tr key={contact.id} className="tr">
                    <td className="td-name">{contact.title}</td>
                    <td className="td">
                      {contact.phone}
                      <br />
                      {contact.email}
                    </td>
                    <td className="td">{contact.address}</td>
                    <td
                      className="td-btns"
                      onClick={(e) => {
                        e.stopPropagation();
                        setSelectedIndex(contact.id);
                        onClickOptions(contact.id);
                      }}
                    >
                      <ActionMenuContacts
                        contact={contact}
                        options={options}
                        onClickOptions={onClickOptions}
                        selectedIndex={selectedIndex}
                        setSelectedIndex={setSelectedIndex}
                        onLoadHandler={onLoadHandler}
                        load={load}
                        setOptions={setOptions}
                        openAlertHandler={openAlertHandler}
                      />
                    </td>
                  </tr>
                ))}
              {openAlertDialogForDelete && (
                <>
                  <ConfirmationPopup
                    actionCallback={removeContact}
                    id={modalDataId}
                    titleQuestion={t(TranslationKeys.contact_delete)}
                    actionTitle={t(TranslationKeys.delete)}
                    onClickBtn={closeAlertHandler}
                    cancelCallback={closeAlertHandler}
                  />
                </>
              )}
            </tbody>
          </table>
        </div>
      </div>
    </>
  );
});
