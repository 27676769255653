import { Dispatch } from 'redux';
import {
  addOrderAC,
  changeStatusOrderAC,
  editOrderAC,
  setAppStatusAC,
  setClientsParamsAC,
  setCurrentOrdersQueryStringAC,
  setListOrderAC,
} from '../actions';
import { orderAPI } from '../../api/order-api';
import { AppRootStateType } from '../store';
import { handleServerAppError, handleServerNetworkError } from '../../utils/error-utils';
import { loadLang, loadToken } from '../../utils/localStorage';
import { setCurrentPageOrdersAC } from '../actions/orders';
import { getObjectFromQueryString } from '../../utils/getObjectFromQueryString';
import { getQueryStringFromObject } from '../../utils/getQueryStringFromObject';
import { OrdersQueryStringType } from './types';

export const fetchOrderList = () => async (dispatch: Dispatch<any>) => {
  dispatch(setAppStatusAC('loading'));
  try {
    let res = await orderAPI.getOrderList();
    dispatch(setListOrderAC(res.data));
    dispatch(setAppStatusAC('succeeded'));
  } catch (error: any) {}
  dispatch(setAppStatusAC('idle'));
};

export const addOrderTC =
  (data: any) => async (dispatch: Dispatch<any>, getState: () => AppRootStateType) => {
    dispatch(setAppStatusAC('loading'));

    const currentBranch = getState().branches.currentBranch;

    try {
      let res = await orderAPI.addOrder(data, currentBranch);
      dispatch(addOrderAC(res.data));
      dispatch(setAppStatusAC('succeeded'));
    } catch (error: any) {
      if (error.message === 'Network Error') {
        handleServerNetworkError(error, dispatch);
      } else {
        handleServerAppError(error, dispatch);
      }
    }
  };

export const changeStatusTC =
  (orderId: number, status: string) => async (dispatch: Dispatch<any>) => {
    try {
      const res = await orderAPI.changeStatusOrder(orderId, status);
      dispatch(changeStatusOrderAC(orderId, res.data));
      dispatch(setAppStatusAC('succeeded'));
    } catch (error: any) {
      if (error.message === 'Network Error') {
        handleServerNetworkError(error, dispatch);
      } else {
        handleServerAppError(error, dispatch);
      }
    }
  };

export const editOrderTC = (id: number, data: any) => async (dispatch: Dispatch<any>) => {
  dispatch(setAppStatusAC('loading'));
  try {
    let res = await orderAPI.editOrder(data, id);
    dispatch(editOrderAC(res.data));
    dispatch(setAppStatusAC('succeeded'));
  } catch (error: any) {
    if (error.message === 'Network Error') {
      handleServerNetworkError(error, dispatch);
    } else {
      handleServerAppError(error, dispatch);
    }
  }
};

export const getNextOrdersList =
  () => async (dispatch: Dispatch<any>, getState: () => AppRootStateType) => {
    const next = getState().orders.orders.links.next;

    const searchParams = next && new URL(next).search;
    const objSearchParams = getObjectFromQueryString(searchParams);
    const queryString = getQueryStringFromObject(
      objSearchParams as unknown as OrdersQueryStringType,
    );

    dispatch(setAppStatusAC('loading'));
    try {
      const tokenLS = await loadToken();
      const langLS = await loadLang();
      const res = await orderAPI.getNextList(tokenLS, langLS, next);
      dispatch(setClientsParamsAC(res.data));
      dispatch(setListOrderAC(res.data));
      dispatch(setCurrentPageOrdersAC(next));
      dispatch(setCurrentOrdersQueryStringAC(queryString));
      dispatch(setAppStatusAC('succeeded'));
    } catch (error: any) {
      if (error.message === 'Network Error') {
        handleServerNetworkError(error, dispatch);
      } else {
        handleServerAppError(error, dispatch);
      }
    }
    dispatch(setAppStatusAC('idle'));
  };

export const getPrevOrdersList =
  () => async (dispatch: Dispatch<any>, getState: () => AppRootStateType) => {
    const prev = getState().orders.orders.links.previous;

    const searchParams = prev && new URL(prev).search;
    const objSearchParams = getObjectFromQueryString(searchParams);
    const queryString = getQueryStringFromObject(
      objSearchParams as unknown as OrdersQueryStringType,
    );

    dispatch(setAppStatusAC('loading'));
    try {
      const tokenLS = await loadToken();
      const langLS = await loadLang();
      const res = await orderAPI.getNextList(tokenLS, langLS, prev);
      dispatch(setClientsParamsAC(res.data));
      dispatch(setListOrderAC(res.data));
      dispatch(setCurrentPageOrdersAC(prev));
      dispatch(setCurrentOrdersQueryStringAC(queryString));
      dispatch(setAppStatusAC('succeeded'));
    } catch (error: any) {
      if (error.message === 'Network Error') {
        handleServerNetworkError(error, dispatch);
      } else {
        handleServerAppError(error, dispatch);
      }
    }
    dispatch(setAppStatusAC('idle'));
  };