/**
 * "getObjectFromQueryString" takes "search" argument.
 * Returns a new object with query params.
 * @param {string} search - search params
 * */
// export const getObjectFromQueryString = (search: string | null): Object | null => {
//   const paramsEntries = search && new URLSearchParams(search).entries();
//   console.log(paramsEntries && Object.fromEntries(paramsEntries));
//   return paramsEntries && Object.fromEntries(paramsEntries);
// };
export const getObjectFromQueryString = (search: any): any => {
  const paramsEntries = new URLSearchParams(search).entries();
  return Object.fromEntries(paramsEntries);
};