import './PreloadClients.scss';
import { logo } from '../../../assets/img/image';
import { FC, useEffect, useRef, useState } from 'react';

type PreloadPropsType = {
}
const PreloaderClient: FC<PreloadPropsType> = ({}) => {
  const [top, setTop] = useState(0)

  const rootEl = useRef<HTMLDivElement>(null)

  useEffect(() => {
    document.body.style.overflow = 'hidden' // пока происходят любые обновления то overflow hidden

    const bounds  = rootEl.current?.getBoundingClientRect() // обращаемся к текущему DOM элементу и вызываем метод, который
    //возвращает объект DOMRect и содержит размеры элемента и его положение относительно видимой области просмотра.

    if (bounds?.top) { //Если top 
      const imageEl = document.querySelector<HTMLElement>('.preloader__client-img') //то тогда вернуть 
      //первый элемент соответсвующий css селектору, то есть img

      if (imageEl) { // если img
        if (bounds.top < 0)  { //если top меньше 0
          const height = bounds.height + bounds.top //то высота равна = высота объекта + top объекта
          //(типа чтобы была одна высота которая включает top который задаем для расположения таблицы ?)

          if (height < window.innerHeight) { //если высота меньше внутренней высоты окна,
            // включая высоту горизонтальной полосы прокрутки, если она присутствует
            setTop((bounds.top * -1) + height / 2) // то установить высоту(сложные математические вычисления =не понимаю)
          } else setTop((bounds.top * -1) + window.innerHeight / 2)  //иначе установить высоту(так же не понимаю)
        } else {
          if (bounds.height + bounds.top < window.innerHeight) { //
            setTop(bounds.height / 2)
          } else setTop((window.innerHeight - bounds.top) / 2)//
        }
      }
    }

    return () => {
      document.body.style.overflow = '' // если произошло вымонтирование то overflow ''
    }
  }, [])

  return (
    <div>
      <div ref={rootEl} className={/* backgroundLoader ? "preloader__event1" : */ 'preloader__client'}>
        <img src={logo.preloader} className="preloader__client-img" style={{top}} alt='preloader' />
      </div>
    </div>

  );
};
export default PreloaderClient;