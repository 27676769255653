import React, { FC, memo, useCallback, useEffect, useState } from 'react';
import './WorkSchedule.scss';
import { useDispatch, useSelector } from 'react-redux';
import { Navigate } from 'react-router-dom';
import { PATH } from '../../routes/Routes';
import { ModalAlertEditWorkSchedule } from '../../components/Popups/WorkSchedule/ModalAlertEditWorkSchedule';
import {
  getBranchWorkScheduleSelector,
  getIsLoggedInSelector,
  getOpenSidebarAppSelector,
  getStatusSelector,
  getUserProfileSelector,
} from '../../redux/selectors';
import { useTranslation } from 'react-i18next';
import TranslationKeys from '../../assets/locales/translationKeys/translationKeys';
import { fetchScheduleWork, fetchWorkScheduleListFieldsTC, initializeAppTC } from "../../redux/middlewares";

type WorkSchedulePropsType = {};

export const WorkSchedule: FC<WorkSchedulePropsType> = memo(() => {
  const dispatch = useDispatch();

  const branches = useSelector(getBranchWorkScheduleSelector);
  const status = useSelector(getStatusSelector);
  const isLoggedIn = useSelector(getIsLoggedInSelector);
  const user = useSelector(getUserProfileSelector);
  const isOpenSidebar = useSelector(getOpenSidebarAppSelector);

  //dialog alerts
  const [openAlertDialogForEdit, setOpenAlertDialogForEdit] = useState<boolean>(false);
  const [modalDataId, setModalDataId] = useState<number>(0);
  const [modalDataWorkScheduleId, setModalDataWorkScheduleId] = useState<number>(0);

  //locale
  const { t } = useTranslation();

  const escFunction = useCallback((event) => {
    if (event.key === 'Escape') {
      setOpenAlertDialogForEdit(false);
    }
  }, []);

  useEffect(() => {
    document.addEventListener('keydown', escFunction, false);

    return () => {
      document.removeEventListener('keydown', escFunction, false);
    };
  }, []);

  useEffect(() => {
    if (status === 'failed') {
      dispatch(initializeAppTC());
    }
  }, [status]);
  useEffect(() => {
    document.title = t(TranslationKeys.work_shedule_nav)
  }, []);

  useEffect(() => {
    if (isLoggedIn) {
      dispatch(fetchScheduleWork());
    }
  }, [openAlertDialogForEdit]);

  useEffect(() => {
    if (isLoggedIn) {
      dispatch(fetchWorkScheduleListFieldsTC());
    }
  }, [dispatch]);

  const openAlertHandler = (branchId: number): void => {
    setModalDataId(branchId);
    setOpenAlertDialogForEdit(true);
  };
  const closeAlertHandler = (): void => {
    setOpenAlertDialogForEdit(false);
  };

  if (!isLoggedIn) {
    return <Navigate to={PATH.LOGIN} />;
  }

  return (
    <>
      <div className={`${isOpenSidebar ? 'schedule-work' : 'schedule-work full-width'}`}>
        <div className="schedule-work__title-wrapper">
          <h1 className="title">{t(TranslationKeys.work_shedule_nav)}</h1>
        </div>
        <div>
          {branches.map((branch) => (
            <div className="schedule-work__table-wrapper" key={branch.id}>
              <h2 className="branch-title">{branch.name}</h2>
              <table className="table">
                <thead className="thead">
                  <tr className="tr">
                    {branch.workschedule
                      .sort((a, b) => (a.day_of_week > b.day_of_week ? 1 : -1))
                      .map((day) => (
                        <th key={day.id} className="th">
                          <span>{day.day_of_week_display}</span>
                        </th>
                      ))}
                  </tr>
                </thead>
                <tbody className="tbody">
                  <tr key={branch.id} className="branch">
                    {branch.workschedule
                      .sort((a, b) => (a.day_of_week > b.day_of_week ? 1 : -1))
                      .map((w) => (
                        <td key={w.id} className={`${w.is_weekend ? 'td td-weekend' : 'td'}`}>
                          {!w.is_weekend && (
                            <div>
                              <b>{t(TranslationKeys.work_time)}: </b>
                              <p>
                                {t(TranslationKeys.start)}: {w.start_time_work.slice(0, 5)}
                              </p>
                              <p>
                                {' '}
                                {t(TranslationKeys.end)}: {w.end_time_work.slice(0, 5)}
                              </p>
                              {w.lunch_break && (
                                <div>
                                  <b>{t(TranslationKeys.lunch)}: </b>
                                  <p>
                                    {t(TranslationKeys.start)}: {w.start_lunch_break.slice(0, 5)}
                                  </p>
                                  <p>
                                    {t(TranslationKeys.end)}: {w.end_lunch_break.slice(0, 5)}
                                  </p>
                                </div>
                              )}
                              {!w.lunch_break && (
                                <div className="lunch-hidden">
                                  <b>{t(TranslationKeys.lunch)}: </b>
                                  <p>
                                    {t(TranslationKeys.start)}: {w.start_lunch_break}
                                  </p>
                                  <p>
                                    {t(TranslationKeys.end)}: {w.end_lunch_break}
                                  </p>
                                </div>
                              )}
                            </div>
                          )}
                          {w.is_weekend && (
                            <div className="weekend">
                              <b className={'day-weekend-title'}>{t(TranslationKeys.weekend)}</b>
                            </div>
                          )}
                          {user.role !== 'doctor' ? (
                            <div className="btn-wrapper">
                              <button
                                onClick={() => {
                                  openAlertHandler(branch.id);
                                  setModalDataWorkScheduleId(w.id);
                                }}
                                className="button button_size-small button_position-right button_color-transparent"
                              >
                                {t(TranslationKeys.edit)}
                              </button>
                            </div>
                          ) : (
                            ''
                          )}
                        </td>
                      ))}
                  </tr>
                </tbody>
              </table>
              {openAlertDialogForEdit && (
                <>
                  <ModalAlertEditWorkSchedule
                    workSchedule={branch.workschedule}
                    branchId={modalDataId}
                    workScheduleId={modalDataWorkScheduleId}
                    cancelCallback={closeAlertHandler}
                  />
                </>
              )}
            </div>
          ))}
        </div>
      </div>
    </>
  );
});

