import { EmployeeType } from "../../api/employees-api";
import { ADD_EMPLOYEE, EDIT_EMPLOYEE, REMOVE_EMPLOYEE, SET_LIST_EMPLOYEES } from "./constants";

export const setListEmployeesAC = (employees: Array<EmployeeType>) =>
  ({ type: SET_LIST_EMPLOYEES, employees } as const);
export const addEmployeeAC = (
  id: number,
  username: string,
  firstName: string,
  lastName: string,
  branch_set: [],
  role: any,
  external: boolean,
) =>
  ({
    type: ADD_EMPLOYEE,
    payload: { id, username, firstName, lastName, branch_set, role, external, },
  } as const);
export const editEmployeeAC = (
  id: number,
  username: string,
  firstName: string,
  lastName: string,
  role: any,
  external: boolean,
) => ({ type: EDIT_EMPLOYEE, payload: { id, username, firstName, lastName, role, external } } as const);
export const removeEmployeesAC = (id: number) => ({ type: REMOVE_EMPLOYEE, id } as const);
