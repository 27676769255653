import React, { FC } from 'react';
import { logo } from '../../../assets/img/image';
import { NavLink } from 'react-router-dom';
import { PATH } from '../../../routes/Routes';
import { BranchType } from '../../../api/branches-api';
import { useTranslation } from 'react-i18next';
import cx from 'classnames';
import Collapse from '@kunukn/react-collapse';
import { MenuBtn } from '../../../components/MenuBtn/MenuBtn';
import TranslationKeys from '../../../assets/locales/translationKeys/translationKeys';
import './ActionMenuBranches.scss';

type ActionMenuPropsType = {
  branch: BranchType;
  options: boolean;
  onClickOptions: (branchId: number) => void;
  selectedIndex: number;
  setSelectedIndex: (value: number) => void;
  onLoadHandler: () => void;
  load: boolean;
  setOptions: (value: boolean) => void;
  openAlertHandler: (value: number) => void;
  openAlertHandlerIframe: (value: number) => void;
};

export const ActionMenuBranches: FC<ActionMenuPropsType> = ({
  onClickOptions,
  selectedIndex,
  branch,
  options,
  setSelectedIndex,
  onLoadHandler,
  load,
  setOptions,
  openAlertHandler,
  openAlertHandlerIframe,
}) => {
  const { t } = useTranslation();

  const onClickOptionHandler = (): void => {
    onClickOptions(branch.id);
  };

  return (
    <>
      <div
        onClick={() => {
          onClickOptions(branch.id);
          setSelectedIndex(branch.id);
        }}
        className={cx('app__toggle', {
          'app__toggle--active': options,
        })}
      >
        <div>
          <MenuBtn
            showSidebar={onClickOptionHandler}
            isOpenMenu={options && selectedIndex === branch.id}
          />
        </div>

        <Collapse
          isOpen={options && selectedIndex === branch.id}
          className={
            'app__collapse app__collapse--gradient ' +
            (options && selectedIndex === branch.id ? 'app__collapse--active' : '')
          }
        >
          <div className="app__content">
            <div className="options">
              <NavLink className={'actions'} to={`${PATH.BRANCHES}${branch.id}/`}>
                <img
                  alt="edit"
                  onLoad={onLoadHandler}
                  className={load ? 'img' : 'img'}
                  src={logo.edit}
                />
                {t(TranslationKeys.edit)}
              </NavLink>

              <span
                className="actions"
                onClick={() => {
                  setOptions(false);
                  openAlertHandler(branch.id);
                }}
              >
                <img onLoad={onLoadHandler} alt="remove" className={'img'} src={logo.trash} />
                {t(TranslationKeys.delete)}
              </span>

              <span
                className="actions"
                onClick={() => {
                  setOptions(false);
                  openAlertHandlerIframe(branch.id);
                }}
              >
                <img onLoad={onLoadHandler} alt="iframe" className={'img'} src={logo.iframe} />
                {t(TranslationKeys.iframe)}
              </span>
            </div>
          </div>
        </Collapse>
      </div>
    </>
  );
};
