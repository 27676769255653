// @ts-ignore
import termiNowLogo from './logo.svg';
// @ts-ignore
import noLogo from './no_image.png';
// @ts-ignore
import arrow_down from './icons/arrows/arrow_down.svg';
// @ts-ignore
import arrow_up from './icons/arrows/arrow_up.svg';
// @ts-ignore
import pencil from './icons/pencil.svg';
// @ts-ignore
import backet from './icons/backet.svg';
// @ts-ignore
import arrow_r from './icons/arrows/arrow_next.svg';
// @ts-ignore
import arrow_l from './icons/arrows/arrow_prev.svg';
// @ts-ignore
import calendar from './icons/calendar.svg';
// @ts-ignore
import time from './icons/times.svg';
// @ts-ignore
import iconClose from './icons/close/close_large_red.png';
// @ts-ignore
import closeLarge from './icons/close/close_large_red.png';
//@ts-ignore
import iconOk from './icons/checkmarks/checkmark_green.svg';
// @ts-ignore
import checkmarkGreen from './icons/checkmarks/checkmark_green.svg';
//@ts-ignore
import edit from './icons/actions_events/edit.svg';
//@ts-ignore
import cancel from './icons/actions_events/cancel.svg';
//@ts-ignore
import info from './icons/actions_events/info.svg';
//@ts-ignore
import waiting from './icons/actions_events/waiting.svg';
//@ts-ignore
import done from './icons/actions_events/done.svg';
//@ts-ignore
import preloader from './preloader.gif';
//@ts-ignore
import add from './icons/actions_events/add.svg';
//@ts-ignore
import add_gray from './icons/actions_events/add_gray.svg';
//@ts-ignore
import appeared from './icons/actions_events/xmark_solid.svg';
//@ts-ignore
import trash from './icons/actions_events/trash.svg';
//@ts-ignore
import download from './icons/actions_events/download.svg';
//@ts-ignore
import iframe from './icons/actions_events/iframe.svg';
//@ts-ignore
import en from './icons/en.png';
//@ts-ignore
import de from './icons/de.png';
//@ts-ignore
import arrowSort from './icons/arrows/arrow_sort.svg';
//@ts-ignore
import arrowGray from './icons/arrows/arrow_down_gray.svg';
//@ts-ignore
import check from './icons/checkmarks/checkmark_green.svg';
//@ts-ignore
import close from './icons/close/close_large_red.png';
//@ts-ignore
import close_gray from './icons/close/cross_gray.svg';
//@ts-ignore
import branch from './icons/menu_right/branch.svg';
//@ts-ignore
import notification from './notification.svg';
//@ts-ignore
import doctor_icon from './icons/doctor-icon.svg';

export const logo = {
  termiLogo: termiNowLogo,
  notLogo: noLogo,
  arrow_down: arrow_down,
  arrow_up: arrow_up,
  add_photo: pencil,
  remove_photo: backet,
  arrow_right: arrow_r,
  arrow_left: arrow_l,
  calendar: calendar,
  time: time,
  iconClose: iconClose,
  iconOk: iconOk,
  closeLarge: closeLarge,
  checkmarkGreen: checkmarkGreen,
  edit: edit,
  cancel: cancel,
  info: info,
  waiting: waiting,
  done: done,
  preloader: preloader,
  add: add,
  add_gray: add_gray,
  en: en,
  de: de,
  appeared: appeared,
  trash: trash,
  download: download,
  arrowSort: arrowSort,
  arrowGray: arrowGray,
  iframe: iframe,
  check: check,
  close: close,
  close_gray: close_gray,
  branch: branch,
  notification: notification,
  doctor_icon: doctor_icon
};

export const addPhotoImg = {
  backgroundImage: `url(${pencil})`,
};
export const removePhotoImg = {
  backgroundImage: `url(${backet})`,
};
export const defaultPhotoImg = {
  backgroundImage: `url(${noLogo})`,
};
