import { instance } from './http';
import { HolidayResponseType } from './holidays-api';
import { DoctorType } from './reasons-api';

export const branchesAPI = {
  getList() {
    return instance.get<Array<BranchType>>('/api/branches/');
  },

  addBranch(data: any) {
    return instance.post<BranchType>('/api/branches/create/', data);
  },

  editBranch(data: any, branchId: number) {
    return instance.put<BranchType>(`/api/branches/${branchId}/`, data);
  },

  removeBranch(branchId: number) {
    return instance.delete<any>(`/api/branches/${branchId}/`);
  },

  getScheduleBranch(branchId: number, date: string | null) {
    return instance.get<ScheduleBranchType>(`/api/branches/${branchId}/schedule/?day=${date}`);
  },

  getScheduleFirstRenderBranch(branchId: number) {
    return instance.get<ScheduleBranchType>(`/api/branches/${branchId}/schedule/`);
  },

  getScheduleBranchOfWeekly(branchId: number, week: string | null | Array<string>, doctorId?: Array<string>) {
    return instance.get<ScheduleBranchType>(
      `/api/branches/${branchId}/schedule/?day=${week}&doctor__in=${doctorId}`);
  },
};

export type DateListEventType = {
  branch: number;
  branch_display: string;
  client: any;
  client_display: string;
  created_on: string;
  date_birth: string;
  desired_date: boolean;
  email: string;
  email_reminded: boolean;
  end: string;
  first_name: string;
  gender: number;
  gender_display: string;
  id: number;
  insurance_type: string;
  insurance_type_display: string;
  is_client: boolean;
  is_client_display: string;
  last_name: string;
  message: string;
  phone: string;
  reason: number;
  reason_display: string;
  sms_reminded: boolean;
  start: string;
  status: string;
  status_display: string;
  updated_on: string;
  uuid_field: string;
  from_start: string;
  reason_color: string;
  doctor: DoctorType;
  visit_time: string;
  waiting_time: string;
  finished_at: string;
  note: any;
  start_waiting_at: string;
};

export type BranchType = {
  id: number;
  name: string;
  phone: string;
  whatsapp: string;
  email: string;
  sity: string;
  street: string;
  street_number: string;
  zip_code: string;
  show_title: boolean;
  free_date: boolean;
  employees: any;
  sms_remind_text: string;
  email_remind_text: string;
  email_cancel_text: string;
  email_order_text: string;
  sms_cancel_text: string;
  sms_order_text: string;
  concurrent_records: any;
  desired_date: boolean;
  doctors: DoctorType[];
  no_free_date_text: string;
};

export type DateOfListType = {
  date: string;
  events: Array<DateListEventType>;
  date_index: number;
  date_name: string;
  holidays: HolidayResponseType[];
  end_lunch_break: string
  end_time_work: string
  start_lunch_break: string
  start_time_work: string
  lunch_break: boolean
};

export type ScheduleBranchType = {
  next_week: string;
  previous_week: string;
  current_week: Array<string>;
  date_of_lists: Array<DateOfListType>;
  day_end: string;
  day_start: string;
};

