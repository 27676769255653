import React, { FC } from 'react';
import { logo } from '../../../assets/img/image';
import './Pagination.scss';

type PaginationPropsType = {
  previous: string | null;
  next: string | null;
  current: number;
  currentPage: number;
  previousCallback: () => void;
  nextCallback: () => void;
  toFirstList?: () => void;
  toLastList?: () => void;
};

export const Pagination: FC<PaginationPropsType> = ({
  previous,
  next,
  currentPage,
  current,
  previousCallback,
  nextCallback,
  toFirstList,
  toLastList,
}) => {
  const btnPrevClassName = previous === null ? 'btn-pagination-disabled' : 'btn-pagination';
  const btnNextClassName = next === null ? 'btn-pagination-disabled' : 'btn-pagination';
  const btnToFirstClassName =
    current === 1 ? 'btn-pagination-to-first-disabled' : 'btn-pagination-to-first';
  const btnToLastClassName =
    next === null ? 'btn-pagination-to-last-disabled' : 'btn-pagination-to-last';
  const toFirstListHandler = (): void => {
    toFirstList && toFirstList();
  };

  const toLastListHandler = (): void => {
    toLastList && toLastList();
  };

  return (
    <div className="pagination">
      <div className="button-container">
        <button onClick={toFirstListHandler} disabled={current === 1} className={btnToFirstClassName}>
          <img className="to_first-arrow" src={logo.arrow_left} alt="first" />
          <img src={logo.arrow_left} alt="first" />
        </button>

        <button
          className={btnPrevClassName}
          disabled={previous === null}
          onClick={previousCallback}
        >
          <img src={logo.arrow_left} alt="previous" />
        </button>
        <span className="page-number">
          {current} / {currentPage}
        </span>
        <button className={btnNextClassName} disabled={next === null} onClick={nextCallback}>
          <img src={logo.arrow_right} alt="next" />
        </button>

        <button onClick={toLastListHandler} disabled={next === null} className={btnToLastClassName}>
          <img className="to_last-arrow" src={logo.arrow_right} alt="first" />
          <img src={logo.arrow_right} alt="first" />
        </button>
      </div>
    </div>
  );
};