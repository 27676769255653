import { instance } from './http';

export const reasonsAPI = {
  getList() {
    return instance.get<Array<ReasonType>>('/api/reasons/');
  },

  getSortList(ordering?: string | null) {
    return instance.get<Array<ReasonType>>(`/api/reasons/?ordering=${ordering}`);
  },

  addReason(data: any) {
    return instance.post<ReasonType>('/api/reasons/create/', data);
  },

  editReason(data: any, reasonId: number) {
    return instance.put<ReasonType>(`/api/reasons/${reasonId}/`, data);
  },

  removeReason(reasonId: number) {
    return instance.delete<any>(`/api/reasons/${reasonId}/`);
  },
};

//types
export type BrunchesType = {
  branches: Array<number>;
};

export type DoctorType = {
  id: number;
  first_name: string;
  last_name: string;
  email: string;
  photo: null;
  phone: null;
  role: string;
  owner: number;
  owner_display: number;
};

export type BranchType = {
  id: number;
  name: string;
};

export type ReasonType = {
  id: number;
  branches: Array<BranchType>;
  title: string;
  offset_time: number;
  limit_time: number;
  color: string;
  order: number;
  doctors: Array<DoctorType>;
  doctor_display: string;
  offset_time_display: string;
  external: boolean;
};

