import { EmployeeType } from '../../api/employees-api';
import {
  ADD_EMPLOYEE,
  EDIT_EMPLOYEE,
  REMOVE_EMPLOYEE,
  RESET_REDUCER_GROUP,
  SET_LIST_EMPLOYEES,
} from '../actions/constants';
import { EmployeesActionsType } from '../actions/types';

const initialState = [] as Array<EmployeeType>;

export const employeesReducer = (
  state: Array<EmployeeType> = initialState,
  action: EmployeesActionsType,
): Array<EmployeeType> => {
  switch (action.type) {
    case SET_LIST_EMPLOYEES:
      return action.employees.map((tl) => ({ ...tl }));
    case ADD_EMPLOYEE: {
      return [
        ...state,
        {
          id: action.payload.id,
          username: action.payload.username,
          first_name: action.payload.firstName,
          last_name: action.payload.lastName,
          branch_set: action.payload.branch_set,
          role: action.payload.role,
          external: action.payload.external,
        },
      ];
    }
    case EDIT_EMPLOYEE: {
      return state.map((e) =>
        e.id === action.payload.id
          ? {
              ...e,
              username: action.payload.username,
              first_name: action.payload.firstName,
              last_name: action.payload.lastName,
              role: action.payload.role,
              external: action.payload.external,
            }
          : e,
      );
    }
    case REMOVE_EMPLOYEE: {
      return state.filter((e) => e.id !== action.id);
    }

    case RESET_REDUCER_GROUP:
      return { ...initialState };

    default:
      return state;
  }
};
