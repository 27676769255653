import { Dispatch } from 'redux';
import {
  changeStatusRequestProfileAC,
  setAppStatusAC,
  setProfileDataAC,
  updateProfileDateAC,
} from '../actions';
import { profileAPI } from '../../api/profile-api';
import { handleServerAppError, handleServerNetworkError } from '../../utils/error-utils';
import { loadProfileDataLS, saveProfileDataLS } from '../../utils/localStorage';
import { UpdateProfileDataModelType } from '../actions/types';

export const getProfileTC = () => async (dispatch: Dispatch<any>) => {
  dispatch(setAppStatusAC('loading'));
  try {
    let response = await profileAPI.getProfile();
    dispatch(setProfileDataAC(response.data));
    dispatch(setAppStatusAC('succeeded'));
  } catch (error: any) {
    if (error.message === 'Network Error') {
      handleServerNetworkError(error, dispatch);
    } else {
      handleServerAppError(error, dispatch);
    }
  }
  dispatch(setAppStatusAC('idle'));
};

export const updateProfileTC =
  (data: UpdateProfileDataModelType) => async (dispatch: Dispatch<any>) => {
    dispatch(setAppStatusAC('loading'));
    try {
      let response = await profileAPI.updateProfile(data);
      saveProfileDataLS(response.data);
      const user = await loadProfileDataLS();
      dispatch(updateProfileDateAC(user));
      dispatch(setAppStatusAC('succeeded'));
      dispatch(changeStatusRequestProfileAC(true));
    } catch (error: any) {
      if (error.message === 'Network Error') {
        handleServerNetworkError(error, dispatch);
      } else {
        handleServerAppError(error, dispatch);
      }
      dispatch(changeStatusRequestProfileAC(false));
    }
  };