import React, { FC, useEffect, useMemo, useState } from 'react';
import './FormOrders.scss';
import Inputs from '../../../components/Widgets/Inputs';
import { useDispatch, useSelector } from 'react-redux';
import { SubmitHandler, useForm } from 'react-hook-form';
import { Navigate, useParams } from 'react-router-dom';
import {
  defaultChoicesMultiSelect,
  defaultChoicesSelect,
  newOption,
} from '../../../components/Widgets/Select/ReactSelectStyles';
import { ReactSelect } from '../../../components/Widgets/Select/ReactSelect';
import { PATH } from '../../../routes/Routes';
import Textarea from '../../../components/Widgets/Textarea';
import { useErrorHandler } from '../../../hooks/useErrorHandler';
import {
  getCurrentBranchSelector,
  getCurrQueryStringOrdersSelector,
  getErrorStateSelector,
  getFieldsOrdersSelector,
  getIsLoggedInSelector,
  getOpenSidebarAppSelector,
  getOrdersSelector,
  getStatusSelector,
} from '../../../redux/selectors';
import { ErrorMessage } from '../../../components/ErrorMessage/ErrorMessage';
import { useTranslation } from 'react-i18next';
import dayjs from 'dayjs';
import { ReactDatePicker } from '../../../components/Widgets/ReactDatePicker';
import TranslationKeys from '../../../assets/locales/translationKeys/translationKeys';
import {
  addOrderTC,
  editOrderTC,
  fetchFilteredOrdersListOfGeneral,
  fetchOrderListFieldsTC,
} from '../../../redux/middlewares';

type FormDataType = {
  comment: null;
  client: { value: any; label: any };
  branch_creator: { value: any; label: any };
  branch_recipient: { value: any; label: any };
  doctor: { value: any; label: any };
  event: { value: any; label: any };
  material: {};
  tooth_color: null;
  deadline: string | Date;
  TP18: null;
  TP17: null;
  TP16: null;
  TP15: null;
  TP14: null;
  TP13: null;
  TP12: null;
  TP11: null;

  TP21: null;
  TP22: null;
  TP23: null;
  TP24: null;
  TP25: null;
  TP26: null;
  TP27: null;
  TP28: null;

  TP48: null;
  TP47: null;
  TP46: null;
  TP45: null;
  TP44: null;
  TP43: null;
  TP42: null;
  TP41: null;

  TP31: null;
  TP32: null;
  TP33: null;
  TP34: null;
  TP35: null;
  TP36: null;
  TP37: null;
  TP38: null;

  R18: null;
  R17: null;
  R16: null;
  R15: null;
  R14: null;
  R13: null;
  R12: null;
  R11: null;

  R21: null;
  R22: null;
  R23: null;
  R24: null;
  R25: null;
  R26: null;
  R27: null;
  R28: null;

  R48: null;
  R47: null;
  R46: null;
  R45: null;
  R44: null;
  R43: null;
  R42: null;
  R41: null;
  R31: null;
  R32: null;
  R33: null;
  R34: null;
  R35: null;
  R36: null;
  R37: null;
  R38: null;

  B18: null;
  B17: null;
  B16: null;
  B15: null;
  B14: null;
  B13: null;
  B12: null;
  B11: null;
  B21: null;
  B22: null;
  B23: null;
  B24: null;
  B25: null;
  B26: null;
  B27: null;
  B28: null;
  B48: null;
  B47: null;
  B46: null;
  B45: null;
  B44: null;
  B43: null;
  B42: null;
  B41: null;
  B31: null;
  B32: null;
  B33: null;
  B34: null;
  B35: null;
  B36: null;
  B37: null;
  B38: null;
};

export const FormOrders: FC = () => {
  const dispatch = useDispatch();
  const params = useParams();

  const orders = useSelector(getOrdersSelector);
  const fields = useSelector(getFieldsOrdersSelector);
  const status = useSelector(getStatusSelector);
  const isLoggedIn = useSelector(getIsLoggedInSelector);
  const errorState = useSelector(getErrorStateSelector);
  const isOpenSidebar = useSelector(getOpenSidebarAppSelector);
  const currentBranch = useSelector(getCurrentBranchSelector);
  const currentQueryString = useSelector(getCurrQueryStringOrdersSelector);

  //locale
  const { t } = useTranslation();

  //for edit
  const idUrl = params.id;
  const orderId = Number(idUrl);
  const currentOrder = orders.length && orders.find((order) => order.id === orderId);

  const [valueOrder, setValueOrder] = useState<boolean>(false);

  const choicesClient = Object.entries(fields.client.choices).map((br) => br);
  const valueOptionClient =
    fields && Object.entries(fields.client.choices).map((b: any) => newOption(b[0], b[1]));
  const labelOptionClient = currentOrder && currentOrder?.client?.toString();

  const choicesMaterial = Object.entries(fields.material.choices).map((br) => br);
  const valueOptionMaterial =
    fields && Object.entries(fields.material.choices).map((b: any) => newOption(b[0], b[1]));
  const labelOptionMaterial = currentOrder && currentOrder?.material?.toString();

  const choicesRecipient = Object.entries(fields.branch_recipient.choices).map((br) => br);
  const valueOptionRecipient =
    fields &&
    Object.entries(fields.branch_recipient.choices).map((b: any) => newOption(b[0], b[1]));
  const labelOptionRecipient = currentOrder && currentOrder?.branch_recipient?.toString();

  const choicesDoctor = Object.entries(fields.doctor.choices).map((br) => br);
  const valueOptionDoctor =
    fields && Object.entries(fields.doctor.choices).map((b: any) => newOption(b[0], b[1]));
  const labelOptionDoctor = currentOrder && currentOrder?.doctor?.toString();

  const choicesEvent = Object.entries(fields.event.choices).map((br) => br);
  const valueOptionEvent =
    fields && Object.entries(fields.event.choices).map((b: any) => newOption(b[0], b[1]));
  const labelOptionEvent = currentOrder && currentOrder?.event?.toString();

  const [selectedDate] = useState<any>(new Date());
  //validation
  const formDataArray = [
    'client',
    'material',
    'tooth_color',
    'deadline',
    'branch_creator',
    'branch_recipient',
    'event',
    'doctor',
  ];

  const clientErrorHandler = useErrorHandler('client', errorState, formDataArray);
  const materialErrorHandler = useErrorHandler('material', errorState, formDataArray);
  const toothColorErrorHandler = useErrorHandler('tooth_color', errorState, formDataArray);
  const deadlineErrorHandler = useErrorHandler('deadline', errorState, formDataArray);
  const recipientErrorHandler = useErrorHandler('branch_recipient', errorState, formDataArray);
  const doctorErrorHandler = useErrorHandler('doctor', errorState, formDataArray);
  const eventErrorHandler = useErrorHandler('event', errorState, formDataArray);

  const orderData = currentOrder && {
    client: currentOrder.client
      ? defaultChoicesSelect(valueOptionClient, labelOptionClient)[0]
      : '',
    material: currentOrder.material
      ? defaultChoicesSelect(valueOptionMaterial, labelOptionMaterial)[0]
      : '',
    branch_recipient: currentOrder.branch_recipient
      ? defaultChoicesMultiSelect(valueOptionRecipient, labelOptionRecipient)[0]
      : '',
    doctor: currentOrder.doctor
      ? defaultChoicesMultiSelect(valueOptionDoctor, labelOptionDoctor)[0]
      : '',
    event: currentOrder.event
      ? defaultChoicesMultiSelect(valueOptionEvent, labelOptionEvent)[0]
      : '',
    tooth_color: currentOrder.tooth_color ? currentOrder.tooth_color : null,
    deadline: currentOrder.deadline ? new Date(currentOrder.deadline) : new Date(),
    TP18: currentOrder.TP18,
    TP17: currentOrder.TP17,
    TP16: currentOrder.TP16,
    TP15: currentOrder.TP15,
    TP14: currentOrder.TP14,
    TP13: currentOrder.TP13,
    TP12: currentOrder.TP12,
    TP11: currentOrder.TP11,

    TP21: currentOrder.TP21,
    TP22: currentOrder.TP22,
    TP23: currentOrder.TP23,
    TP24: currentOrder.TP24,
    TP25: currentOrder.TP25,
    TP26: currentOrder.TP26,
    TP27: currentOrder.TP27,
    TP28: currentOrder.TP28,

    TP48: currentOrder.TP48,
    TP47: currentOrder.TP47,
    TP46: currentOrder.TP46,
    TP45: currentOrder.TP45,
    TP44: currentOrder.TP44,
    TP43: currentOrder.TP43,
    TP42: currentOrder.TP42,
    TP41: currentOrder.TP41,

    TP31: currentOrder.TP31,
    TP32: currentOrder.TP32,
    TP33: currentOrder.TP33,
    TP34: currentOrder.TP34,
    TP35: currentOrder.TP35,
    TP36: currentOrder.TP36,
    TP37: currentOrder.TP37,
    TP38: currentOrder.TP38,

    R18: currentOrder.R18,
    R17: currentOrder.R17,
    R16: currentOrder.R16,
    R15: currentOrder.R15,
    R14: currentOrder.R14,
    R13: currentOrder.R13,
    R12: currentOrder.R12,
    R11: currentOrder.R11,

    R21: currentOrder.R21,
    R22: currentOrder.R22,
    R23: currentOrder.R23,
    R24: currentOrder.R24,
    R25: currentOrder.R25,
    R26: currentOrder.R26,
    R27: currentOrder.R27,
    R28: currentOrder.R28,

    R48: currentOrder.R48,
    R47: currentOrder.R47,
    R46: currentOrder.R46,
    R45: currentOrder.R45,
    R44: currentOrder.R44,
    R43: currentOrder.R43,
    R42: currentOrder.R42,
    R41: currentOrder.R41,

    R31: currentOrder.R31,
    R32: currentOrder.R32,
    R33: currentOrder.R33,
    R34: currentOrder.R34,
    R35: currentOrder.R35,
    R36: currentOrder.R36,
    R37: currentOrder.R37,
    R38: currentOrder.R38,

    B18: currentOrder.B18,
    B17: currentOrder.B17,
    B16: currentOrder.B16,
    B15: currentOrder.B15,
    B14: currentOrder.B14,
    B13: currentOrder.B13,
    B12: currentOrder.B12,
    B11: currentOrder.B11,

    B21: currentOrder.B21,
    B22: currentOrder.B22,
    B23: currentOrder.B23,
    B24: currentOrder.B24,
    B25: currentOrder.B25,
    B26: currentOrder.B26,
    B27: currentOrder.B27,
    B28: currentOrder.B28,

    B48: currentOrder.B48,
    B47: currentOrder.B47,
    B46: currentOrder.B46,
    B45: currentOrder.B45,
    B44: currentOrder.B44,
    B43: currentOrder.B43,
    B42: currentOrder.B42,
    B41: currentOrder.B41,

    B31: currentOrder.B31,
    B32: currentOrder.B32,
    B33: currentOrder.B33,
    B34: currentOrder.B34,
    B35: currentOrder.B35,
    B36: currentOrder.B36,
    B37: currentOrder.B37,
    B38: currentOrder.B38,
  };

  useEffect(() => {
    if (isLoggedIn) {
      dispatch(fetchOrderListFieldsTC(currentBranch));
      if (currentQueryString) {
        dispatch(fetchFilteredOrdersListOfGeneral(undefined, currentQueryString));
      } else {
        dispatch(fetchFilteredOrdersListOfGeneral(currentBranch.toString()));
      }
    }
  }, [dispatch]);

  useEffect(() => {
    if (status === 'failed') {
      clientErrorHandler.setErrorCallback();
      materialErrorHandler.setErrorCallback();
      toothColorErrorHandler.setErrorCallback();
      deadlineErrorHandler.setErrorCallback();
      recipientErrorHandler.setErrorCallback();
      doctorErrorHandler.setErrorCallback();
      eventErrorHandler.setErrorCallback();
    }
  }, [status]);

  const { register, handleSubmit, control, reset } = useForm<FormDataType>({
    mode: 'onBlur',
    defaultValues: useMemo(() => {
      if (orderData) {
        return orderData;
      }
    }, [orderData]),
  });

  useEffect(() => {
    if (orderData) {
      reset(orderData);
    }
  }, [currentOrder, fields]);
  useEffect(() => {
    document.title = currentOrder
      ? t(TranslationKeys.order_edit)
      : t(TranslationKeys.order_form_add);
  }, []);

  const onSubmit: SubmitHandler<FormDataType> = (data) => {
    const newData = {
      tooth_color: data.tooth_color,
      client: data.client && Object.values(data.client)[0],
      branch_recipient: data.branch_recipient && data.branch_recipient.value,
      doctor: data.doctor && data.doctor.value,
      event: data.event && data.event.value,
      material: data.material && Object.values(data.material)[0],
      deadline: dayjs(data.deadline).format('YYYY-MM-DD'),
      TP18: data.TP18,
      TP17: data.TP17,
      TP16: data.TP16,
      TP15: data.TP15,
      TP14: data.TP14,
      TP13: data.TP13,
      TP12: data.TP12,
      TP11: data.TP11,

      TP21: data.TP21,
      TP22: data.TP22,
      TP23: data.TP23,
      TP24: data.TP24,
      TP25: data.TP25,
      TP26: data.TP26,
      TP27: data.TP27,
      TP28: data.TP28,

      TP48: data.TP48,
      TP47: data.TP47,
      TP46: data.TP46,
      TP45: data.TP45,
      TP44: data.TP44,
      TP43: data.TP43,
      TP42: data.TP42,
      TP41: data.TP41,

      TP31: data.TP31,
      TP32: data.TP32,
      TP33: data.TP33,
      TP34: data.TP34,
      TP35: data.TP35,
      TP36: data.TP36,
      TP37: data.TP37,
      TP38: data.TP38,

      R18: data.R18,
      R17: data.R17,
      R16: data.R16,
      R15: data.R15,
      R14: data.R14,
      R13: data.R13,
      R12: data.R12,
      R11: data.R11,

      R21: data.R21,
      R22: data.R22,
      R23: data.R23,
      R24: data.R24,
      R25: data.R25,
      R26: data.R26,
      R27: data.R27,
      R28: data.R28,

      R48: data.R48,
      R47: data.R47,
      R46: data.R46,
      R45: data.R45,
      R44: data.R44,
      R43: data.R43,
      R42: data.R42,
      R41: data.R41,

      R31: data.R31,
      R32: data.R32,
      R33: data.R33,
      R34: data.R34,
      R35: data.R35,
      R36: data.R36,
      R37: data.R37,
      R38: data.R38,

      B18: data.B18,
      B17: data.B17,
      B16: data.B16,
      B15: data.B15,
      B14: data.B14,
      B13: data.B13,
      B12: data.B12,
      B11: data.B11,

      B21: data.B21,
      B22: data.B22,
      B23: data.B23,
      B24: data.B24,
      B25: data.B25,
      B26: data.B26,
      B27: data.B27,
      B28: data.B28,

      B48: data.B48,
      B47: data.B47,
      B46: data.B46,
      B45: data.B45,
      B44: data.B44,
      B43: data.B43,
      B42: data.B42,
      B41: data.B41,

      B31: data.B31,
      B32: data.B32,
      B33: data.B33,
      B34: data.B34,
      B35: data.B35,
      B36: data.B36,
      B37: data.B37,
      B38: data.B38,
    };
    if (currentOrder) {
      dispatch(editOrderTC(orderId, newData));
    } else {
      dispatch(addOrderTC(newData));
    }
    setValueOrder(true);
  };

  if (valueOrder && status === 'succeeded') {
    return <Navigate to={PATH.ORDERS} />;
  }

  if (!isLoggedIn) {
    return <Navigate to={PATH.LOGIN} />;
  }

  return (
    <>
      <div className={`${isOpenSidebar ? 'template-order' : 'template-order full-width'}`}>
        <div className="wrapper">
          <h1 className="page__header">
            {currentOrder ? t(TranslationKeys.order_edit) : t(TranslationKeys.order_form_add)}
          </h1>

          <div>
            <div className="forms">
              <div className="setting">
                <label className="setting__text">{t(TranslationKeys.client_data)}</label>
                <div className="setting__content">
                  <ReactSelect
                    name="client"
                    error={clientErrorHandler.error}
                    help_text={
                      fields.client.help_text ||
                      (clientErrorHandler.error && clientErrorHandler.errorMessageCurrentField[1])
                    }
                    label={fields.client.label}
                    placeholder={fields.client.label}
                    onClick={clientErrorHandler.onFieldClick}
                    control={control}
                    isMulti={false}
                    required={fields.client.required}
                    defaultValue={
                      currentOrder && defaultChoicesSelect(valueOptionClient, labelOptionClient)[0]
                    }
                    options={
                      choicesClient &&
                      Object.entries(fields.client.choices).map((b: any) => newOption(b[0], b[1]))
                    }
                  />
                  <ReactSelect
                    name="material"
                    error={materialErrorHandler.error}
                    help_text={
                      materialErrorHandler.error && materialErrorHandler.errorMessageCurrentField[1]
                    }
                    label={fields.material.label}
                    placeholder={fields.material.label}
                    onClick={materialErrorHandler.onFieldClick}
                    control={control}
                    isMulti={false}
                    required={fields.material.required}
                    defaultValue={
                      currentOrder &&
                      defaultChoicesSelect(valueOptionMaterial, labelOptionMaterial)[0]
                    }
                    options={
                      choicesMaterial &&
                      Object.entries(fields.material.choices).map((b: any) => newOption(b[0], b[1]))
                    }
                  />
                  <Inputs
                    error={toothColorErrorHandler.error}
                    help_text={
                      fields.tooth_color.help_text ||
                      (toothColorErrorHandler.error &&
                        toothColorErrorHandler.errorMessageCurrentField[1])
                    }
                    onClick={toothColorErrorHandler.onFieldClick}
                    register={register}
                    name={'tooth_color'}
                    label={fields.tooth_color.label}
                    defaultValue={
                      currentOrder ? currentOrder.tooth_color : fields.tooth_color.initial_value
                    }
                    {...reset}
                  />
                  <ReactDatePicker
                    selectedDate={selectedDate}
                    control={control}
                    label={fields.deadline.label}
                    onClick={deadlineErrorHandler.onFieldClick}
                    name={'deadline'}
                    minDate={new Date()}
                    help_text={
                      deadlineErrorHandler.error && deadlineErrorHandler.errorMessageCurrentField[1]
                    }
                    error={deadlineErrorHandler.error}
                    register={register}
                  />

                  <ReactSelect
                    name="branch_recipient"
                    error={recipientErrorHandler.error}
                    help_text={
                      recipientErrorHandler.error &&
                      recipientErrorHandler.errorMessageCurrentField[1]
                    }
                    label={fields.branch_recipient.label}
                    placeholder={fields.branch_recipient.label}
                    onClick={recipientErrorHandler.onFieldClick}
                    control={control}
                    isMulti={false}
                    required={fields.branch_recipient.required}
                    defaultValue={
                      currentOrder &&
                      defaultChoicesSelect(valueOptionRecipient, labelOptionRecipient)[0]
                    }
                    options={
                      choicesRecipient &&
                      Object.entries(fields.branch_recipient.choices).map((b: any) =>
                        newOption(b[0], b[1]),
                      )
                    }
                  />

                  <ReactSelect
                    name="doctor"
                    error={doctorErrorHandler.error}
                    help_text={
                      doctorErrorHandler.error && doctorErrorHandler.errorMessageCurrentField[1]
                    }
                    label={fields.doctor.label}
                    placeholder={fields.doctor.label}
                    onClick={doctorErrorHandler.onFieldClick}
                    control={control}
                    isMulti={false}
                    required={fields.doctor.required}
                    defaultValue={
                      currentOrder && defaultChoicesSelect(valueOptionDoctor, labelOptionDoctor)[0]
                    }
                    options={
                      choicesDoctor &&
                      Object.entries(fields.doctor.choices).map((b: any) => newOption(b[0], b[1]))
                    }
                  />
                  <ReactSelect
                    name="event"
                    error={eventErrorHandler.error}
                    help_text={
                      eventErrorHandler.error && eventErrorHandler.errorMessageCurrentField[1]
                    }
                    label={fields.event.label}
                    placeholder={fields.event.label}
                    onClick={eventErrorHandler.onFieldClick}
                    control={control}
                    isMulti={false}
                    required={fields.event.required}
                    defaultValue={
                      currentOrder && defaultChoicesSelect(valueOptionEvent, labelOptionEvent)[0]
                    }
                    options={
                      choicesEvent &&
                      Object.entries(fields.event.choices).map((b: any) => newOption(b[0], b[1]))
                    }
                  />
                </div>
                <div style={{ marginBottom: '-50px' }}>
                  <Textarea
                    state={'active'}
                    register={register}
                    input_type={fields.comment.input_type}
                    name={'email_cancel_text'}
                    label={fields.comment.label}
                    defaultValue={
                      currentOrder ? currentOrder.comment : fields.comment.initial_value
                    }
                    help_text={fields.comment.help_text}
                    {...reset}
                  />
                </div>
              </div>
            </div>
            <div className="page__text">
              <form onSubmit={handleSubmit(onSubmit)}>
                <label className="template-order__title">
                  {t(TranslationKeys.order_second_chance)}
                </label>
                <table className="template-order__table">
                  <tbody className="tbody">
                    <tr className="tr">
                      <th className="th-color">TP</th>
                      <td className="td">
                        <Inputs
                          state="order"
                          maxLength={3}
                          name={'TP18'}
                          {...reset}
                          register={register}
                          defaultValue={
                            currentOrder ? currentOrder?.TP18 : fields.TP18.initial_value
                          }
                          label={fields.TP18.label}
                        />
                      </td>
                      <td className="td">
                        <div style={{ marginBottom: '0px' }}>
                          <Inputs
                            state="order"
                            maxLength={3}
                            name={'TP17'}
                            {...reset}
                            register={register}
                            label={fields.TP17.label}
                            defaultValue={
                              currentOrder ? currentOrder?.TP17 : fields.TP17.initial_value
                            }
                          />
                        </div>
                      </td>
                      <td className="td">
                        <Inputs
                          maxLength={3}
                          state="order"
                          name={'TP16'}
                          {...reset}
                          register={register}
                          label={fields.TP16.label}
                          defaultValue={
                            currentOrder ? currentOrder?.TP16 : fields.TP16.initial_value
                          }
                        />
                      </td>
                      <td className="td">
                        <Inputs
                          maxLength={3}
                          state="order"
                          name={'TP15'}
                          {...reset}
                          register={register}
                          label={fields.TP15.label}
                          defaultValue={
                            currentOrder ? currentOrder?.TP15 : fields.TP15.initial_value
                          }
                        />
                      </td>
                      <td className="td">
                        <Inputs
                          maxLength={3}
                          state="order"
                          name={'TP14'}
                          {...reset}
                          register={register}
                          label={fields.TP14.label}
                          defaultValue={
                            currentOrder ? currentOrder?.TP14 : fields.TP14.initial_value
                          }
                        />
                      </td>
                      <td className="td">
                        <Inputs
                          maxLength={3}
                          state="order"
                          name={'TP13'}
                          {...reset}
                          register={register}
                          label={fields.TP13.label}
                          defaultValue={
                            currentOrder ? currentOrder?.TP13 : fields.TP13.initial_value
                          }
                        />
                      </td>
                      <td className="td">
                        <Inputs
                          maxLength={3}
                          state="order"
                          name={'TP12'}
                          {...reset}
                          register={register}
                          label={fields.TP12.label}
                          defaultValue={
                            currentOrder ? currentOrder?.TP12 : fields.TP12.initial_value
                          }
                        />
                      </td>
                      <td className="td">
                        <Inputs
                          maxLength={3}
                          state="order"
                          name={'TP11'}
                          {...reset}
                          register={register}
                          label={fields.TP11.label}
                          defaultValue={
                            currentOrder ? currentOrder?.TP11 : fields.TP11.initial_value
                          }
                        />
                      </td>
                      <td className="circle">
                        <div className="td-middle"></div>
                      </td>
                      <td className="td">
                        <Inputs
                          maxLength={3}
                          state="order"
                          name={'TP21'}
                          {...reset}
                          register={register}
                          label={fields.TP21.label}
                          defaultValue={
                            currentOrder ? currentOrder?.TP21 : fields.TP21.initial_value
                          }
                        />
                      </td>
                      <td className="td">
                        <Inputs
                          maxLength={3}
                          state="order"
                          name={'TP22'}
                          {...reset}
                          register={register}
                          label={fields.TP22.label}
                          defaultValue={
                            currentOrder ? currentOrder?.TP22 : fields.TP22.initial_value
                          }
                        />
                      </td>
                      <td className="td">
                        <Inputs
                          maxLength={3}
                          state="order"
                          name={'TP23'}
                          {...reset}
                          register={register}
                          label={fields.TP23.label}
                          defaultValue={
                            currentOrder ? currentOrder?.TP23 : fields.TP23.initial_value
                          }
                        />
                      </td>
                      <td className="td">
                        <Inputs
                          maxLength={3}
                          state="order"
                          name={'TP24'}
                          {...reset}
                          register={register}
                          label={fields.TP24.label}
                          defaultValue={
                            currentOrder ? currentOrder?.TP24 : fields.TP24.initial_value
                          }
                        />
                      </td>
                      <td className="td">
                        <Inputs
                          maxLength={3}
                          state="order"
                          name={'TP25'}
                          {...reset}
                          register={register}
                          defaultValue={
                            currentOrder ? currentOrder?.TP25 : fields.TP25.initial_value
                          }
                          label={fields.TP25.label}
                        />
                      </td>
                      <td className="td">
                        <Inputs
                          maxLength={3}
                          state="order"
                          name={'TP26'}
                          {...reset}
                          register={register}
                          label={fields.TP26.label}
                          defaultValue={
                            currentOrder ? currentOrder?.TP26 : fields.TP26.initial_value
                          }
                        />
                      </td>
                      <td className="td">
                        <Inputs
                          maxLength={3}
                          state="order"
                          name={'TP27'}
                          {...reset}
                          register={register}
                          label={fields.TP27.label}
                          defaultValue={
                            currentOrder ? currentOrder?.TP27 : fields.TP27.initial_value
                          }
                        />
                      </td>
                      <td className="td">
                        <Inputs
                          maxLength={3}
                          state="order"
                          name={'TP28'}
                          {...reset}
                          register={register}
                          label={fields.TP28.label}
                          defaultValue={
                            currentOrder ? currentOrder?.TP28 : fields.TP28.initial_value
                          }
                        />
                      </td>
                    </tr>

                    <tr className="tr">
                      <th className="th-color">R</th>
                      <td className="td">
                        <Inputs
                          maxLength={3}
                          state="order"
                          name={'R18'}
                          {...reset}
                          register={register}
                          label={fields.R18.label}
                          defaultValue={currentOrder ? currentOrder?.R18 : fields.R18.initial_value}
                        />
                      </td>
                      <td className="td">
                        <Inputs
                          maxLength={3}
                          state="order"
                          name={'R17'}
                          {...reset}
                          register={register}
                          label={fields.R17.label}
                          defaultValue={currentOrder ? currentOrder?.R17 : fields.R17.initial_value}
                        />
                      </td>
                      <td className="td">
                        <Inputs
                          maxLength={3}
                          state="order"
                          name={'R16'}
                          {...reset}
                          register={register}
                          label={fields.R16.label}
                          defaultValue={currentOrder ? currentOrder?.R16 : fields.R16.initial_value}
                        />
                      </td>
                      <td className="td">
                        <Inputs
                          maxLength={3}
                          state="order"
                          name={'R15'}
                          {...reset}
                          register={register}
                          label={fields.R15.label}
                          defaultValue={currentOrder ? currentOrder?.R15 : fields.R15.initial_value}
                        />
                      </td>
                      <td className="td">
                        <Inputs
                          maxLength={3}
                          state="order"
                          name={'R14'}
                          {...reset}
                          register={register}
                          label={fields.R14.label}
                          defaultValue={currentOrder ? currentOrder?.R14 : fields.R14.initial_value}
                        />
                      </td>
                      <td className="td">
                        <Inputs
                          maxLength={3}
                          state="order"
                          name={'R13'}
                          {...reset}
                          register={register}
                          label={fields.R13.label}
                          defaultValue={currentOrder ? currentOrder?.R13 : fields.R13.initial_value}
                        />
                      </td>
                      <td className="td">
                        <Inputs
                          maxLength={3}
                          state="order"
                          name={'R12'}
                          {...reset}
                          register={register}
                          label={fields.R12.label}
                          defaultValue={currentOrder ? currentOrder?.R12 : fields.R12.initial_value}
                        />
                      </td>
                      <td className="td">
                        <Inputs
                          maxLength={3}
                          state="order"
                          name={'R11'}
                          {...reset}
                          register={register}
                          label={fields.R11.label}
                          defaultValue={currentOrder ? currentOrder?.R11 : fields.R11.initial_value}
                        />
                      </td>
                      <td className="circle">
                        <div className="td-middle"></div>
                      </td>
                      <td className="td">
                        <Inputs
                          maxLength={3}
                          state="order"
                          name={'R21'}
                          {...reset}
                          register={register}
                          label={fields.R21.label}
                          defaultValue={currentOrder ? currentOrder?.R21 : fields.R21.initial_value}
                        />
                      </td>
                      <td className="td">
                        <Inputs
                          maxLength={3}
                          state="order"
                          name={'R22'}
                          {...reset}
                          register={register}
                          label={fields.R22.label}
                          defaultValue={currentOrder ? currentOrder?.R22 : fields.R22.initial_value}
                        />
                      </td>
                      <td className="td">
                        <Inputs
                          maxLength={3}
                          state="order"
                          name={'R23'}
                          {...reset}
                          register={register}
                          label={fields.R23.label}
                          defaultValue={currentOrder ? currentOrder?.R23 : fields.R23.initial_value}
                        />
                      </td>
                      <td className="td">
                        <Inputs
                          maxLength={3}
                          state="order"
                          name={'R24'}
                          {...reset}
                          register={register}
                          label={fields.R24.label}
                          defaultValue={currentOrder ? currentOrder?.R24 : fields.R24.initial_value}
                        />
                      </td>
                      <td className="td">
                        <Inputs
                          maxLength={3}
                          state="order"
                          name={'R25'}
                          {...reset}
                          register={register}
                          label={fields.R25.label}
                          defaultValue={currentOrder ? currentOrder?.R25 : fields.R25.initial_value}
                        />
                      </td>
                      <td className="td">
                        <Inputs
                          maxLength={3}
                          state="order"
                          name={'R26'}
                          {...reset}
                          register={register}
                          label={fields.R26.label}
                          defaultValue={currentOrder ? currentOrder?.R26 : fields.R26.initial_value}
                        />
                      </td>
                      <td className="td">
                        <Inputs
                          maxLength={3}
                          state="order"
                          name={'R27'}
                          {...reset}
                          register={register}
                          label={fields.R27.label}
                          defaultValue={currentOrder ? currentOrder?.R27 : fields.R27.initial_value}
                        />
                      </td>
                      <td className="td">
                        <Inputs
                          maxLength={3}
                          state="order"
                          name={'R28'}
                          {...reset}
                          register={register}
                          label={fields.R28.label}
                          defaultValue={currentOrder ? currentOrder?.R28 : fields.R28.initial_value}
                        />
                      </td>
                    </tr>
                    <tr className="tr">
                      <th className="th-color">B</th>
                      <td className="td">
                        <Inputs
                          maxLength={3}
                          state="order"
                          name={'B18'}
                          {...reset}
                          register={register}
                          label={fields.B18.label}
                          defaultValue={currentOrder ? currentOrder?.B18 : fields.B18.initial_value}
                        />
                      </td>
                      <td className="td">
                        <Inputs
                          maxLength={3}
                          state="order"
                          name={'B17'}
                          {...reset}
                          register={register}
                          label={fields.B17.label}
                          defaultValue={currentOrder ? currentOrder?.B17 : fields.B17.initial_value}
                        />
                      </td>
                      <td className="td">
                        <Inputs
                          maxLength={3}
                          state="order"
                          name={'B16'}
                          {...reset}
                          register={register}
                          label={fields.B16.label}
                          defaultValue={currentOrder ? currentOrder?.B16 : fields.B16.initial_value}
                        />
                      </td>
                      <td className="td">
                        <Inputs
                          maxLength={3}
                          state="order"
                          name={'B15'}
                          {...reset}
                          register={register}
                          label={fields.B15.label}
                          defaultValue={currentOrder ? currentOrder?.B15 : fields.B15.initial_value}
                        />
                      </td>
                      <td className="td">
                        <Inputs
                          maxLength={3}
                          state="order"
                          name={'B14'}
                          {...reset}
                          register={register}
                          label={fields.B14.label}
                          defaultValue={currentOrder ? currentOrder?.B14 : fields.B14.initial_value}
                        />
                      </td>
                      <td className="td">
                        <Inputs
                          maxLength={3}
                          state="order"
                          name={'B13'}
                          {...reset}
                          register={register}
                          label={fields.B13.label}
                          defaultValue={currentOrder ? currentOrder?.B13 : fields.B13.initial_value}
                        />
                      </td>
                      <td className="td">
                        <Inputs
                          maxLength={3}
                          state="order"
                          name={'B12'}
                          {...reset}
                          register={register}
                          label={fields.B12.label}
                          defaultValue={currentOrder ? currentOrder?.B12 : fields.B12.initial_value}
                        />
                      </td>
                      <td className="td">
                        <Inputs
                          maxLength={3}
                          state="order"
                          name={'B11'}
                          {...reset}
                          register={register}
                          label={fields.B11.label}
                          defaultValue={currentOrder ? currentOrder?.B11 : fields.B11.initial_value}
                        />
                      </td>
                      <td className="circle">
                        <div className="td-middle"></div>
                      </td>
                      <td className="td">
                        <Inputs
                          maxLength={3}
                          state="order"
                          name={'B21'}
                          {...reset}
                          register={register}
                          label={fields.B21.label}
                          defaultValue={currentOrder ? currentOrder?.B21 : fields.B21.initial_value}
                        />
                      </td>
                      <td className="td">
                        <Inputs
                          maxLength={3}
                          state="order"
                          name={'B22'}
                          {...reset}
                          register={register}
                          label={fields.B22.label}
                          defaultValue={currentOrder ? currentOrder?.B22 : fields.B22.initial_value}
                        />
                      </td>
                      <td className="td">
                        <Inputs
                          maxLength={3}
                          state="order"
                          name={'B23'}
                          {...reset}
                          register={register}
                          label={fields.B23.label}
                          defaultValue={currentOrder ? currentOrder?.B23 : fields.B23.initial_value}
                        />
                      </td>
                      <td className="td">
                        <Inputs
                          maxLength={3}
                          state="order"
                          name={'B24'}
                          {...reset}
                          register={register}
                          label={fields.B24.label}
                          defaultValue={currentOrder ? currentOrder?.B24 : fields.B24.initial_value}
                        />
                      </td>
                      <td className="td">
                        <Inputs
                          maxLength={3}
                          state="order"
                          name={'B25'}
                          {...reset}
                          register={register}
                          label={fields.B25.label}
                          defaultValue={currentOrder ? currentOrder?.B25 : fields.B25.initial_value}
                        />
                      </td>
                      <td className="td">
                        <Inputs
                          maxLength={3}
                          state="order"
                          name={'B26'}
                          {...reset}
                          register={register}
                          label={fields.B26.label}
                          defaultValue={currentOrder ? currentOrder?.B26 : fields.B26.initial_value}
                        />
                      </td>
                      <td className="td">
                        <Inputs
                          maxLength={3}
                          state="order"
                          name={'B27'}
                          {...reset}
                          register={register}
                          label={fields.B27.label}
                          defaultValue={currentOrder ? currentOrder?.B27 : fields.B27.initial_value}
                        />
                      </td>
                      <td className="td">
                        <Inputs
                          maxLength={3}
                          state="order"
                          name={'B28'}
                          {...reset}
                          register={register}
                          label={fields.B28.label}
                          defaultValue={currentOrder ? currentOrder?.B28 : fields.B28.initial_value}
                        />
                      </td>
                    </tr>
                    <tr className="tr">
                      <th className="th-color">#</th>
                      <td className="td-color">18</td>
                      <td className="td-color">17</td>
                      <td className="td-color">16</td>
                      <td className="td-color">15</td>
                      <td className="td-color">14</td>
                      <td className="td-color">13</td>
                      <td className="td-color">12</td>
                      <td className="td-color">11</td>
                      <td className="circle">
                        <div className="td-middle"></div>
                      </td>
                      <td className="td-color">21</td>
                      <td className="td-color">22</td>
                      <td className="td-color">23</td>
                      <td className="td-color">24</td>
                      <td className="td-color">25</td>
                      <td className="td-color">26</td>
                      <td className="td-color">27</td>
                      <td className="td-color">28</td>
                    </tr>
                    <tr className="tr">
                      <th className="th-color">#</th>
                      <td className="td-color">48</td>
                      <td className="td-color">47</td>
                      <td className="td-color">46</td>
                      <td className="td-color">45</td>
                      <td className="td-color">44</td>
                      <td className="td-color">43</td>
                      <td className="td-color">42</td>
                      <td className="td-color">41</td>
                      <td className="circle">
                        <div className="td-middle"></div>
                      </td>
                      <td className="td-color">31</td>
                      <td className="td-color">32</td>
                      <td className="td-color">33</td>
                      <td className="td-color">34</td>
                      <td className="td-color">35</td>
                      <td className="td-color">36</td>
                      <td className="td-color">37</td>
                      <td className="td-color">38</td>
                    </tr>
                    <tr className="tr">
                      <th className="th-color">B</th>
                      <td className="td">
                        <Inputs
                          maxLength={3}
                          state="order"
                          name={'B48'}
                          {...reset}
                          register={register}
                          label={fields.B48.label}
                          defaultValue={currentOrder ? currentOrder?.B48 : fields.B48.initial_value}
                        />
                      </td>
                      <td className="td">
                        <Inputs
                          maxLength={3}
                          state="order"
                          name={'B47'}
                          {...reset}
                          register={register}
                          label={fields.B47.label}
                          defaultValue={currentOrder ? currentOrder?.B47 : fields.B47.initial_value}
                        />
                      </td>
                      <td className="td">
                        <Inputs
                          maxLength={3}
                          state="order"
                          name={'B46'}
                          {...reset}
                          register={register}
                          label={fields.B46.label}
                          defaultValue={currentOrder ? currentOrder?.B46 : fields.B46.initial_value}
                        />
                      </td>
                      <td className="td">
                        <Inputs
                          maxLength={3}
                          state="order"
                          name={'B45'}
                          {...reset}
                          register={register}
                          label={fields.B45.label}
                          defaultValue={currentOrder ? currentOrder?.B45 : fields.B45.initial_value}
                        />
                      </td>
                      <td className="td">
                        <Inputs
                          maxLength={3}
                          state="order"
                          name={'B44'}
                          {...reset}
                          register={register}
                          label={fields.B44.label}
                          defaultValue={currentOrder ? currentOrder?.B44 : fields.B44.initial_value}
                        />
                      </td>
                      <td className="td">
                        <Inputs
                          maxLength={3}
                          state="order"
                          name={'B43'}
                          {...reset}
                          register={register}
                          label={fields.B43.label}
                          defaultValue={currentOrder ? currentOrder?.B43 : fields.B43.initial_value}
                        />
                      </td>
                      <td className="td">
                        <Inputs
                          maxLength={3}
                          state="order"
                          name={'B42'}
                          {...reset}
                          register={register}
                          label={fields.B42.label}
                          defaultValue={currentOrder ? currentOrder?.B42 : fields.B42.initial_value}
                        />
                      </td>
                      <td className="td">
                        <Inputs
                          maxLength={3}
                          state="order"
                          name={'B41'}
                          {...reset}
                          register={register}
                          label={fields.B41.label}
                          defaultValue={currentOrder ? currentOrder?.B41 : fields.B41.initial_value}
                        />
                      </td>
                      <td className="circle">
                        <div className="td-middle"></div>
                      </td>
                      <td className="td">
                        <Inputs
                          maxLength={3}
                          state="order"
                          name={'B31'}
                          {...reset}
                          register={register}
                          label={fields.B31.label}
                          defaultValue={currentOrder ? currentOrder?.B31 : fields.B31.initial_value}
                        />
                      </td>
                      <td className="td">
                        <Inputs
                          maxLength={3}
                          state="order"
                          name={'B32'}
                          {...reset}
                          register={register}
                          label={fields.B32.label}
                          defaultValue={currentOrder ? currentOrder?.B32 : fields.B32.initial_value}
                        />
                      </td>
                      <td className="td">
                        <Inputs
                          maxLength={3}
                          state="order"
                          name={'B33'}
                          {...reset}
                          register={register}
                          label={fields.B33.label}
                          defaultValue={currentOrder ? currentOrder?.B33 : fields.B33.initial_value}
                        />
                      </td>
                      <td className="td">
                        <Inputs
                          maxLength={3}
                          state="order"
                          name={'B34'}
                          {...reset}
                          register={register}
                          label={fields.B34.label}
                          defaultValue={currentOrder ? currentOrder?.B34 : fields.B34.initial_value}
                        />
                      </td>
                      <td className="td">
                        <Inputs
                          maxLength={3}
                          state="order"
                          name={'B35'}
                          {...reset}
                          register={register}
                          label={fields.B35.label}
                          defaultValue={currentOrder ? currentOrder?.B35 : fields.B35.initial_value}
                        />
                      </td>
                      <td className="td">
                        <Inputs
                          maxLength={3}
                          state="order"
                          name={'B36'}
                          {...reset}
                          register={register}
                          label={fields.B36.label}
                          defaultValue={currentOrder ? currentOrder?.B36 : fields.B36.initial_value}
                        />
                      </td>
                      <td className="td">
                        <Inputs
                          maxLength={3}
                          state="order"
                          name={'B37'}
                          {...reset}
                          register={register}
                          label={fields.B37.label}
                          defaultValue={currentOrder ? currentOrder?.B37 : fields.B37.initial_value}
                        />
                      </td>
                      <td className="td">
                        <Inputs
                          maxLength={3}
                          state="order"
                          name={'B38'}
                          {...reset}
                          register={register}
                          label={fields.B38.label}
                          defaultValue={currentOrder ? currentOrder?.B38 : fields.B38.initial_value}
                        />
                      </td>
                    </tr>
                    <tr className="tr">
                      <th className="th-color">R</th>
                      <td className="td">
                        <Inputs
                          maxLength={3}
                          state="order"
                          name={'R48'}
                          {...reset}
                          register={register}
                          label={fields.R48.label}
                          defaultValue={currentOrder ? currentOrder?.R48 : fields.R48.initial_value}
                        />
                      </td>
                      <td className="td">
                        <Inputs
                          maxLength={3}
                          state="order"
                          name={'R47'}
                          {...reset}
                          register={register}
                          label={fields.R47.label}
                          defaultValue={currentOrder ? currentOrder?.R47 : fields.R47.initial_value}
                        />
                      </td>
                      <td className="td">
                        <Inputs
                          maxLength={3}
                          state="order"
                          name={'R46'}
                          {...reset}
                          register={register}
                          label={fields.R46.label}
                          defaultValue={currentOrder ? currentOrder?.R46 : fields.R46.initial_value}
                        />
                      </td>
                      <td className="td">
                        <Inputs
                          state="order"
                          name={'R45'}
                          {...reset}
                          register={register}
                          label={fields.R45.label}
                          defaultValue={currentOrder ? currentOrder?.R45 : fields.R45.initial_value}
                        />
                      </td>
                      <td className="td">
                        <Inputs
                          maxLength={3}
                          state="order"
                          name={'R44'}
                          {...reset}
                          register={register}
                          label={fields.R44.label}
                          defaultValue={currentOrder ? currentOrder?.R44 : fields.R44.initial_value}
                        />
                      </td>
                      <td className="td">
                        <Inputs
                          maxLength={3}
                          state="order"
                          name={'R43'}
                          {...reset}
                          register={register}
                          label={fields.R43.label}
                          defaultValue={currentOrder ? currentOrder?.R43 : fields.R43.initial_value}
                        />
                      </td>
                      <td className="td">
                        <Inputs
                          maxLength={3}
                          state="order"
                          name={'R42'}
                          {...reset}
                          register={register}
                          label={fields.R42.label}
                          defaultValue={currentOrder ? currentOrder?.R42 : fields.R42.initial_value}
                        />
                      </td>
                      <td className="td">
                        <Inputs
                          state="order"
                          name={'R41'}
                          {...reset}
                          register={register}
                          label={fields.R41.label}
                          defaultValue={currentOrder ? currentOrder?.R41 : fields.R41.initial_value}
                        />
                      </td>
                      <td className="circle">
                        <div className="td-middle"></div>
                      </td>
                      <td className="td">
                        <Inputs
                          maxLength={3}
                          state="order"
                          name={'R31'}
                          {...reset}
                          register={register}
                          label={fields.R31.label}
                          defaultValue={currentOrder ? currentOrder?.R31 : fields.R31.initial_value}
                        />
                      </td>
                      <td className="td">
                        <Inputs
                          maxLength={3}
                          state="order"
                          name={'R32'}
                          {...reset}
                          register={register}
                          label={fields.R32.label}
                          defaultValue={currentOrder ? currentOrder?.R32 : fields.R32.initial_value}
                        />
                      </td>
                      <td className="td">
                        <Inputs
                          maxLength={3}
                          state="order"
                          name={'R33'}
                          {...reset}
                          register={register}
                          label={fields.R33.label}
                          defaultValue={currentOrder ? currentOrder?.R33 : fields.R33.initial_value}
                        />
                      </td>
                      <td className="td">
                        <Inputs
                          maxLength={3}
                          state="order"
                          name={'R34'}
                          {...reset}
                          register={register}
                          label={fields.R34.label}
                          defaultValue={currentOrder ? currentOrder?.R34 : fields.R34.initial_value}
                        />
                      </td>
                      <td className="td">
                        <Inputs
                          maxLength={3}
                          state="order"
                          name={'R35'}
                          {...reset}
                          register={register}
                          label={fields.R35.label}
                          defaultValue={currentOrder ? currentOrder?.R35 : fields.R35.initial_value}
                        />
                      </td>
                      <td className="td">
                        <Inputs
                          maxLength={3}
                          state="order"
                          name={'R36'}
                          {...reset}
                          register={register}
                          label={fields.R36.label}
                          defaultValue={currentOrder ? currentOrder?.R36 : fields.R36.initial_value}
                        />
                      </td>
                      <td className="td">
                        <Inputs
                          maxLength={3}
                          state="order"
                          name={'R37'}
                          {...reset}
                          register={register}
                          label={fields.R37.label}
                          defaultValue={currentOrder ? currentOrder?.R37 : fields.R37.initial_value}
                        />
                      </td>
                      <td className="td">
                        <Inputs
                          maxLength={3}
                          state="order"
                          name={'R38'}
                          {...reset}
                          register={register}
                          label={fields.R38.label}
                          defaultValue={currentOrder ? currentOrder?.R38 : fields.R38.initial_value}
                        />
                      </td>
                    </tr>
                    <tr className="tr">
                      <th className="th-color">TP</th>
                      <td className="td">
                        <Inputs
                          maxLength={3}
                          state="order"
                          name={'TP48'}
                          {...reset}
                          register={register}
                          label={fields.TP48.label}
                          defaultValue={
                            currentOrder ? currentOrder?.TP48 : fields.TP48.initial_value
                          }
                        />
                      </td>
                      <td className="td">
                        <Inputs
                          maxLength={3}
                          state="order"
                          name={'TP47'}
                          {...reset}
                          register={register}
                          label={fields.TP47.label}
                          defaultValue={
                            currentOrder ? currentOrder?.TP47 : fields.TP47.initial_value
                          }
                        />
                      </td>
                      <td className="td">
                        <Inputs
                          maxLength={3}
                          state="order"
                          name={'TP46'}
                          {...reset}
                          register={register}
                          label={fields.TP46.label}
                          defaultValue={
                            currentOrder ? currentOrder?.TP46 : fields.TP46.initial_value
                          }
                        />
                      </td>
                      <td className="td">
                        <Inputs
                          maxLength={3}
                          state="order"
                          name={'TP45'}
                          {...reset}
                          register={register}
                          label={fields.TP45.label}
                          defaultValue={
                            currentOrder ? currentOrder?.TP45 : fields.TP45.initial_value
                          }
                        />
                      </td>
                      <td className="td">
                        <Inputs
                          maxLength={3}
                          state="order"
                          name={'TP44'}
                          {...reset}
                          register={register}
                          label={fields.TP44.label}
                          defaultValue={
                            currentOrder ? currentOrder?.TP44 : fields.TP44.initial_value
                          }
                        />
                      </td>
                      <td className="td">
                        <Inputs
                          maxLength={3}
                          state="order"
                          name={'TP43'}
                          {...reset}
                          register={register}
                          label={fields.TP43.label}
                          defaultValue={
                            currentOrder ? currentOrder?.TP43 : fields.TP43.initial_value
                          }
                        />
                      </td>
                      <td className="td">
                        <Inputs
                          maxLength={3}
                          state="order"
                          name={'TP42'}
                          {...reset}
                          register={register}
                          label={fields.TP42.label}
                          defaultValue={
                            currentOrder ? currentOrder?.TP42 : fields.TP42.initial_value
                          }
                        />
                      </td>
                      <td className="td">
                        <Inputs
                          maxLength={3}
                          state="order"
                          name={'TP41'}
                          {...reset}
                          register={register}
                          label={fields.TP41.label}
                          defaultValue={
                            currentOrder ? currentOrder?.TP41 : fields.TP41.initial_value
                          }
                        />
                      </td>
                      <td className="circle">
                        <div className="td-middle"></div>
                      </td>
                      <td className="td">
                        <Inputs
                          maxLength={3}
                          state="order"
                          name={'TP31'}
                          {...reset}
                          register={register}
                          label={fields.TP31.label}
                          defaultValue={
                            currentOrder ? currentOrder?.TP31 : fields.TP31.initial_value
                          }
                        />
                      </td>
                      <td className="td">
                        <Inputs
                          maxLength={3}
                          state="order"
                          name={'TP32'}
                          {...reset}
                          register={register}
                          label={fields.TP32.label}
                          defaultValue={
                            currentOrder ? currentOrder?.TP32 : fields.TP32.initial_value
                          }
                        />
                      </td>
                      <td className="td">
                        <Inputs
                          maxLength={3}
                          state="order"
                          name={'TP33'}
                          {...reset}
                          register={register}
                          label={fields.TP33.label}
                          defaultValue={
                            currentOrder ? currentOrder?.TP33 : fields.TP33.initial_value
                          }
                        />
                      </td>
                      <td className="td">
                        <Inputs
                          maxLength={3}
                          state="order"
                          name={'TP34'}
                          {...reset}
                          register={register}
                          label={fields.TP34.label}
                          defaultValue={
                            currentOrder ? currentOrder?.TP34 : fields.TP34.initial_value
                          }
                        />
                      </td>
                      <td className="td">
                        <Inputs
                          maxLength={3}
                          state="order"
                          name={'TP35'}
                          {...reset}
                          register={register}
                          label={fields.TP35.label}
                          defaultValue={
                            currentOrder ? currentOrder?.TP35 : fields.TP35.initial_value
                          }
                        />
                      </td>
                      <td className="td">
                        <Inputs
                          maxLength={3}
                          state="order"
                          name={'TP36'}
                          {...reset}
                          register={register}
                          label={fields.TP36.label}
                          defaultValue={
                            currentOrder ? currentOrder?.TP36 : fields.TP36.initial_value
                          }
                        />
                      </td>
                      <td className="td">
                        <Inputs
                          maxLength={3}
                          state="order"
                          name={'TP37'}
                          {...reset}
                          register={register}
                          label={fields.TP37.label}
                          defaultValue={
                            currentOrder ? currentOrder?.TP37 : fields.TP37.initial_value
                          }
                        />
                      </td>
                      <td className="td">
                        <Inputs
                          maxLength={3}
                          state="order"
                          name={'TP38'}
                          {...reset}
                          register={register}
                          label={fields.TP38.label}
                          defaultValue={
                            currentOrder ? currentOrder?.TP38 : fields.TP38.initial_value
                          }
                        />
                      </td>
                    </tr>
                  </tbody>
                </table>
                <div>
                  <button className="button button_size-middle button_position-right button_color-black">
                    {t(TranslationKeys.submit)}
                  </button>
                </div>
              </form>
            </div>
          </div>

          <ErrorMessage status={status} errorState={errorState} />
        </div>
      </div>
    </>
  );
};
