import React, { FC, memo, useMemo, useRef } from 'react';
import { PATH } from '../../../routes/Routes';
import { logo } from '../../../assets/img/image';
import { useDispatch, useSelector } from 'react-redux';
import { getOpenMenuAppSelector } from '../../../redux/selectors';
import { useTranslation } from 'react-i18next';
import { MenuBtn } from '../../../components/MenuBtn/MenuBtn';
import { ProfileMenu } from './ProfileMenu/ProfileMenu';
import TranslationKeys from '../../../assets/locales/translationKeys/translationKeys';
import { CustomLink } from '../../../routes/CustomLink';
import { editStateMenuAC } from "../../../redux/actions";

type HeaderPropsType = {
  isOpenSidebar: boolean;
  showSidebar: () => void;
};

export const Header: FC<HeaderPropsType> = memo(({ isOpenSidebar, showSidebar }) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const componentRef = useRef();

  const openMenu = useSelector(getOpenMenuAppSelector);

  useMemo(() => {
    const handleClick = (e: any): void => {
      if (componentRef && componentRef.current) {
        const ref: any = componentRef.current;
        if (!ref.contains(e.target)) {
          dispatch(editStateMenuAC(false));
        }
      }
    };
    document.addEventListener('click', handleClick);
    return () => document.removeEventListener('click', handleClick);
  }, [dispatch]);

  const editStateMenu = (): void => {
    dispatch(editStateMenuAC(!openMenu));
  };

  const closeMenu = (): void => {
    if (openMenu) {
      dispatch(editStateMenuAC(false));
    }
  };

  return (
    <header
      onClick={() => {
        closeMenu();
      }}
      ref={componentRef as any}
      className="admin__header"
    >
      <nav className="admin__header_nav">
        <ul className="admin__header_nav_list">
          <li className="admin__header_nav_list_item menu-btn" onClick={closeMenu}>
            <MenuBtn isOpenMenu={isOpenSidebar} showSidebar={showSidebar} />
          </li>

          <li className="admin__header_nav_list_item logo-terminow" onClick={closeMenu}>
            <CustomLink to={PATH.SCHEDULE} className="admin__header_nav_list_item-logo">
              <img
                className={`${isOpenSidebar ? 'img img-open-sidebar' : 'img'}`}
                src={logo.termiLogo}
                alt="termiNow"
              />
            </CustomLink>
          </li>

          <li className="admin__header_nav_list_item" onClick={closeMenu}>
            <CustomLink className="admin__header_nav_list_item_link" to={PATH.SCHEDULE}>
              <span className="span">{t(TranslationKeys.shedule_name)}</span>
            </CustomLink>
          </li>
          <li className="admin__header_nav_list_item" onClick={closeMenu}>
            <CustomLink className="admin__header_nav_list_item_link" to={PATH.EVENTS}>
              <span className="span">{t(TranslationKeys.event_header)}</span>
            </CustomLink>
          </li>
          <li className="admin__header_nav_list_item" onClick={closeMenu}>
            <CustomLink className="admin__header_nav_list_item_link" to={PATH.WAITING_ROOM}>
              <span className="span">{t(TranslationKeys.waiting_header)}</span>
            </CustomLink>
          </li>
          <li className="admin__header_nav_list_item" onClick={closeMenu}>
            <CustomLink className="admin__header_nav_list_item_link" to={PATH.CLIENTS}>
              <span className="span">{t(TranslationKeys.clients_header)}</span>
            </CustomLink>
          </li>
          <li className="admin__header_nav_list_item" onClick={closeMenu}>
            <CustomLink className="admin__header_nav_list_item_link" to={PATH.ORDERS}>
              <span className="span">{t(TranslationKeys.orders_header)}</span>
            </CustomLink>
          </li>
        </ul>
      </nav>

      <div className="info-right">
        <ProfileMenu editStateMenu={editStateMenu} openMenu={openMenu} />
      </div>
    </header>
  );
});