import React, { FC, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import './CopyScriptModalAlert.scss';
import TranslationKeys from '../../../assets/locales/translationKeys/translationKeys';

type CopyScriptModalAlertType = {
  isOpenModalAlert: boolean;
};

export const CopyScriptModalAlert: FC<CopyScriptModalAlertType> = ({ isOpenModalAlert }) => {
  const [openAlertDialogForCopyScripts, setOpenAlertDialogCopyScripts] = useState(true);

  //locale
  const { t } = useTranslation();

  useEffect(() => {
    let timer: ReturnType<typeof setTimeout> = setTimeout(() => {
      if (isOpenModalAlert) {
        setOpenAlertDialogCopyScripts(false);
      }
    }, 500);
    return () => clearTimeout(timer);
  }, [isOpenModalAlert]);

  return (
    <div
      className={`${
        openAlertDialogForCopyScripts ? 'copy-script-modal' : 'copy-script-modal close-copy-alert'
      }`}
    >
      <span className="copy-content">{t(TranslationKeys.copy_script_text_message)}...</span>
    </div>
  );
};
