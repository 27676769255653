export const TranslationKeys = {
    "address": "address",
    "advanced_setting": "advanced_setting",
    "all_day": "all_day",
    "at_the_doctor": "at_the_doctor",

    "begin": "begin",
    "booking_success": "booking_success",
    "branches_nav": "branches_nav",
    "branch_list": "branch_list",
    "branch_btn_add": "branch_btn_add",
    "branch_form_add": "branch_form_add",
    "branch_delete": "branch_delete?",
    "branch_form_edit": "branch_form_edit",
    "branch": "branch",
    "branch_for_title": "branch_for_title",

    "cancel": "cancel",
    "chose": "chose",
    "click_conformation": "click_conformation",
    "client": "client",
    "clients_header": "clients_header",
    "client_data": "client_data",
    "client_history": "client_history",
    "client_list": "client_list",
    "client_btn_add": "client_btn_add",
    "client_form_add": "client_form_add",
    "client_form_edit": "client_form_edit",
    "client_not_appeared": "client_not_appeared",
    "client_not_appeared_btn": "client_not_appeared_btn",
    "contacts_nav": "contacts_nav",
    "contact_delete": "contact_delete",
    "contact_add_btn": "contact_add_btn",
    "contact_form_add": "contact_form_add",
    "contact_form_edit": "contact_form_edit",
    "copy": "copy",
    "copy_script_text_message": "copy_script_text_message",
    "create_acc": "create_acc",
    "current_branch": "current_branch",

    "data_time": "data_time",
    "data_time_desc": "data_time_desc",
    "date": "date",
    "date_birth": "date_birth",
    "de": "de",
    "delete": "delete",
    "desc_name": "desc_name",
    "desc_reason": "desc_reason",
    "details": "details",
    "did_not_wait": "did_not_wait",
    "doctor": "doctor",
    "done": "done",
    "duration": "duration",

    "edit": "edit",
    "email": "email",
    "email_short": "email_short",
    "employees_info": "employees_info",
    "employees_setting": "employees_setting",
    "employees_nav": "employees_nav",
    "employees_list": "employees_list",
    "employee_form_add": "employee_form_add",
    "employee_btn_add": "employee_btn_add",
    "employee_delete": "employee_delete",
    "employee_form_edit": "employee_form_edit",
    "en": "en",
    "end": "end",
    "end_alert": "end_alert",
    "event_data": "event_data",
    "event_header": "event_header",
    "event_list": "event_list",
    "event_btn_Add": "event_btn_Add",
    "event_btn_add": "event_btn_add",
    "event_form_add": "event_form_add",
    "event_form_edit": "event_form_edit",
    "event_cancel": "event_cancel",
    "event_did_not_come": "event_did_not_come",
    "event_at_the_doctor": "event_at_the_doctor",
    "event_did_not_wait": "event_did_not_wait",

    "failed": "failed",
    "full_name": "full_name",

    "gender": "gender",

    "holiday_btn_add": "holiday_btn_add",
    "holiday_delete": "holiday_delete",
    "holiday_form_add": "holiday_form_add",
    "holiday_form_edit": "holiday_form_edit",
    "holiday_list": "holiday_list",
    "holidays_nav": "holidays_nav",
    "holiday_official": "holiday_official",
    "holiday_private": "holiday_private",
    "holiday_suggestion": "holiday_suggestion",
    "home_btn": "home_btn",

    "iframe": "iframe",
    "info": "info",
    "insurance": "insurance",
    "incurance_desc": "incurance_desc",

    "join": "join",

    "language": "language",
    "leave": "leave",
    "login": "login",
    "logout_nav": "logout_nav",
    "lunch": "lunch",
    "lunch_alert": "lunch_alert",

    "material": "material",

    "name": "name",
    "not_found": "not_found",

    "options": "options",
    "order_btn_add": "order_btn_add",
    "order_delete": "order_delete",
    "order_download":"order_download",
    "order_edit":"order_edit",
    "order_form_add": "order_form_add",
    "orders_header": "orders_header",
    "order_list": "order_list",
    "order_second_chance": "order_second_chance",

    "password": "password",
    "phone_number": "phone_number",
    "phone_number_short": "phone_number_short",
    "profile_first_name": "profile_first_name",
    "profile_last_name": "profile_last_name",
    "profile_nav": "profile_nav",
    "profile_personal_info": "profile_personal_info",

    "reasonst_list": "reasonst_list",
    "reasons_nav": "reasons_nav",
    "reason": "reason",
    "reason_btn_add": "reason_btn_add",
    "reason_form_add": "reason_form_add",
    "reason_delete": "reason_delete",
    "reason_form_edit": "reason_form_edit",
    "register": "register",

    "saved": "saved",
    "setting": "setting",
    "shedule_name": "shedule_name",
    "shedule_time": "shedule_time",
    "shedule_edit": "shedule_edit",
    "sign_up": "sign_up",
    "sort": "sort",
    "start": "start",
    "start_alert": "start_alert",
    "status": "status",
    "status_desc": "status_desc (descending)",
    "submit": "submit",

    "title": "title",

    "user": "user",
    "user_not_foud": "user_not_foud",

    "waiting_header": "waiting_header",
    "waiting": "waiting",
    "waiting_period": "waiting_period",
    "weekend": "weekend",
    "weekend_alert": "weekend_alert",
    "welcome": "welcome",
    "work_shedule_nav": "work_shedule_nav",
    "work_time": "work_time",

    "yes": "yes"
}

export default TranslationKeys;
