import React, { FC, useEffect, useMemo, useState } from 'react';
import './OrdersFilter.scss';
import { useDispatch, useSelector } from 'react-redux';
import CheckboxInput from '../../../components/Widgets/CheckboxInput';
import { useForm } from 'react-hook-form';
import {
  getCurrentBranchSelector,
  getCurrentDoctorParamsOrdersSelector,
  getFilterParamsOrdersSelector,
  getUserProfileSelector,
} from '../../../redux/selectors';
import { useTranslation } from 'react-i18next';
import Inputs from '../../../components/Widgets/Inputs';
import { ReactSelectBranch } from '../../../components/Widgets/Select/ReactSelectBranch';
import {
  defaultChoicesMultiSelect,
  defaultChoicesSelect,
  newOption,
} from '../../../components/Widgets/Select/ReactSelectStyles';
import cx from 'classnames';
import Collapse from '@kunukn/react-collapse';
import TranslationKeys from '../../../assets/locales/translationKeys/translationKeys';
import { setCurrentBranchesAC } from '../../../redux/actions';
import {
  fetchFilteredOrdersListOfGeneral,
  fetchFilteredOrdersParamsListTC,
  setFilteredOrdersOfDoctorTC,
  setFilteredOrdersSearchTC,
} from '../../../redux/middlewares';
import { useCleanFilters } from "../../../hooks/useCleanFilters";

type FormDataType = {
  branch_creator__in: { value: string; label: string };
  doctor__in: { value: string; label: string };
};

export const OrdersFilter: FC = () => {
  const dispatch = useDispatch();
  const cleanFilters = useCleanFilters();

  const currentBranch = useSelector(getCurrentBranchSelector);
  const filterParams = useSelector(getFilterParamsOrdersSelector);
  const currentDoctorParams = useSelector(getCurrentDoctorParamsOrdersSelector);
  const user = useSelector(getUserProfileSelector);

  //locale
  const { t } = useTranslation();

  const [stateDoctor, setStateDoctor] = useState<boolean>(true);
  const [openDoc, isOpenDoc] = useState(true);

  useEffect(() => {
    if (currentDoctorParams.length) {
      setStateDoctor(true);
    }
  }, []);

  const choicesBranch =
    filterParams.branch_creator__in &&
    Object.entries(filterParams.branch_creator__in.choices).map((br) => br);
  const choicesDoctor =
    filterParams.doctor__in && Object.entries(filterParams.doctor__in.choices).map((br) => br);

  const valueOptionBranches =
    filterParams.branch_creator__in?.choices &&
    Object.entries(filterParams.branch_creator__in.choices).map((b: any) => newOption(b[0], b[1]));
  const labelOptionBranches = currentBranch.toString();

  const valueOptionDoctor =
    filterParams.doctor__in?.choices &&
    Object.entries(filterParams.doctor__in?.choices).map((b: any) => newOption(b[0], b[1]));
  const labelOptionDoctor = currentBranch.toString();

  const setBranch = (branchId: number): void => {
    cleanFilters();
    dispatch(setCurrentBranchesAC(branchId));
    dispatch(fetchFilteredOrdersParamsListTC());
    dispatch(fetchFilteredOrdersListOfGeneral());
  };

  const onSearchChange = (value: string): void => {
    dispatch(setFilteredOrdersSearchTC(value));
  };

  const setFilterDoctor = (params: string): void => {
    dispatch(setFilteredOrdersOfDoctorTC(params));
  };
  const countDoctor = useMemo(
    () =>
      choicesDoctor?.reduce(
        (counte, [doctorId]) => (currentDoctorParams.includes(doctorId) ? counte + 1 : counte),
        0,
      ),
    [currentDoctorParams],
  );

  const filterData = currentBranch && {
    branch_creator__in:
      filterParams.branch_creator__in && currentBranch
        ? defaultChoicesSelect(valueOptionBranches, labelOptionBranches)
        : {
            value: '',
            label: '',
          },
    doctor__in:
      filterParams.doctor__in &&
      currentBranch &&
      defaultChoicesMultiSelect(valueOptionDoctor, labelOptionDoctor),
  };

  const { register, control } = useForm<FormDataType>({
    mode: 'onBlur',
    defaultValues: useMemo(() => {
      if (filterData) {
        return filterData;
      }
    }, [filterData, currentBranch]),
  });

  return (
    <div className="order-filter">
      <div className="filter-block">
        <div className="filter-block-select">
          <ReactSelectBranch
            name="branches"
            label={t(TranslationKeys.current_branch)}
            placeholder={t(TranslationKeys.current_branch)}
            control={control}
            isMulti={false}
            defaultValue={
              currentBranch && filterParams.branch_creator__in
                ? defaultChoicesSelect(valueOptionBranches, labelOptionBranches)[0]
                : { value: '', label: '' }
            }
            onChange={(e) => setBranch(e.value)}
            options={
              choicesBranch &&
              Object.entries(filterParams.branch_creator__in.choices).map((b: any) =>
                newOption(b[0], b[1]),
              )
            }
          />
        </div>
      </div>

      <div className="search">
        <Inputs
          onChangeText={onSearchChange}
          label={filterParams?.search?.label}
          state={'search'}
          name="search"
          style={{
            height: '50px',
            paddingTop: '14px',
            marginBottom: '0',
          }}
          register={register}
        />
      </div>

      {user.role !== 'doctor' && (
        <div className="filter-block">
          <div
            className={cx('app__toggle', {
              'app__toggle--active': openDoc,
            })}
            onClick={() => {
              setStateDoctor(!stateDoctor);
              isOpenDoc(!openDoc);
            }}
          >
            <span className="filter-title-name">
              {t(TranslationKeys.doctor)}
              <span
                style={{
                  marginLeft: '15px',
                  fontSize: '15px',
                  fontWeight: 'lighter',
                  color: 'rgba(45, 45, 45, 0.852)',
                }}
              >
                [
                <span
                  style={{
                    color: 'black',
                  }}
                >
                  {countDoctor || 0}
                </span>{' '}
                /{' '}
                {filterParams.doctor__in?.choices !== undefined &&
                  Object.keys(filterParams.doctor__in?.choices).length}
                ]
              </span>
            </span>
            <div
              onClick={() => isOpenDoc(!openDoc)}
              className={openDoc ? 'cross active' : 'cross'}
            />
          </div>
          <Collapse
            isOpen={openDoc}
            className={
              'app__collapse app__collapse--gradient ' + (openDoc ? 'app__collapse--active' : '')
            }
          >
            <div className="app__content">
              {filterParams.doctor__in &&
                choicesDoctor.map((doctor, index) => (
                  <div key={index} className={stateDoctor ? 'filter-param' : 'filter-param-none'}>
                    <CheckboxInput
                      id={doctor[0]}
                      state={'square'}
                      checked={currentDoctorParams.find((cs) => cs === doctor[0]) === doctor[0]}
                      onChange={() => {
                        setFilterDoctor(doctor[0]);
                      }}
                      name={'status'}
                      value={doctor[0]}
                      label={doctor[1]}
                    />
                  </div>
                ))}
            </div>
          </Collapse>
        </div>
      )}
    </div>
  );
};
