import React, { FC, memo } from 'react';
import { DateOfListType, ScheduleBranchType } from '../../../api/branches-api';

type NonWorkingHoursSmallPropsType = {
  scheduleBranchDate: Array<DateOfListType>;
  dayIndex: number;
  scheduleBranch: ScheduleBranchType;
  heightHour: string;
  DayNowBlockStyle: string;
};

export const CurrentDay: FC<NonWorkingHoursSmallPropsType> = memo(
  ({ scheduleBranch, scheduleBranchDate, heightHour, DayNowBlockStyle }) => {
    const now = new Date().getDay() - 1;
    const currentDay = scheduleBranchDate.find((day) => day.date_index === now);

    const HeightNonWorkingStartHours = (): number => {
      const startCurrDayMS = new Date(
        `${currentDay?.date} ${currentDay?.start_time_work}`,
      ).getTime();
      const startEarlyDayMS = new Date(`${currentDay?.date} ${scheduleBranch.day_start}`).getTime();

      return (startCurrDayMS - startEarlyDayMS) / (1000 * 60) / 60;
    };

    const HeightNonWorkingEndHours = (): number => {
      const ednCurrDayMS = new Date(`${currentDay?.date} ${currentDay?.end_time_work}`).getTime();
      const endEarlyDayMS = new Date(`${currentDay?.date} ${scheduleBranch.day_end}`).getTime();

      return (endEarlyDayMS - ednCurrDayMS) / (1000 * 60) / 60;
    };

    const NonWorkingHoursPosition = (): number => {
      const startEarlyDayMS = new Date(`${currentDay?.date} ${scheduleBranch.day_start}`).getTime();
      const endEarlyDayMS = new Date(`${currentDay?.date} ${scheduleBranch.day_end}`).getTime();
      const heightDay = (endEarlyDayMS - startEarlyDayMS) / (1000 * 60) / 60;

      return heightDay - HeightNonWorkingEndHours();
    };

    return (
      <>
        <div
          className="border-today"
          style={{
            top: `calc((${heightHour} * ${HeightNonWorkingStartHours()} + 60px)`,
            height: `calc(((${heightHour} * ${NonWorkingHoursPosition()}) - (${heightHour} * ${HeightNonWorkingStartHours()}))`,
            display: `${DayNowBlockStyle === 'day dayNow' ? 'inherit' : 'none'}`,
          }}
        >
        </div>
      </>
    );
  },
);
