import React, { FC } from 'react';
import { logo } from '../../../../assets/img/image';
import { NavLink } from 'react-router-dom';
import { PATH } from '../../../../routes/Routes';
import { useLogout } from '../../../../hooks/useLogout';
import { useSelector } from 'react-redux';
import { getUserProfileSelector } from '../../../../redux/selectors';
import { useTranslation } from 'react-i18next';
//@ts-ignore
import log from '../../../../assets/img/icons/menu_right/exit_arrow.svg';
//@ts-ignore
import branch from '../../../../assets/img/icons/menu_right/branch.svg';
//@ts-ignore
import contacts from '../../../../assets/img/icons/menu_right/contacts.svg';
//@ts-ignore
import employee from '../../../../assets/img/icons/menu_right/employee.svg';
//@ts-ignore
import holiday from '../../../../assets/img/icons/menu_right/holiday.svg';
//@ts-ignore
import schedule from '../../../../assets/img/icons/menu_right/schedule.svg';
//@ts-ignore
import reason from '../../../../assets/img/icons/menu_right/reason.svg';
//@ts-ignore
import profile from '../../../../assets/img/icons/menu_right/profile.svg';
import { Notification } from '../../../../components/Popups/Notification/Notification';
import cx from "classnames";
import Collapse from "@kunukn/react-collapse";
import TranslationKeys from '../../../../assets/locales/translationKeys/translationKeys';
import { ArrowCollapse } from '../../../../components/ArrowСollapse/ArrowCollapse';

type ProfileMenuPropsType = {
  editStateMenu: () => void
  openMenu: boolean
}

const baseURL = process.env.REACT_APP_BASE_URL;

export const ProfileMenu: FC<ProfileMenuPropsType> = ({
  editStateMenu,
  openMenu
}) => {
  const logoutHandler = useLogout();
  const { t } = useTranslation();

  const user = useSelector(getUserProfileSelector);
  const userPhoto = user.photo ? `${baseURL}${user.photo}` : logo.notLogo;

  return (
    <>
      <Notification />
      <div onClick={editStateMenu}
        className={cx("app__toggle", {
          "app__toggle--active": openMenu
        })}
      >

        <div className={'profile'}>
          <div className="user">
            <div className="user-name" onClick={editStateMenu}>
              <span className="first-name">
                {user.first_name} {user.last_name}
              </span>
              <ArrowCollapse changeStateArrow={editStateMenu} stateArrow={openMenu} />
            </div>
            <img src={userPhoto} className="user-photo" alt="user" />
          </div>

          <Collapse
            isOpen={openMenu}
            className={
              "app__collapse app__collapse--gradient " +
              (openMenu ? "app__collapse--active" : "")
            }
          >
            <div className="app__content">
              <div className={'profile-menu-open'}>

                <nav className="nav">
                  <ul className="menu-list">
                    <li className="list-item-first" onClick={editStateMenu}>
                      <div className='list-item-last-log-block'>
                        <img alt="profile" className='list-item-last-log-profile' src={profile} />
                        <NavLink className="item-link" to={PATH.PROFILE}>
                          {t(TranslationKeys.profile_nav)}
                        </NavLink>

                      </div>

                    </li>
                    <li className="list-item" onClick={editStateMenu}>
                      <div className='list-item-last-log-block'>
                        <img alt="branches" className='list-item-last-log-img' src={branch} />
                        <NavLink className="item-link" to={PATH.BRANCHES}>
                          {t(TranslationKeys.branches_nav)}
                        </NavLink>

                      </div>

                    </li>
                    <li
                      className={user.role !== 'owner' ? 'none' : 'list-item'}
                      onClick={editStateMenu}
                    >
                      <div className='list-item-last-log-block'>
                        <img alt="employee" className='list-item-last-log-img' src={employee} />
                        <NavLink className="item-link" to={PATH.EMPLOYEES}>
                          {t(TranslationKeys.employees_nav)}
                        </NavLink>

                      </div>

                    </li>

                    <li className="list-item" onClick={editStateMenu}>
                      <div className='list-item-last-log-block'>
                        <img alt="reasons" className='list-item-last-log-img' src={reason} />
                        <NavLink className="item-link" to={PATH.REASONS}>
                          {t(TranslationKeys.reasons_nav)}
                        </NavLink>

                      </div>

                    </li>

                    <li className="list-item" onClick={editStateMenu}>
                      <div className='list-item-last-log-block'>
                        <img alt="work schedule" className='list-item-last-log-img' src={schedule} />
                        <NavLink className="item-link" to={PATH.WORK_SCHEDULE}>
                          {t(TranslationKeys.work_shedule_nav)}
                        </NavLink>

                      </div>

                    </li>
                    <div className="list-item" onClick={editStateMenu}>
                      <div className='list-item-last-log-block'>
                        <img alt="holidays" className='list-item-last-log-img' src={holiday} />
                        <NavLink className="item-link" onClick={editStateMenu} to={PATH.HOLIDAYS}>
                          {t(TranslationKeys.holidays_nav)}
                        </NavLink>

                      </div>

                    </div>

                    <li className="list-item" onClick={editStateMenu}>
                      <div className='list-item-last-log-block'>
                        <img alt="contacts" className='list-item-last-log-img' src={contacts} />
                        <NavLink className="item-link" to={PATH.CONTACTS}>
                          {t(TranslationKeys.contacts_nav)}
                        </NavLink>

                      </div>

                    </li>

                    <li className="list-item-last-log" onClick={logoutHandler}>
                      <div className='list-item-last-log-block'>
                        <img alt="logout" className='list-item-last-log-img' src={log} />
                        <NavLink className="item-link" to={''}>
                          {t(TranslationKeys.logout_nav)}
                        </NavLink>

                      </div>

                    </li>
                  </ul>
                </nav>
              </div>

            </div>
          </Collapse>
        </div>
      </div>
    </>

  )
}