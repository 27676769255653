import './Preloader.scss';
import { logo } from '../../assets/img/image';

const Preloader: React.FC = () => {
  return (
    <div className="preloader">
      <img src={logo.preloader} className="preloader-img" />
    </div>
  );
};

export default Preloader;

