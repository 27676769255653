import axios from 'axios';
import { ClientType } from './clients-api';
import { instance } from './http';

export const eventsAPI = {
  addEvent(data: any, branch: number) {
    return instance.post<EventType>(`/api/events/create/?branch=${branch}`, data);
  },

  editEvent(data: any, eventId: number) {
    return instance.put<EventType>(`/api/events/${eventId}/`, data);
  },

  removeEvents(eventId: number) {
    return instance.delete<any>(`/api/events/${eventId}/`);
  },

  getNextList(token: string, next: string | null) {
    return axios.get<EventsResponseData>(`${next}`, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
  },

  getPrevList(token: string, prev: string | null) {
    return axios.get<EventsResponseData>(`${prev}`, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
  },

  getEventDetails(eventId: number) {
    return instance.get<EventDetailType>(`/api/events/${eventId}/`);
  },

  cancelOrderEvent(uuid_field: string) {
    return instance.get<EventType>(`/api/events/${uuid_field}/cancel/`);
  },

  getFilteredParamsList(branchId: number) {
    return instance.get<EventFilterParamsType>(`/api/events/filter-form/?branch=${branchId}`);
  },

  getFilteredListOfGeneral(params: string | null) {
    return instance.get<EventsResponseData>(
      `/api/events/?${params}`
    );
  },

  changeStatus(eventId: number, status: string) {
    return instance.get<EventType>(`api/client/events/${eventId}/set-status/?status=${status}`);
  },
};

export type StatusParamsType = {
  at_the_doctor: string;
  canceled: string;
  canceled_by_client: string;
  confirmed: string;
  did_not_wait: string;
  done: string;
  new: string;
  not_warned: string;
  waiting_room: string;
  warned: string;
};

export type EventFilterParamsType = {
  full_name: { choices: {} };
  reason__color: { choices: {} };
  insurance_type__in: { choices: {} };
  reason__in: { choices: {} };
  status__in: { choices: StatusParamsType };
  branch: { choices: {} };
  search: {};
  doctor__in: { choices: {} };
};

export type EventDetailType = {
  branch: number;
  branch_display: string;
  client: ClientType;
  created_on: string;
  desired_date: boolean;
  doctor: DoctorType;
  email_reminded: boolean;
  end: string;
  events: EventType[];
  finished_at: string;
  id: number;
  insurance_type: string;
  insurance_type_display: string;
  is_client: boolean;
  is_client_display: string;
  message: string;
  note: string;
  reason: number;
  reason_color: string;
  reason_display: string;
  sms_reminded: boolean;
  start: string;
  start_waiting_at: string;
  status: string;
  status_display: string;
  updated_on: string;
  uuid_field: string;
  visit_time: string;
  waiting_time: string;
};

type DoctorType = {
  email: string;
  first_name: string;
  id: number;
  last_name: string;
  owner: number;
  owner_display: string;
  phone: string;
  photo: string;
  role: string;
};

export type EventType = {
  id: number;
  uuid_field: string;
  start: string;
  end: string;
  desired_date: boolean;
  client: ClientType;
  status: any;
  insurance_type: string;
  branch: number;
  reason: number;
  is_client: boolean;
  message: string;
  created_on: string;
  updated_on: string;
  email_reminded: boolean;
  sms_reminded: boolean;
  branch_display: string;
  client_display: string;
  reason_display: string;
  reason_color: string;
  gender_display: string;
  insurance_type_display: string;
  is_client_display: string;
  status_display: any;
  doctor: DoctorType;
  finished_at: any;
  note: string;
  start_waiting_at: any;
  visit_time: string;
  waiting_time: string;
};

export type EventsLinksType = {
  next: string | null;
  previous: string | null;
};

export type EventsResponseData = {
  links: EventsLinksType;
  count: number;
  current_page: number;
  lastPage: number;
  current: number;
  results: Array<EventType>;
};
