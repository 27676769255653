import { ProfileDataType } from '../../api/profile-api';
import {
  CHANGE_STATUS_REQUEST_PROFILE,
  RESET_REDUCER_GROUP,
  SET_PROFILE_DATA,
  UPDATE_PROFILE_DATA,
} from '../actions/constants';
import { ProfileActionsType } from '../actions/types';

const initialState = {
  success_request: null,
} as ProfileDataType;

type InitialStateType = typeof initialState;

export const profileReducer = (
  state: InitialStateType = initialState,
  action: ProfileActionsType,
): InitialStateType => {
  switch (action.type) {
    case SET_PROFILE_DATA: {
      return { ...state, ...action.model };
    }

    case UPDATE_PROFILE_DATA: {
      return {
        ...state,
        ...action.model,
      };
    }

    case CHANGE_STATUS_REQUEST_PROFILE: {
      return {
        ...state,
        success_request: action.success,
      };
    }
    case RESET_REDUCER_GROUP:
      return { ...initialState };
    default:
      return state;
  }
};