import React, { FC, memo, useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Navigate, NavLink } from 'react-router-dom';
import { PATH } from '../../routes/Routes';
import './OrderList.scss';
import { ConfirmationPopup } from '../../components/Popups/ConfirmationPopup/ConfirmationPopup';
import Preloader from '../../components/Preloaders/Preloader';
import {
  getActiveWeekSelector,
  getCurrentBranchSelector,
  getCurrentOrdersSelector,
  getCurrentPagePaginationOrdersSelector,
  getCurrQueryStringOrdersSelector,
  getIsLoggedInSelector,
  getNextOrdersSelector,
  getOpenSidebarAppSelector,
  getOrdersSelector,
  getPreviousOrdersSelector,
  getStatusSelector,
} from '../../redux/selectors';
import { useTranslation } from 'react-i18next';
import { setPreloader } from '../../redux/selectors/OrdersSelectors';
import PreloaderEvent from '../../components/Preloaders/EventsPreload/PreloadEvents';
import { ActionMenuOrders } from './ActionMenuOrders/ActionMenuOrders';
import TranslationKeys from '../../assets/locales/translationKeys/translationKeys';
import {
  fetchBranchesListFirstRender,
  fetchFilteredClientsListOfGeneral,
  fetchFilteredOrdersListOfGeneral,
  fetchFilteredOrdersParamsListTC,
  initializeAppTC,
} from '../../redux/middlewares';
import {
  editStateMenuAC,
  setAppStatusAC,
  setCurrentOrdersQueryStringAC,
} from '../../redux/actions';
import { Pagination } from '../../components/ComponentsForLists/Pagination/Pagination';
import { getNextOrdersList, getPrevOrdersList } from '../../redux/middlewares/ordersThunks';
import { getObjectFromQueryString } from '../../utils/getObjectFromQueryString';
import { getQueryStringFromObject } from '../../utils/getQueryStringFromObject';

export const OrderList: FC = memo(() => {
  const dispatch = useDispatch();
  const componentRef = useRef();

  const status = useSelector(getStatusSelector);
  const isLoggedIn = useSelector(getIsLoggedInSelector);
  const orders = useSelector(getOrdersSelector);
  const isOpenSidebar = useSelector(getOpenSidebarAppSelector);
  const activeWeek = useSelector(getActiveWeekSelector);
  const preloader = useSelector(setPreloader);
  const currentBranch = useSelector(getCurrentBranchSelector);
  const nextPage = useSelector(getNextOrdersSelector);
  const previousPage = useSelector(getPreviousOrdersSelector);
  const countPagePagination = useSelector(getCurrentPagePaginationOrdersSelector);
  const currentPage = useSelector(getCurrentOrdersSelector);
  const currentQueryString = useSelector(getCurrQueryStringOrdersSelector);

  //dialog alerts
  const [openAlertDialogForDelete, setOpenAlertDialogForDelete] = useState(false);
  const [modalDataId, setModalDataId] = useState<number>(0);

  const [options, setOptions] = useState(false);
  const [selectedIndex, setSelectedIndex] = useState(0);
  const [load, setLoad] = useState(false);

  //locale
  const { t } = useTranslation();

  const escFunction = useCallback((event) => {
    if (event.key === 'Escape') {
      setOpenAlertDialogForDelete(false);
    }
  }, []);

  useEffect(() => {
    document.addEventListener('keydown', escFunction, false);

    return () => {
      document.removeEventListener('keydown', escFunction, false);
    };
  }, []);

  useEffect(() => {
    if (status === 'failed') {
      dispatch(initializeAppTC());
    }
  }, [status]);

  useEffect(() => {
    if (currentBranch !== 0) {
      if (currentQueryString) {
        dispatch(fetchFilteredOrdersListOfGeneral(undefined, currentQueryString));
      } else {
        dispatch(fetchFilteredOrdersListOfGeneral(currentBranch.toString()));
      }
      dispatch(fetchFilteredOrdersParamsListTC());
    }
  }, [dispatch]);

  useEffect(() => {
    if (isLoggedIn) {
      if (!activeWeek) {
        dispatch(fetchBranchesListFirstRender());
      }
    }
    return () => {
      dispatch(setAppStatusAC('idle'));
    };
  }, [orders]);

  const removeOrder = (): void => {
    setModalDataId(0);
  };

  const openAlertHandler = (employeeId: number): void => {
    setModalDataId(employeeId);
    setOpenAlertDialogForDelete(true);
  };
  const closeAlertHandler = (): void => {
    setOpenAlertDialogForDelete(false);
  };
  const onClickOptions = (oderId: number): void => {
    dispatch(editStateMenuAC(false));
    if (options) {
      if (oderId === selectedIndex) {
        setOptions(false);
      } else {
        setOptions(true);
      }
    } else {
      setOptions(true);
    }
  };
  const onLoadHandler = (): void => {
    setLoad(true);
  };

  useMemo(() => {
    const handleClick = (e: any): void => {
      if (componentRef && componentRef.current) {
        const ref: any = componentRef.current;
        if (!ref.contains(e.target)) {
          setOptions(false);
        }
      }
    };
    document.addEventListener('click', handleClick);
    return () => document.removeEventListener('click', handleClick);
  }, [dispatch]);

  useEffect(() => {
    document.title = t(TranslationKeys.orders_header);
  }, []);

  const getNextList = (): void => {
    if (nextPage !== null) {
      dispatch(getNextOrdersList());
    }
  };

  const getPrevList = (): void => {
    if (previousPage !== null) {
      dispatch(getPrevOrdersList());
    }
  };

  const toFirstList = (): void => {
    const currQueryStringToObject = getObjectFromQueryString(currentQueryString);
    const currQueryStringToString = getQueryStringFromObject({
      ...currQueryStringToObject,
      page: '1',
    });
    if (currentQueryString) {
      dispatch(setCurrentOrdersQueryStringAC(currQueryStringToString));
      dispatch(fetchFilteredOrdersListOfGeneral(undefined, currQueryStringToString));
    } else {
      dispatch(fetchFilteredClientsListOfGeneral(currentBranch.toString()));
    }
  };

  const toLastList = (): void => {
    const currQueryStringToObject = getObjectFromQueryString(currentQueryString);
    const currQueryStringToString = getQueryStringFromObject({
      ...currQueryStringToObject,
      page: `${countPagePagination}`,
    });
    if (currentQueryString) {
      dispatch(setCurrentOrdersQueryStringAC(currQueryStringToString));
      dispatch(fetchFilteredOrdersListOfGeneral(undefined, currQueryStringToString));
    } else {
      dispatch(fetchFilteredOrdersListOfGeneral(currentBranch.toString()));
    }
  };

  if (!isLoggedIn) {
    return <Navigate to={PATH.LOGIN} />;
  }

  return (
    <>
      <div ref={componentRef as any}></div>
      <div className={`${isOpenSidebar ? 'orders' : 'orders full-width'}`}>
        <div className="orders__header">
          <h1 className="orders__header__title">{t(TranslationKeys.order_list)}</h1>
          <div className="orders__header__add">
            <NavLink
              className="button button_size-small button_position-left button_color-transparent"
              to={PATH.ADD_ORDER}
            >
              + {t(TranslationKeys.order_btn_add)}
            </NavLink>
          </div>
        </div>

        <div>
          <table className="orders__table">
            <thead className="thead">
              <tr className="tr">
                {/*    <th className="th-name"><span>{'#'}</span></th> */}
                <th className="th">
                  <span>{t(TranslationKeys.clients_header)}</span>
                </th>
                <th style={{ width: '200px' }} className="th">
                  <span>{t(TranslationKeys.material)}</span>
                </th>
                <th className="th">
                  <span>{t(TranslationKeys.insurance)}</span>
                </th>
                <th className="th">
                  <span>{t(TranslationKeys.status)}</span>
                </th>
                <th className="th-options">
                  <span>{t(TranslationKeys.options)}</span>
                </th>
              </tr>
            </thead>

            {orders.length === 0 && preloader ? (
              <tbody>
                <tr>
                  <td>
                    <Preloader />
                  </td>
                </tr>
              </tbody>
            ) : (
              preloader && (
                <tbody>
                  <tr>
                    <td>
                      <PreloaderEvent />
                    </td>
                  </tr>
                </tbody>
              )
            )}

            <tbody className="tbody">
              {orders.length > 0 &&
                orders.map((order) => (
                  <tr key={order.id} className="tr">
                    <td className="td-name">{order.client_display}</td>
                    <td className="td">{order.material_display}</td>
                    <td className="td">{order.insurance_type_display}</td>
                    <td className="td">{order.status_display}</td>
                    <td
                      onClick={(e) => {
                        e.stopPropagation();
                        setSelectedIndex(order.id);
                        onClickOptions(order.id);
                      }}
                      className="td-btns"
                    >
                      <ActionMenuOrders
                        order={order}
                        options={options}
                        onClickOptions={onClickOptions}
                        selectedIndex={selectedIndex}
                        setSelectedIndex={setSelectedIndex}
                        onLoadHandler={onLoadHandler}
                        load={load}
                        setOptions={setOptions}
                        openAlertHandler={openAlertHandler}
                        // onChangeStatus={onChangeStatus}
                      />
                    </td>
                  </tr>
                ))}
              {openAlertDialogForDelete && (
                <>
                  <ConfirmationPopup
                    actionCallback={removeOrder}
                    id={modalDataId}
                    titleQuestion={t('order_delete')}
                    actionTitle={t('delete')}
                    onClickBtn={closeAlertHandler}
                    cancelCallback={closeAlertHandler}
                  />
                </>
              )}
            </tbody>
          </table>
          {(nextPage !== null || previousPage !== null) && (
            <Pagination
              previous={previousPage}
              next={nextPage}
              current={currentPage}
              currentPage={countPagePagination}
              previousCallback={getPrevList}
              nextCallback={getNextList}
              toFirstList={toFirstList}
              toLastList={toLastList}
            />
          )}
        </div>
      </div>
    </>
  );
});
