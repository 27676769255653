import { WaitingRoomType } from '../../api/waitingRoom-api';
import { WaitingRoomActionsType } from '../actions/types';
import { SET_LIST_WAITING_ROOM } from '../actions/constants';

const initialState: WaitingRoomType = {
  waiting_room: [],
  at_the_doctor: [],
};

export const waitingRoomReducer = (
  state: WaitingRoomType = initialState,
  action: WaitingRoomActionsType,
): WaitingRoomType => {
  switch (action.type) {
    case SET_LIST_WAITING_ROOM: {
      return { ...state, ...action.waitingRoom };
    }
    default:
      return state;
  }
};