import { AppRootStateType } from '../store';

export const getIsLoggedInSelector = (state: AppRootStateType): boolean => state.auth.isLoggedIn;

export const getStatusSelector = (state: AppRootStateType): string => state.app.status;

export const getErrorStateSelector = (state: AppRootStateType): string => state.app.error;

export const getOpenMenuAppSelector = (state: AppRootStateType): boolean =>
  state.app.isOpenProfileMenu;

export const getOpenSidebarAppSelector = (state: AppRootStateType): boolean =>
  state.app.isOpenSidebar;

export const getCurrentPathSelector = (state: AppRootStateType): string | null =>
  state.app.currentPath;