/* eslint-disable */
import { useSelector } from 'react-redux';
import { getFilterParamsEventsSelector } from '../redux/selectors';
import { StatusParamsType } from '../api/events-api';

/**
 * "useStatusFilterParams" is a custom hook for getting event statuses. Returns an object with event statuses.
 * Hook takes statuses from "getFilterParamsEventsSelector" selector.
 * */
export const useStatusFilterParams = () => {
  const filterParams = useSelector(getFilterParamsEventsSelector);

  return Object.keys(filterParams.status__in.choices).reduce(
    (a: any, i) => ((a[i] = i), a),
    {},
  ) as StatusParamsType;
};