import React, { useEffect, useState } from 'react';
import '../assets/fonts/poppins/style.css';
import { useDispatch, useSelector } from 'react-redux';
import { PATH, RoutesComponent } from '../routes/Routes';
import { SideBar } from '../pages/Main/SideBar/SideBar';
import { Header } from '../pages/Main/Header/Header';
import './App.scss';
import { useLocation } from 'react-router-dom';
import { Login } from '../pages/Login/Login';
import { Registration } from '../pages/Registration/Registration';
import SuccessPage from '../pages/Success/SuccessPage/SuccessPage';
import { useTranslation } from 'react-i18next';
import { getIsLoggedInSelector, getOpenSidebarAppSelector } from '../redux/selectors';
import i18next from 'i18next';
import { loadLang, saveLangLS } from '../utils/localStorage';
import { Activation } from '../pages/Activation/Activation';
import { initializeAppTC, refreshTC } from "../redux/middlewares";
import { editStateSidebarAC, setAppErrorAC, setAppStatusAC } from '../redux/actions';

type AppType = {};

const App: React.FC<AppType> = () => {
  const dispatch = useDispatch();
  const location = useLocation().pathname;
  const currentSearchParam = useLocation().search;

  const isLoggedIn = useSelector(getIsLoggedInSelector);
  const isOpenSidebar = useSelector(getOpenSidebarAppSelector);

  const [currentPath, setCurrentPath] = useState<string>();

  const DEV_LANG_PARAM = '?lng=dev';
  const langLS = loadLang();

  //locale
  const { t } = useTranslation();

  useEffect(() => {
    if (langLS === '' || !langLS) {
      saveLangLS('de');
      i18next.changeLanguage('de');
    }
    if (langLS === 'dev') {
      saveLangLS('dev');
      i18next.changeLanguage('dev');
    }
    if (langLS === 'en') {
      saveLangLS('en');
      i18next.changeLanguage('en');
    }
  }, [langLS]);

  useEffect(() => {
    if (currentSearchParam === DEV_LANG_PARAM) {
      saveLangLS('dev');
      i18next.changeLanguage('dev');
    }
  }, [location.search, langLS]);

  useEffect(() => {
    dispatch(initializeAppTC());
  }, [isLoggedIn]);

  useEffect(() => {
    if (location !== currentPath && location !== PATH.LOGIN) {
      setCurrentPath(location);
      if (isLoggedIn) {
        dispatch(refreshTC());
      }
    }
  }, [location]);

  useEffect(() => {
    if (!isLoggedIn) {
      dispatch(initializeAppTC());
    }
  }, [dispatch]);

  useEffect(() => {
    const interval = setInterval(() => {
      dispatch(initializeAppTC());
    }, 300000);
    return () => {
      clearInterval(interval);
    };
  }, [dispatch]);

  useEffect(() => {
    if (location !== currentPath) {
      dispatch(setAppStatusAC('idle'));
      dispatch(setAppErrorAC(''));
    }
  }, [currentPath]);

  const showSidebar = (): void => {
    dispatch(editStateSidebarAC());
  };

  switch (location) {
    case '/login/':
      return <Login />;
    case '/register/':
      return <Registration />;
    case '/activation/':
      return <Activation />;
    case '/success-email/':
      return <SuccessPage textSuccess={t('click_conformation')} />;
    case '/success-page/':
      return <SuccessPage textSuccess={t('booking_success')} />;
    case '/success-error/':
      return <SuccessPage textSuccess={t('user_not_foud')} />;
    case '/success-create/':
      return <SuccessPage textSuccess={t('create_acc')} />;
  }

  return (
    <>
      <div className="admin">
        <SideBar isOpenSidebar={isOpenSidebar} />
        <Header isOpenSidebar={isOpenSidebar} showSidebar={showSidebar} />
        <RoutesComponent />
      </div>
    </>
  );
};

export default App;
