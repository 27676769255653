import { Dispatch } from 'redux';
import { editWorkScheduleAC, setAppStatusAC, setListWorkScheduleLAC } from '../actions';
import { scheduleWorkApi } from '../../api/scheduleWork-api';
import { handleServerAppError, handleServerNetworkError } from '../../utils/error-utils';

export const fetchScheduleWork = () => async (dispatch: Dispatch<any>) => {
  dispatch(setAppStatusAC('loading'));
  try {
    const res = await scheduleWorkApi.getList();
    dispatch(setListWorkScheduleLAC(res.data));
    dispatch(setAppStatusAC('succeeded'));
  } catch (error: any) {
    if (error.message === 'Network Error') {
      handleServerNetworkError(error, dispatch);
    } else {
      handleServerAppError(error, dispatch);
    }
  }
  dispatch(setAppStatusAC('idle'));
};

export const editScheduleWorkTC = (id: number, data: any) => async (dispatch: Dispatch<any>) => {
  dispatch(setAppStatusAC('loading'));
  try {
    const res = await scheduleWorkApi.editScheduleWork(data, id);
    dispatch(editWorkScheduleAC(res.data));
    dispatch(setAppStatusAC('succeeded'));
  } catch (error: any) {
    if (error.message === 'Network Error') {
      handleServerNetworkError(error, dispatch);
    } else {
      handleServerAppError(error, dispatch);
    }
  }
  dispatch(setAppStatusAC('idle'));
};