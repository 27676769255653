import React, { FC, useEffect, useMemo, useState } from 'react';
import './EventsFilter.scss';
import { useDispatch, useSelector } from 'react-redux';
import CheckboxInput from '../../../components/Widgets/CheckboxInput';
import Inputs from '../../../components/Widgets/Inputs';
import { useForm } from 'react-hook-form';
import {
  getCurrentBranchParamsEventsSelector,
  getCurrentBranchSelector,
  getCurrentColorParamsEventsSelector,
  getCurrentDoctorParamsEventsSelector,
  getCurrentInsuranceParamsEventsSelector,
  getCurrentReasonParamsEventsSelector,
  getCurrentStatusParamsEventsSelector,
  getCurrEventsQueryStringAppSelector,
  getFilterParamsEventsSelector,
  getUserProfileSelector,
} from '../../../redux/selectors';
import { useTranslation } from 'react-i18next';
import { ReactSelectBranch } from '../../../components/Widgets/Select/ReactSelectBranch';
import {
  defaultChoicesSelect,
  newOption,
} from '../../../components/Widgets/Select/ReactSelectStyles';
import {
  isCollapsedIncuranceFilterEventsSelector,
  isCollapsedReasonFilterEventsSelector,
  isCollapsedStatusFilterEventsSelector,
} from '../../../redux/selectors/EventsSelectors';
import cx from 'classnames';
import Collapse from '@kunukn/react-collapse';
import TranslationKeys from '../../../assets/locales/translationKeys/translationKeys';
import { getObjectFromQueryString } from '../../../utils/getObjectFromQueryString';
import {
  collapseInsuranceEventsFilterAC,
  collapseReasonEventsFilterAC,
  collapseStatusEventsFilterAC,
  setCurrentBranchesAC,
} from '../../../redux/actions';
import {
  fetchFilteredEventsListOfGeneral,
  fetchFilteredEventsParamsListTC,
  setFilteredEventsOfDoctorTC,
  setFilteredEventsOfInsuranceTC,
  setFilteredEventsOfReasonTC,
  setFilteredEventsOfStatusTC,
  setFilteredEventsSearchTC,
} from '../../../redux/middlewares';
import { useCleanFilters } from '../../../hooks/useCleanFilters';

type FormDataType = {
  branches: { value: any; label: any };
};

type EventsSideBarPropsType = {};

export const EventsFilter: FC<EventsSideBarPropsType> = () => {
  const dispatch = useDispatch();
  const cleanFilters = useCleanFilters();

  const currentBranch = useSelector(getCurrentBranchSelector);
  const filterParams = useSelector(getFilterParamsEventsSelector);
  const currentInsuranceParams = useSelector(getCurrentInsuranceParamsEventsSelector);
  const currentReasonParams = useSelector(getCurrentReasonParamsEventsSelector);
  const currentStatusParams = useSelector(getCurrentStatusParamsEventsSelector);
  const currentColorParams = useSelector(getCurrentColorParamsEventsSelector);
  const currentBranchParams = useSelector(getCurrentBranchParamsEventsSelector);
  const currentDoctorParams = useSelector(getCurrentDoctorParamsEventsSelector);
  const user = useSelector(getUserProfileSelector);
  const isCollapsedInsurance = useSelector(isCollapsedIncuranceFilterEventsSelector);
  const isCollapsedReason = useSelector(isCollapsedReasonFilterEventsSelector);
  const isCollapsedStatus = useSelector(isCollapsedStatusFilterEventsSelector);
  const currentQueryString = useSelector(getCurrEventsQueryStringAppSelector);

  const [stateReason, setStateReason] = useState<boolean>(true);
  const [stateDoctor, setStateDoctor] = useState<boolean>(true);
  const [open, isOpen] = useState(false);
  const [openDoc, isOpenDoc] = useState(true);
  const [openStatus, isOpenStatus] = useState(false);
  const [openReason, isOpenReason] = useState(false);

  //locale
  const { t } = useTranslation();
  useEffect(() => {
    if (currentInsuranceParams.length) {
    }

    if (currentReasonParams.length) {
      setStateReason(!stateReason);
    }

    if (currentStatusParams.length) {
    }

    if (currentColorParams.length) {
    }

    if (currentBranchParams.length) {
    }

    if (currentDoctorParams.length) {
      setStateDoctor(true);
    }
  }, []);

  const choicesInsurance =
    filterParams.insurance_type__in &&
    Object.entries(filterParams.insurance_type__in.choices).map((br) => br);
  const choicesReason =
    filterParams.reason__in && Object.entries(filterParams.reason__in.choices).map((br) => br);
  const choicesReasonColor =
    filterParams.reason__color &&
    Object.entries(filterParams.reason__color.choices).map((br) => br);

  const choicesBranch =
    filterParams.branch && Object.entries(filterParams.branch.choices).map((br) => br);

  const valueOptionBranches =
    filterParams &&
    Object.entries(filterParams.branch.choices).map((b: any) => newOption(b[0], b[1]));
  const labelOptionBranches = currentBranch.toString();
  const colors = choicesReasonColor.map((color) => color[1]);
  const choicesStatus =
    filterParams.status__in && Object.entries(filterParams.status__in.choices).map((br) => br);
  const choicesDoctor =
    filterParams.doctor__in && Object.entries(filterParams.doctor__in.choices).map((br) => br);

  const countDoctor = useMemo(
    () =>
      choicesDoctor.reduce(
        (count, [doctorId]) => (currentDoctorParams.includes(doctorId) ? count + 1 : count),
        0,
      ),
    [currentDoctorParams, choicesDoctor],
  );
  const countInsurance = useMemo(
    () =>
      choicesInsurance.reduce(
        (count, [insuranceId]) =>
          currentInsuranceParams.includes(insuranceId) ? count + 1 : count,
        0,
      ),
    [currentInsuranceParams, choicesInsurance],
  );
  const countReason = useMemo(
    () =>
      choicesReason.reduce(
        (count, [reasonId]) => (currentReasonParams.includes(reasonId) ? count + 1 : count),
        0,
      ),
    [currentReasonParams, choicesReason],
  );
  const countStatus = useMemo(
    () =>
      choicesStatus.reduce(
        (count, [statusId]) => (currentStatusParams.includes(statusId) ? count + 1 : count),
        0,
      ),
    [currentStatusParams, choicesStatus],
  );

  const search = filterParams.search && Object.entries(filterParams.search).map((l) => l[1]);
  const defaultSearchParam = getObjectFromQueryString(currentQueryString)?.search;

  const setBranch = (branchId: number): void => {
    cleanFilters();
    dispatch(setCurrentBranchesAC(branchId));
    dispatch(fetchFilteredEventsParamsListTC());
    dispatch(fetchFilteredEventsListOfGeneral());
  };

  const setFilterInsurance = (params: string): void => {
    dispatch(setFilteredEventsOfInsuranceTC(params));
  };

  const setFilterReason = (params: string): void => {
    dispatch(setFilteredEventsOfReasonTC(params));
  };

  const setFilterStatus = (params: string): void => {
    dispatch(setFilteredEventsOfStatusTC(params));
  };

  const onSearchChange = (value: string): void => {
    dispatch(setFilteredEventsSearchTC(value));
  };
  const setFilterDoctor = (params: string): void => {
    dispatch(setFilteredEventsOfDoctorTC(params));
  };

  const collapseInsuranceFilter = (): void => {
    dispatch(collapseInsuranceEventsFilterAC(!isCollapsedInsurance));
  };
  const collapseReasonFilter = (): void => {
    dispatch(collapseReasonEventsFilterAC(!isCollapsedReason));
  };
  const collapseStatusFilter = (): void => {
    dispatch(collapseStatusEventsFilterAC(!isCollapsedStatus));
  };

  const filterData = currentBranch && {
    branches: currentBranch && defaultChoicesSelect(valueOptionBranches, labelOptionBranches),
  };
  const { register, control } = useForm<FormDataType>({
    mode: 'onBlur',
    defaultValues: useMemo(() => {
      if (filterData) {
        return filterData;
      }
    }, [filterData, currentBranch]),
  });

  return (
    <div className="form-event-filter">
      <div className="filter-block">
        <div className="filter-block-select">
          <ReactSelectBranch
            name="branches"
            label={t(TranslationKeys.current_branch)}
            placeholder={t(TranslationKeys.current_branch)}
            control={control}
            isMulti={false}
            defaultValue={
              currentBranch && defaultChoicesSelect(valueOptionBranches, labelOptionBranches)
            }
            onChange={(e) => setBranch(e.value)}
            options={
              choicesBranch &&
              Object.entries(filterParams.branch.choices).map((b: any) => newOption(b[0], b[1]))
            }
          />
        </div>
      </div>
      <div className="search">
        <Inputs
          onChangeText={onSearchChange}
          label={search[5]}
          state={'search'}
          name="search"
          defaultValue={defaultSearchParam && defaultSearchParam}
          style={{
            height: '50px',
            paddingTop: '14px',
            marginBottom: '0',
          }}
          register={register}
        />
      </div>

      {user.role !== 'doctor' && (
        <div className="filter-block">
          <div
            className={cx('app__toggle', {
              'app__toggle--active': openDoc,
            })}
            onClick={() => {
              setStateDoctor(!stateDoctor);
              isOpenDoc(!openDoc);
            }}
          >
            <span className="filter-title-name">
              {t(TranslationKeys.doctor)}
              <span
                style={{
                  marginLeft: '15px',
                  fontSize: '15px',
                  fontWeight: 'lighter',
                  color: 'rgba(45, 45, 45, 0.852)',
                }}
              >
                [
                <span
                  style={{
                    color: 'black',
                  }}
                >
                  {countDoctor}
                </span>{' '}
                / {Object.keys(filterParams.doctor__in.choices).length}]
              </span>
            </span>

            <div
              onClick={() => setStateDoctor(!stateDoctor)}
              className={stateDoctor ? 'cross active' : 'cross'}
            />
          </div>
          <Collapse
            isOpen={openDoc}
            className={
              'app__collapse app__collapse--gradient ' + (openDoc ? 'app__collapse--active' : '')
            }
          >
            <div className="app__content">
              {filterParams.doctor__in &&
                choicesDoctor.map((doctor, index) => (
                  <div key={index} className={stateDoctor ? 'filter-param ' : ' filter-param-none'}>
                    <CheckboxInput
                      id={doctor[0]}
                      state={'square'}
                      checked={currentDoctorParams.find((cs) => cs === doctor[0]) === doctor[0]}
                      onChange={() => {
                        setFilterDoctor(doctor[0]);
                      }}
                      name={'status'}
                      value={doctor[0]}
                      label={doctor[1]}
                    />
                  </div>
                ))}
            </div>
          </Collapse>
        </div>
      )}
      <div
        className={cx('app__toggle', {
          'app__toggle--active': openReason,
        })}
        onClick={() => {
          isOpenReason(!openReason);
          collapseReasonFilter;
        }}
      >
        <span className="filter-title-name">
          {t(TranslationKeys.reason)}
          <span
            style={{
              marginLeft: '15px',
              fontSize: '15px',
              fontWeight: 'lighter',
              color: 'rgba(45, 45, 45, 0.852)',
            }}
          >
            [
            <span
              style={{
                color: 'black',
              }}
            >
              {countReason}
            </span>{' '}
            / {Object.keys(filterParams.reason__in.choices).length}]
          </span>
        </span>
        <div
          onClick={() => isOpenReason(!openReason)}
          className={openReason ? 'cross active' : 'cross'}
        />
      </div>
      <Collapse
        isOpen={openReason}
        className={
          'app__collapse app__collapse--gradient ' + (openReason ? 'app__collapse--active' : '')
        }
      >
        <div className="app__content">
          <div className="filter-block-color">
            <div
              style={{
                width: '80%',
                marginLeft: '30px',
              }}
            >
              {filterParams.reason__in &&
                choicesReason.map((reason, index) => (
                  <div
                    key={index}
                    className={!isCollapsedReason ? 'filter-param-color' : 'filter-param-none'}
                  >
                    <CheckboxInput
                      id={reason[0]}
                      state={'color'}
                      checked={currentReasonParams.find((cr) => cr === reason[0]) === reason[0]}
                      onChange={() => {
                        {
                          setFilterReason(reason[0]);
                        }
                      }}
                      name={'reasons'}
                      value={reason[0]}
                      label={`${reason[1]}  `}
                    />
                  </div>
                ))}
            </div>
            <div>
              {filterParams.reason__color &&
                colors.map((reason, index) => (
                  <div
                    key={index}
                    className={!isCollapsedReason ? 'filter-param-color' : 'filter-param-none'}
                  >
                    <div
                      className="color"
                      style={{
                        background: `#${reason}`,
                      }}
                    ></div>
                  </div>
                ))}
            </div>
          </div>
        </div>
      </Collapse>

      <div className="filter-block">
        <div
          className={cx('app__toggle', {
            'app__toggle--active': open,
          })}
          onClick={() => {
            collapseInsuranceFilter;
            isOpen(!open);
          }}
        >
          <span className="filter-title-name">
            {t(TranslationKeys.insurance)}
            <span
              style={{
                marginLeft: '15px',
                fontSize: '15px',
                fontWeight: 'lighter',
                color: 'rgba(45, 45, 45, 0.852)',
              }}
            >
              [
              <span
                style={{
                  color: 'black',
                }}
              >
                {countInsurance}{' '}
              </span>
              / {Object.keys(filterParams.insurance_type__in.choices).length}]
            </span>
          </span>

          <div onClick={() => isOpen(!open)} className={open ? 'cross active' : 'cross'}></div>
        </div>

        <Collapse
          isOpen={open}
          className={
            'app__collapse app__collapse--gradient ' + (open ? 'app__collapse--active' : '')
          }
        >
          <div className="app__content">
            {filterParams.insurance_type__in &&
              choicesInsurance.map((insurance, index) => (
                <div
                  key={index}
                  className={!isCollapsedInsurance ? ' filter-param' : 'filter-param-none'}
                >
                  <CheckboxInput
                    id={insurance[0][1]}
                    state={'square'}
                    checked={
                      currentInsuranceParams.find((ci) => ci === insurance[0]) === insurance[0]
                    }
                    onChange={() => {
                      setFilterInsurance(insurance[0]);
                    }}
                    name={'insurance_type'}
                    value={insurance[0]}
                    label={insurance[1]}
                  />
                </div>
              ))}
          </div>
        </Collapse>
      </div>
      <div className="filter-block">
        <div
          className={cx('app__toggle', {
            'app__toggle--active': openStatus,
          })}
          onClick={() => {
            collapseStatusFilter;
            isOpenStatus(!openStatus);
          }}
        >
          <span className="filter-title-name">
            {t(TranslationKeys.status)}
            <span
              style={{
                marginLeft: '15px',
                fontSize: '15px',
                fontWeight: 'lighter',
                color: 'rgba(45, 45, 45, 0.852)',
              }}
            >
              [
              <span
                style={{
                  color: 'black',
                }}
              >
                {countStatus}
              </span>{' '}
              / {Object.keys(filterParams.status__in.choices).length}]
            </span>
          </span>

          <div
            onClick={() => isOpenStatus(!openStatus)}
            className={openStatus ? 'cross active' : 'cross'}
          />
        </div>
        <Collapse
          isOpen={openStatus}
          className={
            'app__collapse app__collapse--gradient ' + (openStatus ? 'app__collapse--active' : '')
          }
        >
          <div className="app__content">
            {filterParams.status__in &&
              choicesStatus.map((status, index) => (
                <div
                  key={index}
                  className={!isCollapsedStatus ? 'filter-param' : 'filter-param-none'}
                >
                  <CheckboxInput
                    id={status[0]}
                    state={'square'}
                    checked={currentStatusParams.find((cs) => cs === status[0]) === status[0]}
                    onChange={() => {
                      setFilterStatus(status[0]);
                    }}
                    name={'status'}
                    value={status[0]}
                    label={status[1]}
                  />
                </div>
              ))}
          </div>
        </Collapse>
      </div>
    </div>
  );
};
