import { saveProfileDataLS, saveToken } from '../utils/localStorage';
import { useDispatch } from 'react-redux';
import { editStateMenuAC, resetReducerGroupAC, setAppIsInitializedAC, setIsLoggedInAC } from "../redux/actions";

/**
 * "useLogout" is a custom logout hook. Returns a function that clears local storage,
 * application state, and modifies "isLoggedIn: false, isInitialized: false".
 * */
export const useLogout = (): () => void => {
  const dispatch = useDispatch();

  return () => {
    dispatch(editStateMenuAC(false));
    saveToken('');
    saveProfileDataLS('');
    dispatch(setAppIsInitializedAC(false));
    dispatch(setIsLoggedInAC(false));
    dispatch(resetReducerGroupAC());
  };
};