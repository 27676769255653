import { instance } from './http';
import { PrivateHolidayUpdateModelType } from "../redux/actions/types";

export const holidaysAPI = {
  getList(branchId: number) {
    return instance.get<HolidaysBranchType>(`/api/holidays/${branchId}/list/`);
  },

  joinToHoliday(holidayId: number, branchId: number) {
    return instance.get<HolidayResponseType>(`/api/holidays/${holidayId}/branch/${branchId}/add/`);
  },

  leaveHoliday(holidayId: number, branchId: number) {
    return instance.get<Array<HolidaysBranchType>>(
      `/api/holidays/${holidayId}/branch/${branchId}/remove/`,
    );
  },

  addHoliday(data: any) {
    return instance.post<HolidayResponseType>('/api/holidays/create/', data);
  },

  editHoliday(data: any, holidayId: number) {
    return instance.put<PrivateHolidayUpdateModelType>(`/api/holidays/${holidayId}/`, data);
  },

  removeHoliday(holidayId: number) {
    return instance.delete<ResponseType>(`/api/holidays/${holidayId}/`);
  },
};

export type ResponseType = {
  config: {};
  data: string;
  headers: {};
  request: {};
  status: number;
  statusText: string;
};

export type HolidayResponseType = {
  id: number;
  owner: number;
  branches: number[];
  name: string;
  date: string;
  start_time: string;
  end_time: string;
  official: boolean;
  fromStart: string;
  external: boolean;
};

export type HolidaysBranchType = {
  official: Array<HolidayResponseType>;
  private: Array<HolidayResponseType>;
  suggestion: Array<HolidayResponseType>;
};

