/* eslint-disable */

/**
 * "convertObjectToArray" takes "fields" argument.
 * Returns a new array.
 * @param {Object} fields - object to be converted
 * */
export const convertObjectToArray = (fields: Object) => {
  const newArray = Object.entries(fields).map((field) => [{ name: field[0], params: field[1] }]);
  return newArray.map((field) => field[0]);
};