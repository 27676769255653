/* eslint-disable */
import i18next from 'i18next';

/**
 * "loadToken" is used to get the token from local storage.
 * The function takes nothing and returns a token.
 * */
export const loadToken = () => {
    try {
        const tokenJSON = localStorage.getItem('token');
        if (tokenJSON === null) {
            return undefined;
        }
        return JSON.parse(tokenJSON);
    } catch (err) {
        return undefined;
    }
};

/**
 * "saveToken" is used to save the token to local storage.
 * The function takes a token and returns nothing.
 * @param {string} token - token
 * */
export const saveToken = (token: string) => {
    try {
        const tokenJSON = JSON.stringify(token);
        localStorage.setItem('token', tokenJSON);
    } catch {
        // ignore write errors
    }
};

/**
 * "loadProfileDataLS" is used to get user data from local storage.
 * The function takes nothing and returns user data.
 * */
export const loadProfileDataLS = () => {
    try {
        const profileDataJSON = localStorage.getItem('profileData');
        if (profileDataJSON === null) {
            return undefined;
        }
        return JSON.parse(profileDataJSON);
    } catch (err) {
        return undefined;
    }
};

/**
 * "saveProfileDataLS" is used to save user data to local storage.
 * The function takes an object with user data and returns nothing.
 * @param {Object} userData - user data (access, first_name, last_name, photo, refresh, role, username)
 * */
export const saveProfileDataLS = (userData: Object) => {
    try {
        const profileDataJSON = JSON.stringify(userData);
        localStorage.setItem('profileData', profileDataJSON);
    } catch {
        // ignore write errors
    }
};

/**
 * "loadLang" is used to get the selected language from local storage.
 * The function takes nothing and returns the selected language.
 * */
export const loadLang = () => {
    // let language = 'de';
    try {
        const langJSON = localStorage.getItem("i18nextLng");
        if (langJSON === null) {
            // return language;
            return '';
        }
        return langJSON;
    } catch (err) {
        // return language;
        return '';
    }
};

/**
 * "saveLangLS" is used to save language to local storage.
 * The function takes an object with language and returns nothing.
 * @param {Object} lang - language (string)
 * */
export const saveLangLS = (lang: string) => {
    try {
        localStorage.setItem('i18nextLng', lang);
    } catch {
        // ignore write errors
    }
};

