import React, { FC, useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Navigate } from 'react-router-dom';
import { PATH } from '../../../routes/Routes';
import './ModalAlertWaitingRoom.scss';
import { ConfirmationPopup } from '../ConfirmationPopup/ConfirmationPopup';
import {
  getCurrentBranchSelector,
  getEventsSelector,
  getIsLoggedInSelector,
  getStatusSelector,
  getWaitingRoomSelector,
} from '../../../redux/selectors';
import { useStatusFilterParams } from '../../../hooks/useStatusFilterParams';
import { useTranslation } from 'react-i18next';
import dayjs from 'dayjs';
import { ButtonClose } from '../../Widgets/ButtonClose/ButtonClose';
import {
  changeStatusEventTC,
  fetchWaitingRoomListTC,
  initializeAppTC,
  removeEventTC,
} from '../../../redux/middlewares';
import TranslationKeys from '../../../assets/locales/translationKeys/translationKeys';
import { logo } from '../../../assets/img/image';
import PreloaderEvent from '../../Preloaders/EventsPreload/PreloadEvents';
import { setSelectedEventAC } from '../../../redux/actions';

type ModalAlertPropsType = {
  onClickBtn?: () => void;
  closeModalAlert?: () => void;
  selectIndex?: any;
  setSelectIndex?: any;
};

export const ModalAlertWaiting: FC<ModalAlertPropsType> = ({
  onClickBtn,
  closeModalAlert,
  selectIndex,
  setSelectIndex,
}) => {
  const dispatch = useDispatch();
  const statusFilterParams = useStatusFilterParams();
  const componentRef = useRef();
  const componentScrollRef = useRef();

  const waitingRoom = useSelector(getWaitingRoomSelector);
  const status = useSelector(getStatusSelector);
  const isLoggedIn = useSelector(getIsLoggedInSelector);
  const events = useSelector(getEventsSelector);
  const currentBranch = useSelector(getCurrentBranchSelector);

  const [openAlertDialogForDelete, setOpenAlertDialogForDelete] = useState(false);
  const [modalDataId, setModalDataId] = useState<number>(0);

  //locale
  const { t } = useTranslation();

  useEffect(() => {
    return () => {
      dispatch(setSelectedEventAC(null));
    };
  }, []);

  useEffect(() => {
    if (status === 'failed') {
      dispatch(initializeAppTC());
    }
  }, [status]);

  useEffect(() => {
    dispatch(fetchWaitingRoomListTC());
  }, [events, currentBranch]);

  useEffect(() => {
    const handleClick = (e: any): void => {
      if (componentRef && componentRef.current) {
        const ref: any = componentRef.current;
        if (!ref.contains(e.target)) {
          setSelectIndex && setSelectIndex(null);
        }
      }
    };

    document.addEventListener('click', handleClick);
    return () => document.removeEventListener('click', handleClick);
  }, []);

  useEffect(() => {
    if (componentScrollRef && componentScrollRef.current) {
      const ref: any = componentScrollRef.current;
      if (ref.id === `${selectIndex}`) {
        ref.scrollIntoView({ block: 'center', behavior: 'smooth' });
      }
    }
  }, [selectIndex, componentScrollRef.current]);

  const handleButtonClick = (): void => {
    if (componentScrollRef && componentScrollRef.current) {
      const ref: any = componentScrollRef.current;
      ref.scrollIntoView({ block: 'center', behavior: 'smooth' });
    }
  };

  const setDate = (date: string): Date => {
    return new Date(date);
  };

  const removeEvents = (id: number): void => {
    dispatch(removeEventTC(id));
    setModalDataId(0);
  };

  const onChangeStatus = async (eventId: number, currStatus: string): Promise<void> => {
    await setSelectIndex && setSelectIndex(eventId);
    await dispatch(changeStatusEventTC(eventId, currStatus));
    await handleButtonClick();
  };

  const closeAlertHandler = (): void => {
    setSelectIndex(null);
    setOpenAlertDialogForDelete(false);
  };

  if (!isLoggedIn) {
    return <Navigate to={PATH.LOGIN} />;
  }

  return (
    <>
      <div
        onClick={() => {
          setSelectIndex && setSelectIndex(0);
          closeModalAlert && closeModalAlert();
        }}
        className={'container'}
      >
        <div onClick={(e) => e.stopPropagation()} className={'waitingRoomAlert'}>
          <div className="waitingRoomAlert__btnBlock">
            <ButtonClose onClickBtn={onClickBtn} />
          </div>
          <h1 className="waitingRoomAlert__title">{t('waiting_header')}</h1>
          <p className="waitingRoomAlert__text">{t('welcome')}</p>

          <div>
            <span className="title">{t('waiting')}</span>

            {status === 'loading' && (
              <div className="preloader-wrap">
                <PreloaderEvent />
              </div>
            )}

            <table className="waitingRoomAlert__table">
              <thead className="thead">
                <tr className="tr">
                  <th className="th th-name">
                    <span>{t('full_name')}</span>
                  </th>
                  <th className="th th-reason">
                    <span>{t('reason')}</span>
                  </th>
                  <th className="th">
                    <span>{t('begin')}</span>
                  </th>
                  <th className="th th-war">
                    <span>{t('waiting_period')}</span>
                  </th>
                  <th className="th th-btn">
                    <span>{'Options'}</span>
                  </th>
                </tr>
              </thead>
              <tbody className="tbody">
                {waitingRoom.waiting_room.map((w) => (
                  <tr
                    ref={w.id === selectIndex ? (componentScrollRef as any) : null}
                    id={`${selectIndex}`}
                    key={w.id}
                    className={w.id === selectIndex ? 'tr active' : 'tr'}
                  >
                    <td className={'td td-name'}>
                      <b>
                        {w?.client?.first_name} {w?.client?.last_name}
                      </b>
                      <br />
                      <span
                        style={{
                          fontSize: '12px',
                        }}
                      >
                        #{w.id}
                      </span>
                    </td>
                    <td
                      style={{
                        backgroundColor: `${w.reason_color}38`,
                        borderColor: `${w.reason_color}`,
                      }}
                      className="td td-color"
                    >
                      <span className="color">{w.reason_display}</span>
                    </td>
                    <td className="td date-waiting">
                      <span>{dayjs(w.start).format('DD MMM. YYYY')}</span>
                      <b className="time">{dayjs(w.start).format('HH:mm')}</b>
                    </td>
                    <td className="td">{w.waiting_time}</td>
                    <td className="td td-btns">
                      <div className="btns">
                        <div
                          onClick={() => {
                            onChangeStatus(w.id, statusFilterParams.at_the_doctor);
                            // handleButtonClick();
                          }}
                          className="wrap-img-doc"
                        >
                          <img
                            title={t(TranslationKeys.event_at_the_doctor)}
                            className={'img-doc'}
                            src={logo.doctor_icon}
                            alt="at_the_doctor"
                          />
                        </div>

                        <div
                          onClick={() => onChangeStatus(w.id, statusFilterParams.did_not_wait)}
                          className="wrap-img-not"
                        >
                          <img
                            title={t(TranslationKeys.event_did_not_wait)}
                            className={'img-not'}
                            src={logo.close}
                            alt="did_not_wait"
                          />
                        </div>
                      </div>
                    </td>

                    <td className={w.id === selectIndex ? 'borderDoctor' : 'borderNone'}></td>
                    {openAlertDialogForDelete && (
                      <>
                        <ConfirmationPopup
                          actionCallback={removeEvents}
                          id={modalDataId}
                          titleQuestion="Delete event?"
                          actionTitle="Delete"
                          cancelCallback={closeAlertHandler}
                        />
                      </>
                    )}
                  </tr>
                ))}
              </tbody>
            </table>
            <span className="title">{t('event_at_the_doctor')}</span>
            <table className="waitingRoomAlert__table">
              <thead className="thead">
                <tr className="tr">
                  <th className="th th-name">
                    <span>{t('full_name')}</span>
                  </th>
                  <th className="th th-reason">
                    <span>{t('reason')}</span>
                  </th>
                  <th className="th">
                    <span>{t('begin')}</span>
                  </th>
                  <th className="th th-war">
                    <span>{t('waiting_period')}</span>
                  </th>
                  <th className="th th-btn">
                    <span>{'Options'}</span>
                  </th>
                </tr>
              </thead>
              <tbody className="tbody">
                {waitingRoom.at_the_doctor.map((w) => (
                  <tr
                    ref={w.id === selectIndex ? (componentScrollRef as any) : null}
                    id={`${selectIndex}`}
                    key={w.id}
                    className={w.id === selectIndex ? 'tr active' : 'tr'}
                  >
                    <td className="td td-name">
                      <b>
                        {w?.client?.first_name} {w?.client?.last_name}
                      </b>
                      <br />
                      <span
                        style={{
                          fontSize: '12px',
                        }}
                      >
                        #{w.id}
                      </span>
                    </td>
                    <td
                      style={{
                        backgroundColor: `${w.reason_color}38`,
                        borderColor: `${w.reason_color}`,
                      }}
                      className="td td-color"
                    >
                      <span
                        className="td"
                        style={{
                          opacity: 1,
                        }}
                      >
                        {w.reason_display}
                      </span>
                    </td>
                    <td className="td date-waiting">
                      <span>
                        {`${setDate(w.start).getUTCDate()} ${setDate(w.start)
                          .toDateString()
                          .slice(4, 7)}. ${setDate(w.start).getFullYear()}`}
                      </span>
                      <b className="time">{`${w.start.slice(11, 16)} `}</b>
                    </td>
                    <td className="td td-visit-time">{w.visit_time}</td>
                    <td className="td td-btns">
                      <div className="btns">
                        <div
                          onClick={() => onChangeStatus(w.id, statusFilterParams.done)}
                          className="wrap-img-done"
                        >
                          <img
                            title={t(TranslationKeys.done)}
                            className={'img-done'}
                            src={logo.check}
                            alt="done"
                          />
                        </div>
                      </div>
                    </td>

                    <td className={w.id === selectIndex ? 'borderDoctor' : 'borderNone'}></td>

                    {openAlertDialogForDelete && (
                      <>
                        <ConfirmationPopup
                          actionCallback={removeEvents}
                          id={modalDataId}
                          titleQuestion="Delete event?"
                          actionTitle="Delete"
                          cancelCallback={closeAlertHandler}
                        />
                      </>
                    )}
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </>
  );
};
