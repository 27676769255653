/**
 * "calcTimeForWorkDay" takes "startTime", "endTimeDay" and "stepMinutes" arguments.
 * Returns an array with the available time depending on the step.
 * @param {string} startTime - working hour
 * @param {string} endTimeDay - end time of the working day
 * @param {number} stepMinutes - step minutes 
 * */
export const calcTimeForWorkDay = (startTime: string, endTimeDay: string, stepMinutes: number): string[] => {
  const stepMinutesToHours = stepMinutes / 100;
  const lastMinute = 61 - stepMinutes;

  const startTimeNumber = Number(`${startTime.slice(0, 2)}.${startTime.slice(3, 5)}`);
  const endTimeNumber = Number(`${startTime.slice(0, 2)}.${lastMinute}`);

  const dynStartTime = startTime === endTimeDay
    ? Number(`${startTime.slice(0, 2)}.0`)
    : startTimeNumber;

  const dynEndTime = startTime === endTimeDay
    ? Number(`${endTimeDay.slice(0, 2)}.${endTimeDay.slice(3, 5)}`) - (stepMinutesToHours)
    : endTimeNumber;

  const timeWorkDefault = [dynStartTime];
  for (let i: any = dynStartTime + stepMinutesToHours; i <= dynEndTime; i = i + stepMinutesToHours) {

    timeWorkDefault.push(i);
  }

  const roundedTime = timeWorkDefault.map((hour) => hour.toFixed(2));
  const validationFormatTime = roundedTime.map((hour) => hour.length === 4
    ? `0${hour.slice(0, 1)}:${hour.slice(2, 4)}` : `${hour.slice(0, 2)}:${hour.slice(3, 5)}`)

  return validationFormatTime
}