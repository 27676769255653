import { BranchType } from '../../api/scheduleWork-api';
import { WorkScheduleActionsType } from '../actions/types';
import { EDIT_WORK_SCHEDULE, SET_LIST_WORK_SCHEDULE } from '../actions/constants';

const initialState: Array<BranchType> = [];

export const scheduleWorkReducer = (
  state: Array<BranchType> = initialState,
  action: WorkScheduleActionsType,
): Array<BranchType> => {
  switch (action.type) {
    case SET_LIST_WORK_SCHEDULE: {
      return action.schedule.map((tl) => ({ ...tl }));
    }
    case EDIT_WORK_SCHEDULE: {
      return state.map((e) => (e.id === action.model.id ? { ...e, ...action.model } : e));
    }
    default:
      return state;
  }
};