import React, { FC, useEffect, useMemo } from 'react';
import { NavLink } from 'react-router-dom';
import { PATH } from '../../../routes/Routes';
import './ProfileSideBar.scss';
import { useDispatch, useSelector } from 'react-redux';
import {
  getCurrentBranchSelector,
  getFilterParamsEventsSelector,
  getOpenMenuAppSelector,
} from '../../../redux/selectors';
import { useTranslation } from 'react-i18next';
import { defaultChoicesSelect, newOption } from '../../../components/Widgets/Select/ReactSelectStyles';
import { useForm } from 'react-hook-form';
import { ReactSelectBranch } from '../../../components/Widgets/Select/ReactSelectBranch';
import TranslationKeys from '../../../assets/locales/translationKeys/translationKeys';
import { editStateMenuAC, setCurrentBranchesAC } from "../../../redux/actions";
import { fetchFilteredEventsParamsListTC } from "../../../redux/middlewares";

type FormDataType = {
  branches: { value: any; label: any };
};

export const ProfileSideBar: FC = () => {
  //locale
  const { t } = useTranslation()
  const dispatch = useDispatch();

  const openMenu = useSelector(getOpenMenuAppSelector);
  const currentBranch = useSelector(getCurrentBranchSelector);
  const filterParams = useSelector(getFilterParamsEventsSelector);

  const valueOptionBranches =
    filterParams &&
    Object.entries(filterParams.branch.choices).map((b: any) => newOption(b[0], b[1]));
  const labelOptionBranches = currentBranch.toString();
  const choicesBranch =
    filterParams.branch && Object.entries(filterParams.branch.choices).map((br) => br);

  useEffect(() => {
    if (!valueOptionBranches.length && currentBranch !== 0) {
      dispatch(fetchFilteredEventsParamsListTC());
    }
  }, [dispatch]);

  const closeMenuCallback = (): void => {
    if (openMenu) {
      dispatch(editStateMenuAC(false));
    }
  };

  const setBranch = (branchId: number): void => {
    dispatch(setCurrentBranchesAC(branchId));
  }

  const filterData = currentBranch && {
    branches: currentBranch && defaultChoicesSelect(valueOptionBranches, labelOptionBranches),
  };

  const { control } = useForm<FormDataType>({
    mode: 'onBlur',
    defaultValues: useMemo(() => {
      if (filterData) {
        return filterData;
      }
    }, [filterData, currentBranch]),
    });

  return (
    <nav className="admin__left-nav">
      <div className='select'>
        <ReactSelectBranch
          name="branches"
          label={t(TranslationKeys.current_branch)}
          placeholder={t(TranslationKeys.current_branch)}
          control={control}
          isMulti={false}
          defaultValue={
            currentBranch  &&
            defaultChoicesSelect(valueOptionBranches, labelOptionBranches)
          }
          onChange={(e) => setBranch(e.value)}
          options={
            choicesBranch &&
            Object.entries(filterParams.branch.choices).map((b: any) => newOption(b[0], b[1]))
          }
        />

      </div>

      <ul className="list">
        <li onClick={closeMenuCallback} className="item">
          <NavLink className="item-link" to={PATH.PROFILE}>{t(TranslationKeys.profile_nav)}</NavLink>
        </li>
        <li onClick={closeMenuCallback} className="item">
          <NavLink className="item-link" to={PATH.BRANCHES}>{t(TranslationKeys.branches_nav)}</NavLink>
        </li>
        <li onClick={closeMenuCallback} className="item">
          <NavLink className="item-link" to={PATH.EMPLOYEES}>{t(TranslationKeys.employees_nav)}</NavLink>
        </li>
        <li onClick={closeMenuCallback} className="item">
          <NavLink className="item-link" to={PATH.REASONS}>{t(TranslationKeys.reasons_nav)}</NavLink>
        </li>
        <li onClick={closeMenuCallback} className="item">
          <NavLink className="item-link" to={PATH.WORK_SCHEDULE}>{t(TranslationKeys.work_shedule_nav)}</NavLink>
        </li>
        <li onClick={closeMenuCallback} className="item">
          <NavLink className="item-link" to={PATH.HOLIDAYS}>{t(TranslationKeys.holidays_nav)}</NavLink>
        </li>

        <li onClick={closeMenuCallback} className="item">
          <NavLink className="item-link" to={PATH.CONTACTS}>{t(TranslationKeys.contacts_nav)}</NavLink>
        </li>
      </ul>
    </nav>
  );
};