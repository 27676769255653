import { WaitingRoomFilterParamsType } from '../../api/waitingRoom-api';
import {
  ADD_CURRENT_DOCTOR_FILTER_WAITING,
  ADD_CURRENT_REASON_COLOR_FILTER_WAITING,
  ADD_CURRENT_REASON_FILTER_WAITING,
  ADD_WAITING_SEARCH,
  CLEAN_WAITING_ROOM_FILTER,
  COLLAPSE_FILTER_DOCTOR_FILTER_WAITING,
  COLLAPSE_FILTER_REASON_FILTER_WAITING,
  REMOVE_CURRENT_DOCTOR_FILTER_WAITING,
  REMOVE_CURRENT_REASON_COLOR_FILTER_WAITING,
  REMOVE_CURRENT_REASON_FILTER_WAITING,
  SET_CURRENT_PARAM_BRANCH_FILTER_WAITING,
  SET_CURRENT_WAITING_ROOM_QUERY_STRING,
  SET_LIST_OF_BRANCH_FILTER_WAITING,
} from './constants';

export const setListOfBranchFilterWaitingAC = (params: WaitingRoomFilterParamsType) =>
  ({ type: SET_LIST_OF_BRANCH_FILTER_WAITING, params } as const);
export const setCurrentParamBranchFilterWaitingAC = (params: any) =>
  ({ type: SET_CURRENT_PARAM_BRANCH_FILTER_WAITING, params } as const);
export const addCurrentReasonFilterWaitingAC = (params: string) =>
  ({ type: ADD_CURRENT_REASON_FILTER_WAITING, params } as const);
export const removeCurrentReasonFilterWaitingAC = (params: string) =>
  ({ type: REMOVE_CURRENT_REASON_FILTER_WAITING, params } as const);
export const addCurrentReasonColorFilterWaitingAC = (params: string) =>
  ({ type: ADD_CURRENT_REASON_COLOR_FILTER_WAITING, params } as const);
export const removeCurrentReasonColorFilterWaitingAC = (params: string) =>
  ({ type: REMOVE_CURRENT_REASON_COLOR_FILTER_WAITING, params } as const);
export const addCurrentDoctorFilterWaitingAC = (params: string) =>
  ({ type: ADD_CURRENT_DOCTOR_FILTER_WAITING, params } as const);
export const removeCurrentDoctorFilterWaitingAC = (params: string) =>
  ({ type: REMOVE_CURRENT_DOCTOR_FILTER_WAITING, params } as const);
export const collapseReasonFilterWaitingAC = (param: boolean) =>
  ({ type: COLLAPSE_FILTER_REASON_FILTER_WAITING, param } as const);
export const collapseDoctorFilterWaitingAC = (param: boolean) =>
  ({ type: COLLAPSE_FILTER_DOCTOR_FILTER_WAITING, param } as const);
export const addWaitingSearchParamAC = (params: string) =>
  ({ type: ADD_WAITING_SEARCH, params } as const);
export const cleanWaitingRoomFilterAC = () => ({ type: CLEAN_WAITING_ROOM_FILTER } as const);
export const setCurrWaitingRoomQueryStringAC = (query: string | null) =>
  ({ type: SET_CURRENT_WAITING_ROOM_QUERY_STRING, query } as const);