import { Dispatch } from 'redux';
import {
  addBranchAC, addCurrentEventFilterDoctorAC,
  editBranchAC,
  getScheduleBranchesAC,
  removeBranchAC, removeCurrentEventFilterDoctorAC,
  setActiveCurrentWeekAC,
  setAppStatusAC,
  setBranchesListAC,
  setCurrentBranchesAC,
  setPreloaderAC
} from "../actions";
import { branchesAPI } from '../../api/branches-api';
import { handleServerAppError, handleServerNetworkError } from '../../utils/error-utils';
import { AppRootStateType } from '../store';

export const fetchBranchesList = () => async (dispatch: Dispatch<any>) => {
  dispatch(setAppStatusAC('loading'));
  await dispatch(setPreloaderAC(true));
  try {
    const res = await branchesAPI.getList();
    dispatch(setBranchesListAC(res.data));
    dispatch(setPreloaderAC(false));
    dispatch(setAppStatusAC('succeeded'));
  } catch (error: any) {
    if (error.message === 'Network Error') {
      handleServerNetworkError(error, dispatch);
    } else {
      handleServerAppError(error, dispatch);
    }
  }
  dispatch(setAppStatusAC('idle'));
};

export const getScheduleBranchFirstRenderTC =
  () => async (dispatch: Dispatch<any>, getState: () => AppRootStateType) => {
    dispatch(setAppStatusAC('loading'));
    try {
      if (getState().branches.branches.length) {
        const firstBranchId = await getState().branches.branches[0].id;
        const res = await branchesAPI.getScheduleFirstRenderBranch(firstBranchId);
        dispatch(getScheduleBranchesAC(res.data));
        dispatch(setActiveCurrentWeekAC(res.data.current_week[0]));
        dispatch(setAppStatusAC('succeeded'));
      }
    } catch (error: any) {
      if (error.message === 'Network Error') {
        handleServerNetworkError(error, dispatch);
      } else {
        handleServerAppError(error, dispatch);
      }
    }
    dispatch(setAppStatusAC('idle'));
  };

export const fetchBranchesListFirstRender = () => async (dispatch: Dispatch<any>) => {
  dispatch(setAppStatusAC('loading'));
  try {
    const res = await branchesAPI.getList();
    dispatch(setBranchesListAC(res.data));
    dispatch(getScheduleBranchFirstRenderTC());
    if (res.data.length) {
      dispatch(setCurrentBranchesAC(res.data[0].id));
    }
  } catch (error: any) {
    if (error.message === 'Network Error') {
      handleServerNetworkError(error, dispatch);
    } else {
      handleServerAppError(error, dispatch);
    }
  }
};

export const addBranchTC = (data: any) => async (dispatch: Dispatch<any>) => {
  dispatch(setAppStatusAC('loading'));
  try {
    const res = await branchesAPI.addBranch(data);
    dispatch(addBranchAC(res.data));
    dispatch(setAppStatusAC('succeeded'));
  } catch (error: any) {
    if (error.message === 'Network Error') {
      handleServerNetworkError(error, dispatch);
    } else {
      handleServerAppError(error, dispatch);
    }
  }
};

export const editBranchTC = (id: any, data: any) => async (dispatch: Dispatch<any>) => {
  dispatch(setAppStatusAC('loading'));
  try {
    const res = await branchesAPI.editBranch(data, id);
    dispatch(editBranchAC(res.data));
    dispatch(setAppStatusAC('succeeded'));
  } catch (error: any) {
    if (error.message === 'Network Error') {
      handleServerNetworkError(error, dispatch);
    } else {
      handleServerAppError(error, dispatch);
    }
  }
};

export const removeBranchTC = (branchId: number) => async (dispatch: Dispatch<any>) => {
  dispatch(setAppStatusAC('loading'));
  try {
    await branchesAPI.removeBranch(branchId);
    dispatch(removeBranchAC(branchId));
    dispatch(setAppStatusAC('succeeded'));
  } catch (error: any) {
    if (error.message === 'Network Error') {
      handleServerNetworkError(error, dispatch);
    } else {
      handleServerAppError(error, dispatch);
    }
  }
  dispatch(setAppStatusAC('idle'));
};

export const getScheduleBranchTC =
  (branchId: number) => async (dispatch: Dispatch<any>, getState: () => AppRootStateType) => {
    const currentDate = getState().branches.activeCurrentWeek;
    dispatch(setAppStatusAC('loading'));
    try {
      const res = await branchesAPI.getScheduleBranch(branchId, currentDate);
      dispatch(getScheduleBranchesAC(res.data));
      dispatch(setAppStatusAC('succeeded'));
    } catch (error: any) {
      if (error.message === 'Network Error') {
        handleServerNetworkError(error, dispatch);
      } else {
        handleServerAppError(error, dispatch);
      }
    }
    dispatch(setAppStatusAC('idle'));
  };

export const getScheduleBranchNextWeekTC =
  (branchId: number) => async (dispatch: Dispatch<any>, getState: () => AppRootStateType) => {
    dispatch(setAppStatusAC('loading'));
    const next = getState().branches.scheduleBranch.next_week;
    const doctorOrdering = getState().eventsFilter.currentFilterParams.doctor__in;
    try {
      const res = await branchesAPI.getScheduleBranchOfWeekly(branchId, next, doctorOrdering);
      dispatch(getScheduleBranchesAC(res.data));
      dispatch(setActiveCurrentWeekAC(res.data.current_week[0]));
      dispatch(setAppStatusAC('succeeded'));
    } catch (error: any) {
      if (error.message === 'Network Error') {
        handleServerNetworkError(error, dispatch);
      } else {
        handleServerAppError(error, dispatch);
      }
    }
    dispatch(setAppStatusAC('idle'));
  };

export const getScheduleBranchPrevWeekTC =
  (branchId: number) => async (dispatch: Dispatch<any>, getState: () => AppRootStateType) => {
    dispatch(setAppStatusAC('loading'));
    const prev = getState().branches.scheduleBranch.previous_week;
    const doctorOrdering = getState().eventsFilter.currentFilterParams.doctor__in;
    try {
      const res = await branchesAPI.getScheduleBranchOfWeekly(branchId, prev, doctorOrdering);
      dispatch(getScheduleBranchesAC(res.data));
      dispatch(setActiveCurrentWeekAC(res.data.current_week[0]));
      dispatch(setAppStatusAC('succeeded'));
    } catch (error: any) {
      if (error.message === 'Network Error') {
        handleServerNetworkError(error, dispatch);
      } else {
        handleServerAppError(error, dispatch);
      }
    }
    dispatch(setAppStatusAC('idle'));
  };

export const getScheduleBranchChosenCalendarWeekTC =
  (branchId: number, week: string) =>
  async (dispatch: Dispatch<any>, getState: () => AppRootStateType) => {
    const doctorOrdering = getState().eventsFilter.currentFilterParams.doctor__in;
    dispatch(setAppStatusAC('loading'));
    try {
      const res = await branchesAPI.getScheduleBranchOfWeekly(branchId, week, doctorOrdering);
      dispatch(getScheduleBranchesAC(res.data));
      dispatch(setActiveCurrentWeekAC(res.data.current_week[0]));
      dispatch(setAppStatusAC('succeeded'));
    } catch (error: any) {
      if (error.message === 'Network Error') {
        handleServerNetworkError(error, dispatch);
      } else {
        handleServerAppError(error, dispatch);
      }
    }
    dispatch(setAppStatusAC('idle'));
  };

export const getScheduleBranchActiveWeekTC =
  (branchId: number) => async (dispatch: Dispatch<any>, getState: () => AppRootStateType) => {
    dispatch(setAppStatusAC('loading'));
    const activeWeek = getState().branches.activeCurrentWeek;
    const doctorOrdering = getState().eventsFilter.currentFilterParams.doctor__in;
    try {
      const res = await branchesAPI.getScheduleBranchOfWeekly(branchId, activeWeek, doctorOrdering);
      dispatch(getScheduleBranchesAC(res.data));
      dispatch(setAppStatusAC('succeeded'));
    } catch (error: any) {
      if (error.message === 'Network Error') {
        handleServerNetworkError(error, dispatch);
      } else {
        handleServerAppError(error, dispatch);
      }
    }
  };

export const setFilteredScheduleOfDoctorTC =
  (currentDoctor: string) => async (dispatch: Dispatch<any>, getState: () => AppRootStateType) => {
    const currentDoctorChecked = getState().eventsFilter.currentFilterParams.doctor__in.find(
      (status) => status === currentDoctor,
    );
    const currentBranchChecked = getState().branches.currentBranch;

    try {
      if (currentDoctorChecked) {
        dispatch(removeCurrentEventFilterDoctorAC(currentDoctor));
      } else {
        dispatch(addCurrentEventFilterDoctorAC(currentDoctor));
      }
      dispatch(getScheduleBranchActiveWeekTC(currentBranchChecked));
    } catch (error: any) {
      if (error.message === 'Network Error') {
        handleServerNetworkError(error, dispatch);
      } else {
        handleServerAppError(error, dispatch);
      }
    }
  };