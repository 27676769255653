import { EventDetailType, EventType } from '../../api/events-api';
import { DateListEventType } from '../../api/branches-api';
import {
  ADD_EVENT,
  CANCEL_ORDER_EVENT,
  CHANGE_STATE_MODAL_WAITING,
  CHANGE_STATUS_EVENTS,
  EDIT_EVENT,
  REMOVE_EVENT,
  SET_CHOSEN_DAY_SCHEDULE,
  SET_CURRENT_EVENT_DETAILS,
  SET_CURRENT_EVENT_SCHEDULE,
  SET_CURRENT_ORDERING_EVENTS,
  SET_CURRENT_PAGE_EVENTS,
  SET_EVENTS_LIST,
  SET_EVENTS_PARAMS,
  SET_SELECTED_EVENT,
} from './constants';
import { EventsParamsType, UpdateEventModelType } from './types';

export const setEventsListAC = (events: Array<EventType>) =>
  ({ type: SET_EVENTS_LIST, events } as const);
export const setEventsParamsAC = (module: EventsParamsType) =>
  ({ type: SET_EVENTS_PARAMS, module } as const);
export const addEventAC = (event: EventType) => ({ type: ADD_EVENT, event } as const);
export const editEventAC = (model: UpdateEventModelType) => ({ type: EDIT_EVENT, model } as const);
export const changeStatusEventAC = (
  eventId: number,
  event: { status: string; status_display: string },
) => ({ type: CHANGE_STATUS_EVENTS, eventId, event } as const);
export const removeEventAC = (id: number) => ({ type: REMOVE_EVENT, id } as const);
export const setCurrentOrderingsEventsAC = (ordering: string | null) =>
  ({ type: SET_CURRENT_ORDERING_EVENTS, ordering } as const);
export const setCurrentPageEventsAC = (page: string | null) =>
  ({ type: SET_CURRENT_PAGE_EVENTS, page } as const);
export const setCurrentEventDetailsAC = (details: EventDetailType) =>
  ({ type: SET_CURRENT_EVENT_DETAILS, details } as const);
export const cancelOrderEventsAC = (event: EventType) =>
  ({ type: CANCEL_ORDER_EVENT, event } as const);
export const setChosenDayInScheduleAC = (day: any) =>
  ({ type: SET_CHOSEN_DAY_SCHEDULE, day } as const);
export const setCurrentEventScheduleAC = (event: DateListEventType | EventDetailType | null) =>
  ({ type: SET_CURRENT_EVENT_SCHEDULE, event } as const);
export const changeStateModalWaitingRoomAC = (value: boolean) =>
  ({ type: CHANGE_STATE_MODAL_WAITING, value } as const);
export const setSelectedEventAC = (value: number | null) =>
  ({ type: SET_SELECTED_EVENT, value } as const);