import React, { FC } from 'react';
import { motion } from 'framer-motion';
import './ConfirmationPopup.scss';
import { useTranslation } from 'react-i18next';
import { ButtonClose } from '../../Widgets/ButtonClose/ButtonClose';

type ModalAlertPropsType = {
  actionCallback: (id: number) => void;
  id: number;
  cancelCallback: () => void;
  onClickBtn?: () => void;
  titleQuestion: string;
  actionTitle: string;
};

export const ConfirmationPopup: FC<ModalAlertPropsType> = ({
  actionCallback,
  id,
  cancelCallback,
  onClickBtn,
  titleQuestion,
  actionTitle,
}) => {
  const onActionHandler = (): void => {
    actionCallback(id);
    cancelCallback();
  };
  const onCancelHandler = (): void => {
    cancelCallback();
  };

  //locale
  const { t } = useTranslation()

  return (
    <>
      <tbody>
        <motion.td className={'delete-window '} onClick={onCancelHandler}>
          <motion.td className="delete-window__alert" onClick={(e) => e.stopPropagation()}>
            <td className="btn-close-wrap">
              <ButtonClose onClickBtn={onClickBtn}/>
            </td>
            <motion.h2
              animate={{
                x: 0,
              }}
              className="title"
            >
              {titleQuestion}
            </motion.h2>
            <motion.td className="btn-wrapper">
              <button
                onClick={onActionHandler}
                className="button button_size-middle button_position-left button_color-delete"
              >
                {actionTitle}
             {/*    {t('delete')} */}
              </button>
              <button
                onClick={onCancelHandler}
                className="button button_size-middle button_position-right button_color-transparent"
              >
                {t('cancel')}
              </button>
            </motion.td>
          </motion.td>
        </motion.td>
      </tbody>
    </>
  );
};

