import { Dispatch } from "redux";
import { AppRootStateType } from "../store";
import { setAppStatusAC, setListWaitingRoomAC } from "../actions";
import { waitingRoomAPI } from "../../api/waitingRoom-api";
import { handleServerAppError, handleServerNetworkError } from "../../utils/error-utils";

export const fetchWaitingRoomListTC =
  () => async (dispatch: Dispatch<any>, getState: () => AppRootStateType) => {
    dispatch(setAppStatusAC('loading'));
    const currentBranchId = getState().branches.currentBranch;
    try {
      let res = await waitingRoomAPI.getList(currentBranchId);
      dispatch(setListWaitingRoomAC(res.data, currentBranchId));
      dispatch(setAppStatusAC('succeeded'));
    } catch (error: any) {
      if (error.message === 'Network Error') {
        handleServerNetworkError(error, dispatch);
      } else {
        handleServerAppError(error, dispatch);
      }
    }
    dispatch(setAppStatusAC('idle'));
  };