import { useCallback, useEffect, useRef, useState } from 'react';
import { logo } from '../../../assets/img/image';
import './Notification.scss';
import cx from "classnames";
import Collapse from "@kunukn/react-collapse";

export const Notification: React.FC = () => {

    const componentRef = useRef();
    const [openMenu, setOpenMenu] = useState(false);

    const onClickHandler = (): void => {
        setOpenMenu(!openMenu)
    }

    const escFunction = useCallback((event) => {
        if (event.key === 'Escape') {
            setOpenMenu(false)
        }
    }, []);

    useEffect(() => {
        document.addEventListener('keydown', escFunction, false);
        return () => {
            document.removeEventListener('keydown', escFunction, false);
        };
    }, []);

    useEffect(() => {
        const handleClick = (e: any): void => {
            if (componentRef && componentRef.current) {
                const ref: any = componentRef.current;
                if (!ref.contains(e.target)) {
                    setOpenMenu(false)
                }
            }
        };

        document.addEventListener('click', handleClick);
        return () => document.removeEventListener('click', handleClick);
    }, []);

    return (
        <div onClick={onClickHandler}
            className={cx("app__toggle", {
                "app__toggle--active": openMenu
            })}>
            <div ref={componentRef as any} onClick={onClickHandler} className="notification">
                <img src={logo.notification} className="notification__bell" />
                <Collapse
                    isOpen={openMenu}
                    className={
                        "app__collapse app__collapse--gradient " +
                        (openMenu ? "app__collapse--active" : "")
                    }
                >
                    <div className="app__content">
 {/*                        {openMenu && */}
                            <div className='menu'>
                                <div className="menu__header" >
                                    <span className='menu__header-name'>Notifications</span>
                                </div>
                                <hr className='menu__line'></hr>

                                <div className="menu__today">No notifications</div>

                                {/*<div className="menu__today">Today</div>*/}
                                {/*<div className="menu__event">*/}
                                {/*    <img className="menu__event-img" src={logo.notLogo} />*/}
                                {/*    <span className="menu__event-info"><b>Stanislav Baltrunas</b> appointment at 17:00 for a riason pain in head</span>*/}
                                {/*    <div className="menu__event-time">2 min ago</div>*/}
                                {/*</div>*/}
                                {/*<hr className="menu__event-line"></hr>*/}
                                {/*<div style={{*/}
                                {/*    backgroundColor: 'white'*/}
                                {/*}} className="menu__event">*/}
                                {/*    <img className="menu__event-img" src={logo.notLogo} />*/}
                                {/*    <span className="menu__event-info"><b>Stanislav Baltrunas</b> appointment at 17:00 for a riason pain in head</span>*/}
                                {/*    <div className="menu__event-time">2 min ago</div>*/}
                                {/*</div>*/}
                                {/*<hr className="menu__event-line"></hr>*/}
                                {/*<div style={{*/}
                                {/*    backgroundColor: 'white'*/}
                                {/*}} className="menu__event">*/}
                                {/*    <img className="menu__event-img" src={logo.notLogo} />*/}
                                {/*    <span className="menu__event-info"><b>Stanislav Baltrunas</b> appointment at 17:00 for a riason pain in head</span>*/}
                                {/*    <div className="menu__event-time">2 min ago</div>*/}
                                {/*</div>*/}
                                {/*<hr className='menu__event-line'></hr>*/}
                                {/*<div className="menu__week">This week</div>*/}
                                {/*<hr className='menu__event-line'></hr>*/}
                                {/*<div style={{*/}
                                {/*    backgroundColor: 'white'*/}
                                {/*}} className="menu__event">*/}
                                {/*    <img className="menu__event-img" src={logo.notLogo} />*/}
                                {/*    <span className="menu__event-info"><b>Stanislav Baltrunas</b> appointment at 17:00 for a riason pain in head</span>*/}
                                {/*    <div className="menu__event-time">2 min ago</div>*/}
                                {/*</div>*/}
                                {/*<hr className='menu__event-line'></hr>*/}
                                {/*<div style={{*/}
                                {/*    backgroundColor: 'white'*/}
                                {/*}} className="menu__event">*/}
                                {/*    <img className="menu__event-img" src={logo.notLogo} />*/}
                                {/*    <span className="menu__event-info"><b>Stanislav Baltrunas</b> appointment at 17:00 for a riason pain in head</span>*/}
                                {/*    <div className="menu__event-time">2 min ago</div>*/}
                                {/*</div>*/}
                                {/*<hr className='menu__event-line'></hr>*/}

                                {/*<div style={{*/}
                                {/*    backgroundColor: 'white'*/}
                                {/*}} className="menu__event">*/}
                                {/*    <img className="menu__event-img" src={logo.notLogo} />*/}
                                {/*    <span className="menu__event-info"><b>Stanislav Baltrunas</b> appointment at 17:00 for a riason pain in head</span>*/}
                                {/*    <div className="menu__event-time">2 min ago</div>*/}
                                {/*</div>*/}
                                {/*<hr className='menu__event-line'></hr>*/}

                            </div>
                  {/*       } */}
                    </div>
                </Collapse>
            </div>
        </div>
    );
};
