import { Dispatch } from 'redux';
import {
  addEventAC,
  cancelOrderEventsAC,
  changeStatusEventAC,
  editEventAC,
  removeEventAC,
  setAppStatusAC,
  setCurrentEventDetailsAC,
  setCurrentEventScheduleAC,
  setCurrentPageEventsAC,
  setEventsListAC,
  setEventsParamsAC,
  setPreloaderEventsFilterAC,
} from '../actions';
import { eventsAPI } from '../../api/events-api';
import { handleServerAppError, handleServerNetworkError } from '../../utils/error-utils';
import { AppRootStateType } from '../store';
import { loadToken } from '../../utils/localStorage';

export const addEventTC = (data: any, branch: number) => async (dispatch: Dispatch<any>) => {
  dispatch(setAppStatusAC('idle'));
  try {
    const res = await eventsAPI.addEvent(data, branch);
    dispatch(addEventAC(res.data));
    dispatch(setAppStatusAC('succeeded'));
  } catch (error: any) {
    if (error.message === 'Network Error') {
      handleServerNetworkError(error, dispatch);
    } else {
      handleServerAppError(error, dispatch);
    }
  }
};
export const editEventTC = (id: number, data: any) => async (dispatch: Dispatch<any>) => {
  try {
    const res = await eventsAPI.editEvent(data, id);
    dispatch(editEventAC(res.data));
    dispatch(setAppStatusAC('succeeded'));
  } catch (error: any) {
    if (error.message === 'Network Error') {
      handleServerNetworkError(error, dispatch);
    } else {
      handleServerAppError(error, dispatch);
    }
  }
};

export const changeStatusEventTC =
  (eventId: number, status: string) => async (dispatch: Dispatch<any>) => {
    try {
      const res = await eventsAPI.changeStatus(eventId, status);
      dispatch(changeStatusEventAC(eventId, res.data));
      dispatch(setAppStatusAC('succeeded'));
    } catch (error: any) {
      if (error.message === 'Network Error') {
        handleServerNetworkError(error, dispatch);
      } else {
        handleServerAppError(error, dispatch);
      }
    }
  };

export const removeEventTC = (eventId: number) => async (dispatch: Dispatch<any>) => {
  try {
    await eventsAPI.removeEvents(eventId);
    dispatch(removeEventAC(eventId));
  } catch (error: any) {
    if (error.message === 'Network Error') {
      handleServerNetworkError(error, dispatch);
    } else {
      handleServerAppError(error, dispatch);
    }
  }
  dispatch(setAppStatusAC('idle'));
};
export const getNextEventsList =
  () => async (dispatch: Dispatch<any>, getState: () => AppRootStateType) => {
    const next = getState().events.events.links.next;
    dispatch(setAppStatusAC('loading'));
    await dispatch(setPreloaderEventsFilterAC(true));
    try {
      const tokenLS = await loadToken();
      const res = await eventsAPI.getNextList(tokenLS, next);
      dispatch(setEventsParamsAC(res.data));
      dispatch(setEventsListAC(res.data.results));
      dispatch(setAppStatusAC('succeeded'));
      dispatch(setCurrentPageEventsAC(next));
      await dispatch(setPreloaderEventsFilterAC(false));
    } catch (error: any) {
      if (error.message === 'Network Error') {
        handleServerNetworkError(error, dispatch);
      } else {
        handleServerAppError(error, dispatch);
      }
      await dispatch(setPreloaderEventsFilterAC(false));
    }
    dispatch(setAppStatusAC('idle'));
  };

export const getPrevEventsList =
  () => async (dispatch: Dispatch<any>, getState: () => AppRootStateType) => {
    const prev = getState().events.events.links.previous;
    dispatch(setAppStatusAC('loading'));
    await dispatch(setPreloaderEventsFilterAC(true));
    try {
      const tokenLS = await loadToken();
      const res = await eventsAPI.getPrevList(tokenLS, prev);
      dispatch(setEventsParamsAC(res.data));
      dispatch(setEventsListAC(res.data.results));
      dispatch(setAppStatusAC('succeeded'));
      dispatch(setCurrentPageEventsAC(prev));
      await dispatch(setPreloaderEventsFilterAC(false));
    } catch (error: any) {
      if (error.message === 'Network Error') {
        handleServerNetworkError(error, dispatch);
      } else {
        handleServerAppError(error, dispatch);
      }
      await dispatch(setPreloaderEventsFilterAC(false));
    }
    dispatch(setAppStatusAC('idle'));
  };

export const getCurrentEventsList =
  () => async (dispatch: Dispatch<any>, getState: () => AppRootStateType) => {
    const curr = getState().events.currentPage;
    dispatch(setAppStatusAC('loading'));
    try {
      const tokenLS = await loadToken();
      const res = await eventsAPI.getNextList(tokenLS, curr);
      dispatch(setEventsParamsAC(res.data));
      dispatch(setEventsListAC(res.data.results));
      dispatch(setAppStatusAC('succeeded'));
      dispatch(setCurrentPageEventsAC(curr));
    } catch (error: any) {
      if (error.message === 'Network Error') {
        handleServerNetworkError(error, dispatch);
      } else {
        handleServerAppError(error, dispatch);
      }
    }
    dispatch(setAppStatusAC('idle'));
  };

export const fetchEventDetailsTC = (eventId: number) => async (dispatch: Dispatch<any>) => {
  dispatch(setAppStatusAC('loading'));
  dispatch(setPreloaderEventsFilterAC(true));
  try {
    const res = await eventsAPI.getEventDetails(eventId);
    dispatch(setCurrentEventDetailsAC(res.data));
    dispatch(setCurrentEventScheduleAC(res.data)); //
    dispatch(setPreloaderEventsFilterAC(false));
    dispatch(setAppStatusAC('succeeded'));
  } catch (error: any) {
    if (error.message === 'Network Error') {
      handleServerNetworkError(error, dispatch);
    } else {
      handleServerAppError(error, dispatch);
    }
    dispatch(setPreloaderEventsFilterAC(false));
  }
};

export const cancelOrderEventTC = (uuid_field: string) => async (dispatch: Dispatch<any>) => {
  try {
    const res = await eventsAPI.cancelOrderEvent(uuid_field);
    dispatch(cancelOrderEventsAC(res.data));
    dispatch(setAppStatusAC('succeeded'));
  } catch (error: any) {
    if (error.message === 'Network Error') {
      handleServerNetworkError(error, dispatch);
    } else {
      handleServerAppError(error, dispatch);
    }
  }
  dispatch(setAppStatusAC('idle'));
};