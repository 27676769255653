import { RegisterType } from '../../api/register-api';
import { RegistrationInitialStateType } from './types';
import { RegistrationActionsType } from '../actions/types';
import { ADD_USER_REGISTRATION, IS_SEND_DATA_REGISTRATION } from '../actions/constants';

const initialState = {
  sendDate: false,
  registerDate: [] as Array<RegisterType>,
};

export const registerReducer = (
  state: RegistrationInitialStateType = initialState,
  action: RegistrationActionsType,
): any => {
  switch (action.type) {
    case ADD_USER_REGISTRATION: {
      return {
        ...state,
        registerDate: [...state.registerDate, action.model],
      };
    }
    case IS_SEND_DATA_REGISTRATION:
      return { ...state, sendData: action.value };

    default:
      return state;
  }
};