import { instance } from './http';
import { UpdateProfileDataModelType } from "../redux/actions/types";

export const profileAPI = {
  getProfile() {
    return instance.get<ProfileDataType>(`api/profile/`);
  },

  updateProfile(data: UpdateProfileDataModelType | any) {
    const formData = new FormData();
    if (data.photo.length && data.photo !== 'not') {
      formData.append('photo', data.photo[0]);
    }
    if (data.photo === 'del') {
      formData.append('photo', '');
    }
    formData.append('first_name', data.first_name);
    formData.append('last_name', data.last_name);
    formData.append('email', data.email);
    formData.append('phone', data.phone);
    return instance.put<any>(`/api/profile/`, formData, {
      headers: {
        'Content-type': 'multipart/form-data',
      },
    });
  },
};

export type ProfileDataType = {
  id: number;
  first_name: string;
  last_name: string;
  email: string;
  photo: File | null;
  phone: string;
  role: string;
  owner: string | null;
  success_request: boolean | null;
};

