import { ReasonType } from '../../api/reasons-api';
import {
  ADD_REASON,
  EDIT_REASON,
  REMOVE_REASON,
  RESET_REDUCER_GROUP,
  SET_LIST_REASONS,
} from '../actions/constants';
import { ReasonsActionsType } from '../actions/types';

const initialState: Array<ReasonType> = [];

export const reasonsReducer = (
  state: Array<ReasonType> = initialState,
  action: ReasonsActionsType,
): Array<ReasonType> => {
  switch (action.type) {
    case SET_LIST_REASONS: {
      return action.reasons.map((tl) => ({ ...tl }));
    }
    case ADD_REASON: {
      return [...state, action.model];
    }
    case EDIT_REASON: {
      return state.map((e) => (e.id === action.model.id ? { ...e, ...action.model } : e));
    }
    case REMOVE_REASON: {
      return state.filter((e) => e.id !== action.id);
    }

    case RESET_REDUCER_GROUP:
      return { ...initialState };

    default:
      return state;
  }
};