import React, { FC, memo, useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Navigate, NavLink } from 'react-router-dom';
import { PATH } from '../../routes/Routes';
import './Reasons.scss';
import { ConfirmationPopup } from '../../components/Popups/ConfirmationPopup/ConfirmationPopup';
import Preloader from '../../components/Preloaders/Preloader';
import {
  getIsLoggedInSelector,
  getOpenSidebarAppSelector,
  getReasonsSelector,
  getStatusSelector,
  getUserProfileSelector,
} from '../../redux/selectors';
import { logo } from '../../assets/img/image';
import { useTranslation } from 'react-i18next';
import { ActionMenuReasons } from './ActionMenuReasons/ActionMenuReasons';
import PreloaderClient from '../../components/Preloaders/ClientsPreload/PreloadClients';
import TranslationKeys from '../../assets/locales/translationKeys/translationKeys';
import { SortByTitle } from '../../components/ComponentsForLists/SortByTitle/SortByTitle';
import { ViewDoctors } from '../../components/ComponentsForLists/ViewDoctors/ViewDoctors';
import {
  fetchBranchesList,
  fetchReasonsSortList,
  initializeAppTC,
  removeReasonTC,
} from '../../redux/middlewares';
import { editStateMenuAC } from '../../redux/actions';

export const Reasons: FC = memo(() => {
  const dispatch = useDispatch();
  const componentRef = useRef();
  //locale
  const { t } = useTranslation();

  const reasons = useSelector(getReasonsSelector);
  const status = useSelector(getStatusSelector);
  const isLoggedIn = useSelector(getIsLoggedInSelector);
  const user = useSelector(getUserProfileSelector);
  const isOpenSidebar = useSelector(getOpenSidebarAppSelector);

  //dialog alerts
  const [openAlertDialogForDelete, setOpenAlertDialogForDelete] = useState(false);
  const [modalDataId, setModalDataId] = useState<number>(0);
  const [options, setOptions] = useState(false);
  const [load, setLoad] = useState(false);
  const [selectedIndex, setSelectedIndex] = useState(0);

  //sort
  const [isActiveTitle, setActiveTitle] = useState(false);
  const [isActiveOffsetTime, setActiveOffsetTime] = useState(false);
  const [isActiveArrowTitle, setActiveArrowTitle] = useState(false);
  const [isActiveArrowOffsetTime, setActiveArrowOffsetTime] = useState(false);

  const escFunction = useCallback((event) => {
    if (event.key === 'Escape') {
      setOpenAlertDialogForDelete(false);
    }
  }, []);

  const sortListByTitle = (ordering: string): void => {
    dispatch(fetchReasonsSortList(ordering));
    setActiveArrowTitle(true);
    setActiveArrowOffsetTime(false);
    setActiveTitle(!isActiveTitle);
  };

  const sortListByOffsetTime = (ordering: string): void => {
    dispatch(fetchReasonsSortList(ordering));
    setActiveArrowOffsetTime(true);
    setActiveArrowTitle(false);
    setActiveOffsetTime(!isActiveOffsetTime);
  };

  useMemo(() => {
    const handleClick = (e: any): void => {
      if (componentRef && componentRef.current) {
        const ref: any = componentRef.current;
        if (!ref.contains(e.target)) {
          setOptions(false);
        }
      }
    };
    document.addEventListener('click', handleClick);
    return () => document.removeEventListener('click', handleClick);
  }, [dispatch]);

  useEffect(() => {
    document.addEventListener('keydown', escFunction, false);

    return () => {
      document.removeEventListener('keydown', escFunction, false);
    };
  }, []);

  useEffect(() => {
    if (status === 'failed') {
      dispatch(initializeAppTC());
    }
  }, [status]);

  useEffect(() => {
    if (isLoggedIn) {
      // dispatch(fetchReasonsList());
      sortListByTitle('title');
      dispatch(fetchBranchesList());
    }
  }, [dispatch]);

  useEffect(() => {
    const remove = new Image();
    remove.src = logo.trash;
    remove.onload = () => {
      setLoad(true);
    };
    const img = new Image();
    img.src = logo.edit;
    img.onload = () => {
      setLoad(true);
    };
  });

  useEffect(() => {
    document.title = t(TranslationKeys.reasons_nav);
  }, []);

  const removeReason = (id: number): void => {
    dispatch(removeReasonTC(id));
    setModalDataId(0);
  };

  const openAlertHandler = (employeeId: number): void => {
    setModalDataId(employeeId);
    setOpenAlertDialogForDelete(true);
  };
  const closeAlertHandler = (): void => {
    setOpenAlertDialogForDelete(false);
  };

  const onClickOptions = (reasonId: number): void => {
    dispatch(editStateMenuAC(false));
    if (options) {
      if (reasonId === selectedIndex) {
        setOptions(false);
      } else {
        setOptions(true);
      }
    } else {
      setOptions(true);
    }
  };

  const onLoadHandler = (): void => {
    setLoad(true);
  };

  if (!isLoggedIn) {
    return <Navigate to={PATH.LOGIN} />;
  }

  return (
    <>
      <div ref={componentRef as any}></div>
      <div className={`${isOpenSidebar ? 'reason' : 'reason full-width'}`}>
        <div className="reason__header">
          <h1 className="reason__header__title">{t(TranslationKeys.reasonst_list)}</h1>
          <div className="reason__header__add">
            {user.role !== 'doctor' ? (
              <NavLink
                className="button button_size-small button_position-left button_color-transparent"
                to={PATH.ADD_REASON}
              >
                + {t(TranslationKeys.reason_btn_add)}
              </NavLink>
            ) : (
              ''
            )}
          </div>
        </div>

        <div>
          <table className="reason__table">
            <thead className="thead">
              <tr className="tr">
                <th className="th">
                  <SortByTitle
                    isActiveSortTitle={isActiveTitle}
                    isActiveArrow={isActiveArrowTitle}
                    sortListCallback={sortListByTitle}
                    sortParam="title"
                    title={TranslationKeys.title}
                  />
                </th>
                {user.role !== 'doctor' ? (
                  <th className="th">
                    <span>{t(TranslationKeys.doctor)}</span>
                  </th>
                ) : (
                  ''
                )}
                <th className="th">
                  <SortByTitle
                    isActiveSortTitle={isActiveOffsetTime}
                    isActiveArrow={isActiveArrowOffsetTime}
                    sortListCallback={sortListByOffsetTime}
                    sortParam="offset_time"
                    title={TranslationKeys.duration}
                  />
                </th>
                {user.role !== 'doctor' ? (
                  <th className="th-options">
                    <span>{t(TranslationKeys.options)}</span>
                  </th>
                ) : (
                  ''
                )}
              </tr>
            </thead>
            <tbody className="tbody">
              {reasons.length === 0 && status === 'loading' ? (
                <tr>
                  <td>
                    <Preloader />
                  </td>
                </tr>
              ) : (
                status === 'loading' && (
                  <tr>
                    <td>
                      <PreloaderClient />
                    </td>
                  </tr>
                )
              )}

              {reasons.length > 0 &&
                reasons.map((reason) => (
                  <tr key={reason.id} className="tr">
                    <td
                      style={{
                        backgroundColor: `${reason.color}38`,
                        borderColor: `${reason.color}`,
                      }}
                      className="td-color"
                    >
                      <span
                        style={{
                          opacity: 1,
                        }}
                        className="td-name"
                      >
                        {reason.title}
                      </span>
                    </td>

                    {user.role !== 'doctor' ? (
                      <td className="td-doc">
                        <ViewDoctors client={reason} />
                      </td>
                    ) : (
                      ''
                    )}
                    <td className="td">{reason.offset_time_display}</td>
                    {user.role !== 'doctor' ? (
                      <td
                        className="td-btns"
                        onClick={(e) => {
                          e.stopPropagation();
                          setSelectedIndex(reason.id);
                          onClickOptions(reason.id);
                        }}
                      >
                        <ActionMenuReasons
                          reason={reason}
                          options={options}
                          onClickOptions={onClickOptions}
                          selectedIndex={selectedIndex}
                          setSelectedIndex={setSelectedIndex}
                          onLoadHandler={onLoadHandler}
                          load={load}
                          setOptions={setOptions}
                          openAlertHandler={openAlertHandler}
                        />
                      </td>
                    ) : (
                      ''
                    )}
                  </tr>
                ))}
              {openAlertDialogForDelete && (
                <>
                  <ConfirmationPopup
                    actionCallback={removeReason}
                    id={modalDataId}
                    titleQuestion={t(TranslationKeys.reason_delete)}
                    actionTitle={t(TranslationKeys.delete)}
                    onClickBtn={closeAlertHandler}
                    cancelCallback={closeAlertHandler}
                  />
                </>
              )}
            </tbody>
          </table>
        </div>
      </div>
    </>
  );
});
