import React, { FC } from 'react';
import './MenuBtn.scss'

type MenuBtnPropsType = {
  isOpenMenu?: boolean
  showSidebar?: () => void
}

export const MenuBtn: FC<MenuBtnPropsType> = ({isOpenMenu, showSidebar}) => {

  return (
     <div className={`${isOpenMenu ? 'btm-menu btm-menu-open' : 'btm-menu'}`} onClick={showSidebar}>
      <span></span>
      <span></span>
      <span></span>
      <span></span>
    </div>
  );
}