import {
  BranchesListFieldsType,
  ClientsListFieldsType,
  ContactsListFieldsType,
  EmployeesListFieldsType,
  EventsListFieldsType,
  HolidayListFieldsType,
  OrderListFieldsType,
  ReasonsListFieldsType,
  UserListFieldsType,
  WorkScheduleListFieldsType,
} from '../../api/fields-api';
import {
  SET_LIST_FIELDS_BRANCHES,
  SET_LIST_FIELDS_CLIENTS,
  SET_LIST_FIELDS_CONTACTS,
  SET_LIST_FIELDS_EMPLOYEES,
  SET_LIST_FIELDS_EVENTS,
  SET_LIST_FIELDS_HOLIDAY,
  SET_LIST_FIELDS_ORDER,
  SET_LIST_FIELDS_REASONS,
  SET_LIST_FIELDS_USER,
  SET_LIST_FIELDS_WORK_SCHEDULE,
} from './constants';

export const setListFieldsEmployeesAC = (fields: EmployeesListFieldsType) =>
  ({ type: SET_LIST_FIELDS_EMPLOYEES, fields } as const);
export const setListFieldsClientsAC = (fields: ClientsListFieldsType) =>
  ({ type: SET_LIST_FIELDS_CLIENTS, fields } as const);
export const setListFieldsEventsAC = (fields: EventsListFieldsType) =>
  ({ type: SET_LIST_FIELDS_EVENTS, fields } as const);
export const setListFieldsReasonsAC = (fields: ReasonsListFieldsType) =>
  ({ type: SET_LIST_FIELDS_REASONS, fields } as const);
export const setListFieldsWorkScheduleAC = (fields: WorkScheduleListFieldsType) =>
  ({ type: SET_LIST_FIELDS_WORK_SCHEDULE, fields } as const);
export const setListFieldsBranchesAC = (fields: BranchesListFieldsType) =>
  ({ type: SET_LIST_FIELDS_BRANCHES, fields } as const);
export const setListFieldsHolidayAC = (fields: HolidayListFieldsType) =>
  ({ type: SET_LIST_FIELDS_HOLIDAY, fields } as const);
export const setListFieldsContactsAC = (fields: ContactsListFieldsType) =>
  ({ type: SET_LIST_FIELDS_CONTACTS, fields } as const);
export const setListFieldsUserAC = (fields: UserListFieldsType) =>
  ({ type: SET_LIST_FIELDS_USER, fields } as const);
export const setListFieldsOrderAC = (fields: OrderListFieldsType) =>
  ({ type: SET_LIST_FIELDS_ORDER, fields } as const);