import { Dispatch } from 'redux';
import { AppRootStateType } from '../store';
import { orderAPI } from '../../api/order-api';
import { handleServerAppError, handleServerNetworkError } from '../../utils/error-utils';
import {
  addCurrentOrderFilterDoctorAC,
  addOrderSearchParamAC,
  removeCurrentOrderFilterDoctorAC,
  setAppStatusAC,
  setCurrentOrdersQueryStringAC,
  setListOrderAC,
  setOrdersFilterParamsAC,
  setPreloaderOrdersFilterAC,
} from '../actions';
import { getQueryStringFromObject } from '../../utils/getQueryStringFromObject';
import { OrdersQueryStringType } from './types';

export const fetchFilteredOrdersParamsListTC =
  () => async (dispatch: Dispatch<any>, getState: () => AppRootStateType) => {
    const branchId = getState().branches.currentBranch;
    try {
      const res = await orderAPI.getFilteredParamsList(branchId);
      dispatch(setOrdersFilterParamsAC(res.data));
    } catch (error: any) {
      if (error.message === 'Network Error') {
        handleServerNetworkError(error, dispatch);
      } else {
        handleServerAppError(error, dispatch);
      }
    }
  };

export const fetchFilteredOrdersListOfGeneral =
  (branchId?: string | null, query?: string) =>
  async (dispatch: Dispatch<any>, getState: () => AppRootStateType) => {
    // filter params
    const branch_creator__in = branchId ? branchId : getState().branches.currentBranch;
    const doctor__in = getState().ordersFilter.currentFilterParams.doctor__in;
    const search = getState().ordersFilter.currentFilterParams.search;

    // query string
    const currentQueryParams = getState().ordersFilter.currentQueryStringOrdersList;
    const newQueryParams = getQueryStringFromObject({
      branch_creator__in,
      doctor__in,
      search,
    } as unknown as OrdersQueryStringType);

    const queryParams = query ? currentQueryParams : newQueryParams;

    dispatch(setAppStatusAC('loading'));
    await dispatch(setPreloaderOrdersFilterAC(true));
    try {
      const res = await orderAPI.getFilteredListOfGeneral(queryParams);
      dispatch(setListOrderAC(res.data));
      await dispatch(setPreloaderOrdersFilterAC(false));
      await dispatch(setCurrentOrdersQueryStringAC(queryParams));
      dispatch(setAppStatusAC('succeeded'));
    } catch (error: any) {
      if (error.message === 'Network Error') {
        handleServerNetworkError(error, dispatch);
      } else {
        handleServerAppError(error, dispatch);
      }
    }
    dispatch(setAppStatusAC('idle'));
  };

export const setFilteredOrdersOfDoctorTC =
  (currentDoctor: string) => async (dispatch: Dispatch<any>, getState: () => AppRootStateType) => {
    const currentDoctorChecked = getState().ordersFilter.currentFilterParams.doctor__in.find(
      (status) => status === currentDoctor,
    );

    try {
      if (currentDoctorChecked) {
        dispatch(removeCurrentOrderFilterDoctorAC(currentDoctor));
      } else {
        dispatch(addCurrentOrderFilterDoctorAC(currentDoctor));
      }
      dispatch(fetchFilteredOrdersListOfGeneral());
    } catch (error: any) {
      if (error.message === 'Network Error') {
        handleServerNetworkError(error, dispatch);
      } else {
        handleServerAppError(error, dispatch);
      }
    }
  };
export const setFilteredOrdersSearchTC = (search: string) => async (dispatch: Dispatch<any>) => {
  try {
    dispatch(addOrderSearchParamAC(search));
    dispatch(fetchFilteredOrdersListOfGeneral());
  } catch (error: any) {
    if (error.message === 'Network Error') {
      handleServerNetworkError(error, dispatch);
    } else {
      handleServerAppError(error, dispatch);
    }
  }
};