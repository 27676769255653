import { instance } from './http';

export const scheduleWorkApi = {
  getList() {
    return instance.get<Array<BranchType>>('/api/work-schedule/');
  },

  editScheduleWork(data: any, branchId: number) {
    return instance.put<any>(`/api/work-schedule/${branchId}/`, data);
  },
};

//types
export type WorkScheduleType = {
  id: number;
  day_of_week: string;
  start_time_work: string;
  end_time_work: string;
  branch: number;
  start_lunch_break: string;
  end_lunch_break: string;
  lunch_break: boolean;
  is_weekend: boolean;
  branch_display: string;
  day_of_week_display: string;
};

export type BranchType = {
  id: number;
  name: string;
  workschedule: Array<WorkScheduleType>;
};
