import { instance } from './http';

export const employeesAPI = {
  getList() {
    return instance.get<Array<EmployeeType>>('/api/employees/');
  },

  addEmployee(data: any) {
    return instance.post<EmployeeType>('/api/employees/create/', data);
  },

  editEmployee(data: any, employeeId: number) {
    return instance.put<EmployeeType>(`/api/employees/${employeeId}/`, data);
  },

  removeEmployee(employeeId: number) {
    return instance.delete<any>(`/api/employees/${employeeId}/`);
  },
};

//types
export type EmployeeType = {
  id: number;
  username: string;
  first_name: string;
  last_name: string;
  branch_set: [];
  role: any;
  photo?: string;
  external: boolean;
};

