import './Inputs.scss';
import React, { ChangeEvent, DetailedHTMLProps, FC, InputHTMLAttributes, useState } from 'react';
import { Path } from 'react-hook-form';
import { FormDataType } from '../../pages/Login/Login';

type DefaultInputPropsType = DetailedHTMLProps<
  InputHTMLAttributes<HTMLInputElement>,
  HTMLInputElement
>;

type InputsNewType = DefaultInputPropsType & {
  onChangeText?: (value: string) => void;
  onEnter?: () => void;
  order?: string;
  error?: string | null;
  spanClassName?: string;
  maxLength?: any;
  label?: any;
  register?: any;
  resetForm?: () => void;
  state?: string;
  name?: Path<FormDataType> | string;
  help_text?: string | null;
  img?: string;
  input_type?: string;
  defaultValue?: any;
  placeholder?: string;
};

const InputsNew: FC<InputsNewType> = ({
  step,
  onChange,
  onChangeText,
  error,
  maxLength,
  name,
  state,
  img,
  input_type,
  defaultValue,
  help_text,
  label,
  register,
                                        placeholder,
  ...restProps
}) => {
  const [focused, setFocus] = useState(false);
  const [value, setValue] = useState('');

  const onFocusHandler = (): void => {
    setFocus(true);
  };

  const onChangeHandler = (e: ChangeEvent<HTMLInputElement>): void => {
    onChange && onChange(e);
    onChangeText && onChangeText(e.currentTarget.value);
    setValue(e.target.value);
  };

  const handleKeyDown = (event: any): void => {
    if (event.key === 'Enter') {
      event.target.blur();
    }
  };

  const customInputClass = ` 
      field_${img} 
      ${focused ? 'field_focus' : 'field_unfocus'} 
      ${focused ? 'order_focus' : 'order_unfocus'} 
      ${state === 'order' ? `order field_${state}` : ` field field_${state}  `}
      ${error ? 'field_error ' : ''}
      ${value.length ? 'field_focus' : 'field_unfocus'}
      ${value.length ? 'order_focus' : 'order_unfocus'}
      ${defaultValue ? 'field_focus' : 'field_unfocus'}
      ${defaultValue ? 'order_focus' : 'order_unfocus'}
      ${state === 'search' ? `search search_${state}` : ` field field_${state}  `}

 `;
  return (
    <div className={customInputClass}>
      <input
        className="field__input"
        {...register(name, {
          onBlur: () => {
            if (value !== '') {
              setFocus(false);
            } else {
              setFocus(false);
            }
          },
          onChange: onChangeHandler,
        })}
        maxLength={maxLength}
        id={`id_${name}`}
        onFocus={onFocusHandler}
        onKeyPress={handleKeyDown}
        defaultValue={defaultValue}
        type={input_type}
        disabled={state === 'disabled'}
        placeholder={(focused && (name === 'phone')) ? `${placeholder}` : undefined}
        step={step}
        {...restProps}
      />
      {state === 'order' ? (
        <label className={'order__label'} htmlFor={`id_${name}`}>
          {label}
        </label>

      ) : (
        <label className={'field__label'} htmlFor={`id_${name}`}>
          {label}
        </label>

      )}
      <span className="field__help-text">{help_text}</span>
    </div>
  );
};

export default InputsNew;
