import React, { FC, useEffect, useMemo, useState } from 'react';
import { SubmitHandler, useForm } from 'react-hook-form';
import './FormClients.scss';
import '../../../components/Widgets/Button/Button.scss';
import { useDispatch, useSelector } from 'react-redux';
import { Navigate, useLocation, useParams } from 'react-router-dom';
import { PATH } from '../../../routes/Routes';
import Inputs from '../../../components/Widgets/Inputs';
import {
  defaultChoicesMultiSelect,
  defaultChoicesSelect,
  newOption,
} from '../../../components/Widgets/Select/ReactSelectStyles';
import CheckboxInput from '../../../components/Widgets/CheckboxInput';
import { ConfirmationPopup } from '../../../components/Popups/ConfirmationPopup/ConfirmationPopup';
import { ReactSelect } from '../../../components/Widgets/Select/ReactSelect';
import { useErrorHandler } from '../../../hooks/useErrorHandler';
import {
  getClientsSelector,
  getCurrClientsQueryStringAppSelector,
  getCurrentBranchSelector,
  getCurrentClientIdSelector,
  getCurrentClientSelector,
  getCurrentPathSelector,
  getErrorStateSelector,
  getFieldsClientsSelector,
  getIsLoggedInSelector,
  getOpenSidebarAppSelector,
  getStatusClientPopupSelector,
  getStatusSelector,
} from '../../../redux/selectors';
import { ErrorMessage } from '../../../components/ErrorMessage/ErrorMessage';
import { useTranslation } from 'react-i18next';
import TranslationKeys from '../../../assets/locales/translationKeys/translationKeys';
import dayjs from 'dayjs';
import { ReactDatePicker } from '../../../components/Widgets/ReactDatePicker';
import Preloader from '../../../components/Preloaders/Preloader';
import { setAppStatusAC, setCurrentClientAC, setCurrentPathAC } from '../../../redux/actions';
import {
  addClientTC,
  editClientTC,
  fetchClient,
  fetchClientsListFieldsTC,
  fetchFilteredClientsListOfGeneral,
  removeClientTC,
} from '../../../redux/middlewares';

type FormClientsPropsType = {
  closeClientPopup?: () => void;
};

type FormDataType = {
  first_name: string;
  last_name: string;
  insurance_type: { value: any; label: any };
  email: string;
  phone: string;
  blocked: any;
  branches: { value: any; label: any } | [];
  doctors: { value: any; label: any };
  gender: { value: any; label: any };
  date_birth: string | Date;
};

export const FormClients: FC<FormClientsPropsType> = ({}) => {
  const dispatch = useDispatch();
  const params = useParams();
  const location = useLocation();

  const clients = useSelector(getClientsSelector);
  const status = useSelector(getStatusSelector);
  const isLoggedIn = useSelector(getIsLoggedInSelector);
  const errorState = useSelector(getErrorStateSelector);
  const fields = useSelector(getFieldsClientsSelector);
  const currentBranch = useSelector(getCurrentBranchSelector);
  const isOpenSidebar = useSelector(getOpenSidebarAppSelector);
  const currentPath = useSelector(getCurrentPathSelector);
  const currentClientIdRedux = useSelector(getCurrentClientIdSelector);
  const currentClientRedux = useSelector(getCurrentClientSelector);
  const isOpenClientPopup = useSelector(getStatusClientPopupSelector);
  const currentQueryString = useSelector(getCurrClientsQueryStringAppSelector);

  const [valueClient, setValueClient] = useState<boolean>(false);

  //locale
  const { t } = useTranslation();

  //for edit client
  const idUrl = params.id;
  const clientId = currentClientIdRedux ? Number(currentClientIdRedux) : Number(idUrl);

  const currentClient =
    clients.length && clients.find((client) => client.id === clientId)
      ? clients.length && clients.find((client) => client.id === clientId)
      : currentClientRedux;

  const [selectedDate] = useState<any>(new Date());

  //validation
  const formDataArray = [
    'first_name',
    'last_name',
    'insurance_type',
    'email',
    'phone',
    'blocked',
    'branches',
    'doctors',
    'gender',
    'date_birth',
  ];

  const firstNameErrorHandler = useErrorHandler('first_name', errorState, formDataArray);
  const lastNameErrorHandler = useErrorHandler('last_name', errorState, formDataArray);
  const insuranceTypeErrorHandler = useErrorHandler('insurance_type', errorState, formDataArray);
  const emailErrorHandler = useErrorHandler('email', errorState, formDataArray);
  const phoneErrorHandler = useErrorHandler('phone', errorState, formDataArray);
  const blockedErrorHandler = useErrorHandler('blocked', errorState, formDataArray);
  const branchesErrorHandler = useErrorHandler('branches', errorState, formDataArray);
  const doctorsErrorHandler = useErrorHandler('doctors', errorState, formDataArray);
  const genderErrorHandler = useErrorHandler('gender', errorState, formDataArray);
  const dateBirthErrorHandler = useErrorHandler('date_birth', errorState, formDataArray);

  const [openAlertDialogForDelete, setOpenAlertDialogForDelete] = useState(false);

  //for select
  const choices = Object.entries(fields.insurance_type.choices).map((br) => br);
  const valueOptionInsurance =
    fields && Object.entries(fields.insurance_type.choices).map((b: any) => newOption(b[0], b[1]));
  const labelOptionInsurance = currentClient && currentClient.insurance_type.toString();

  const choicesBranches = Object.entries(fields.branches.choices).map((br) => br);
  const valueOptionBranches =
    fields && Object.entries(fields.branches.choices).map((b: any) => newOption(b[0], b[1]));
  const labelOptionBranches =
    currentClient && currentClient.branches.map((el: any) => el.toString());

  const choicesDoctor = Object.entries(fields.doctors.choices).map((br) => br);
  const valueOptionDoctor =
    fields && Object.entries(fields.doctors.choices).map((b: any) => newOption(b[0], b[1]));
  const labelOptionDoctor =
    currentClient && currentClient.doctors.map((el: any) => el.id?.toString());
  const choicesGender = Object.entries(fields.gender.choices).map((br) => br);
  const valueOptionGender =
    fields && Object.entries(fields.gender.choices).map((b: any) => newOption(b[0], b[1]));
  const labelOptionGender = currentClient && currentClient.gender.toString();

  const clientData = currentClient && {
    first_name: currentClient.first_name,
    last_name: currentClient.last_name,
    email: currentClient.email,
    phone: currentClient.phone,
    branches:
      currentClient.branches && defaultChoicesMultiSelect(valueOptionBranches, labelOptionBranches),
    insurance_type:
      currentClient.insurance_type &&
      defaultChoicesSelect(valueOptionInsurance, labelOptionInsurance)[0],
    doctors: currentClient ? defaultChoicesMultiSelect(valueOptionDoctor, labelOptionDoctor) : '',
    block: currentClient.blocked,
    gender: currentClient && defaultChoicesSelect(valueOptionGender, labelOptionGender)[0],
    date_birth: new Date(currentClient.date_birth),
  };

  const removeClient = async (currClientId: number): Promise<void> => {
    await dispatch(removeClientTC(currClientId));
    setValueClient(true);
  };

  const openAlertHandler = (): void => {
    setOpenAlertDialogForDelete(true);
  };
  const closeAlertHandler = (): void => {
    setOpenAlertDialogForDelete(false);
  };

  useEffect(() => {
    if (isLoggedIn) {
      dispatch(fetchClientsListFieldsTC(currentBranch));
      if (currentQueryString) {
        dispatch(
          fetchFilteredClientsListOfGeneral(
            currentBranch.toString(),
            undefined,
            currentQueryString,
          ),
        );
      } else {
        dispatch(fetchFilteredClientsListOfGeneral(currentBranch.toString()));
      }
    }
  }, [dispatch]);

  useEffect(() => {
    if (!currentClient || currentClient.id !== clientId) {
      dispatch(fetchClient(clientId));
    }
    return () => {
      dispatch(setCurrentClientAC(null));
    };
  }, [dispatch]);

  useEffect(() => {
    if (status === 'failed') {
      firstNameErrorHandler.setErrorCallback();
      lastNameErrorHandler.setErrorCallback();
      insuranceTypeErrorHandler.setErrorCallback();
      emailErrorHandler.setErrorCallback();
      phoneErrorHandler.setErrorCallback();
      blockedErrorHandler.setErrorCallback();
      branchesErrorHandler.setErrorCallback();
      doctorsErrorHandler.setErrorCallback();
      genderErrorHandler.setErrorCallback();
      dateBirthErrorHandler.setErrorCallback();
    }
  }, [status]);

  useEffect(() => {
    return () => {
      dispatch(setCurrentPathAC(null));
      dispatch(setAppStatusAC('idle'));
    };
  }, [currentPath]);

  const { register, handleSubmit, reset, control } = useForm<FormDataType>({
    mode: 'onBlur',
    defaultValues: useMemo(() => {
      if (clientData) {
        return clientData;
      }
    }, [clientData]),
  });

  useEffect(() => {
    if (clientData) {
      reset(clientData);
    }
  }, [currentClient, fields]);
  useEffect(() => {
    document.title = currentClient ? t('client_form_edit') : t('client_form_add');
  }, []);

  const onSubmit: SubmitHandler<FormDataType> = (data) => {
    const branchData =
      isOpenClientPopup === 'add' || isOpenClientPopup === 'edit'
        ? [currentBranch]
        : data.branches && Object.values(data.branches).map((br: any) => br.value);

    const newData = {
      branches: branchData,
      insurance_type: data.insurance_type && data.insurance_type.value,
      first_name: data.first_name,
      last_name: data.last_name,
      email: data.email,
      phone: data.phone,
      blocked: data.blocked,
      doctors: data.doctors && Object.values(data.doctors).map((br: any) => br.value),
      gender: data.gender && data.gender.value,
      date_birth: data.date_birth && dayjs(data.date_birth).format('YYYY-MM-DD'),
    };

    if (currentClient) {
      dispatch(editClientTC(clientId, newData));
    } else {
      dispatch(addClientTC(newData));
    }
    setValueClient(true);
  };

  if (valueClient && status === 'succeeded') {
    if (
      location.pathname === PATH.ADD_CLIENT ||
      location.pathname === `${PATH.CLIENTS}${clientId}/`
    ) {
      return <Navigate to={PATH.CLIENTS} />;
    }
  }

  if (!isLoggedIn) {
    return <Navigate to={PATH.LOGIN} />;
  }

  return (
    <>
      <div className={`${isOpenSidebar ? 'add-client' : 'add-client full-width'}`}>
        <h1 className="add-client__title">
          {currentClient ? t(TranslationKeys.client_form_edit) : t(TranslationKeys.client_form_add)}
        </h1>
        <div>
          <form onSubmit={handleSubmit(onSubmit)} className="add-client__form">
            {status === 'loading' && <Preloader />}
            <label className="add-client__inputs-label">{t(TranslationKeys.info)}</label>

            <div className="add-client__inputs-info">
              <ReactSelect
                name="gender"
                error={genderErrorHandler.error}
                help_text={
                  genderErrorHandler.error && genderErrorHandler.errorMessageCurrentField[1]
                }
                label={fields.gender.label}
                placeholder={fields.gender.label}
                onClick={genderErrorHandler.onFieldClick}
                control={control}
                isMulti={false}
                required={fields.gender.required}
                defaultValue={
                  currentClient && defaultChoicesSelect(valueOptionGender, labelOptionGender)[0]
                }
                options={
                  choicesGender &&
                  Object.entries(fields.gender.choices).map((b: any) => newOption(b[0], b[1]))
                }
              />

              <ReactDatePicker
                selectedDate={selectedDate}
                control={control}
                label={fields?.date_birth?.label}
                onClick={dateBirthErrorHandler.onFieldClick}
                name={'date_birth'}
                maxDate={new Date()}
                help_text={
                  dateBirthErrorHandler.error && dateBirthErrorHandler.errorMessageCurrentField[1]
                }
                error={dateBirthErrorHandler.error}
                register={register}
              />
            </div>

            <div className="add-client__inputs-info">
              <Inputs
                error={firstNameErrorHandler.error}
                help_text={
                  fields.first_name.help_text ||
                  (firstNameErrorHandler.error && firstNameErrorHandler.errorMessageCurrentField[1])
                }
                onClick={firstNameErrorHandler.onFieldClick}
                state={'active'}
                register={register}
                input_type={fields.first_name.input_type}
                name={'first_name'}
                label={fields.first_name.label}
                defaultValue={
                  currentClient ? currentClient.first_name : fields.first_name.initial_value
                }
                {...reset}
              />
              <Inputs
                error={lastNameErrorHandler.error}
                onClick={lastNameErrorHandler.onFieldClick}
                help_text={
                  fields.last_name.help_text ||
                  (lastNameErrorHandler.error && lastNameErrorHandler.errorMessageCurrentField[1])
                }
                state={'active'}
                register={register}
                input_type={fields.last_name.input_type}
                name={'last_name'}
                label={fields.last_name.label}
                defaultValue={
                  currentClient ? currentClient.last_name : fields.last_name.initial_value
                }
                {...reset}
              />
              <Inputs
                error={emailErrorHandler.error}
                onClick={emailErrorHandler.onFieldClick}
                help_text={
                  fields.email.help_text ||
                  (emailErrorHandler.error && emailErrorHandler.errorMessageCurrentField[1])
                }
                state={'active'}
                input_type="email"
                register={register}
                name={'email'}
                label={fields.email.label}
                defaultValue={currentClient ? currentClient.email : fields.email.initial_value}
                {...reset}
              />
              <Inputs
                error={phoneErrorHandler.error}
                onClick={phoneErrorHandler.onFieldClick}
                help_text={
                  fields.phone.help_text ||
                  (phoneErrorHandler.error && phoneErrorHandler.errorMessageCurrentField[1])
                }
                state={'active'}
                register={register}
                input_type={fields.phone.input_type}
                name={'phone'}
                placeholder={'+49 (XX) XXX-XX-XX'}
                label={fields.phone.label}
                defaultValue={currentClient ? currentClient.phone : fields.phone.initial_value}
                {...reset}
              />
            </div>
            <div className="add-client__inputs-setting">
              <div>
                <ReactSelect
                  name="insurance_type"
                  error={insuranceTypeErrorHandler.error}
                  help_text={
                    insuranceTypeErrorHandler.error &&
                    insuranceTypeErrorHandler.errorMessageCurrentField[1]
                  }
                  label={fields.insurance_type.label}
                  placeholder={fields.insurance_type.label}
                  onClick={insuranceTypeErrorHandler.onFieldClick}
                  control={control}
                  defaultValue={
                    currentClient &&
                    defaultChoicesSelect(valueOptionInsurance, labelOptionInsurance)[0]
                  }
                  isMulti={false}
                  required={fields.insurance_type.required}
                  options={
                    choices &&
                    Object.entries(fields.insurance_type.choices).map((b: any) =>
                      newOption(b[0], b[1]),
                    )
                  }
                />
              </div>
            </div>
            <label className="add-client__inputs-label">{fields.doctors.label}</label>
            <div className="add-client__inputs-setting">
              <ReactSelect
                name="doctors"
                error={doctorsErrorHandler.error}
                help_text={
                  doctorsErrorHandler.error && doctorsErrorHandler.errorMessageCurrentField[1]
                }
                label={fields.doctors.label}
                placeholder={fields.doctors.label}
                onClick={doctorsErrorHandler.onFieldClick}
                control={control}
                isMulti={true}
                required={fields.doctors.required}
                options={
                  choicesDoctor &&
                  Object.entries(fields.doctors.choices).map((b: any) => newOption(b[0], b[1]))
                }
              />
            </div>
            <label className="add-client__inputs-label branch-client-popup">
              {/*{fields.branches.label}*/}
              {t(TranslationKeys.branch_for_title)}
            </label>
            <div className="add-client__inputs-setting branch-client-popup">
              <ReactSelect
                name="branches"
                error={branchesErrorHandler.error}
                help_text={
                  branchesErrorHandler.error && branchesErrorHandler.errorMessageCurrentField[1]
                }
                label={fields.branches.label}
                placeholder={fields.branches.label}
                onClick={branchesErrorHandler.onFieldClick}
                control={control}
                isMulti={true}
                required={fields.branches.required}
                options={
                  choicesBranches &&
                  Object.entries(fields.branches.choices).map((b: any) => newOption(b[0], b[1]))
                }
              />
            </div>
            <div className="blocked">
              <CheckboxInput
                state="square"
                key={fields.blocked.initial_value}
                name="blocked"
                register={register}
                defaultChecked={
                  currentClient ? currentClient.blocked : fields.blocked.initial_value
                }
                id={`${fields.blocked.initial_value}`}
                label={fields.blocked.label}
              />
            </div>

            <div className="add-client__inputs-setting">
              <div></div>
              <div className="btn-client-wrapp">
                <button
                  disabled={status === 'loading'}
                  className="button button_size-middle button_position-right button_color-black client_submit"
                >
                  {t(TranslationKeys.submit)}
                </button>
                {currentClient ? (
                  <div className="btnd">
                    <button
                      type="button"
                      onClick={() => openAlertHandler()}
                      disabled={status === 'loading'}
                      className="button button_size-middle button_position-right button_color-delete"
                    >
                      {t(TranslationKeys.delete)}
                    </button>
                  </div>
                ) : (
                  <div></div>
                )}
              </div>
            </div>

            <ErrorMessage status={status} errorState={errorState} />
          </form>
        </div>

        {openAlertDialogForDelete && (
          <ConfirmationPopup
            actionCallback={removeClient}
            id={clientId}
            titleQuestion="Delete client?"
            actionTitle="Delete"
            cancelCallback={closeAlertHandler}
          />
        )}
      </div>
    </>
  );
};
