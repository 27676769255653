import React, { ChangeEvent, FC, useEffect, useState } from 'react';
import { Controller } from 'react-hook-form';
import Select from 'react-select';
import { customStyles, customStylesError, customStylesMulti } from './ReactSelectStyles';

type ReactSelectType = {
  error?: string | null;
  help_text?: string | null;
  label?: string;
  placeholder?: string;
  control: any;
  onClick?: (e: any) => void;
  onChange?: (value1: any) => void;
  isMulti: boolean;
  options: any;
  name: string;
  defaultValue?: any;
  required?: boolean;
};

export const ReactSelectBranch: FC<ReactSelectType> = ({
  control,
  onClick,
  onChange,
  error,
  placeholder,
  defaultValue,
  isMulti,
  options,
  help_text,
  name,
  required,
}) => {
  const [focusInput, setFocusInput] = useState<boolean>(false);
  const [selected, setSelected] = useState(defaultValue);

  useEffect(() => {
    if (defaultValue !== selected) {
      setSelected(defaultValue);
    }
  }, [defaultValue]);

  const changeLabelStatusOn = (): void => {
    setFocusInput(true);
  };
  const changeLabelStatusOff = (): void => {
    setFocusInput(false);
  };

  const onChangeHandler = (value1: ChangeEvent<HTMLSelectElement>): void => {
    onChange && onChange(value1);
    setSelected(value1);
  };

  return (
    <div
      style={{ display: 'flex', flexDirection: 'column', marginBottom: '20px' }}
    >
      <div onClick={onClick}>
        <Controller
          name={name}
          control={control}
          render={({ field }) => (
            <Select
              {...field}
              name={field.name}
              styles={error ? customStylesError : isMulti ? customStylesMulti : customStyles}
              placeholder={isMulti ? placeholder : ''}
              isMulti={isMulti}
              isClearable={required !== undefined && !required}
              onFocus={changeLabelStatusOn}
              onChange={onChangeHandler}
              value={selected}
              onBlur={field.value ? changeLabelStatusOn : changeLabelStatusOff}
              options={options}
            />
          )}
        />

        {!isMulti && (
          <span
            style={{
              position: 'relative',
              top: `${focusInput || defaultValue ? '-60px' : '-45px'}`,
              left: '18px',
              width: '1%',
              color: `${error ? '#c30052' : '#737484'}`,
              fontSize: `${focusInput || defaultValue ? '14px' : '16px'}`,
              margin: '8px',
              letterSpacing: `${focusInput || defaultValue ? '0.4px' : '0.75px'}`,
              transition: '0s',
            }}
          >
            {placeholder}
          </span>
        )}
      </div>

      {!isMulti && (
        <div style={{ width: '50%' }}>
          <p
            style={{
              color: '#c30052',
              marginTop: '-20px',
              fontSize: '14px',
              letterSpacing: '0.25px',
            }}
          >
            {error && help_text}
          </p>
        </div>
      )}

      {isMulti && (
        <div style={{ width: '50%' }}>
          <p
            style={{
              color: '#c30052',
              marginTop: '3px',
              fontSize: '14px',
              letterSpacing: '0.25px',
            }}
          >
            {error && help_text}
          </p>
        </div>
      )}
    </div>
  );
};