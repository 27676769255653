// app
export const SET_INITIALIZED = 'appReducer/SET-INITIALIZED';
export const SET_STATUS = 'appReducer/SET-STATUS';
export const SET_ERROR = 'appReducer/SET_ERROR';
export const EDIT_STATE_MENU = 'appReducer/EDIT_STATE_MENU';
export const EDIT_STATE_SIDEBAR = 'appReducer/EDIT_STATE_SIDEBAR';
export const SET_CURRENT_PATH = 'appReducer/SET_CURRENT_PATH';

// auth
export const IS_LOGGED_IN = 'authReducer/SET-IS-LOGGED-IN';
export const RESET_REDUCER_GROUP = 'authReducer/RESET_REDUCER_GROUP';

// branches
export const SET_BRANCHES_LIST = 'branchesReducer/SET_BRANCHES_LIST';
export const ADD_BRANCH = 'branchesReducer/ADD_BRANCH';
export const EDIT_BRANCH = 'branchesReducer/EDIT_BRANCH';
export const REMOVE_BRANCH = 'branchesReducer/REMOVE_BRANCH';
export const GET_SCHEDULE_BRANCH = 'branchesReducer/GET_SCHEDULE_BRANCH';
export const SET_CURRENT_BRANCH = 'branchesReducer/SET_CURRENT_BRANCH';
export const SET_ACTIVE_CURRENT_WEEK = 'branchesReducer/SET_ACTIVE_CURRENT_WEEK';
export const CHANGE_STATE_CALENDAR_PATH = 'branchesReducer/CHANGE_STATE_CALENDAR_PATH';
export const SET_PRELOADER_BRANCHES = 'eventsFilterReducer/SET_PRELOADER_BRANCHES';

// clients filter
export const SET_CLIENT_FILTER_PARAMS = 'clientsFilterReducer/SET_CLIENT_FILTER_PARAMS';
export const ADD_CURRENT_CLIENTS_FILTER_INSURANCE =
  'clientsFilterReducer/ADD_CURRENT_CLIENTS_FILTER_INSURANCE';
export const REMOVE_CURRENT_CLIENTS_FILTER_INSURANCE =
  'clientsFilterReducer/REMOVE_CURRENT_CLIENTS_FILTER_INSURANCE';
export const ADD_CURRENT_CLIENT_FILTER_DOCTOR = 'ordersFilterReducer/ADD_CURRENT_CLIENT_FILTER_DOCTOR';
export const REMOVE_CURRENT_CLIENT_FILTER_DOCTOR =
  'ordersFilterReducer/REMOVE_CURRENT_CLIENT_FILTER_DOCTOR';
export const ADD_CURRENT_CLIENTS_BRANCHES = 'clientsFilterReducer/ADD_CURRENT_CLIENTS_BRANCHES';
export const COLLAPSE_FILTER_INSURANCE = 'clientsFilterReducer/COLLAPSE_FILTER_INSURANCE';
export const COLLAPSE_FILTER_DOCTOR = 'clientsFilterReducer/COLLAPSE_FILTER_DOCTOR';
export const SET_PRELOADER_CLIENTS_FILTER = 'clientsFilterReducer/SET_PRELOADER_CLIENTS_FILTER';
export const ADD_CLIENT_SEARCH = 'ordersFilterReducer/ADD_CLIENT_SEARCH';
export const CLEAN_CLIENTS_FILTER = 'clientsFilterReducer/CLEAN_CLIENTS_FILTER';
export const SET_SORT_BY_FULL_NAME = 'clientsFilterReducer/SET_SORT_BY_ACTIVE_FULL_NAME';
export const SET_SORT_BY_INSURANCE = 'clientsFilterReducer/SET_SORT_BY_INSURANCE';
export const SET_ACTIVE_ARROW_FULL_NAME = 'clientsFilterReducer/SET_ACTIVE_ARROW_FULL_NAME';
export const SET_ACTIVE_ARROW_INSURANCE = 'clientsFilterReducer/SET_ACTIVE_ARROW_INSURANCE';
export const SET_CURRENT_SORT_PARAM = 'clientsFilterReducer/SET_CURRENT_SORT_PARAM';
export const SET_CURRENT_CLIENTS_QUERY_STRING = 'clientsFilterReducer/SET_CURRENT_CLIENTS_QUERY_STRING';

// clients
export const SET_CLIENTS_LIST = 'clientsReducer/SET_CLIENTS_LIST';
export const ADD_CLIENT = 'clientsReducer/ADD_CLIENT';
export const EDIT_CLIENT = 'clientReducer/EDIT_CLIENT';
export const REMOVE_CLIENT = 'clientsReducer/REMOVE_CLIENTS';
export const SET_CLIENTS_PARAMS = 'clientsReducer/SET_CLIENTS_PARAMS';
export const SET_APPEARED = 'clientsReducer/SET_APPEARED';
export const SET_CURRENT_PAGE = 'clientsReducer/SET_CURRENT_PAGE';
export const SET_CURRENT_CLIENT_ID = 'clientsReducer/SET_CURRENT_CLIENT_ID';
export const SET_CURRENT_CLIENT = 'clientsReducer/SET_CURRENT_CLIENT';
export const SET_STATE_CLIENT_POPUP = 'clientsReducer/SET_STATE_CLIENT_POPUP';
export const SET_CLIENT_FROM_POPUP = 'clientsReducer/SET_CLIENT_FROM_POPUP';

// contacts
export const SET_LIST_CONTACTS = 'reasonsReducer/SET_LIST_CONTACTS';
export const ADD_CONTACTS = 'reasonsReducer/ADD_CONTACTS';
export const EDIT_CONTACTS = 'reasonsReducer/EDIT_CONTACTS';
export const REMOVE_CONTACTS = 'reasonsReducer/REMOVE_CONTACTS';

// employees
export const SET_LIST_EMPLOYEES = 'employeesReducer/SET_LIST_EMPLOYEES';
export const ADD_EMPLOYEE = 'employeesReducer/ADD_EMPLOYEE';
export const EDIT_EMPLOYEE = 'employeesReducer/EDIT_EMPLOYEE';
export const REMOVE_EMPLOYEE = 'employeesReducer/REMOVE_EMPLOYEE';

// events-filter
export const SET_EVENT_FILTER_PARAMS = 'eventsFilterReducer/SET_EVENT_FILTER_PARAMS';
export const ADD_CURRENT_EVENT_FILTER_INSURANCE = 'eventsFilterReducer/ADD_CURRENT_EVENT_FILTER_INSURANCE';
export const REMOVE_CURRENT_EVENT_FILTER_INSURANCE =
  'eventsFilterReducer/REMOVE_CURRENT_EVENT_FILTER_INSURANCE';
export const ADD_CURRENT_EVENT_FILTER_REASON = 'eventsFilterReducer/ADD_CURRENT_EVENT_FILTER_REASON';
export const REMOVE_CURRENT_EVENT_FILTER_REASON = 'eventsFilterReducer/REMOVE_CURRENT_EVENT_FILTER_REASON';
export const ADD_CURRENT_EVENT_FILTER_REASON_COLOR =
  'eventsFilterReducer/ADD_CURRENT_EVENT_FILTER_REASON_COLOR';
export const REMOVE_CURRENT_EVENT_FILTER_REASON_COLOR =
  'eventsFilterReducer/REMOVE_CURRENT_EVENT_FILTER_REASON_COLOR';
export const ADD_CURRENT_EVENT_FILTER_STATUS = 'eventsFilterReducer/ADD_CURRENT_EVENT_FILTER_STATUS';
export const REMOVE_CURRENT_EVENT_FILTER_STATUS = 'eventsFilterReducer/REMOVE_CURRENT_EVENT_FILTER_STATUS';
export const ADD_CURRENT_EVENT_FILTER_BRANCH = 'eventsFilterReducer/ADD_CURRENT_EVENT_FILTER_BRANCH';
export const ADD_CURRENT_EVENT_FILTER_DOCTOR = 'eventsFilterReducer/ADD_CURRENT_EVENT_FILTER_DOCTOR';
export const REMOVE_CURRENT_EVENT_FILTER_DOCTOR = 'eventsFilterReducer/REMOVE_CURRENT_EVENT_FILTER_DOCTOR';
export const CURRENT_TITLE_EVENTS_FILTER = 'eventsFilterReducer/CURRENT_TITLE_EVENTS_FILTER';
export const COLLAPSE_FILTER_EVENTS_FILTER = 'eventsFilterReducer/COLLAPSE_FILTER_EVENTS_FILTER';
export const COLLAPSE_FILTER_INSURANCE_EVENTS_FILTER = 'eventsFilterReducer/COLLAPSE_FILTER_INSURANCE_EVENTS_FILTER';
export const COLLAPSE_FILTER_REASON_EVENTS_FILTER = 'eventsFilterReducer/COLLAPSE_FILTER_REASON_EVENTS_FILTER';
export const COLLAPSE_FILTER_STATUS_EVENTS_FILTER = 'eventsFilterReducer/COLLAPSE_FILTER_STATUS_EVENTS_FILTER';
export const SET_PRELOADER_EVENTS_FILTER = 'eventsFilterReducer/SET_PRELOADER_BRANCHES';
export const ADD_EVENT_SEARCH = 'eventsFilterReducer/ADD_EVENT_SEARCH';
export const CLEAN_EVENTS_FILTER = 'eventsFilterReducer/CLEAN_EVENTS_FILTER';
export const SET_CURRENT_EVENTS_QUERY_STRING = 'eventsFilterReducer/SET_CURRENT_EVENTS_QUERY_STRING';
export const SET_CURRENT_SORT_PARAM_EVENTS = 'eventsFilterReducer/SET_CURRENT_SORT_PARAM_EVENTS';
export const SET_SORT_BY_FULL_NAME_EVENTS = 'eventsFilterReducer/SET_SORT_BY_FULL_NAME_EVENTS';
export const SET_SORT_BY_REASON_EVENTS = 'eventsFilterReducer/SET_SORT_BY_REASON_EVENTS';
export const SET_SORT_BY_STATUS_EVENTS = 'eventsFilterReducer/SET_SORT_BY_STATUS_EVENTS';
export const SET_SORT_BY_DATE_EVENTS = 'eventsFilterReducer/SET_SORT_BY_DATE_EVENTS';
export const SET_ACTIVE_ARROW_FULL_NAME_EVENTS = 'eventsFilterReducer/SET_ACTIVE_ARROW_FULL_NAME_EVENTS';
export const SET_ACTIVE_ARROW_REASON_EVENTS = 'eventsFilterReducer/SET_ACTIVE_ARROW_REASON_EVENTS';
export const SET_ACTIVE_ARROW_STATUS_EVENTS = 'eventsFilterReducer/SET_ACTIVE_ARROW_STATUS_EVENTS';
export const SET_ACTIVE_ARROW_DATE_EVENTS = 'eventsFilterReducer/SET_ACTIVE_ARROW_DATE_EVENTS';

// events
export const SET_EVENTS_LIST = 'eventsReducer/SET_EVENTS_LIST';
export const SET_EVENTS_PARAMS = 'eventsReducer/SET_EVENTS_PARAMS';
export const ADD_EVENT = 'eventsReducer/ADD_EVENT';
export const EDIT_EVENT = 'eventsReducer/EDIT_EVENT';
export const REMOVE_EVENT = 'eventsReducer/REMOVE_EVENT';
export const SET_CURRENT_ORDERING_EVENTS = 'eventsReducer/SET_CURRENT_ORDERING_EVENTS';
export const SET_CURRENT_PAGE_EVENTS = 'eventsReducer/SET_CURRENT_PAGE_EVENTS';
export const SET_CURRENT_EVENT_DETAILS = 'eventsReducer/SET_CURRENT_EVENT_DETAILS';
export const CANCEL_ORDER_EVENT = 'eventsReducer/CANCEL_ORDER_EVENT';
export const SET_CHOSEN_DAY_SCHEDULE = 'eventsReducer/SET_CHOSEN_DAY_SCHEDULE';
export const CHANGE_STATUS_EVENTS = 'eventsReducer/CHANGE_STATUS_EVENTS';
export const SET_CURRENT_EVENT_SCHEDULE = 'eventsReducer/SET_CURRENT_EVENT_SCHEDULE';
export const CHANGE_STATE_MODAL_WAITING = 'eventsReducer/CHANGE_STATE_MODAL_WAITING';
export const SET_SELECTED_EVENT = 'eventsReducer/SET_SELECTED_EVENT';

// fields
export const SET_LIST_FIELDS_EMPLOYEES = 'fieldsReducer/SET_LIST_FIELDS_EMPLOYEES';
export const SET_LIST_FIELDS_CLIENTS = 'fieldsReducer/SET_LIST_FIELDS_CLIENTS';
export const SET_LIST_FIELDS_EVENTS = 'employeesReducer/SET_LIST_FIELDS_EVENTS';
export const SET_LIST_FIELDS_REASONS = 'employeesReducer/SET_LIST_FIELDS_REASONS';
export const SET_LIST_FIELDS_WORK_SCHEDULE = 'employeesReducer/SET_LIST_FIELDS_WORK_SCHEDULE';
export const SET_LIST_FIELDS_BRANCHES = 'employeesReducer/SET_LIST_FIELDS_BRANCHES';
export const SET_LIST_FIELDS_HOLIDAY = 'employeesReducer/SET_LIST_FIELDS_HOLIDAY';
export const SET_LIST_FIELDS_CONTACTS = 'employeesReducer/SET_LIST_FIELDS_CONTACTS';
export const SET_LIST_FIELDS_USER = 'employeesReducer/SET_LIST_FIELDS_USER';
export const SET_LIST_FIELDS_ORDER = 'fieldsReducer/SET_LIST_FIELDS_ORDER';

// holidays
export const SET_LIST_HOLIDAYS = 'holidaysReducer/GET_LIST_HOLIDAYS';
export const ADD_HOLIDAY = 'holidaysReducer/ADD_HOLIDAY';
export const EDIT_HOLIDAY = 'holidaysReducer/EDIT_HOLIDAY';
export const REMOVE_HOLIDAY = 'holidaysReducer/REMOVE_HOLIDAY';
export const JOIN_HOLIDAY = 'holidaysReducer/JOIN_HOLIDAY';
export const LEAVE_HOLIDAY = 'holidaysReducer/LEAVE_HOLIDAY';

//orders
export const SET_LIST_ORDER = 'ordersReducer/SET_LIST_ORDER';
export const ADD_ORDER = 'ordersReducer/ADD_ORDER';
export const EDIT_ORDER = 'ordersReducer/EDIT_ORDER';
export const CHANGE_STATUS_ORDER = 'ordersReducer/CHANGE_STATUS_ORDER';
export const SET_CURRENT_PAGE_ORDERS = 'ordersReducer/SET_CURRENT_PAGE_ORDERS';

// orders-filter
export const SET_ORDER_FILTER_PARAMS = 'ordersFilterReducer/SET_ORDER_FILTER_PARAMS';
export const ADD_CURRENT_ORDER_FILTER_BRANCH = 'ordersFilterReducer/ADD_CURRENT_ORDER_FILTER_BRANCH';
export const ADD_CURRENT_ORDER_FILTER_DOCTOR = 'ordersFilterReducer/ADD_CURRENT_ORDER_FILTER_DOCTOR';
export const REMOVE_CURRENT_ORDER_FILTER_DOCTOR = 'ordersFilterReducer/REMOVE_CURRENT_ORDER_FILTER_DOCTOR';
export const CURRENT_TITLE_ORDER_FILTER = 'ordersFilterReducer/CURRENT_TITLE_ORDER_FILTER';
export const COLLAPSE_DOCTOR_ORDER_FILTER = 'ordersFilterReducer/COLLAPSE_DOCTOR_ORDER_FILTER';
export const SET_PRELOADER_ORDER_FILTER = 'ordersFilterReducer/SET_PRELOADER_BRANCHES';
export const ADD_ORDER_SEARCH = 'ordersFilterReducer/ADD_CURRENT_SEARCH';
export const CLEAN_ORDERS_FILTER = 'ordersFilterReducer/CLEAN_ORDERS_FILTER';
export const SET_CURRENT_ORDERS_QUERY_STRING = 'ordersFilterReducer/SET_CURRENT_ORDERS_QUERY_STRING';

// profile
export const SET_PROFILE_DATA = 'profileReducer/SET_PROFILE_DATA';
export const UPDATE_PROFILE_DATA = 'profileReducer/UPDATE_PROFILE_DATA';
export const CHANGE_STATUS_REQUEST_PROFILE = 'profileReducer/CHANGE_STATUS_REQUEST_PROFILE';

// reasons
export const SET_LIST_REASONS = 'reasonsReducer/SET_LIST_REASONS';
export const ADD_REASON = 'reasonsReducer/ADD_REASON';
export const EDIT_REASON = 'reasonsReducer/EDIT_REASON';
export const REMOVE_REASON = 'reasonsReducer/REMOVE_REASON';

// registration
export const ADD_USER_REGISTRATION = 'registerReducer/ADD_USER_REGISTRATION';
export const ACTIVATION_REGISTRATION = 'registerReducer/ACTIVATION_REGISTRATION';
export const IS_SEND_DATA_REGISTRATION = 'registerReducer/IS_SEND_DATA_REGISTRATION';

// work-schedule
export const SET_LIST_WORK_SCHEDULE = 'scheduleWorkReducer/SET_LIST_WORK_SCHEDULE';
export const EDIT_WORK_SCHEDULE = 'scheduleWorkReducer/EDIT_WORK_SCHEDULE';

// waiting-room-filter
export const SET_LIST_OF_BRANCH_FILTER_WAITING = 'waitingRoomFilterReducer/SET_LIST_OF_BRANCH_FILTER_WAITING';
export const SET_CURRENT_PARAM_BRANCH_FILTER_WAITING = 'waitingRoomFilterReducer/SET_CURRENT_PARAM_BRANCH_FILTER_WAITING';
export const ADD_CURRENT_DOCTOR_FILTER_WAITING = 'waitingRoomFilterReducer/ADD_CURRENT_EVENT_DOCTOR_FILTER_WAITING';
export const REMOVE_CURRENT_DOCTOR_FILTER_WAITING = 'waitingRoomFilterReducer/REMOVE_CURRENT_EVENT_DOCTOR_FILTER_WAITING';
export const ADD_CURRENT_REASON_FILTER_WAITING = 'waitingRoomFilterReducer/ADD_CURRENT_EVENT_REASON_FILTER_WAITING';
export const REMOVE_CURRENT_REASON_FILTER_WAITING = 'waitingRoomFilterReducer/REMOVE_CURRENT_EVENT_REASON_FILTER_WAITING';
export const ADD_CURRENT_REASON_COLOR_FILTER_WAITING = 'waitingRoomFilterReducer/ADD_CURRENT_EVENT_REASON_COLOR_FILTER_WAITING';
export const REMOVE_CURRENT_REASON_COLOR_FILTER_WAITING = 'waitingRoomFilterReducer/REMOVE_CURRENT_EVENT_REASON_COLOR_FILTER_WAITING';
export const COLLAPSE_FILTER_REASON_FILTER_WAITING = 'waitingRoomFilterReducer/COLLAPSE_FILTER_REASON_FILTER_WAITING';
export const COLLAPSE_FILTER_DOCTOR_FILTER_WAITING = 'waitingRoomFilterReducer/COLLAPSE_FILTER_DOCTOR_FILTER_WAITING';
export const ADD_WAITING_SEARCH = 'waitingRoomFilterReducer/ADD_WAITING_SEARCH';
export const CLEAN_WAITING_ROOM_FILTER = 'waitingRoomFilterReducer/CLEAN_WAITING_ROOM_FILTER';
export const SET_CURRENT_WAITING_ROOM_QUERY_STRING = 'waitingRoomFilterReducer/SET_CURRENT_WAITING_ROOM_QUERY_STRING';

// waiting-room
export const SET_LIST_WAITING_ROOM = 'waitingRoomReducer/SET_LIST_WAITING_ROOM';