import { useDispatch } from 'react-redux';
import {
  cleanClientsFilterAC,
  cleanEventsFilterAC, cleanOrdersFilterAC, cleanWaitingRoomFilterAC,
  setCurrClientsQueryStringAC,
  setCurrEventsQueryStringAC,
  setCurrWaitingRoomQueryStringAC
} from "../redux/actions";

/**
 * "useCleanFilters" is a custom hook. Returns a function that clears
 * filters and parameters in query strings.
 * */
export const useCleanFilters = (): () => void => {
  const dispatch = useDispatch();

  return () => {
    dispatch(cleanEventsFilterAC());
    dispatch(cleanWaitingRoomFilterAC());
    dispatch(cleanClientsFilterAC());
    dispatch(cleanOrdersFilterAC());
    dispatch(setCurrEventsQueryStringAC(null));
    dispatch(setCurrClientsQueryStringAC(null));
    dispatch(setCurrWaitingRoomQueryStringAC(null));
  };
};