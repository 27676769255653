import TranslationKeys from '../translationKeys/translationKeys';

let English = { ...TranslationKeys };

English = {
  address: 'Address',
  advanced_setting: "Advanced settings",
  all_day: 'All day',
  at_the_doctor: 'At the doctor',

  begin: 'Begin',
  booking_success: 'You booking is successfull',
  branches_nav: 'Branches',
  branch_list: 'Branches list',
  branch_btn_add: 'add branch',
  branch_form_add: 'Branch add',
  branch_delete: 'Delete branch?',
  branch_form_edit: 'Branch edit',
  branch: 'Branch',
  branch_for_title: 'Branches',

  cancel: 'Cancel',
  chose: 'choose an option',
  click_conformation: 'Click on the confirmation link in the email to activate you account',
  client: 'Client',
  clients_header: 'Clients',
  client_data: 'Client data',
  client_history: 'Client history',
  client_list: 'Clients list',
  client_btn_add: 'add client',
  client_form_add: 'Client add',
  client_form_edit: 'Client edit',
  client_not_appeared: 'Not appeared',
  client_not_appeared_btn: 'Not appeared',
  contacts_nav: 'Contacts',
  contact_delete: 'Delete contact?',
  contact_add_btn: 'add contact',
  contact_form_add: 'Contact add',
  contact_form_edit: 'Contact edit',
  copy: 'Copy',
  copy_script_text_message: 'Script copied to clipboard',
  create_acc: 'Your account has been created',
  current_branch: 'Current branch',

  data_time: 'Date/Time',
  data_time_desc: 'Date/Time (descending)',
  date: 'Date',
  date_birth: 'Date of birth',
  de: "Deutsch",
  delete: 'Delete',
  desc_name: 'Name (descending)',
  desc_reason: 'Reason (descending)',
  details: 'Details',
  did_not_wait: 'Did not wait',
  doctor: 'Doctor',
  done: 'Done',
  duration: 'Appointment duration',

  edit: 'Edit',
  email: 'Email address',
  email_short: 'Email',
  employees_info: 'Info',
  employees_setting: 'Setting',
  employees_nav: 'Employees',
  employees_list: 'Employee list',
  employee_form_add: 'Employee add',
  employee_btn_add: 'add employee',
  employee_delete: 'Delete employee?',
  employee_form_edit: 'Employee edit',
  en: 'English',
  end: 'End',
  end_alert: 'End',
  event_data: 'Event data',
  event_header: 'Events',
  event_list: 'Events list',
  event_btn_Add: 'Add event',
  event_btn_add: 'add event',
  event_form_add: 'Event add',
  event_form_edit: 'Event edit',
  event_cancel: 'Cancel event?',
  event_did_not_come: 'Did not come',
  event_at_the_doctor: 'At the doctor',
  event_did_not_wait: 'Did not wait',

  failed: 'Failed',
  full_name: 'Full name',

  gender: 'Gender',

  holiday_btn_add: 'add Holiday',
  holiday_delete: 'Delete holiday?',
  holiday_form_add: 'Holiday add',
  holiday_form_edit: 'Holiday edit',
  holiday_list: 'Holidays list',
  holidays_nav: 'Holidays',
  holiday_official: 'Official',
  holiday_private: 'Private',
  holiday_suggestion: 'Suggestion',
  home_btn: 'Go Home',

  iframe: "Iframe",
  info: 'Info',
  insurance: 'Insurance',
  incurance_desc: 'Insurance (descending)',

  join: 'Join',

  language: 'Language',
  leave: 'Leave',
  login: 'Login',
  logout_nav: 'Logout',
  lunch: 'Lunch',
  lunch_alert: 'Lunch',

  material: "Material",

  name: 'Name',
  not_found: 'Page not found.',

  options: 'Options',
  order_btn_add: 'add order',
  order_delete: 'Delete order?',
  order_download: 'Download',
  order_edit: 'Order edit',
  order_form_add: 'Order add',
  orders_header: 'Orders',
  order_list: 'Orders list',
  order_second_chance: 'Second Chance',

  password: 'Password',
  phone_number: 'Phone Number',
  phone_number_short: 'Phone',
  profile_first_name: 'First name',
  profile_last_name: 'Last name',
  profile_nav: 'Profile',
  profile_personal_info: 'Personal information',

  reasonst_list: 'Reasons list',
  reasons_nav: 'Reasons',
  reason: 'Reason',
  reason_btn_add: 'add reason',
  reason_form_add: 'Reason add',
  reason_delete: 'Delete reason?',
  reason_form_edit: 'Reason edit',
  register: 'Register',

  saved: 'Saved',
  setting: 'Setting',
  shedule_name: 'Schedule',
  shedule_time: 'Time',
  shedule_edit: 'Edit schedule',
  sign_up: 'Signup',
  sort: 'Sort by:',
  start: 'Start',
  start_alert: 'Start',
  status: 'Status',
  status_desc: 'Status (descending)',
  submit: 'Submit',

  title: 'Title',

  user: 'User',
  user_not_foud: 'User not found',

  waiting_header: 'Waiting room',
  waiting: 'Waiting',
  waiting_period: 'Waitng period',
  weekend: 'Weekend',
  weekend_alert: 'Weekend',
  welcome: 'Welcome to the waiting room!',
  work_shedule_nav: 'Work schedule',
  work_time: 'Work time',

  yes: 'Yes',
};

export default English;
