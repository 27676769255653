import React, { FC, memo } from 'react';
import { EventScheduleSmall } from './EventScheduleSmall/EventScheduleSmall';
import { DateListEventType, DateOfListType, ScheduleBranchType } from '../../../api/branches-api';
import dayjs from 'dayjs';
import { HolidayScheduleSmall } from './HolidayScheduleSmall/HolidayScheduleSmall';
import { calcTimeForWorkDay } from '../../../utils/schedule/calcTimeForWorkDay';
import { NonWorkingHoursSmall } from './NonWorkingHoursSmall/NonWorkingHoursSmall';
import { CurrentDay } from '../CurrentDay/CurrentDay';
import '../DayOfTheWeek/DayOfTheWeek.scss';

type DayOfTheWeekPropsType = {
  dayIndex: number;
  dayName: string;
  scheduleBranchDate: Array<DateOfListType>;
  scheduleBranch: ScheduleBranchType;
  onFreeDateClick: (date: string | undefined, time: string) => void;
  setDateForModalAlert: (date: string, date_of_lists: DateOfListType) => void;
  onStatusChangeEventOpenAlert: (eventId: number, status: string) => void;
  onStatusChangeEvent: (eventId: number, status: string) => void;
  calcGrid: {
    timeWorkDayFinally: Array<string>;
    currentHeightLine: (hour: number) => number;
    currentHeightLineStart: (hour: number) => number;
    fullHeight: number;
    sumHours: number;
    dynamicHeightFirstHour: (hour: string) => string;
  };
  setCurrentEventFromSchedule: (event: DateListEventType | null) => void;
  openEventDetails: () => void;
};

export const DayOfTheWeekSmall: FC<DayOfTheWeekPropsType> = memo(
  ({
    scheduleBranchDate,
    scheduleBranch,
    onFreeDateClick,
    dayIndex,
    dayName,
    setDateForModalAlert,
    onStatusChangeEventOpenAlert,
    onStatusChangeEvent,
    calcGrid,
    setCurrentEventFromSchedule,
    openEventDetails,
  }) => {
    const STEP_MINUTES = 10;
    const AVAILABLE_TIME = 60 / STEP_MINUTES;
    const CURRENT_DATE = scheduleBranchDate.find((day) => day.date_index === dayIndex);

    const weeklyWorkSchedule = calcGrid.timeWorkDayFinally.map((time) => ({
      time,
      days: scheduleBranchDate,
    }));

    const heightHour = `(${calcGrid.fullHeight}vh - 251px) / ${calcGrid.sumHours}`;

    const dayNow = dayjs().format('YYYY-MM-DD');

    const DayNowBlockStyle = CURRENT_DATE && CURRENT_DATE?.date === dayNow ? 'day dayNow' : 'day';
    const WeekDateNowBlockStyle =
      CURRENT_DATE && CURRENT_DATE?.date === dayNow ? 'dayDate dayDateNow' : 'dayDate';

    const DayWeekendBlockStyle =
      CURRENT_DATE && CURRENT_DATE?.holidays?.length > 0 ? 'day dayHoliday' : 'day';
    const WeekDateWeekendBlockStyle =
      CURRENT_DATE && CURRENT_DATE?.holidays?.length > 0 ? 'dayDate dayDateHoliday' : 'dayDate';

    const generalDayStyle =
      CURRENT_DATE && CURRENT_DATE?.date === dayNow && CURRENT_DATE?.holidays?.length > 0
        ? DayNowBlockStyle
        : CURRENT_DATE?.date === dayNow
        ? DayNowBlockStyle
        : CURRENT_DATE?.holidays?.length
        ? DayWeekendBlockStyle
        : 'day';

    const generalDateStyle =
      CURRENT_DATE && CURRENT_DATE?.date === dayNow && CURRENT_DATE?.holidays.length
        ? WeekDateNowBlockStyle
        : CURRENT_DATE?.date === dayNow
        ? WeekDateNowBlockStyle
        : CURRENT_DATE?.holidays?.length
        ? WeekDateWeekendBlockStyle
        : 'dayDate';

    const currentHoliday =
      CURRENT_DATE && CURRENT_DATE?.holidays?.length ? CURRENT_DATE?.holidays[0]?.name : '';

    return (
      <div
        className={generalDayStyle}
        title={generalDayStyle === 'day dayHoliday' ? currentHoliday : ''}
      >
        <div className={generalDateStyle}>
          <span className={'dayDate_weekDay'}>{dayName}</span>
          <span className={'dayDate_date'}>
            {CURRENT_DATE && dayjs(CURRENT_DATE.date).format("DD.MM")}
          </span>
        </div>

        <NonWorkingHoursSmall
          scheduleBranchDate={scheduleBranchDate}
          dayIndex={dayIndex}
          scheduleBranch={scheduleBranch}
          heightHour={heightHour}
        />

        <CurrentDay
          scheduleBranchDate={scheduleBranchDate}
          dayIndex={dayIndex}
          scheduleBranch={scheduleBranch}
          heightHour={heightHour}
          DayNowBlockStyle={DayNowBlockStyle}
        />

        <EventScheduleSmall
          events={CURRENT_DATE ? CURRENT_DATE?.events : []}
          dayIndex={dayIndex}
          scheduleBranchDate={scheduleBranchDate}
          scheduleBranch={scheduleBranch}
          timeWorkDayFinally={calcGrid.timeWorkDayFinally}
          setDateForModalAlert={setDateForModalAlert}
          onFreeDateClick={onFreeDateClick}
          onStatusChangeEventOpenAlert={onStatusChangeEventOpenAlert}
          onStatusChangeEvent={onStatusChangeEvent}
          calcGrid={calcGrid}
          heightHour={heightHour}
          CURRENT_DATE={CURRENT_DATE}
          setCurrentEventFromSchedule={setCurrentEventFromSchedule}
          openEventDetails={openEventDetails}
        />

        <HolidayScheduleSmall
          dayIndex={dayIndex}
          scheduleBranchDate={scheduleBranchDate}
          scheduleBranch={scheduleBranch}
          calcGrid={calcGrid}
          heightHour={heightHour}
          CURRENT_DATE={CURRENT_DATE}
        />

        <div className="dayDate_free-day-block">
          {weeklyWorkSchedule &&
            weeklyWorkSchedule.map((day, index) => (
              <div
                key={index}
                style={
                  index === 0
                    ? {
                        height: `calc(${calcGrid.dynamicHeightFirstHour(day.time)})`,
                      }
                    : index === calcGrid.timeWorkDayFinally.lastIndexOf(day.time)
                    ? {
                        height: `calc((${calcGrid.currentHeightLine(
                          Number(day.time.slice(3, 5)),
                        )}vh - 251px) / ${calcGrid.sumHours})`,
                      }
                    : {
                        height: `calc((${calcGrid.currentHeightLine(
                          Number(day.time.slice(3, 5)),
                        )}vh - 251px) / ${calcGrid.sumHours})`,
                      }
                }
                className="dayDate_free-day-block_free-day"
              >
                <div className="free-time-block">
                  {calcTimeForWorkDay(day.time, scheduleBranch.day_end, STEP_MINUTES).map(
                    (time, ind) => (
                      <div
                        key={ind}
                        className="time-elem"
                        style={{ height: `calc(${heightHour} / ${AVAILABLE_TIME}` }}
                        onClick={(e) => {
                          e.stopPropagation();
                          setCurrentEventFromSchedule(null);
                          onFreeDateClick(
                            day.days.find((el) => el.date_index === dayIndex)?.date,
                            time,
                          );
                        }}
                      >
                        <span className="content">{time}</span>
                      </div>
                    ),
                  )}
                </div>
              </div>
            ))}
        </div>
      </div>
    );
  },
);