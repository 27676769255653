import React, { FC } from 'react';
import { ClientType } from '../../../api/clients-api';
import { logo } from '../../../assets/img/image';
import { DoctorType, ReasonType } from '../../../api/reasons-api';
import { BranchType } from '../../../api/branches-api';
import './ViewDoctors.scss';

export type ViewDoctorsPropsType = {
  client: ClientType | ReasonType | BranchType;
};

export const ViewDoctors: FC<ViewDoctorsPropsType> = ({ client }) => {
  const baseURL = process.env.REACT_APP_BASE_URL;
  const windowWidth = window.innerWidth;
  const titleCount = client?.doctors?.map(
    (el) => `
${el.first_name} ${el.last_name}`,
  );

  return (
    <>
      {windowWidth <= 1210 ? (
        <>
          {client.doctors
            .filter((cl, index) => index < 2)
            .map((doc) => (
              <span
                className={`${client.doctors.length > 4 ? 'doc doc-long' : 'doc'}`}
                key={doc.id}
              >
                <img
                  alt="photo"
                  className={`${client.doctors.length > 4 ? 'doc-img doc-long-img' : 'doc-img'}`}
                  src={doc.photo === null ? logo.notLogo : `${baseURL}${doc.photo}`}
                  title={`${doc.first_name} ${doc.last_name}`}
                />
              </span>
            ))}
          {client.doctors.length > 4 && (
            <span title={titleCount.filter((name, index) => index > 1).join()} className="count">
              {`+${client.doctors.length - 2}`}
            </span>
          )}
        </>
      ) : windowWidth > 1210 && windowWidth < 1480 ? (
        <>
          {client.doctors
            .filter((cl, index) => index < 3)
            .map((doctor) => (
              <span
                className={`${client.doctors.length > 4 ? 'doc doc-long' : 'doc'}`}
                key={doctor.id}
              >
                <img
                  alt="photo"
                  className={`${client.doctors.length > 4 ? 'doc-img doc-long-img' : 'doc-img'}`}
                  src={doctor.photo === null ? logo.notLogo : `${baseURL}${doctor.photo}`}
                  title={`${doctor.first_name} ${doctor.last_name}`}
                />
              </span>
            ))}
          {client.doctors.length > 4 && (
            <span title={titleCount.filter((name, index) => index > 2).join()} className="count">
              {`+${client.doctors.length - 3}`}
            </span>
          )}
        </>
      ) : windowWidth >= 1480 && windowWidth < 1600 ? (
        <>
          {client.doctors
            .filter((cl, index) => index < 5)
            .map((doc) => (
              <span
                className={`${client.doctors.length > 4 ? 'doc doc-long' : 'doc'}`}
                key={doc.id}
              >
                <img
                  alt="photo"
                  className={`${client.doctors.length > 4 ? 'doc-img doc-long-img' : 'doc-img'}`}
                  src={doc.photo === null ? logo.notLogo : `${baseURL}${doc.photo}`}
                  title={`${doc.first_name} ${doc.last_name}`}
                />
              </span>
            ))}
          {client?.doctors?.length > 4 && (
            <span title={titleCount.filter((name, index) => index > 4).join()} className="count">
              {`+${client.doctors.length - 5}`}
            </span>
          )}
        </>
      ) : (
        <>
          {client?.doctors?.filter((cl, index) => index < 6)
            .map((doc: DoctorType) => (
              <span
                className={`${client?.doctors?.length > 4 ? 'doc doc-long' : 'doc'}`}
                key={doc.id}
              >
                <img
                  alt="photo"
                  className={`${client?.doctors?.length > 4 ? 'doc-img doc-long-img' : 'doc-img'}`}
                  src={doc.photo === null ? logo.notLogo : `${baseURL}${doc.photo}`}
                  title={`${doc.first_name} ${doc.last_name}`}
                />
              </span>
            ))}
          {client?.doctors?.length > 4 && (
            <span
              style={{
                display: `${
                  titleCount.filter((name, index) => index > 5).length > 0 ? 'inherit' : 'none'
                }`,
              }}
              title={titleCount.filter((name, index) => index > 5).join()}
              className="count"
            >
              {`+${client?.doctors?.length - 6}`}
            </span>
          )}
        </>
      )}
    </>
  );
};
