import { AppRootStateType } from '../store';
import { WaitingRoomFilterParamsType, WaitingRoomType } from '../../api/waitingRoom-api';

export const getWaitingRoomSelector = (state: AppRootStateType): WaitingRoomType =>
  state.waitingRoom;

export const getFilterParamsSelector = (state: AppRootStateType): WaitingRoomFilterParamsType =>
  state.waitingRoomFilter.waitingRoomFilterParamsList;

export const getCurrentColorParamsWaitingSelector = (state: AppRootStateType): Array<string> =>
  state.waitingRoomFilter.currentFilterParams.color;

export const getCurrentReasonParamsWaitingSelector = (state: AppRootStateType): Array<string> =>
  state.waitingRoomFilter.currentFilterParams.reason__in;

export const getCurrentDoctorParamsWaitingSelector = (state: AppRootStateType): Array<string> =>
  state.waitingRoomFilter.currentFilterParams.doctor__in;

export const isCollapsedReasonFilterWaitingSelector = (state: AppRootStateType): boolean =>
  state.waitingRoomFilter.isCollapsedFilterReason;

export const isCollapsedDoctorFilterWaitingSelector = (state: AppRootStateType): boolean =>
  state.waitingRoomFilter.isCollapsedFilterDoctor;

export const getCurrWaitingRoomQueryStringAppSelector = (state: AppRootStateType): string | null =>
  state.waitingRoomFilter.currentQueryStringWaitingRoomList;
