import { ClientType, DoctorType } from './clients-api';
import { instance } from './http';

export const waitingRoomAPI = {
  getList(branchId: number) {
    return instance.get<WaitingRoomType>(`/api/${branchId}/client/waiting-room/`);
  },

  getFilteredListOfGeneral(branchId: number, params: string | null) {
    return instance.get<WaitingRoomType>(
      `/api/${branchId}/client/waiting-room/?${params}`);
  },

  getFilteredListOfBranch(branchId: any) {
    return instance.get<WaitingRoomFilterParamsType>(`/api/client/waiting-room/filter-form/?branch=${branchId}`);
  },
};

export type WaitingRoomFilterParamsType = {
  branch: {
    type: string
    widget_type: string
    input_type: string
    hidden: boolean
    required: boolean
    label: string | null
    help_text: string
    initial_value: string | null
    choices: {}
  };
  doctor__in: {
    type: string
    widget_type: string
    input_type: string
    hidden: boolean
    required: boolean
    label: string | null
    help_text: string
    initial_value: string | null
    choices: {}
  };
  reason__in: {
    type: string
    widget_type: string
    input_type: string
    hidden: boolean
    required: boolean
    label: string | null
    help_text: string
    initial_value: string | null
    choices: {}
  };
  color: {
    type: string
    widget_type: string
    input_type: string
    hidden: boolean
    required: boolean
    label: string | null
    help_text: string
    initial_value: string | null
    choices: { reason: string }
  };
  search: {
    type: string
    widget_type: string
    input_type: string
    hidden: boolean
    required: boolean
    label: string
    help_text: string
    initial_value: string | null
  };
};

//types
export type WaitingResponseType = {
  id: number;
  uuid_field: string;
  start: string;
  end: string;
  desired_date: boolean;
  client: ClientType;
  client_display: string;
  status: string;
  status_display: string;
  insurance_type: string;
  insurance_type_display: string;
  branch: number;
  branch_display: string;
  reason: number;
  reason_display: string;
  reason_color: string;
  is_client: boolean;
  is_client_display: string;
  message: string;
  note: string;
  created_on: string;
  updated_on: string;
  email_reminded: boolean;
  sms_reminded: boolean;
  waiting_time: string;
  visit_time: string;
  doctor: DoctorType;
  finished_at: any;
  start_waiting_at: string;
};
export type WaitingRoomType = {
  waiting_room: Array<WaitingResponseType>;
  at_the_doctor: Array<WaitingResponseType>;
};

