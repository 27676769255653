import { AppRootStateType } from '../store';
import { EventDetailType, EventFilterParamsType, EventType } from '../../api/events-api';
import { DateListEventType } from '../../api/branches-api';

export const getEventsSelector = (state: AppRootStateType): Array<EventType> =>
  state.events.events.results;

export const getPreviousEventsSelector = (state: AppRootStateType): string | null =>
  state.events.events.links.previous;

export const getNextEventsSelector = (state: AppRootStateType): string | null =>
  state.events.events.links.next;

export const getCurrentPagePaginationEventsSelector = (state: AppRootStateType): number =>
  state.events.events.current_page;

export const getCurrentEventsSelector = (state: AppRootStateType): number =>
  state.events.events.current;

export const getCurrentPageSelector = (state: AppRootStateType): string | null =>
  state.events.currentPage;

export const getCurrentOrderingSelector = (state: AppRootStateType): string | null =>
  state.events.currentOrdering;

export const getChosenDaySelector = (
  state: AppRootStateType,
): {
  date: string;
  time: string;
} | null => state.events.chosenDayInSchedule;

export const getDetailsEventsSelector = (state: AppRootStateType): EventDetailType =>
  state.events.currentDetails;

export const getFilterParamsEventsSelector = (state: AppRootStateType): EventFilterParamsType =>
  state.eventsFilter.eventFilterParamsList;

export const getCurrentInsuranceParamsEventsSelector = (state: AppRootStateType): Array<string> =>
  state.eventsFilter.currentFilterParams.insurance_type__in;

export const getCurrentReasonParamsEventsSelector = (state: AppRootStateType): Array<string> =>
  state.eventsFilter.currentFilterParams.reason__in;

export const getCurrentStatusParamsEventsSelector = (state: AppRootStateType): Array<string> =>
  state.eventsFilter.currentFilterParams.status__in;

export const getCurrentColorParamsEventsSelector = (state: AppRootStateType): Array<string> =>
  state.eventsFilter.currentFilterParams.reason__color;

export const getCurrentBranchParamsEventsSelector = (state: AppRootStateType): Array<string> =>
  state.eventsFilter.currentFilterParams.branch;

export const getCurrentDoctorParamsEventsSelector = (state: AppRootStateType): Array<string> =>
  state.eventsFilter.currentFilterParams.doctor__in;

export const isCollapsedFilterEventsSelector = (state: AppRootStateType): boolean =>
  state.eventsFilter.isCollapsedFilter;

export const isCollapsedIncuranceFilterEventsSelector = (state: AppRootStateType): boolean =>
  state.eventsFilter.isCollapsedFilterIncurance;

export const isCollapsedReasonFilterEventsSelector = (state: AppRootStateType): boolean =>
  state.eventsFilter.isCollapsedFilterReason;

export const isCollapsedStatusFilterEventsSelector = (state: AppRootStateType): boolean =>
  state.eventsFilter.isCollapsedFilterStatus;

export const setPreloader = (state: AppRootStateType): boolean =>
  state.eventsFilter.isActivePreloader;

export const getCurrentEventFromSchedule = (state: AppRootStateType): DateListEventType | EventDetailType | null =>
  state.events.currentEventFromSchedule;

export const getStateModalWaitingRoom = (state: AppRootStateType): boolean =>
  state.events.isOpenModalWaitingRoom;

export const getSortStateByFullNameEventsSelector = (state: AppRootStateType): boolean =>
  state.eventsFilter.currentSortParams?.isActiveFullName;

export const getSortStateByReasonEventsSelector = (state: AppRootStateType): boolean =>
  state.eventsFilter.currentSortParams?.isActiveReason;

export const getSortStateByStatusEventsSelector = (state: AppRootStateType): boolean =>
  state.eventsFilter.currentSortParams?.isActiveStatus;

export const getSortStateByDateEventsSelector = (state: AppRootStateType): boolean =>
  state.eventsFilter.currentSortParams?.isActiveDate;

export const getSortStateArrowFullNameEventsSelector = (state: AppRootStateType): boolean =>
  state.eventsFilter.currentSortParams?.isActiveArrowFullName;

export const getSortStateArrowReasonEventsSelector = (state: AppRootStateType): boolean =>
  state.eventsFilter.currentSortParams?.isActiveArrowReason;

export const getSortStateArrowStatusEventsSelector = (state: AppRootStateType): boolean =>
  state.eventsFilter.currentSortParams?.isActiveArrowStatus;

export const getSortStateArrowDateEventsSelector = (state: AppRootStateType): boolean =>
  state.eventsFilter.currentSortParams?.isActiveArrowDate;

export const getCurrEventsQueryStringAppSelector = (state: AppRootStateType): string | null =>
  state.eventsFilter.currentQueryStringEventsList;

export const getCurrentSortParamEventsSelector = (state: AppRootStateType): string =>
  state.eventsFilter.currentSortParams?.currentSortParam;

export const getSelectedEventSelector = (state: AppRootStateType): number | null =>
  state.events.selectedEvent;