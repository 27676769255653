import React, { FC, useEffect, useMemo, useState } from 'react';
import './ClientsFilter.scss';
import { useDispatch, useSelector } from 'react-redux';
import CheckboxInput from '../../../components/Widgets/CheckboxInput';
import { SubmitHandler, useForm } from 'react-hook-form';
import Inputs from '../../../components/Widgets/Inputs';
import {
  getCurrClientsQueryStringAppSelector,
  getCurrentBranchSelector,
  getCurrentInsuranceParamsClientsSelector,
  getFilterParamsClientsSelector,
  getUserProfileSelector,
} from '../../../redux/selectors';
import { ReactSelectBranch } from '../../../components/Widgets/Select/ReactSelectBranch';
import {
  defaultChoicesSelect,
  newOption,
} from '../../../components/Widgets/Select/ReactSelectStyles';
import { useTranslation } from 'react-i18next';
import {
  getCurrentDoctorsParamsClientsSelector,
  isCollapsedInsuranceFilterClientsSelector,
} from '../../../redux/selectors/ClientsSelectors';
import cx from 'classnames';
import Collapse from '@kunukn/react-collapse';
import TranslationKeys from '../../../assets/locales/translationKeys/translationKeys';
import { getObjectFromQueryString } from '../../../utils/getObjectFromQueryString';
import {
  collapseFilterInsuranceAC,
  setCurrentBranchesAC,
  setCurrentParamBranchAC
} from "../../../redux/actions";
import {
  fetchFilteredClientsListOfBranchTC,
  fetchFilteredClientsParamsListTC,
  setFilteredClientOfDoctorTC,
  setFilteredClientsOfInsuranceTC,
  setFilteredClientsSearchTC
} from "../../../redux/middlewares";
import { useCleanFilters } from "../../../hooks/useCleanFilters";

type FormDataType = {};

type EventsSideBarPropsType = {};

export const ClientsFilter: FC<EventsSideBarPropsType> = () => {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const cleanFilters = useCleanFilters();

  const filterParams = useSelector(getFilterParamsClientsSelector);
  const currentInsuranceParams = useSelector(getCurrentInsuranceParamsClientsSelector);
  const currentDoctorParams = useSelector(getCurrentDoctorsParamsClientsSelector);
  const currentBranch = useSelector(getCurrentBranchSelector);
  const isCollapsedInsurance = useSelector(isCollapsedInsuranceFilterClientsSelector);
  const user = useSelector(getUserProfileSelector);
  const currentQueryString = useSelector(getCurrClientsQueryStringAppSelector);

  const [stateDoctor, setStateDoctor] = useState<boolean>(true);
  const [openDoc, isOpenDoc] = useState(true);
  const [openInsurance, isOpenInsurance] = useState(false);

  useEffect(() => {
    if (currentDoctorParams.length) {
      setStateDoctor(true);
    }
  }, []);

  const defaultSearchParam = getObjectFromQueryString(currentQueryString)?.search;

  const valueOptionBranches =
    filterParams &&
    Object.entries(filterParams.branches__in.choices).map((b: any) => newOption(b[0], b[1]));
  const labelOptionBranches = currentBranch.toString();

  const choicesInsurance =
    filterParams.insurance_type__in &&
    Object.entries(filterParams.insurance_type__in.choices).map((br) => br);
  const choicesBranch =
    filterParams.branches__in && Object.entries(filterParams.branches__in.choices).map((br) => br);
  const search = filterParams.search && Object.entries(filterParams.search).map((l) => l[1]);
  const choicesDoctor =
    filterParams.doctors__in && Object.entries(filterParams.doctors__in.choices).map((br) => br);

  const setFilterInsurance = (params: string): void => {
    dispatch(setFilteredClientsOfInsuranceTC(params));
  };

  const setFilterDoctor = (params: string): void => {
    dispatch(setFilteredClientOfDoctorTC(params));
  };

  const onSearchChange = (event: string): void => {
    dispatch(setFilteredClientsSearchTC(event));
  };

  const setFilterBranch = (params: string): void => {
    cleanFilters();
    dispatch(setCurrentBranchesAC(Number(params)));
    dispatch(setCurrentParamBranchAC(params));
    dispatch(fetchFilteredClientsParamsListTC());
    dispatch(fetchFilteredClientsListOfBranchTC(params));
  };
  const collapseInsuranceFilter = (): void => {
    dispatch(collapseFilterInsuranceAC(!isCollapsedInsurance));
  };
  const countDoctor = useMemo(
    () =>
      choicesDoctor.reduce(
        (count, [doctorId]) => (currentDoctorParams.includes(doctorId) ? count + 1 : count),
        0,
      ),
    [currentDoctorParams, choicesDoctor],
  );
  const countInsurance = useMemo(
    () =>
      choicesInsurance.reduce(
        (count, [insuranceId]) =>
          currentInsuranceParams.includes(insuranceId) ? count + 1 : count,
        0,
      ),
    [currentInsuranceParams, choicesInsurance],
  );

  const { register, handleSubmit, control } = useForm<FormDataType>({
    mode: 'onBlur',
  });

  const onSubmit: SubmitHandler<FormDataType> = () => {};

  return (
    <form className="form-client-filter" onSubmit={handleSubmit(onSubmit)}>
      <div className="filter-block">
        <div className="filter-block-select">
          <ReactSelectBranch
            name="branches"
            label={t(TranslationKeys.current_branch)}
            placeholder={t(TranslationKeys.current_branch)}
            control={control}
            isMulti={false}
            defaultValue={
              currentBranch && defaultChoicesSelect(valueOptionBranches, labelOptionBranches)
            }
            onChange={(e) => setFilterBranch(e.value)}
            options={
              choicesBranch &&
              Object.entries(filterParams.branches__in.choices).map((b: any) =>
                newOption(b[0], b[1]),
              )
            }
          />
        </div>
      </div>
      <div className="search">
        <Inputs
          state="search"
          label={search[5]}
          name="search"
          defaultValue={defaultSearchParam && defaultSearchParam}
          style={{
            height: '50px',
            paddingTop: '14px',
            marginBottom: '0',
          }}
          onChangeText={onSearchChange}
          register={register}
        />
      </div>
      {user.role !== 'doctor' && (
        <div className="filter-block">
          <div
            className={cx('app__toggle', {
              'app__toggle--active': openDoc,
            })}
            onClick={() => {
              setStateDoctor(!stateDoctor);
              isOpenDoc(!openDoc);
            }}
          >
            <span className="filter-title-name">
              {t(TranslationKeys.doctor)}
              <span
                style={{
                  marginLeft: '15px',
                  fontSize: '15px',
                  fontWeight: 'lighter',
                  color: 'rgba(45, 45, 45, 0.852)',
                }}
              >
                [
                <span
                  style={{
                    color: 'black',
                  }}
                >
                  {countDoctor}
                </span>{' '}
                / {Object.keys(filterParams.doctors__in.choices).length}]
              </span>
            </span>
            <div
              onClick={() => isOpenDoc(!openDoc)}
              className={openDoc ? 'cross active' : 'cross'}
            />
          </div>
          <Collapse
            isOpen={openDoc}
            className={
              'app__collapse app__collapse--gradient ' + (openDoc ? 'app__collapse--active' : '')
            }
          >
            <div className="app__content">
              {filterParams.doctors__in &&
                choicesDoctor.map((doctor, index) => (
                  <div key={index} className={stateDoctor ? 'filter-param ' : ' filter-param-none'}>
                    <CheckboxInput
                      id={doctor[0]}
                      state={'square'}
                      checked={currentDoctorParams.find((cs) => cs === doctor[0]) === doctor[0]}
                      onChange={() => {
                        setFilterDoctor(doctor[0]);
                      }}
                      name={'status'}
                      value={doctor[0]}
                      label={doctor[1]}
                    />
                  </div>
                ))}
            </div>
          </Collapse>
        </div>
      )}
      <div className="filter-block">
        <div
          className={cx('app__toggle', {
            'app__toggle--active': openInsurance,
          })}
          onClick={() => {
            isOpenInsurance(!openInsurance);
            collapseInsuranceFilter();
          }}
        >
          <span className="filter-title-name">
            {t(TranslationKeys.insurance)}
            <span
              style={{
                marginLeft: '15px',
                fontSize: '15px',
                fontWeight: 'lighter',
                color: 'rgba(45, 45, 45, 0.852)',
              }}
            >
              [
              <span
                style={{
                  color: 'black',
                }}
              >
                {countInsurance}
              </span>{' '}
              / {Object.keys(filterParams.insurance_type__in.choices).length}]
            </span>
          </span>
          <div
            onClick={() => isOpenInsurance(!openInsurance)}
            className={openInsurance ? 'cross active' : 'cross'}
          />
        </div>
        <Collapse
          isOpen={openInsurance}
          className={
            'app__collapse app__collapse--gradient ' +
            (openInsurance ? 'app__collapse--active' : '')
          }
        >
          <div className="app__content">
            {filterParams.insurance_type__in &&
              choicesInsurance.map((insurance, index) => (
                <div className={!openInsurance ? ' filter-param' : 'filter-param-none'} key={index}>
                  <CheckboxInput
                    id={insurance[0][1]}
                    state={'square'}
                    checked={
                      currentInsuranceParams.find((ci) => ci === insurance[0]) === insurance[0]
                    }
                    onChange={() => {
                      setFilterInsurance(insurance[0]);
                    }}
                    name={'insurance_type'}
                    value={insurance[0]}
                    label={insurance[1]}
                  />
                </div>
              ))}
          </div>
        </Collapse>
      </div>
    </form>
  );
};