import React, { FC } from 'react';
import { logo } from '../../../assets/img/image';
import { NavLink } from 'react-router-dom';
import { PATH } from '../../../routes/Routes';
import { useTranslation } from 'react-i18next';
import { OrderType } from '../../../api/order-api';
import cx from 'classnames';
import Collapse from '@kunukn/react-collapse';
import { MenuBtn } from '../../../components/MenuBtn/MenuBtn';
import TranslationKeys from '../../../assets/locales/translationKeys/translationKeys';
import './ActionMenuOrders.scss';

type ActionMenuPropsType = {
  order: OrderType;
  options: boolean;
  onClickOptions: (orderId: number) => void;
  selectedIndex: number;
  setSelectedIndex: (value: number) => void;
  onLoadHandler: () => void;
  load: boolean;
  setOptions: (value: boolean) => void;
  openAlertHandler: (value: number) => void;
  onChangeStatus?: (orderId: number, currentStatus: string) => void;
};

export const ActionMenuOrders: FC<ActionMenuPropsType> = ({
  onClickOptions,
  selectedIndex,
  order,
  options,
  setSelectedIndex,
  onLoadHandler,
  load,
}) => {
  const { t } = useTranslation();

  const onClickOptionHandler = (): void => {
    onClickOptions(order.id);
  };

  return (
    <>
      <div
        onClick={() => {
          onClickOptions(order.id);
          setSelectedIndex(order.id);
        }}
        className={cx('app__toggle', {
          'app__toggle--active': options,
        })}
      >
        <div>
          <MenuBtn
            showSidebar={onClickOptionHandler}
            isOpenMenu={options && selectedIndex === order.id}
          />
        </div>

        <Collapse
          isOpen={options && selectedIndex === order.id}
          className={
            'app__collapse app__collapse--gradient ' +
            (options && selectedIndex === order.id ? 'app__collapse--active' : '')
          }
        >
          <div className="app__content">
            <div className="options">
              <NavLink
                style={{
                  paddingTop: '5px',
                }}
                className={'actions'}
                to={`${PATH.ORDERS}${order.id}`}
              >
                <img alt="edit" onLoad={onLoadHandler} className={load ? 'img' : 'img'} src={logo.edit} />
                {t(TranslationKeys.edit)}
              </NavLink>

              <a className={'actions'} href={order.url}>
                <img alt="download" onLoad={onLoadHandler} className={'img'} src={logo.download} />
                {t(TranslationKeys.order_download)}
              </a>
            </div>
          </div>
        </Collapse>
      </div>
    </>
  );
};