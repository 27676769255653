import React, { FC, useCallback, useEffect, useState } from 'react';
import { motion } from 'framer-motion';
import './EventDetails.scss';
import { useDispatch, useSelector } from 'react-redux';
import {
  getClientsSelector,
  getCurrentEventFromSchedule,
  getDetailsEventsSelector,
  getEventsSelector,
  getIsLoggedInSelector,
  getStatusClientPopupSelector,
} from '../../../redux/selectors';
import { useTranslation } from 'react-i18next';
import { useStatusFilterParams } from '../../../hooks/useStatusFilterParams';
import { DateListEventType } from '../../../api/branches-api';
import { PATH } from '../../../routes/Routes';
import { Navigate, useNavigate, useParams } from 'react-router-dom';
import { EventDetailType } from '../../../api/events-api';
import PreloaderEvent from '../../../components/Preloaders/EventsPreload/PreloadEvents';
import { setPreloader } from '../../../redux/selectors/EventsSelectors';
import TranslationKeys from '../../../assets/locales/translationKeys/translationKeys';
import dayjs from 'dayjs';
import { ButtonClose } from '../../../components/Widgets/ButtonClose/ButtonClose';
import { AddClientPopup } from '../../Clients/AddClientPopup/AddClientPopup';
import {
  changeStateModalWaitingRoomAC,
  setCurrentClientIdAC,
  setCurrentEventScheduleAC,
  setCurrentPathAC,
  setStateClientsPopupAC,
} from '../../../redux/actions';
import {
  changeStatusEventTC,
  fetchAppearedTC,
  fetchEventDetailsTC,
} from '../../../redux/middlewares';

type EventDetailsType = {
  closeEventDetails?: () => void;
  onStatusChangeEventOpenAlert?: (eventId: number, status: string) => void;
  onStatusChangeEvent?: (eventId: number, status: string) => void;
  onFreeDateClick?: (date: string, time: string) => void;
  setCurrentEventFromSchedule?: (event: DateListEventType | EventDetailType | null) => void;
  date?: string;
  setSelectedIndex?: (value: number) => void;
};

export const EventDetails: FC<EventDetailsType> = ({
  closeEventDetails,
  onStatusChangeEventOpenAlert,
  onStatusChangeEvent,
  onFreeDateClick,
  setCurrentEventFromSchedule,
  date,
  setSelectedIndex,
}) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const params = useParams();

  const idUrl = params.id;
  const currentEventId = Number(idUrl);

  //locale
  const { t } = useTranslation();

  const selectedEventFromSchedule = useSelector(getCurrentEventFromSchedule);
  const eventsDetails = useSelector(getDetailsEventsSelector);
  const events = useSelector(getEventsSelector);
  const clients = useSelector(getClientsSelector);
  const preloader = useSelector(setPreloader);
  const isLoggedIn = useSelector(getIsLoggedInSelector);
  const isOpenClientPopup = useSelector(getStatusClientPopupSelector);

  const statusFilterParams = useStatusFilterParams();
  const [openModalWaiting, setModalOpenWaiting] = useState(false);

  const currentEvent = selectedEventFromSchedule;
  const currentClient = eventsDetails?.client;

  const sortEventDetails = eventsDetails?.events?.sort(function (a, b) {
    if (a.start > b.start) {
      return -1;
    }
    if (a.start > b.start) {
      return 1;
    }
    return 0;
  });

  const escFunction = useCallback((event) => {
    if (event.key === 'Escape') {
      setModalOpenWaiting(false);
      navigate(`${PATH.EVENTS}`);
    }
  }, []);
  useEffect(() => {
    if (currentEventId) {
      document.addEventListener('keydown', escFunction, false);
      return () => {
        document.removeEventListener('keydown', escFunction, false);
      };
    }
  }, []);

  useEffect(() => {
    if (isLoggedIn) {
      if (selectedEventFromSchedule) {
        dispatch(fetchEventDetailsTC(selectedEventFromSchedule?.id));
      }
      if (currentEventId) {
        dispatch(fetchEventDetailsTC(currentEventId));
      }
    }
  }, [events, clients]);

  const addEvent = (e: any): void => {
    e.stopPropagation();
    if (currentEventId) {
      dispatch(setCurrentEventScheduleAC(null));
      navigate(`${PATH.ADD_EVENT}`);
    } else {
      setCurrentEventFromSchedule && setCurrentEventFromSchedule(null);
      dispatch(setCurrentPathAC(location.pathname));
      onFreeDateClick && date && onFreeDateClick(date, `${date.slice(11, 14)}00`);
    }
  };

  const editEvent = (eventId: number | undefined): void => {
    navigate(`${PATH.EVENTS}${eventId}/`);
  };

  const onOpenAlertWaiting = (eventId: number, currentStatus: string): void => {
    dispatch(changeStatusEventTC(eventId, currentStatus));
    setModalOpenWaiting(true);

    dispatch(changeStateModalWaitingRoomAC(true));
  };

  const onStatusChangeEventOpenAlertHandler = (eventId: number, status: string): void => {
    if (currentEventId) {
      onOpenAlertWaiting(eventId, status);
    }
    onStatusChangeEventOpenAlert && onStatusChangeEventOpenAlert(eventId, status);
    setSelectedIndex && setSelectedIndex(eventId);
  };

  const onStatusChangeEventHandler = (eventId: number, status: string): void => {
    if (currentEventId) {
      dispatch(changeStatusEventTC(eventId, status));
    }
    onStatusChangeEvent && onStatusChangeEvent(eventId, status);
  };

  const closeEventDetailsHandler = (): void => {
    if (currentEventId) {
      navigate(`${PATH.EVENTS}`);
    } else {
      closeEventDetails && closeEventDetails();
    }
  };

  const setCurrentEventFromScheduleHandler = (
    event: DateListEventType | EventDetailType | null,
  ): void => {
    setCurrentEventFromSchedule && setCurrentEventFromSchedule(event);
  };

  const setNotAppeared = (): void => {
    dispatch(fetchAppearedTC(currentClient.id));
  };

  const openClientPopupEdit = (): void => {
    dispatch(setStateClientsPopupAC('edit'));
    dispatch(setCurrentClientIdAC(currentClient && currentClient?.id));
  };

  const closeClientPopup = (): void => {
    dispatch(setStateClientsPopupAC(null));
  };

  if (openModalWaiting) {
    return <Navigate to={PATH.EVENTS} />;
  }
  if (!isLoggedIn) {
    return <Navigate to={PATH.LOGIN} />;
  }

  return (
    <motion.div className={'events-details-window '} onMouseDown={closeEventDetailsHandler}>
      <motion.div className={'events-details-window__wrap'}>
        <div onClick={closeEventDetailsHandler} className="btn-close-block">
          <ButtonClose onClickBtn={closeEventDetailsHandler} />
        </div>

        <motion.div
          className="events-details-window__wrap__alert"
          onMouseDown={(e) => e.stopPropagation()}
          onClick={(e) => e.stopPropagation()}
        >
          <div className="events-details-window__wrap__alert__event-block">
            <div className="events-details-window__wrap__alert__event-block__header">
              <div className="events-details-window__wrap__alert__event-block__header_time-block">
                {/*{currentEvent?.start?.slice(11, 16)} — {currentEvent?.end?.slice(11, 16)}*/}
                {dayjs(currentEvent?.start).format('HH:mm')} —{' '}
                {dayjs(currentEvent?.end).format('HH:mm')}
              </div>

              <div className="events-details-window__wrap__alert__event-block__header_date-block">
                <div className="events-details-window__wrap__alert__event-block__header_date-block_date">
                  {currentEvent?.start && dayjs(currentEvent?.start).format('ddd, DD MMM')}
                </div>
              </div>
            </div>

            {preloader && (
              <div className="preloader-wrap-client">
                <PreloaderEvent />
              </div>
            )}

            <table className="events-details-window__wrap__alert__event-block__client-block">
              <thead className="events-details-window__wrap__alert__event-block__client-block_title">
                <tr className="tr">
                  <th className="th" colSpan={2}>
                    {currentClient?.first_name} {currentClient?.last_name}
                  </th>
                </tr>
              </thead>

              <tbody className={`${preloader ? 'tbody tbody-loading' : 'tbody'}`}>
                <tr className="tr">
                  <td className="td">
                    <b>{t(TranslationKeys.gender)}:</b> {currentClient?.gender_display}
                  </td>
                  <td className="td">
                    <b>{t(TranslationKeys.phone_number_short)}:</b> {currentClient?.phone}
                  </td>
                </tr>
                <tr className="tr">
                  <td className="td">
                    <b>{t(TranslationKeys.insurance)}:</b> {currentEvent?.insurance_type_display}
                  </td>
                  <td className="td">
                    <b>{t(TranslationKeys.email_short)}:</b> {currentClient?.email}
                  </td>
                </tr>
                <tr className="tr">
                  <td className="td">
                    <b>{t(TranslationKeys.date_birth)}:</b>{' '}
                    {currentClient?.date_birth &&
                      dayjs(currentClient?.date_birth).format('DD MMM YYYY')}
                  </td>
                  {currentClient?.not_appeared !== 0 && (
                    <td className="td">
                      <b>{t(TranslationKeys.client_not_appeared)}:</b> {currentClient?.not_appeared}
                    </td>
                  )}
                </tr>

                <tr className="tr">
                  <td colSpan={2} className="td-btn">
                    <button
                      onClick={setNotAppeared}
                      className="button button_size-small  button_color-delete"
                    >
                      {t(TranslationKeys.client_not_appeared_btn)}
                    </button>

                    <button
                      className="button button_size-small  button_color-green btn-edit"
                      onClick={(e) => {
                        e.stopPropagation();
                        openClientPopupEdit();
                      }}
                    >
                      {t(TranslationKeys.edit)}
                    </button>
                  </td>
                </tr>
              </tbody>
            </table>

            {preloader && (
              <div className="preloader-wrap-event">
                <PreloaderEvent />
              </div>
            )}

            <table className="events-details-window__wrap__alert__event-block__event-data">
              <thead className="events-details-window__wrap__alert__event-block__event-data_title">
                <tr className="tr">
                  <th className="th" colSpan={2}>
                    {t(TranslationKeys.event_data)}
                  </th>
                </tr>
              </thead>
              <tbody className={`${preloader ? 'tbody tbody-loading' : 'tbody'}`}>
                <tr className="tr">
                  <td className="td">
                    <b>{t(TranslationKeys.date)}:</b>{' '}
                    {currentEvent?.start && dayjs(currentEvent?.start).format('DD MMM YYYY')}{' '}
                    {currentEvent?.start && dayjs(currentEvent?.start).format('HH:mm')} -{' '}
                    {currentEvent?.end && dayjs(currentEvent?.end).format('HH:mm')}
                  </td>
                  <td className="td">
                    <b>{t(TranslationKeys.doctor)}:</b> {currentEvent?.doctor?.first_name}{' '}
                    {currentEvent?.doctor?.last_name}
                  </td>
                </tr>
                <tr className="tr">
                  <td className="td">
                    <b>{t(TranslationKeys.reason)}:</b> {currentEvent?.reason_display}
                  </td>
                  <td className="td">
                    <b>{t(TranslationKeys.branch)}:</b> {currentEvent?.branch_display}
                  </td>
                </tr>
                <tr className="tr">
                  <td className="td">
                    <b>{t(TranslationKeys.status)}:</b> {currentEvent?.status_display}
                    {eventsDetails.status === 'waiting_room' && (
                      <span> ({eventsDetails.waiting_time})</span>
                    )}
                    {eventsDetails.status === 'at_the_doctor' && (
                      <span> ({currentEvent?.visit_time})</span>
                    )}
                  </td>
                </tr>
                <tr className="tr">
                  <td colSpan={2} className="td-btn">
                    {eventsDetails?.status === 'waiting_room' && (
                      <span>
                        <button
                          className="button button_size-small  button_color-green2 btn-left"
                          onClick={(e) => {
                            onStatusChangeEventOpenAlertHandler(
                              eventsDetails?.id,
                              statusFilterParams.at_the_doctor,
                            );
                            closeEventDetailsHandler();
                            e.stopPropagation();
                          }}
                        >
                          {t(TranslationKeys.at_the_doctor)}
                        </button>

                        <button
                          className="button button_size-small  button_color-delete2 btn-right"
                          onClick={(e) => {
                            onStatusChangeEventHandler(
                              eventsDetails?.id,
                              statusFilterParams.did_not_wait,
                            );
                            e.stopPropagation();
                          }}
                        >
                          {t(TranslationKeys.did_not_wait)}
                        </button>
                      </span>
                    )}

                    {eventsDetails?.status === 'at_the_doctor' && (
                      <button
                        className="button button_size-small  button_color-green2 btn-one"
                        onClick={(e) => {
                          onStatusChangeEventHandler(eventsDetails?.id, statusFilterParams.done);
                          e.stopPropagation();
                        }}
                      >
                        {t(TranslationKeys.done)}
                      </button>
                    )}

                    {eventsDetails?.status === 'new' && (
                      <button
                        className="button button_size-small  button_color-green2 btn-one"
                        onClick={(e) => {
                          onStatusChangeEventOpenAlertHandler(
                            eventsDetails?.id,
                            statusFilterParams.waiting_room,
                          );
                          closeEventDetailsHandler();
                          e.stopPropagation();
                        }}
                      >
                        {t(TranslationKeys.waiting_header)}
                      </button>
                    )}

                    <button
                      className="button button_size-small  button_color-green btn-edit"
                      onClick={(e) => {
                        e.stopPropagation();
                        editEvent(currentEvent?.id);
                        setCurrentEventFromScheduleHandler(currentEvent);
                      }}
                    >
                      {t(TranslationKeys.edit)}
                    </button>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>

          <div
            className="events-details-window__wrap__alert__client-history"
            style={{ overflow: `${eventsDetails?.events?.length > 2 ? 'scroll' : 'hidden'}` }}
          >
            <h2 className="events-details-window__wrap__alert__client-history_title">
              {t(TranslationKeys.client_history)}
            </h2>
            <table className="table">
              <thead className="thead">
                <tr className="tr">
                  <th className="th">{t(TranslationKeys.branches_nav)}</th>
                  <th className="th">{t(TranslationKeys.reason)}</th>
                  <th className="th">{t(TranslationKeys.doctor)}</th>
                  <th className="th">{t(TranslationKeys.date)}</th>
                  <th className="th">{t(TranslationKeys.status)}</th>
                </tr>
              </thead>

              {preloader && (
                <tbody className="preloader-wrap-history">
                  <tr>
                    <td rowSpan={4} colSpan={4}>
                      <PreloaderEvent />
                    </td>
                  </tr>
                </tbody>
              )}

              <tbody className={`${preloader ? 'tbody tbody-loading' : 'tbody'}`}>
                {sortEventDetails?.map((event) => (
                  <tr key={event.id} className="tr">
                    <td className="td">{event?.branch_display}</td>
                    <td
                      className="td-reason"
                      style={{
                        borderColor: `${event.reason_color}`,
                        background: `${event.reason_color}38`,
                      }}
                    >
                      {event?.reason_display}
                    </td>
                    <td className="td td-doctor">
                      {event?.doctor?.first_name} {event?.doctor?.last_name}
                    </td>
                    <td className="td">{dayjs(event?.start).format('DD MMM YYYY')}</td>
                    <td className="td">{event?.status_display}</td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>

          <button
            className="button button_size-small button_position-right button_color-transparent btn-history"
            onClick={addEvent}
          >
            {t(TranslationKeys.event_btn_Add)}
          </button>

          {isOpenClientPopup && <AddClientPopup closeClientPopup={closeClientPopup} />}
        </motion.div>
      </motion.div>
    </motion.div>
  );
};