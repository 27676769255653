import React, { FC } from 'react';
import { Link, useMatch } from 'react-router-dom';

export type CustomLinkPropsType = {
  to: string;
  className?: string
};

export const CustomLink: FC<CustomLinkPropsType> = ({
  children,
    to,
    className,
    ...restProps
}) => {
  const match = useMatch(to);

  return (
    <Link
      to={to}
      className={match ? `${className} active_link` : `${className}`} {...restProps}
    >
      {children}
    </Link>
  );
};