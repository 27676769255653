import { AppRootStateType } from '../store';
import { ClientFilterParamsType, ClientType } from '../../api/clients-api';

export const getClientsSelector = (state: AppRootStateType): Array<ClientType> =>
  state.clients.clientsData.results;

export const getPreviousClientsSelector = (state: AppRootStateType): string | null =>
  state.clients.clientsData.links.previous;

export const getNextClientsSelector = (state: AppRootStateType): string | null =>
  state.clients.clientsData.links.next;

export const getCurrentPagePaginationClientsSelector = (state: AppRootStateType): number =>
  state.clients.clientsData.current_page;

export const getCurrentClientsSelector = (state: AppRootStateType): number =>
  state.clients.clientsData.current;

export const getCurrentClientsPageSelector = (state: AppRootStateType): string | null =>
  state.clients.currentPage;

export const getFilterParamsClientsSelector = (state: AppRootStateType): ClientFilterParamsType =>
  state.clientsFilter.clientsFilterParamsList;

export const getCurrentInsuranceParamsClientsSelector = (state: AppRootStateType): Array<string> =>
  state.clientsFilter.currentFilterParams.insurance_type__in;

export const getCurrentDoctorsParamsClientsSelector = (state: AppRootStateType): Array<string> =>
  state.clientsFilter.currentFilterParams.doctors__in;

export const isCollapsedInsuranceFilterClientsSelector = (state: AppRootStateType): boolean =>
  state.clientsFilter.isCollapseInsuranceFilter;

export const isCollapsedDoctorFilterClientsSelector = (state: AppRootStateType): boolean =>
  state.clientsFilter.isCollapseDoctorFilter;

export const setPreloader = (state: AppRootStateType): boolean =>
  state.clientsFilter.isActivePreloader;

export const getCurrentClientIdSelector = (state: AppRootStateType): number  | null =>
  state.clients.currentClientId;

export const getCurrentClientSelector = (state: AppRootStateType): ClientType | null =>
  state.clients.currentClient;

export const getStatusClientPopupSelector = (state: AppRootStateType): string | null =>
  state.clients.openClientPopup;

export const getClientFromPopupSelector = (state: AppRootStateType): number | null =>
  state.clients.selectedClientFromPopup;

export const getSortStateByFullNameSelector = (state: AppRootStateType): boolean =>
  state.clientsFilter.currentSortParams?.isActiveFullName;

export const getSortStateByInsuranceSelector = (state: AppRootStateType): boolean =>
  state.clientsFilter.currentSortParams?.isActiveInsurance;

export const getSortStateArrowFullNameSelector = (state: AppRootStateType): boolean =>
  state.clientsFilter.currentSortParams?.isActiveArrowFullName;

export const getSortStateArrowInsuranceSelector = (state: AppRootStateType): boolean =>
  state.clientsFilter.currentSortParams?.isActiveArrowInsurance;

export const getCurrentSortParamSelector = (state: AppRootStateType): string =>
  state.clientsFilter.currentSortParams?.currentSortParam;

export const getCurrClientsQueryStringAppSelector = (state: AppRootStateType): string | null =>
  state.clientsFilter.currentQueryStringClientsList;