import { AppRootStateType } from '../store';
import { OrderFilterParamsType, OrderType } from '../../api/order-api';

export const getOrdersSelector = (state: AppRootStateType): OrderType[] => state.orders.orders.results;

export const getPreviousOrdersSelector = (state: AppRootStateType): string | null =>
  state.orders.orders.links.previous;

export const getNextOrdersSelector = (state: AppRootStateType): string | null =>
  state.orders.orders.links.next;

export const getCurrentPagePaginationOrdersSelector = (state: AppRootStateType): number =>
  state.orders.orders.current_page;

export const getCurrentOrdersSelector = (state: AppRootStateType): number =>
  state.orders.orders.current;

export const getCurrentOrdersPageSelector = (state: AppRootStateType): string | null =>
  state.orders.currentPage;

export const getFilterParamsOrdersSelector = (state: AppRootStateType): OrderFilterParamsType =>
  state.ordersFilter.orderFilterParamsList;

export const getCurrentDoctorParamsOrdersSelector = (state: AppRootStateType): Array<string> =>
  state.ordersFilter.currentFilterParams.doctor__in;

export const getCurrentBranchParamsOrdersSelector = (state: AppRootStateType): Array<string> =>
  state.ordersFilter.currentFilterParams.branch_creator__in;

export const isCollapsedDoctorFilterOrdersSelector = (state: AppRootStateType): boolean =>
  state.ordersFilter.isCollapsedDoctorFilter;

export const setPreloader = (state: AppRootStateType): boolean =>
  state.ordersFilter.isActivePreloader;

export const getCurrQueryStringOrdersSelector = (state: AppRootStateType): string | null =>
  state.ordersFilter.currentQueryStringOrdersList;

