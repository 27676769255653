import { ClientType } from '../../api/clients-api';
import {
  ADD_CLIENT,
  EDIT_CLIENT,
  REMOVE_CLIENT,
  RESET_REDUCER_GROUP,
  SET_APPEARED,
  SET_CLIENT_FROM_POPUP,
  SET_CLIENTS_LIST,
  SET_CLIENTS_PARAMS,
  SET_CURRENT_CLIENT,
  SET_CURRENT_CLIENT_ID,
  SET_CURRENT_PAGE,
  SET_STATE_CLIENT_POPUP,
} from '../actions/constants';
import { ClientsInitialStateType } from './types';
import { ClientsActionsType } from '../actions/types';

const initialState: ClientsInitialStateType = {
  currentPage: null,
  currentClientId: null,
  currentClient: null,
  openClientPopup: null,
  selectedClientFromPopup: null,
  clientsData: {
    links: {
      next: null,
      previous: null,
    },
    count: 0,
    current_page: 0,
    lastPage: 0,
    current: 0,
    results: [] as Array<ClientType>,
  },
};

export const clientsReducer = (
  state: ClientsInitialStateType = initialState,
  action: ClientsActionsType,
): ClientsInitialStateType => {
  switch (action.type) {
    case SET_CLIENTS_PARAMS: {
      return { ...state, clientsData: { ...state.clientsData, ...action.module } };
    }
    case SET_CLIENTS_LIST: {
      return {
        ...state,
        clientsData: {
          ...state.clientsData,
          results: action.clients.map((tl) => ({ ...tl })),
        },
      };
    }
    case ADD_CLIENT: {
      return {
        ...state,
        clientsData: {
          ...state.clientsData,
          results: [action.model, ...state.clientsData.results],
        },
      };
    }
    case EDIT_CLIENT: {
      return {
        ...state,
        clientsData: {
          ...state.clientsData,
          results: state.clientsData.results.map((e) =>
            e.id === action.model.id ? { ...e, ...action.model } : e,
          ),
        },
      };
    }
    case REMOVE_CLIENT: {
      return {
        ...state,
        clientsData: {
          ...state.clientsData,
          results: state.clientsData.results.filter((e) => e.id !== action.id),
        },
      };
    }

    case SET_APPEARED: {
      return {
        ...state,
        clientsData: {
          ...state.clientsData,
          results: state.clientsData.results.map((e) =>
            e.id === action.model.id ? { ...action.model } : e,
          ),
        },
      };
    }

    case SET_CURRENT_PAGE: {
      return {
        ...state,
        currentPage: action.page,
      };
    }

    case SET_CURRENT_CLIENT_ID: {
      return {
        ...state,
        currentClientId: action.clientId,
      };
    }

    case SET_CURRENT_CLIENT: {
      return {
        ...state,
        currentClient: action.client,
      };
    }

    case SET_STATE_CLIENT_POPUP: {
      return {
        ...state,
        openClientPopup: action.isOpen,
      };
    }

    case SET_CLIENT_FROM_POPUP: {
      return {
        ...state,
        selectedClientFromPopup: action.client,
      };
    }

    case RESET_REDUCER_GROUP:
      return { ...initialState };

    default:
      return state;
  }
};