import { HolidayResponseType, HolidaysBranchType } from '../../api/holidays-api';
import {
  ADD_HOLIDAY,
  EDIT_HOLIDAY,
  JOIN_HOLIDAY,
  LEAVE_HOLIDAY,
  REMOVE_HOLIDAY,
  SET_LIST_HOLIDAYS,
} from './constants';
import { PrivateHolidayUpdateModelType } from './types';

export const addHolidayAC = (privateHoliday: HolidayResponseType) =>
  ({ type: ADD_HOLIDAY, privateHoliday } as const);
export const setListHolidaysAC = (holidays: HolidaysBranchType) =>
  ({ type: SET_LIST_HOLIDAYS, holidays } as const);
export const joinHolidaysAC = (officialHoliday: HolidayResponseType) =>
  ({ type: JOIN_HOLIDAY, officialHoliday } as const);
export const leaveHolidaysAC = (holidayId: number) => ({ type: LEAVE_HOLIDAY, holidayId } as const);
export const editHolidayAC = (model: PrivateHolidayUpdateModelType) =>
  ({ type: EDIT_HOLIDAY, model } as const);
export const removeHolidayAC = (holidayId: number) =>
  ({ type: REMOVE_HOLIDAY, holidayId } as const);