import axios from 'axios';
import { loadLang, loadToken } from '../../utils/localStorage';

export const instance = axios.create({
  baseURL: process.env.REACT_APP_BASE_URL,
});

instance.interceptors.request.use((config: any) => {
  config.headers.Authorization = `Bearer ${loadToken()}`;
  config.headers['Accept-Language'] = `${loadLang()}`;
  return config;
});

// export default instance;

export const instanceReg = axios.create({
  baseURL: process.env.REACT_APP_BASE_URL,
});

instanceReg.interceptors.request.use((config: any) => {
  config.headers['Accept-Language'] = `${loadLang()}`;
  return config;
});