import { Dispatch } from 'redux';
import { AppRootStateType } from '../store';
import { eventsAPI } from '../../api/events-api';
import { handleServerAppError, handleServerNetworkError } from '../../utils/error-utils';
import { getObjectFromQueryString } from '../../utils/getObjectFromQueryString';
import { getQueryStringFromObject } from '../../utils/getQueryStringFromObject';
import {
  addCurrentEventFilterDoctorAC,
  addCurrentEventFilterInsuranceAC,
  addCurrentEventFilterReasonAC,
  addCurrentEventFilterReasonColorAC,
  addCurrentEventFilterStatusAC,
  addEventSearchParamAC,
  removeCurrentEventFilterDoctorAC,
  removeCurrentEventFilterInsuranceAC,
  removeCurrentEventFilterReasonAC,
  removeCurrentEventFilterReasonColorAC,
  removeCurrentEventFilterStatusAC,
  setActiveArrowDateEventsAC,
  setActiveArrowFullNameEventsAC,
  setActiveArrowReasonEventsAC,
  setActiveArrowStatusEventsAC,
  setAppStatusAC,
  setCurrEventsQueryStringAC,
  setEventFilterParamsAC, setEventsListAC, setEventsParamsAC,
  setPreloaderEventsFilterAC,
  setSortByDateEventsAC,
  setSortByFullNameEventsAC,
  setSortByReasonEventsAC,
  setSortByStatusEventsAC
} from "../actions";
import { EventsQueryStringType } from './types';

export const fetchFilteredEventsParamsListTC =
  () => async (dispatch: Dispatch<any>, getState: () => AppRootStateType) => {
    const branchId = getState().branches.currentBranch;
    try {
      const res = await eventsAPI.getFilteredParamsList(branchId);
      dispatch(setEventFilterParamsAC(res.data));
    } catch (error: any) {
      if (error.message === 'Network Error') {
        handleServerNetworkError(error, dispatch);
      } else {
        handleServerAppError(error, dispatch);
      }
    }
  };

export const fetchFilteredEventsListOfGeneral =
  (ordering?: string | null, query?: string) =>
  async (dispatch: Dispatch<any>, getState: () => AppRootStateType) => {
    // filter params
    const branch = [getState().branches.currentBranch];
    const insurance_type__in = getState().eventsFilter.currentFilterParams.insurance_type__in;
    const reason__in = getState().eventsFilter.currentFilterParams.reason__in;
    const status__in = getState().eventsFilter.currentFilterParams.status__in;
    const reason__color = getState().eventsFilter.currentFilterParams.reason__color;
    const doctor__in = getState().eventsFilter.currentFilterParams.doctor__in;
    const search = getState().eventsFilter.currentFilterParams.search;

    // sort params
    const currentSortParam = getState().eventsFilter.currentSortParams.currentSortParam;
    const isActiveFullName = getState().eventsFilter.currentSortParams.isActiveFullName;
    const isActiveReason = getState().eventsFilter.currentSortParams.isActiveReason;
    const isActiveStatus = getState().eventsFilter.currentSortParams.isActiveStatus;
    const isActiveDate = getState().eventsFilter.currentSortParams.isActiveDate;

    // query string
    const currentQueryParams = getState().eventsFilter.currentQueryStringEventsList;
    const branchFromQueryString = getObjectFromQueryString(currentQueryParams)?.branch;
    const updatedCurrentQueryParams = getObjectFromQueryString(currentQueryParams);

    if (branchFromQueryString !== branch[0]) {
      updatedCurrentQueryParams.branch = `${branch[0]}`;
    }
    const updatedQueryString = getQueryStringFromObject(updatedCurrentQueryParams);

    const newQueryParams = getQueryStringFromObject({
      branch,
      insurance_type__in,
      reason__in,
      status__in,
      reason__color,
      doctor__in,
      ordering,
      search,
    } as unknown as EventsQueryStringType);

    const queryParams = query ? updatedQueryString : newQueryParams;

    dispatch(setAppStatusAC('loading'));
    await dispatch(setPreloaderEventsFilterAC(true));
    try {
      const res = await eventsAPI.getFilteredListOfGeneral(queryParams);

      if (currentSortParam === 'client__first_name' || currentSortParam === '-client__first_name') {
        dispatch(setActiveArrowFullNameEventsAC(true));
        dispatch(setActiveArrowReasonEventsAC(false));
        dispatch(setActiveArrowStatusEventsAC(false));
        dispatch(setActiveArrowDateEventsAC(false));
        dispatch(setSortByFullNameEventsAC(!isActiveFullName));
      }

      if (currentSortParam === 'reason' || currentSortParam === '-reason') {
        dispatch(setActiveArrowFullNameEventsAC(false));
        dispatch(setActiveArrowReasonEventsAC(true));
        dispatch(setActiveArrowStatusEventsAC(false));
        dispatch(setActiveArrowDateEventsAC(false));
        dispatch(setSortByReasonEventsAC(!isActiveReason));
      }

      if (currentSortParam === 'start' || currentSortParam === '-start') {
        dispatch(setActiveArrowFullNameEventsAC(false));
        dispatch(setActiveArrowReasonEventsAC(false));
        dispatch(setActiveArrowStatusEventsAC(false));
        dispatch(setActiveArrowDateEventsAC(true));
        dispatch(setSortByDateEventsAC(!isActiveDate));
      }

      if (currentSortParam === 'status' || currentSortParam === '-status') {
        dispatch(setActiveArrowFullNameEventsAC(false));
        dispatch(setActiveArrowReasonEventsAC(false));
        dispatch(setActiveArrowStatusEventsAC(true));
        dispatch(setActiveArrowDateEventsAC(false));
        dispatch(setSortByStatusEventsAC(!isActiveStatus));
      }

      dispatch(setEventsParamsAC(res.data));
      dispatch(setEventsListAC(res.data.results));
      dispatch(setPreloaderEventsFilterAC(false));
      dispatch(setCurrEventsQueryStringAC(queryParams));
      dispatch(setAppStatusAC('succeeded'));
    } catch (error: any) {
      if (error.message === 'Network Error') {
        handleServerNetworkError(error, dispatch);
      } else {
        handleServerAppError(error, dispatch);
      }
      await dispatch(setPreloaderEventsFilterAC(false));
    }
    dispatch(setAppStatusAC('idle'));
  };

export const setFilteredEventsOfInsuranceTC =
  (currentInsurance: string) =>
  async (dispatch: Dispatch<any>, getState: () => AppRootStateType) => {
    const currentInsuranceChecked =
      getState().eventsFilter.currentFilterParams.insurance_type__in.find(
        (insurance) => insurance === currentInsurance,
      );
    try {
      if (currentInsuranceChecked) {
        dispatch(removeCurrentEventFilterInsuranceAC(currentInsurance));
      } else {
        dispatch(addCurrentEventFilterInsuranceAC(currentInsurance));
      }
      dispatch(fetchFilteredEventsListOfGeneral());
    } catch (error: any) {
      if (error.message === 'Network Error') {
        handleServerNetworkError(error, dispatch);
      } else {
        handleServerAppError(error, dispatch);
      }
    }
  };

export const setFilteredEventsOfReasonTC =
  (currentReason: string) => async (dispatch: Dispatch<any>, getState: () => AppRootStateType) => {
    const currentReasonChecked = getState().eventsFilter.currentFilterParams.reason__in.find(
      (reason) => reason === currentReason,
    );
    try {
      if (currentReasonChecked) {
        dispatch(removeCurrentEventFilterReasonAC(currentReason));
        dispatch(removeCurrentEventFilterReasonColorAC(currentReason));
      } else {
        dispatch(addCurrentEventFilterReasonAC(currentReason));
        dispatch(addCurrentEventFilterReasonColorAC(currentReason));
      }
      dispatch(fetchFilteredEventsListOfGeneral());
    } catch (error: any) {
      if (error.message === 'Network Error') {
        handleServerNetworkError(error, dispatch);
      } else {
        handleServerAppError(error, dispatch);
      }
    }
  };

export const setFilteredEventsOfStatusTC =
  (currentStatus: string) => async (dispatch: Dispatch<any>, getState: () => AppRootStateType) => {
    const currentReasonChecked = getState().eventsFilter.currentFilterParams.status__in.find(
      (status) => status === currentStatus,
    );

    try {
      if (currentReasonChecked) {
        dispatch(removeCurrentEventFilterStatusAC(currentStatus));
      } else {
        dispatch(addCurrentEventFilterStatusAC(currentStatus));
      }
      dispatch(fetchFilteredEventsListOfGeneral());
    } catch (error: any) {
      if (error.message === 'Network Error') {
        handleServerNetworkError(error, dispatch);
      } else {
        handleServerAppError(error, dispatch);
      }
    }
  };

export const setFilteredEventsOfDoctorTC =
  (currentDoctor: string) => async (dispatch: Dispatch<any>, getState: () => AppRootStateType) => {
    const currentDoctorChecked = getState().eventsFilter.currentFilterParams.doctor__in.find(
      (status) => status === currentDoctor,
    );

    try {
      if (currentDoctorChecked) {
        dispatch(removeCurrentEventFilterDoctorAC(currentDoctor));
      } else {
        dispatch(addCurrentEventFilterDoctorAC(currentDoctor));
      }
      dispatch(fetchFilteredEventsListOfGeneral());
    } catch (error: any) {
      if (error.message === 'Network Error') {
        handleServerNetworkError(error, dispatch);
      } else {
        handleServerAppError(error, dispatch);
      }
    }
  };

export const setFilteredEventsSearchTC = (search: string) => async (dispatch: Dispatch<any>) => {
  try {
    dispatch(addEventSearchParamAC(search));
    dispatch(fetchFilteredEventsListOfGeneral());
  } catch (error: any) {
    if (error.message === 'Network Error') {
      handleServerNetworkError(error, dispatch);
    } else {
      handleServerAppError(error, dispatch);
    }
  }
};