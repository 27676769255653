import React, { FC } from 'react';
import './ArrowCollapse.scss';

type ArrowCollapsePropsType = {
  changeStateArrow: () => void;
  stateArrow: boolean;
};

export const ArrowCollapse: FC<ArrowCollapsePropsType> = ({ changeStateArrow, stateArrow }) => {
  return (
    <div onClick={changeStateArrow} className="wrap">
      <span className={stateArrow ? 'arrow' : 'arrow active'}>
        <span></span>
        <span></span>
      </span>
    </div>
  );
};
