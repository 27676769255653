import React, { FC, useEffect, useRef, useState } from 'react';
import { Controller } from 'react-hook-form';
import DatePicker from 'react-datepicker';
import InputsWidgets from './InputsWidgets';
import './ReactDateTimePicker.scss';
import dayjs from 'dayjs';

type ReactSelectType1 = {
  id?: string;
  error?: string | null;
  help_text?: string | null;
  label?: string;
  placeholder?: string;
  onChange?: () => void;
  onFocus?: () => void;
  onInputClick?: () => void;
  control: any;
  onClick?: () => void;
  name: string;
  defaultValue?: any;
  onChangeText?: (value: string) => any;
  selectedDate: any;
  register?: any;
  input_type?: any;
  maxLenght?: any;
};

export const ReactDateTimePicker: FC<ReactSelectType1> = ({
  control,
  onClick,
  error,
  defaultValue,
  help_text,
  label,
  name,
}) => {
  const componentRef = useRef();

  const [calendarIsOpen, setCalendarIsOpen] = useState(false);

  useEffect(() => {
    const handleClick = (e: any): void => {
      if (componentRef && componentRef.current) {
        const ref: any = componentRef.current;
        if (!ref.contains(e.target)) {
          setCalendarIsOpen(false);
        }
      }
    };

    document.addEventListener('click', handleClick);
    return () => document.removeEventListener('click', handleClick);
  }, []);

  const onChangeHandler = (value: string, onChange: (...event: any[]) => void): void => {
    let date = new Date(value);
    if (dayjs(date).isValid() && date.getFullYear() >= 1940) {
      onChange(date);
    } else {
    }
  };

  return (
    <div ref={componentRef as any} className="dateTimePicker">
      <div onClick={() => setCalendarIsOpen(!calendarIsOpen)}>
        <Controller
          name={name}
          control={control}
          render={({ field: { value, onChange } }) => (
            <div>
              <DatePicker
                name={name}
                id={`id_${name}`}
                onChange={onChange}
                selected={value || defaultValue}
                calendarStartDay={1}
                showTimeSelect
                timeIntervals={15}
                dateFormat={`yyyy-MM-dd'T'HH:mm:ss`}
                popperPlacement="bottom-end"
                timeFormat="HH:mm"
                timeCaption="time"
                customInput={
                  <InputsWidgets
                    state="datePicker"
                    error={error}
                    help_text={help_text}
                    max="2999-12-31T23:59:59"
                    min="1940-01-01T00:00:00"
                    input_type={'datetime-local'}
                    name={name}
                    defaultValue={dayjs(defaultValue).format('YYYY-MM-DDTHH:mm')}
                    onInputClick={onClick}
                    onChangeText={(text) => onChangeHandler(text, onChange)}
                    label={label}
                  />
                }
              />
            </div>
          )}
        />
      </div>
    </div>
  );
};
