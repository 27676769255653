import { ContactsType } from '../../api/contacts-api';
import {
  ADD_CONTACTS,
  EDIT_CONTACTS,
  REMOVE_CONTACTS,
  RESET_REDUCER_GROUP,
  SET_LIST_CONTACTS,
} from '../actions/constants';
import { ContactsActionsType } from '../actions/types';

const initialState: Array<ContactsType> = [];

export const contactsReducer = (
  state: Array<ContactsType> = initialState,
  action: ContactsActionsType,
): Array<ContactsType> => {
  switch (action.type) {
    case SET_LIST_CONTACTS: {
      return action.contacts.map((tl) => ({ ...tl }));
    }
    case ADD_CONTACTS: {
      return [...state, action.model];
    }
    case EDIT_CONTACTS: {
      return state.map((e) => (e.id === action.model.id ? { ...e, ...action.model } : e));
    }
    case REMOVE_CONTACTS: {
      return state.filter((e) => e.id !== action.id);
    }

    case RESET_REDUCER_GROUP:
      return { ...initialState };

    default:
      return state;
  }
};