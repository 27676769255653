import React, { FC, memo, useEffect, useState } from 'react';
import { logo } from '../../../assets/img/image';
import { ConfirmationPopup } from '../../../components/Popups/ConfirmationPopup/ConfirmationPopup';
import { Pagination } from '../../../components/ComponentsForLists/Pagination/Pagination';
import { useTranslation } from 'react-i18next';
import { EventDetailType, EventType, StatusParamsType } from '../../../api/events-api';
import { useSelector } from 'react-redux';
import { ActionMenuEvents } from './ActionMenuEvents/ActionMenuEvents';
import { setPreloader } from '../../../redux/selectors/EventsSelectors';
import PreloaderEvent from '../../../components/Preloaders/EventsPreload/PreloadEvents';
import Preloader from '../../../components/Preloaders/Preloader';
import dayjs from 'dayjs';
import { useLocation } from 'react-router-dom';
import { PATH } from '../../../routes/Routes';
import TranslationKeys from '../../../assets/locales/translationKeys/translationKeys';
import { SortByTitle } from '../../../components/ComponentsForLists/SortByTitle/SortByTitle';
import { DateListEventType } from '../../../api/branches-api';

type EventsListPropsType = {
  events: EventType[];
  onClickOptions: (eventId: number) => void;
  options: boolean;
  isActiveFullName: boolean;
  isActiveStatus: boolean;
  isActiveDate: boolean;
  isActiveReason: boolean;
  isActiveArrowFullName: boolean;
  isActiveArrowReason: boolean;
  isActiveArrowStatus: boolean;
  isActiveArrowDate: boolean;
  selectedIndex: number | null;
  setSelectedIndex: (value: number) => void;
  onChangeStatus: (eventId: number, currentStatus: string) => void;
  statusFilterParams: StatusParamsType;
  openModalAlertWaitingRoom: () => void;
  openAlertOnCancel: (eventsId: number) => void;
  openAlertDialogForCancel: boolean;
  modalDataId: number;
  closeModalAlert: () => void;
  cancelEvent: (id: number) => void;
  next: string | null;
  previous: string | null;
  current: number;
  currentPagePagination: number;
  prevList: () => void;
  nextList: () => void;
  toFirstList: () => void;
  toLastList: () => void;
  sortListByReasonCallback: (ordering: string) => void;
  sortListByFullNameCallback: (ordering: string) => void;
  sortListByStatusCallback: (ordering: string) => void;
  sortListByDateCallback: (ordering: string) => void;
  setCurrentPathForRedirect?: (event: DateListEventType | EventDetailType | null) => void;
};

export const EventsList: FC<EventsListPropsType> = memo(
  ({
    events,
    onClickOptions,
    options,
    selectedIndex,
    setSelectedIndex,
    onChangeStatus,
    statusFilterParams,
    openModalAlertWaitingRoom,
    openAlertOnCancel,
    openAlertDialogForCancel,
    modalDataId,
    closeModalAlert,
    cancelEvent,
    next,
    previous,
    current,
    currentPagePagination,
    prevList,
    nextList,
    toFirstList,
    toLastList,
    isActiveReason,
    isActiveFullName,
    isActiveStatus,
    isActiveDate,
    sortListByFullNameCallback,
    sortListByReasonCallback,
    sortListByStatusCallback,
    sortListByDateCallback,
    isActiveArrowFullName,
    isActiveArrowReason,
    isActiveArrowStatus,
    isActiveArrowDate,
    setCurrentPathForRedirect,
  }) => {
    const preloader = useSelector(setPreloader);
    const location = useLocation();
    const { t } = useTranslation();
    const [load, setLoad] = useState(false);

    useEffect(() => {
      const imgEdit = new Image();
      imgEdit.src = logo.cancel;
      imgEdit.onload = () => {
        setLoad(true);
      };
      const img = new Image();
      img.src = logo.edit;
      img.onload = () => {
        setLoad(true);
      };
      const imgInfo = new Image();
      imgInfo.src = logo.info;
      imgInfo.onload = () => {
        setLoad(true);
      };
      const imgWait = new Image();
      imgWait.src = logo.waiting;
      imgWait.onload = () => {
        setLoad(true);
      };
      const imgClose = new Image();
      imgClose.src = logo.close;
      imgClose.onload = () => {
        setLoad(true);
      };
      const imgCheck = new Image();
      imgCheck.src = logo.check;
      imgCheck.onload = () => {
        setLoad(true);
      };
    });

    const onLoadHandler = (): void => {
      setLoad(true);
    };

    return (
      <>
        <div>
          <table className={'events__table'}>
            <thead className="thead">
              <tr className="tr">
                <th className="th">
                  <SortByTitle
                    isActiveSortTitle={isActiveFullName}
                    isActiveArrow={isActiveArrowFullName}
                    sortListCallback={sortListByFullNameCallback}
                    sortParam="client__first_name"
                    title={TranslationKeys.full_name}
                  />
                </th>
                <th className="th">
                  <SortByTitle
                    isActiveSortTitle={isActiveReason}
                    isActiveArrow={isActiveArrowReason}
                    sortListCallback={sortListByReasonCallback}
                    sortParam="reason"
                    title={TranslationKeys.reason}
                  />
                </th>
                <th className="th">
                  <SortByTitle
                    isActiveSortTitle={isActiveDate}
                    isActiveArrow={isActiveArrowDate}
                    sortListCallback={sortListByDateCallback}
                    sortParam="start"
                    title={TranslationKeys.date}
                  />
                </th>
                <th className="th">
                  <SortByTitle
                    isActiveSortTitle={isActiveStatus}
                    isActiveArrow={isActiveArrowStatus}
                    sortListCallback={sortListByStatusCallback}
                    sortParam="status"
                    title={TranslationKeys.status}
                  />
                </th>
                <th className="th-options">
                  <span className={'th-name-light'}>{t(TranslationKeys.options)}</span>
                </th>
              </tr>
            </thead>

            {events.length === 0 && preloader
              ? location.pathname === PATH.EVENTS && (
                  <tbody>
                    <tr>
                      <td>
                        <Preloader />
                      </td>
                    </tr>
                  </tbody>
                )
              : location.pathname === PATH.EVENTS &&
                preloader && (
                  <tbody>
                    <tr>
                      <td>
                        <PreloaderEvent />
                      </td>
                    </tr>
                  </tbody>
                )}

            <tbody className="tbody">
              {events.length > 0 &&
                events.map((event) => {
                  return (
                    <tr key={event.id} className="tr">
                      <td className="td-name">
                        <span
                          style={{
                            fontWeight: 'bold',
                          }}
                        >
                          {event?.client?.first_name} {event?.client?.last_name}
                        </span>
                        <br />
                        <span
                          style={{
                            fontSize: '12px',
                          }}
                        >
                          #{event.id}
                        </span>
                      </td>
                      <td
                        style={{
                          backgroundColor: `${event.reason_color}38`,
                          borderColor: `${event.reason_color}`,
                        }}
                        className="td-color"
                      >
                        <span
                          style={{
                            opacity: 1,
                          }}
                          className="td"
                        >
                          {event.reason_display}
                        </span>
                      </td>
                      <td className="td date-event">
                        <span>{dayjs(event?.start).format('DD MMM. YYYY')}</span>

                        <b className="time">
                          {dayjs(event?.start).format('HH:mm')} -{' '}
                          {dayjs(event?.end).format('HH:mm')}
                        </b>
                      </td>
                      <td className="td">{event.status_display} </td>

                      <td
                        onClick={(e) => {
                          e.stopPropagation();
                          setSelectedIndex(event.id);
                          onClickOptions(event.id);
                        }}
                        className=" td-button-group"
                      >
                        <ActionMenuEvents
                          event={event}
                          options={options}
                          onClickOptions={onClickOptions}
                          selectedIndex={selectedIndex}
                          setSelectedIndex={setSelectedIndex}
                          onLoadHandler={onLoadHandler}
                          load={load}
                          onChangeStatus={onChangeStatus}
                          statusFilterParams={statusFilterParams}
                          openModalAlertWaitingRoom={openModalAlertWaitingRoom}
                          openAlertOnCancel={openAlertOnCancel}
                          setCurrentPathForRedirect={setCurrentPathForRedirect}
                        />
                      </td>
                    </tr>
                  );
                })}

              {openAlertDialogForCancel && (
                <>
                  <ConfirmationPopup
                    actionCallback={cancelEvent}
                    id={modalDataId}
                    titleQuestion={t(TranslationKeys.event_cancel)}
                    actionTitle={t(TranslationKeys.yes)}
                    onClickBtn={closeModalAlert}
                    cancelCallback={closeModalAlert}
                  />
                </>
              )}
            </tbody>
            {/*   )} */}
          </table>
          {(next !== null || previous !== null) && (
            <Pagination
              previous={previous}
              next={next}
              current={current}
              currentPage={currentPagePagination}
              previousCallback={prevList}
              nextCallback={nextList}
              toFirstList={toFirstList}
              toLastList={toLastList}
            />
          )}
        </div>
      </>
    );
  },
);