import React, { FC } from 'react';
import { logo } from '../../../../assets/img/image';
import i18next from 'i18next';
import { useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';
import TranslationKeys from '../../../../assets/locales/translationKeys/translationKeys';
import { saveLangLS } from '../../../../utils/localStorage';
import { editStateMenuAC } from "../../../../redux/actions";

type LanguageMenuPropsType = {
  setOpenMenuLang: (value: boolean) => void;
  openMenuLang: boolean;
};

export const LanguageMenu: FC<LanguageMenuPropsType> = ({ setOpenMenuLang, openMenuLang }) => {
  const dispatch = useDispatch();
  const { t } = useTranslation();

  return (
    <>
      <span className="title">{t(TranslationKeys.language)}:</span>
      <div
        onClick={(e) => {
          e.stopPropagation();
          setOpenMenuLang(!openMenuLang);
          dispatch(editStateMenuAC(false));
        }}
        className='lang'
      >
        {i18next.language === 'en' ? (
          <img className='lang__img' alt='language' src={logo.en} />
        ) : (
          <img className='lang__img' alt='language' src={logo.de} />
        )}

        {openMenuLang ? (
          <img src={logo.arrow_up} alt='up' className='lang__arrow' />
        ) : (
          <img src={logo.arrow_down} alt='down' className='lang__arrow' />
        )}

        <div
          style={{
            paddingTop: '10px',
          }}
          className={openMenuLang ? 'lang__menu' : 'lang__menuNone'}
        >
          <div
            onClick={() => {
              saveLangLS('de');
              i18next.changeLanguage('de');
            }}
            style={{
              paddingBottom: '15px',
            }}
            className='lang__menu-info'
          >
            <img className='de' src={logo.de} />
            {t(TranslationKeys.de)}
          </div>
          <div
            onClick={() => {
              saveLangLS('en');
              i18next.changeLanguage('en');
            }}
            className='lang__menu-info'
          >
            <img className='en' src={logo.en} />
            {t(TranslationKeys.en)}
          </div>
        </div>
      </div>
    </>
  );
};