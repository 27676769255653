import {
  EDIT_STATE_MENU,
  EDIT_STATE_SIDEBAR,
  SET_CURRENT_PATH,
  SET_ERROR,
  SET_INITIALIZED,
  SET_STATUS,
} from './constants';
import { RequestStatusType } from "../reducers/types";

export const setAppIsInitializedAC = (isInitialized: boolean) =>
  ({ type: SET_INITIALIZED, isInitialized } as const);

export const setAppErrorAC = (error: string) => ({ type: SET_ERROR, error } as const);

export const setAppStatusAC = (status: RequestStatusType) =>
  ({ type: SET_STATUS, status } as const);

export const editStateMenuAC = (openMenu: boolean) =>
  ({ type: EDIT_STATE_MENU, openMenu } as const);

export const editStateSidebarAC = () => ({ type: EDIT_STATE_SIDEBAR } as const);

export const setCurrentPathAC = (path: string | null) =>
  ({ type: SET_CURRENT_PATH, path } as const);