import { AppRootStateType } from '../store';
import {
  BranchesListFieldsType,
  ClientsListFieldsType,
  ContactsListFieldsType,
  EmployeesListFieldsType,
  EventsListFieldsType,
  HolidayListFieldsType,
  OrderListFieldsType,
  ReasonsListFieldsType,
  UserListFieldsType,
  WorkScheduleListFieldsType,
} from '../../api/fields-api';

export const getFieldsEventsSelector = (state: AppRootStateType): EventsListFieldsType =>
  state.fields.eventsFields;

export const getFieldsBranchesSelector = (state: AppRootStateType): BranchesListFieldsType =>
  state.fields.branchesFields;

export const getFieldsClientsSelector = (state: AppRootStateType): ClientsListFieldsType =>
  state.fields.clientsFields;

export const getFieldsOrdersSelector = (state: AppRootStateType): OrderListFieldsType =>
  state.fields.orderFields;

export const getFieldsEmployeesSelector = (state: AppRootStateType): EmployeesListFieldsType =>
  state.fields.employeesFields;

export const getFieldsReasonsSelector = (state: AppRootStateType): ReasonsListFieldsType =>
  state.fields.reasonsFields;

export const getFieldsWorkScheduleSelector = (
  state: AppRootStateType,
): WorkScheduleListFieldsType => state.fields.workScheduleFields;

export const getFieldsHolidaysSelector = (state: AppRootStateType): HolidayListFieldsType =>
  state.fields.holidayFields;

export const getFieldsContactsSelector = (state: AppRootStateType): ContactsListFieldsType =>
  state.fields.contactsFields;

export const getFieldsUserRegisterSelector = (state: AppRootStateType): UserListFieldsType =>
  state.fields.userFields;
