import React, { FC, memo, useCallback, useEffect, useState } from 'react';

import './Schedule.scss';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { Navigate, useLocation, useNavigate } from 'react-router-dom';

import { DateListEventType, DateOfListType } from '../../api/branches-api';
import { logo } from '../../assets/img/image';
import { CalendarGeneral } from '../../components/Calendar/Calendar';
import { ModalAlertEvents } from '../../components/Popups/ModalAlertEvents/ModalAlertEvents';
import { ModalAlertWaiting } from '../../components/Popups/WaitingRoom/ModalAlertWaitingRoom';
import {
  getActiveWeekSelector,
  getCurrentBranchSelector,
  getEndTimeWorkDayDayDateSelector,
  getEventsSelector,
  getIsLoggedInSelector,
  getOpenSidebarAppSelector,
  getScheduleBranchDateSelector,
  getScheduleBranchSelector,
  getSelectedEventSelector,
  getStartTimeWorkDayDateSelector,
  getStataCalendarSelector,
  getStatusSelector,
} from '../../redux/selectors';
import { calcScheduleGrid } from '../../utils/schedule/calcScheduleGrid';
import { calcScheduleGridSmall } from '../../utils/schedule/calcScheduleGridSmall';
import { PATH } from '../../routes/Routes';

import { DayOfTheWeek } from './DayOfTheWeek/DayOfTheWeek';
import { DayOfTheWeekSmall } from './DayOfTheWeekSmall/DayOfTheWeekSmall';
import { EventDetails } from '../Events/EventDetails/EventDetails';
import { EventDetailType } from '../../api/events-api';
import PreloaderEvent from '../../components/Preloaders/EventsPreload/PreloadEvents';
import Preloader from '../../components/Preloaders/Preloader';
import dayjs from 'dayjs';
import TranslationKeys from '../../assets/locales/translationKeys/translationKeys';
import { ArrowCollapse } from '../../components/ArrowСollapse/ArrowCollapse';
import {
  changeStatusEventTC,
  fetchBranchesListFirstRender,
  getScheduleBranchActiveWeekTC,
  getScheduleBranchChosenCalendarWeekTC,
  getScheduleBranchNextWeekTC,
  getScheduleBranchPrevWeekTC,
  getScheduleBranchTC,
  initializeAppTC,
} from '../../redux/middlewares';
import {
  changeStateCalendarAC,
  setAppStatusAC,
  setChosenDayInScheduleAC,
  setCurrentEventScheduleAC,
  setCurrentPathAC,
  setSelectedEventAC,
} from '../../redux/actions';

type ScheduleType = {};

export const Schedule: FC<ScheduleType> = memo(() => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const location = useLocation();

  const status = useSelector(getStatusSelector);
  const isLoggedIn = useSelector(getIsLoggedInSelector);
  const scheduleBranch = useSelector(getScheduleBranchSelector);
  const scheduleBranchDate = useSelector(getScheduleBranchDateSelector);
  const currentBranch = useSelector(getCurrentBranchSelector);
  const activeWeek = useSelector(getActiveWeekSelector);
  const startTimeWorkDay = useSelector(getStartTimeWorkDayDateSelector);
  const endTimeWorkDay = useSelector(getEndTimeWorkDayDayDateSelector);
  const events = useSelector(getEventsSelector);
  const stateCalendar = useSelector(getStataCalendarSelector);
  const isOpenSidebar = useSelector(getOpenSidebarAppSelector);
  const selectedIndex = useSelector(getSelectedEventSelector);

  const [openAlertDialogEvents, setOpenAlertDialogEvents] = useState(false);
  const [modalDateEvents, setModalDateEvents] = useState<string>('');
  const [modalEventsData, setModalEventsData] = useState<Array<DateListEventType>>([]);
  const [openCalendarGeneral, setOpenCalendarGeneral] = useState<boolean>(false);
  const [showDetails, setShowDetails] = useState<boolean>(false);

  const [openModalWaiting, setModalOpenWaiting] = useState(false);

  const firstEl = 0;
  const lengthArray = 1;

  // locale
  const { t } = useTranslation();

  useEffect(() => {
    if (status === 'failed') {
      dispatch(initializeAppTC());
    }
  }, [status]);

  const escFunction = useCallback((event) => {
    if (event.key === 'Escape') {
      setModalOpenWaiting(false);
      setOpenCalendarGeneral(false);
      setOpenAlertDialogEvents(false);
      setShowDetails(false);
    }
  }, []);
  useEffect(() => {
    document.title = t(TranslationKeys.shedule_name);
  }, []);

  useEffect(() => {
    document.addEventListener('keydown', escFunction, false);
    return () => {
      document.removeEventListener('keydown', escFunction, false);
    };
  }, []);

  useEffect(() => {
    dispatch(initializeAppTC());
    if (isLoggedIn) {
      if (!activeWeek) {
        dispatch(fetchBranchesListFirstRender());
      } else {
        dispatch(getScheduleBranchActiveWeekTC(currentBranch));
      }
    }
    return () => {
      dispatch(setAppStatusAC('idle'));
    };
  }, [dispatch, events]);

  useEffect(() => {
    const interval = 60000;
    const intervalId = setInterval(() => {
      if (isLoggedIn) {
        if (activeWeek) {
          dispatch(getScheduleBranchActiveWeekTC(currentBranch));
        } else {
          dispatch(getScheduleBranchTC(currentBranch));
        }
      }
    }, interval);
    return () => clearInterval(intervalId);
  }, [currentBranch]);

  const nextWeek = (branchId: number): void => {
    dispatch(getScheduleBranchNextWeekTC(branchId));
  };

  const prevWeek = (branchId: number): void => {
    dispatch(getScheduleBranchPrevWeekTC(branchId));
  };

  const setChosenWeekInCalendar = useCallback((branchId: number, week: string) => {
    dispatch(getScheduleBranchChosenCalendarWeekTC(branchId, week));
  }, []);

  const closeAlertHandler = useCallback(() => {
    setOpenAlertDialogEvents(false);
  }, []);

  const openEventDetails = (): void => {
    setShowDetails(true);
  };

  const closeEventDetails = (): void => {
    setShowDetails(false);
  };

  const setDateForModalAlert = useCallback((time: string, dateOfLists: DateOfListType) => {
    const startSlice = 11;
    const endSlice = 13;
    const selectedHour = dateOfLists.events.filter(
      (event) => event.start.slice(startSlice, endSlice) === time.slice(startSlice, endSlice),
    );

    dispatch(setCurrentPathAC(location.pathname));
    setModalDateEvents(time);

    const openAlertHandlerCancel = (): void => {
      setOpenAlertDialogEvents(true);
    };

    if (selectedHour && selectedHour.length === lengthArray) {
      openEventDetails();
    }

    if (selectedHour && selectedHour.length > lengthArray) {
      setModalEventsData(selectedHour);
      openAlertHandlerCancel();
    }
  }, []);

  const onFreeDateClick = useCallback((date: string | undefined, time: string) => {
    dispatch(setChosenDayInScheduleAC({ date, time }));
    dispatch(setCurrentPathAC(location.pathname));
    navigate(PATH.ADD_EVENT);
  }, []);

  const calcGrid = calcScheduleGrid(startTimeWorkDay, endTimeWorkDay);
  const calcGridShort = calcScheduleGridSmall(startTimeWorkDay, endTimeWorkDay);

  const onStatusChangeEventOpenAlert = (eventId: number, currentStatus: string): void => {
    dispatch(changeStatusEventTC(eventId, currentStatus));
    setModalOpenWaiting(true);
  };

  const onStatusChangeEvent = (eventId: number, currentStatus: string): void => {
    dispatch(changeStatusEventTC(eventId, currentStatus));
  };

  const onStatusSchedule = (): void => {
    dispatch(changeStateCalendarAC(!stateCalendar));
  };

  const setCurrentEventFromSchedule = (event: DateListEventType | EventDetailType | null): void => {
    dispatch(setCurrentEventScheduleAC(event));
  };

  const setSelectedIndex = (eventId: number | null): void => {
    dispatch(setSelectedEventAC(eventId));
  };

  if (!isLoggedIn) {
    return <Navigate to={PATH.LOGIN} />;
  }

  return (
    <div className={`${isOpenSidebar ? 'schedule' : 'schedule full-width'}`}>
      <div className="schedule__title-wrapper">
        <h1 className="title">{t(TranslationKeys.shedule_name)}</h1>
        <ArrowCollapse changeStateArrow={onStatusSchedule} stateArrow={stateCalendar} />
      </div>

      <div className="header">
        <div className="nav">
          <span className="nav-current">
            {scheduleBranchDate[firstEl]?.date
              ? dayjs(scheduleBranchDate[firstEl] && scheduleBranchDate[firstEl]?.date).format(
                  'MMM YYYY',
                )
              : ''}
          </span>
          <div
            role="presentation"
            onClick={() => {
              setOpenCalendarGeneral(!openCalendarGeneral);
            }}
            className="nav-week"
          >
            {scheduleBranchDate[firstEl]?.date && (
              <img src={logo.calendar} alt="calendar" className="calendar-img" />
            )}
            {scheduleBranchDate[firstEl]?.date ? (
              <div className="nav-week__date">
                {dayjs(scheduleBranchDate[firstEl] && scheduleBranchDate[firstEl]?.date).format(
                  'DD.MM',
                )}{' '}
                <div className="dash"> &nbsp; &mdash; &nbsp;</div>
                {dayjs(
                  scheduleBranchDate[firstEl] &&
                    scheduleBranchDate[scheduleBranchDate.length - 1]?.date,
                ).format('DD.MM')}
              </div>
            ) : (
              ''
            )}
          </div>
          <div
            role="presentation"
            className="nav-prev"
            onClick={() => {
              prevWeek(currentBranch);
            }}
          >
            {scheduleBranchDate[firstEl]?.date && <img src={logo.arrow_left} alt="previous" />}
          </div>
          <div
            role="presentation"
            className="nav-next"
            onClick={() => {
              nextWeek(currentBranch);
            }}
          >
            {scheduleBranchDate[firstEl]?.date && <img src={logo.arrow_right} alt="next" />}
          </div>
        </div>
      </div>

      {
        status === 'loading' && calcGrid.timeWorkDayFinally.length === 1 ? (
          <Preloader />
        ) : (
          currentBranch !== 0 && (
            <div className="schedule__calendar">
              {calcGrid.timeWorkDayFinally.length !== 1 && status === 'loading' && (
                <div
                  className={`${
                    isOpenSidebar ? 'preloader-wrap preloader-wrap-open-sidebar' : 'preloader-wrap'
                  }`}
                >
                  <PreloaderEvent />
                </div>
              )}

              {stateCalendar ? (
                <div>
                  <div
                    className={`${isOpenSidebar ? 'timeLine timeLine-open-sidebar' : 'timeLine'}`}
                  >
                    <span className="time-title">{t(TranslationKeys.shedule_time)}</span>
                    {calcGrid.timeWorkDayFinally &&
                      calcGrid.timeWorkDayFinally.map((hour, index) => (
                        <span
                          key={hour}
                          style={
                            index === 0
                              ? {
                                  height: `${calcGrid.currentHeightLineStart(
                                    Number(hour.slice(3, 5)),
                                  )}px`,
                                }
                              : index === calcGrid.timeWorkDayFinally.lastIndexOf(hour)
                              ? {
                                  height: `${calcGrid.currentHeightLine(
                                    Number(hour.slice(3, 5)),
                                  )}px`,
                                }
                              : {
                                  height: `${calcGrid.currentHeightLine(
                                    Number(hour.slice(3, 5)),
                                  )}px`,
                                }
                          }
                          className="line"
                        >
                          {index === 0
                            ? hour
                            : index === calcGrid.timeWorkDayFinally.lastIndexOf(hour)
                            ? `${hour.slice(0, 3)}00`
                            : hour}
                        </span>
                      ))}
                  </div>
                  <div className="days">
                    {scheduleBranchDate.map((dayOfWeek) => (
                      <DayOfTheWeek
                        key={dayOfWeek?.date_index}
                        dayIndex={dayOfWeek?.date_index}
                        dayName={dayOfWeek?.date_name}
                        scheduleBranchDate={scheduleBranchDate}
                        scheduleBranch={scheduleBranch}
                        onFreeDateClick={onFreeDateClick}
                        setDateForModalAlert={setDateForModalAlert}
                        onStatusChangeEventOpenAlert={onStatusChangeEventOpenAlert}
                        onStatusChangeEvent={onStatusChangeEvent}
                        calcGrid={calcGrid}
                        setCurrentEventFromSchedule={setCurrentEventFromSchedule}
                      />
                    ))}
                  </div>
                </div>
              ) : (
                <div>
                  <div
                    className={`${isOpenSidebar ? 'timeLine timeLine-open-sidebar' : 'timeLine'}`}
                  >
                    <span className="time-title">{t(TranslationKeys.shedule_time)}</span>
                    {calcGridShort.timeWorkDayFinally &&
                      calcGridShort.timeWorkDayFinally.map((hour, index) => (
                        <span
                          key={hour}
                          style={
                            index === 0
                              ? {
                                  height: `calc(${calcGridShort.dynamicHeightFirstHour(hour)})`,
                                }
                              : index === calcGridShort.timeWorkDayFinally.lastIndexOf(hour)
                              ? {
                                  height: `calc((${calcGridShort.currentHeightLine(
                                    Number(hour.slice(3, 5)),
                                  )}vh - 251px) / ${calcGridShort.sumHours})`,
                                }
                              : {
                                  height: `calc((${calcGridShort.currentHeightLine(
                                    Number(hour.slice(3, 5)),
                                  )}vh - 251px) / ${calcGridShort.sumHours})`,
                                }
                          }
                          className="line"
                        >
                          {index === 0
                            ? hour
                            : index === calcGrid.timeWorkDayFinally.lastIndexOf(hour)
                            ? `${hour.slice(0, 3)}00`
                            : hour}
                        </span>
                      ))}
                  </div>
                  <div className="days">
                    {scheduleBranchDate.map((dayOfWeek) => (
                      <DayOfTheWeekSmall
                        key={dayOfWeek?.date_index}
                        dayIndex={dayOfWeek?.date_index}
                        dayName={dayOfWeek?.date_name}
                        scheduleBranchDate={scheduleBranchDate}
                        scheduleBranch={scheduleBranch}
                        onFreeDateClick={onFreeDateClick}
                        setDateForModalAlert={setDateForModalAlert}
                        onStatusChangeEventOpenAlert={onStatusChangeEventOpenAlert}
                        onStatusChangeEvent={onStatusChangeEvent}
                        calcGrid={calcGridShort}
                        setCurrentEventFromSchedule={setCurrentEventFromSchedule}
                        openEventDetails={openEventDetails}
                      />
                    ))}
                  </div>
                </div>
              )}
            </div>
          )
        ) //
      }
      {openAlertDialogEvents && (
        <ModalAlertEvents
          date={modalDateEvents}
          eventsData={modalEventsData}
          cancelCallback={closeAlertHandler}
          onFreeDateClick={onFreeDateClick}
          onStatusChangeEventOpenAlert={onStatusChangeEventOpenAlert}
          onStatusChangeEvent={onStatusChangeEvent}
          onClickBtn={closeAlertHandler}
          setCurrentEventFromSchedule={setCurrentEventFromSchedule}
          openEventDetails={openEventDetails}
        />
      )}
      {openModalWaiting ? (
        <ModalAlertWaiting
          onClickBtn={() => setModalOpenWaiting(false)}
          closeModalAlert={() => setModalOpenWaiting(false)}
          selectIndex={selectedIndex}
          setSelectIndex={setSelectedIndex}
        />
      ) : (
        ''
      )}
      {openCalendarGeneral && (
        <div
          role="presentation"
          onClick={() => {
            setOpenCalendarGeneral(false);
          }}
          className="schedule__wrapper-calendar-general"
        >
          <CalendarGeneral
            branchId={currentBranch}
            setChosenWeekInCalendar={setChosenWeekInCalendar}
            onDayClick={() => {
              setOpenCalendarGeneral(false);
            }}
            onClickBtn={() => {
              setOpenCalendarGeneral(false);
            }}
          />
        </div>
      )}

      {showDetails && (
        <EventDetails
          closeEventDetails={closeEventDetails}
          onStatusChangeEventOpenAlert={onStatusChangeEventOpenAlert}
          onStatusChangeEvent={onStatusChangeEvent}
          onFreeDateClick={onFreeDateClick}
          setCurrentEventFromSchedule={setCurrentEventFromSchedule}
          date={modalDateEvents}
          setSelectedIndex={setSelectedIndex}
        />
      )}
    </div>
  );
});
