import { AppInitialStateType } from "./types";
import { AppActionsType } from '../actions/types';
import {
  EDIT_STATE_MENU,
  EDIT_STATE_SIDEBAR,
  RESET_REDUCER_GROUP,
  SET_CURRENT_PATH,
  SET_ERROR,
  SET_INITIALIZED,
  SET_STATUS,
} from '../actions/constants';

const initialState: AppInitialStateType = {
  status: 'idle',
  error: '',
  currentPath: null,
  isInitialized: false,
  isOpenProfileMenu: false,
  isOpenSidebar: true,
};

export const appReducer = (
  state: AppInitialStateType = initialState,
  action: AppActionsType,
): AppInitialStateType => {
  switch (action.type) {
    case SET_INITIALIZED:
      return { ...state, isInitialized: action.isInitialized };

    case SET_STATUS:
      return { ...state, status: action.status };

    case SET_ERROR:
      return { ...state, error: action.error };

    case EDIT_STATE_MENU:
      return { ...state, isOpenProfileMenu: action.openMenu };

    case EDIT_STATE_SIDEBAR:
      return { ...state, isOpenSidebar: !state.isOpenSidebar };

    case SET_CURRENT_PATH: {
      return { ...state, currentPath: action.path };
    }

    case RESET_REDUCER_GROUP:
      return { ...initialState };

    default:
      return state;
  }
};