import React, { FC, useCallback, useEffect } from 'react';
import { motion } from 'framer-motion';
import './AddClientPopup.scss';
import { FormClients } from '../FormClients/FormClients';
import { ButtonClose } from '../../../components/Widgets/ButtonClose/ButtonClose';

type EventDetailsType = {
  closeClientPopup?: () => void;
};

export const AddClientPopup: FC<EventDetailsType> = ({ closeClientPopup }) => {

  const escFunction = useCallback((event) => {
    if (event.key === 'Escape') {
      closeClientPopup && closeClientPopup();
    }
  }, []);
  useEffect(() => {
    document.addEventListener('keydown', escFunction, false);
    return () => {
      document.removeEventListener('keydown', escFunction, false);
    };
  }, []);

  const closePopup = (): void => {
    closeClientPopup && closeClientPopup();
  };

  return (
    <motion.div className={'add-client-window'} onMouseDown={closePopup}>

    <motion.div className={'add-client-window__wrap'}>
      <div onClick={closePopup} className="btn-close-block">
        <ButtonClose onClickBtn={closePopup}/>
      </div>

      <motion.div
        className="add-client-window__wrap__alert"
        onMouseDown={(e) => e.stopPropagation()}
        onClick={(e) => e.stopPropagation()}
      >
        <FormClients closeClientPopup={closeClientPopup} />
      </motion.div>
    </motion.div>
    </motion.div>
  );
};