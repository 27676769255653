import { Dispatch } from 'redux';
import { resetReducerGroupAC, setAppErrorAC, setAppStatusAC, setIsLoggedInAC } from "../redux/actions";

/**
 * "handleServerAppError" takes "error" and "dispatch" argument.
 * The function is used to handle server and client errors.
 * Depending on the error status, saves the error for display or, if the error status is 401,
 * logs out.
 * @param {any} error - error response
 * @param {Dispatch<any>} dispatch - A dispatching function (or simply dispatch function) is a
 * function that accepts an action or an async action; it then may or may not dispatch one or more
 * actions to the store.
 * */
export const handleServerAppError = (error: any, dispatch: Dispatch<any>): void => {
  if (error.response.status === 401) {
    dispatch(setIsLoggedInAC(false));
    dispatch(resetReducerGroupAC());
  }

  if (error.response.status !== 404 && error.response.status !== 500) {
    dispatch(setAppErrorAC(error.response.data));
  } else {
    dispatch(setAppErrorAC(error.message));
  }

  dispatch(setAppStatusAC('failed'));
};

/**
 * "handleServerNetworkError" takes "error" and "dispatch" argument.
 * The function is used to handle network errors. The function does not return anything.
 * @param {any} error - error response
 * @param {Dispatch<any>} dispatch - A dispatching function (or simply dispatch function) is a
 * function that accepts an action or an async action; it then may or may not dispatch one or more
 * actions to the store.
 * */
export const handleServerNetworkError = (error: any, dispatch: Dispatch<any>): void => {
  if (error.message === 'Network Error') {
    dispatch(setAppErrorAC(error.message));
  }
  dispatch(setAppStatusAC('failed'));
};
