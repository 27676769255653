import { ClientFilterParamsType } from '../../api/clients-api';
import {
  ADD_CLIENT_SEARCH,
  ADD_CURRENT_CLIENT_FILTER_DOCTOR,
  ADD_CURRENT_CLIENTS_BRANCHES,
  ADD_CURRENT_CLIENTS_FILTER_INSURANCE,
  CLEAN_CLIENTS_FILTER,
  COLLAPSE_FILTER_DOCTOR,
  COLLAPSE_FILTER_INSURANCE,
  REMOVE_CURRENT_CLIENT_FILTER_DOCTOR,
  REMOVE_CURRENT_CLIENTS_FILTER_INSURANCE,
  SET_ACTIVE_ARROW_FULL_NAME,
  SET_ACTIVE_ARROW_INSURANCE,
  SET_CLIENT_FILTER_PARAMS,
  SET_CURRENT_CLIENTS_QUERY_STRING,
  SET_CURRENT_SORT_PARAM,
  SET_PRELOADER_CLIENTS_FILTER,
  SET_SORT_BY_FULL_NAME,
  SET_SORT_BY_INSURANCE,
} from './constants';

export const setClientFilterParamsAC = (params: ClientFilterParamsType) =>
  ({ type: SET_CLIENT_FILTER_PARAMS, params } as const);
export const addCurrentClientsFilterInsuranceAC = (params: string) =>
  ({ type: ADD_CURRENT_CLIENTS_FILTER_INSURANCE, params } as const);
export const removeCurrentClientsFilterInsuranceAC = (params: string) =>
  ({ type: REMOVE_CURRENT_CLIENTS_FILTER_INSURANCE, params } as const);
export const setCurrentParamBranchAC = (params: string) =>
  ({ type: ADD_CURRENT_CLIENTS_BRANCHES, params } as const);
export const collapseFilterInsuranceAC = (param: boolean) =>
  ({ type: COLLAPSE_FILTER_INSURANCE, param } as const);
export const collapseFilterDoctorAC = (param: boolean) =>
  ({ type: COLLAPSE_FILTER_DOCTOR, param } as const);
export const addCurrentClientFilterDoctorAC = (params: string) =>
  ({ type: ADD_CURRENT_CLIENT_FILTER_DOCTOR, params } as const);
export const removeCurrentClientFilterDoctorAC = (params: string) =>
  ({ type: REMOVE_CURRENT_CLIENT_FILTER_DOCTOR, params } as const);
export const setPreloaderClientsFilterAC = (param: boolean) =>
  ({ type: SET_PRELOADER_CLIENTS_FILTER, param } as const);
export const addClientSearchParamAC = (params: string) =>
  ({ type: ADD_CLIENT_SEARCH, params } as const);
export const cleanClientsFilterAC = () => ({ type: CLEAN_CLIENTS_FILTER } as const);

//sort action
export const setCurrentSortParamAC = (value: string) =>
  ({ type: SET_CURRENT_SORT_PARAM, value } as const);
export const setSortByFullNameAC = (value: boolean) =>
  ({ type: SET_SORT_BY_FULL_NAME, value } as const);
export const setSortByInsuranceAC = (value: boolean) =>
  ({ type: SET_SORT_BY_INSURANCE, value } as const);
export const setActiveArrowFullNameAC = (value: boolean) =>
  ({ type: SET_ACTIVE_ARROW_FULL_NAME, value } as const);
export const setActiveArrowInsuranceAC = (value: boolean) =>
  ({ type: SET_ACTIVE_ARROW_INSURANCE, value } as const);
export const setCurrClientsQueryStringAC = (query: string | null) =>
  ({ type: SET_CURRENT_CLIENTS_QUERY_STRING, query } as const);