import { BranchType, DateOfListType, ScheduleBranchType } from '../../api/branches-api';
import {
  ADD_BRANCH,
  CHANGE_STATE_CALENDAR_PATH,
  EDIT_BRANCH,
  GET_SCHEDULE_BRANCH,
  REMOVE_BRANCH,
  RESET_REDUCER_GROUP,
  SET_ACTIVE_CURRENT_WEEK,
  SET_BRANCHES_LIST,
  SET_CURRENT_BRANCH,
  SET_PRELOADER_BRANCHES,
} from '../actions/constants';
import { BranchesActionsType } from '../actions/types';
import { BranchesInitialStateType } from "./types";

const initialState = {
  currentBranch: 0,
  isFullSchedule: false,
  activeCurrentWeek: null,
  branches: [] as Array<BranchType>,
  scheduleBranch: {
    next_week: '',
    previous_week: '',
    current_week: [''],
    day_end: '',
    day_start: '',
    date_of_lists: [{} as DateOfListType] as Array<DateOfListType>,
  } as ScheduleBranchType,
  isActivePreloader: false,
};

export const branchesReducer = (
  state: BranchesInitialStateType = initialState,
  action: BranchesActionsType,
): BranchesInitialStateType => {
  switch (action.type) {
    case SET_BRANCHES_LIST: {
      return { ...state, branches: action.branches.map((tl) => ({ ...tl })) };
    }
    case ADD_BRANCH: {
      return { ...state, branches: [action.branch, ...state.branches] };
    }
    case EDIT_BRANCH: {
      return {
        ...state,
        branches: state.branches.map((e) =>
          e.id === action.module.id
            ? {
                ...e,
                ...action.module,
              }
            : e,
        ),
      };
    }
    case REMOVE_BRANCH: {
      return { ...state, branches: state.branches.filter((e) => e.id !== action.id) };
    }
    case GET_SCHEDULE_BRANCH: {
      return { ...state, scheduleBranch: action.scheduleBranch };
    }
    case SET_CURRENT_BRANCH: {
      return { ...state, currentBranch: action.currentBranchId };
    }
    case SET_ACTIVE_CURRENT_WEEK: {
      return { ...state, activeCurrentWeek: action.activeWeek };
    }

    case CHANGE_STATE_CALENDAR_PATH: {
      return { ...state, isFullSchedule: action.state };
    }

    case SET_PRELOADER_BRANCHES: {
      return {
        ...state,
        isActivePreloader: action.param,
      };
    }

    case RESET_REDUCER_GROUP:
      return { ...initialState };

    default:
      return state;
  }
};
