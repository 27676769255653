import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';
import './SortByTitle.scss';

type SortTitlePropsType = {
  isActiveSortTitle: boolean;
  isActiveArrow: boolean;
  sortListCallback: (ordering: string) => void;
  sortParam: string;
  title: string;
};

export const SortByTitle: FC<SortTitlePropsType> = ({
  isActiveSortTitle,
  isActiveArrow,
  sortListCallback,
  sortParam,
  title,
}) => {
  const { t } = useTranslation();

  const optionalClassTitle = t(title).length > 15 ? 'th-name-long' : 'th-name';
  const optionalClassTitleLight = t(title).length > 15 ? 'th-name-long-light' : 'th-name-light';
  const optionalClassArrow = t(title).length > 15 ? 'wrap-long' : 'wrap';

  return (
    <>
      {!isActiveSortTitle ? (
        <div
          className="sort-title"
          onClick={() => {
            sortListCallback(sortParam);
          }}
        >
          <span className={isActiveArrow ? optionalClassTitle : optionalClassTitleLight}>
            {t(title)}
          </span>
          <div
            onClick={() => {
              sortListCallback(sortParam);
            }}
            className={optionalClassArrow}
          >
            <span className={isActiveArrow ? 'arrow active' : 'arrow-gray active'}>
              <span></span>
              <span></span>
            </span>
          </div>
        </div>
      ) : (
        <div
          className="sort-title"
          onClick={() => {
            sortListCallback(`-${sortParam}`);
          }}
        >
          <span className={isActiveArrow ? optionalClassTitle : optionalClassTitleLight}>
            {t(title)}
          </span>
          <div
            onClick={() => {
              sortListCallback(`-${sortParam}`);
            }}
            className={optionalClassArrow}
          >
            <span className={isActiveArrow ? 'arrow' : 'arrow-gray'}>
              <span></span>
              <span></span>
            </span>
          </div>
        </div>
      )}
    </>
  );
};