import React, { FC, useEffect, useMemo, useState } from 'react';
import './WaitingRoomFilter.scss';
import { useDispatch, useSelector } from 'react-redux';
import { useForm } from 'react-hook-form';
import {
  getCurrentBranchSelector,
  getCurrWaitingRoomQueryStringAppSelector,
  getFilterParamsSelector,
  getUserProfileSelector,
} from '../../../redux/selectors';
import { ReactSelectBranch } from '../../../components/Widgets/Select/ReactSelectBranch';
import {
  defaultChoicesSelect,
  newOption,
} from '../../../components/Widgets/Select/ReactSelectStyles';
import { useTranslation } from 'react-i18next';
import Inputs from '../../../components/Widgets/Inputs';
import {
  getCurrentDoctorParamsWaitingSelector,
  getCurrentReasonParamsWaitingSelector,
  isCollapsedReasonFilterWaitingSelector,
} from '../../../redux/selectors/WaitingRoomSelectors';
import CheckboxInput from '../../../components/Widgets/CheckboxInput';
import cx from 'classnames';
import Collapse from '@kunukn/react-collapse';
import TranslationKeys from '../../../assets/locales/translationKeys/translationKeys';
import { getObjectFromQueryString } from '../../../utils/getObjectFromQueryString';
import {
  collapseReasonFilterWaitingAC,
  setCurrentBranchesAC,
} from '../../../redux/actions';
import {
  fetchFilteredWaitingRoomParamsListTC,
  setFilteredOfDoctorTC,
  setFilteredWaitingOfReasonTC,
  setFilteredWaitingSearchTC,
} from '../../../redux/middlewares';
import { useCleanFilters } from "../../../hooks/useCleanFilters";

type FormDataType = {};
type EventsSideBarPropsType = {};

export const WaitingRoomFilter: FC<EventsSideBarPropsType> = () => {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const cleanFilters = useCleanFilters();

  const currentBranch = useSelector(getCurrentBranchSelector);
  const filterParams = useSelector(getFilterParamsSelector);
  const currentReasonParams = useSelector(getCurrentReasonParamsWaitingSelector);
  const currentDoctorParams = useSelector(getCurrentDoctorParamsWaitingSelector);
  const isCollapsedReason = useSelector(isCollapsedReasonFilterWaitingSelector);
  const user = useSelector(getUserProfileSelector);
  const currentQueryString = useSelector(getCurrWaitingRoomQueryStringAppSelector);

  const [stateDoctor, setStateDoctor] = useState<boolean>(true);
  const [openDoc, isOpenDoc] = useState(true);
  const [openReason, isOpenReason] = useState(false);

  useEffect(() => {
    if (currentDoctorParams.length) {
      setStateDoctor(true);
    }
  }, []);

  const defaultSearchParam = getObjectFromQueryString(currentQueryString)?.search;

  const valueOptionBranches =
    filterParams.branch &&
    Object.entries(filterParams.branch.choices).map((b: any) => newOption(b[0], b[1]));
  const labelOptionBranches = currentBranch.toString();
  const choicesReason =
    filterParams?.reason__in && Object.entries(filterParams.reason__in?.choices).map((br) => br);
  const choicesReasonColor =
    filterParams.color && Object.entries(filterParams.color.choices).map((br) => br);
  const choicesDoctor =
    filterParams?.doctor__in && Object.entries(filterParams.doctor__in?.choices).map((br) => br);

  const choicesBranch =
    filterParams.branch && Object.entries(filterParams.branch.choices).map((br) => br);

  const setFilterBranch = (params: any): void => {
    cleanFilters();
    dispatch(setCurrentBranchesAC(params));
    dispatch(fetchFilteredWaitingRoomParamsListTC());
  };
  const countDoctor = useMemo(
    () =>
      choicesDoctor?.reduce(
        (count1, [doctorId]) => (currentDoctorParams.includes(doctorId) ? count1 + 1 : count1),
        0,
      ),
    [currentDoctorParams, choicesDoctor],
  );

  const сountReason = useMemo(
    () =>
      choicesReason?.reduce(
        (count2, [reasonId]) => (currentReasonParams.includes(reasonId) ? count2 + 1 : count2),
        0,
      ),
    [currentReasonParams, choicesReason],
  );

  const onSearchChange = (value: string): void => {
    dispatch(setFilteredWaitingSearchTC(value));
  };
  const setFilterReason = (params: string): void => {
    dispatch(setFilteredWaitingOfReasonTC(params));
  };
  const setFilterDoctor = (params: string): void => {
    dispatch(setFilteredOfDoctorTC(params));
  };
  const collapseReasonFilter = (): void => {
    dispatch(collapseReasonFilterWaitingAC(!isCollapsedReason));
  };

  const { control, register } = useForm<FormDataType>({
    mode: 'onBlur',
  });

  return (
    <div className="form-waiting-filter">
      <div className="filter-block">
        <div className="filter-block-select">
          <ReactSelectBranch
            name="branches"
            label={t(TranslationKeys.current_branch)}
            placeholder={t(TranslationKeys.current_branch)}
            control={control}
            isMulti={false}
            defaultValue={
              currentBranch && filterParams.branch
                ? defaultChoicesSelect(valueOptionBranches, labelOptionBranches)[0]
                : { value: '', label: '' }
            }
            onChange={(e) => setFilterBranch(e.value)}
            options={
              choicesBranch &&
              Object.entries(filterParams.branch.choices).map((b: any) => newOption(b[0], b[1]))
            }
          />
        </div>
      </div>
      <div className="search">
        <Inputs
          onChangeText={onSearchChange}
          label={filterParams?.search?.label}
          state={'search'}
          name="search"
          defaultValue={defaultSearchParam && defaultSearchParam}
          style={{
            height: '50px',
            paddingTop: '14px',
            marginBottom: '0',
          }}
          register={register}
        />
      </div>
      {user.role !== 'doctor' && (
        <div className="filter-block">
          <div
            className={cx('app__toggle', {
              'app__toggle--active': openDoc,
            })}
            onClick={() => {
              setStateDoctor(!stateDoctor);
              isOpenDoc(!openDoc);
            }}
          >
            <span className="filter-title-name">
              {t(TranslationKeys.doctor)}
              <span
                style={{
                  marginLeft: '15px',
                  fontSize: '15px',
                  fontWeight: 'lighter',
                  color: 'rgba(45, 45, 45, 0.852)',
                }}
              >
                [
                <span
                  style={{
                    color: 'black',
                  }}
                >
                  {countDoctor || 0}
                </span>{' '}
                /{' '}
                {filterParams.doctor__in?.choices !== undefined &&
                  Object.keys(filterParams.doctor__in?.choices).length}
                ]
              </span>
            </span>
            <div
              onClick={() => isOpenDoc(!openDoc)}
              className={openDoc ? 'cross active' : 'cross'}
            />
          </div>
          <Collapse
            isOpen={openDoc}
            className={
              'app__collapse app__collapse--gradient ' + (openDoc ? 'app__collapse--active' : '')
            }
          >
            <div className="app__content">
              {filterParams.doctor__in &&
                choicesDoctor.map((doctor, index) => (
                  <div key={index} className={stateDoctor ? 'filter-param ' : ' filter-param-none'}>
                    <CheckboxInput
                      id={doctor[0]}
                      state={'square'}
                      checked={currentDoctorParams.find((cs) => cs === doctor[0]) === doctor[0]}
                      onChange={() => {
                        setFilterDoctor(doctor[0]);
                      }}
                      name={'status'}
                      value={doctor[0]}
                      label={doctor[1]}
                    />
                  </div>
                ))}
            </div>
          </Collapse>
        </div>
      )}
      <div
        className={cx('app__toggle', {
          'app__toggle--active': openReason,
        })}
        onClick={() => {
          isOpenReason(!openReason);
          collapseReasonFilter;
        }}
      >
        <span className="filter-title-name">
          {t(TranslationKeys.reason)}
          <span
            style={{
              marginLeft: '15px',
              fontSize: '15px',
              fontWeight: 'lighter',
              color: 'rgba(45, 45, 45, 0.852)',
            }}
          >
            [
            <span
              style={{
                color: 'black',
              }}
              className="d"
            >
              {сountReason || 0}
            </span>{' '}
            /{' '}
            {filterParams.reason__in?.choices !== undefined &&
              Object.keys(filterParams.reason__in?.choices).length}
            ]
          </span>
        </span>
        <div
          onClick={() => isOpenReason(!openReason)}
          className={openReason ? 'cross active' : 'cross'}
        />
      </div>
      <Collapse
        isOpen={openReason}
        className={
          'app__collapse app__collapse--gradient ' + (openReason ? 'app__collapse--active' : '')
        }
      >
        <div className="app__content">
          <div className="filter-block-color">
            <div
              style={{
                width: '80%',
                marginLeft: '30px',
              }}
            >
              {filterParams.reason__in &&
                choicesReason.map((reason, index) => (
                  <div
                    key={index}
                    className={!isCollapsedReason ? 'filter-param-color' : 'filter-param-none'}
                  >
                    <CheckboxInput
                      id={reason[0]}
                      state={'color'}
                      checked={currentReasonParams.find((cr) => cr === reason[0]) === reason[0]}
                      onChange={() => {
                        {
                          setFilterReason(reason[0]);
                        }
                      }}
                      name={'reasons'}
                      value={reason[0]}
                      label={`${reason[1]}  `}
                    />
                  </div>
                ))}
            </div>
            <div>
              {filterParams.color &&
                choicesReasonColor.map((reason, index) => (
                  <div
                    key={index}
                    className={!isCollapsedReason ? 'filter-param-color' : 'filter-param-none'}
                  >
                    <div
                      className="color"
                      style={{
                        background: `#${reason[1]}`,
                      }}
                    ></div>
                  </div>
                ))}
            </div>
          </div>
        </div>
      </Collapse>
    </div>
  );
};
