import './SuccessPage.scss';
import React, { FC } from 'react';
//@ts-ignore
import mainImg from '../../../assets/img/img_send.svg';
import { PATH } from '../../../routes/Routes';
import { NavLink } from 'react-router-dom';

type SuccessType = {
  textSuccess?: string;
};
const SuccessPage: FC<SuccessType> = ({ textSuccess }) => {
  return (
    <div className="main">
      <div className="main__content">
        <img src={mainImg} className="main__content-img" />
        <h1 className="main__content-title">Thank you!</h1>
        <h2 className="main__content-subtitle">{textSuccess}</h2>
        <div className="main__content_block-btn">
          <div className="main__content-btn">
            <NavLink
              className="button button_size-middle button_position-center button_color-black"
              to={PATH.SCHEDULE}
            >
              Home page
            </NavLink>
          </div>
        </div>
      </div>
    </div>
  );
};

export default SuccessPage;