/* eslint-disable */
import React, { FC, useEffect, useRef, useState } from 'react';
import { Controller } from 'react-hook-form';
import DatePicker from 'react-datepicker';
import InputsWidgets from './InputsWidgets';
import './TimePicker.scss';

type ReactSelectType1 = {
  error?: string | null;
  help_text?: string | null;
  label?: string;
  placeholder?: string;
  control: any;
  onClick?: () => void;
  value?: any;
  name: string;
  defaultValue?: any;
  onChangeText?: (value: any) => void;
  selectedDate: any;
  register?: any;
  step?: any;
  state?: any;
  disabled?: any;
  minTime?: Date | undefined;
  maxTime?: Date | undefined;
  timeIntervals?: number;
};

export const TimePicker: FC<ReactSelectType1> = ({
  control,
  error,
  step,
  state,
  help_text,
  label,
  name,
  disabled,
  minTime,
  maxTime,
  onChangeText,
  timeIntervals,
}) => {
  const componentRef = useRef();

  const [calendarIsOpen, setCalendarIsOpen] = useState(false);

  useEffect(() => {
    const handleClick = (e: any): void => {
      if (componentRef && componentRef.current) {
        const ref: any = componentRef.current;
        if (!ref.contains(e.target)) {
          setCalendarIsOpen(false);
        }
      }
    };
    document.addEventListener('click', handleClick);
    return () => document.removeEventListener('click', handleClick);
  }, []);

  const onChangeHandler = (value: string, onChange: (...event: any[]) => void): void => {
    const date = new Date(value);
    date.toISOString();
    onChange(date.getTime());
  };

  const onChangeTextHandler = (value: Date | null): void => {
    onChangeText && onChangeText(value);
  };

  return (
    <div
      ref={componentRef as any}
      onClick={() => setCalendarIsOpen(!calendarIsOpen)}
      className="timePicker"
    >
      <div>
        <Controller
          name={name}
          control={control}
          render={({ field: { value, onChange, name } }) => (
            <div>
              <DatePicker
                name={name}
                id={`id_${name}`}
                onChange={(e) => {
                  onChange(e);
                  onChangeTextHandler(e);
                }}
                onBlur={() => setCalendarIsOpen(false)}
                selected={value}
                showTimeSelect
                showTimeSelectOnly
                minTime={minTime}
                maxTime={maxTime}
                timeIntervals={timeIntervals ? timeIntervals : 15}
                timeCaption="Time"
                dateFormat="HH:mm"
                open={disabled ? false : calendarIsOpen}
                timeFormat="HH:mm"
                disabled={disabled}
                popperPlacement="bottom-end"
                customInput={
                  <InputsWidgets
                    state={state}
                    help_text={help_text}
                    input_type={'time'}
                    autoFocus={true}
                    step={step}
                    error={error}
                    name={name}
                    label={label}
                    defaultValue={value}
                    onChangeText={(text) => {
                      onChangeHandler(text, onChange);
                    }}
                  />
                }
              />
            </div>
          )}
        />
      </div>
    </div>
  );
};
