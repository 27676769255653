import { Dispatch } from 'redux';
import { addUserRegistrationAC, setAppStatusAC, setIsSendDataRegistrationAC } from '../actions';
import { registerAPI } from '../../api/register-api';
import { handleServerAppError, handleServerNetworkError } from '../../utils/error-utils';

export const addUserTC = (data: any) => async (dispatch: Dispatch<any>) => {
  dispatch(setAppStatusAC('loading'));
  try {
    let res = await registerAPI.addUser(data);
    dispatch(addUserRegistrationAC(res.data));
    dispatch(setIsSendDataRegistrationAC(true));
    dispatch(setAppStatusAC('succeeded'));
  } catch (error: any) {
    if (error.message === 'Network Error') {
      handleServerNetworkError(error, dispatch);
    } else {
      handleServerAppError(error, dispatch);
    }
  }
};

export const activationTC =
  (id: number, token: string | null) => async (dispatch: Dispatch<any>) => {
    try {
      await registerAPI.getVerificationOfEmail(id, token);
      await dispatch(setAppStatusAC('succeeded'));
    } catch (error: any) {
      if (error.message === 'Network Error') {
        handleServerNetworkError(error, dispatch);
        dispatch(setAppStatusAC('failed'));
      } else {
        handleServerAppError(error, dispatch);
        dispatch(setAppStatusAC('failed'));
      }
    }
  };