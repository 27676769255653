import { OrderFilterParamsType } from '../../api/order-api';
import { OrdersFilterInitialStateType } from './types';
import { OrdersFilterActionsType } from '../actions/types';
import {
  ADD_CURRENT_ORDER_FILTER_BRANCH,
  ADD_CURRENT_ORDER_FILTER_DOCTOR,
  ADD_ORDER_SEARCH,
  CLEAN_ORDERS_FILTER,
  COLLAPSE_DOCTOR_ORDER_FILTER,
  REMOVE_CURRENT_ORDER_FILTER_DOCTOR,
  RESET_REDUCER_GROUP,
  SET_CURRENT_ORDERS_QUERY_STRING,
  SET_ORDER_FILTER_PARAMS,
  SET_PRELOADER_ORDER_FILTER,
} from '../actions/constants';

const initialState: OrdersFilterInitialStateType = {
  currentQueryStringOrdersList: null,

  currentFilterParams: {
    search: [],
    branch_creator__in: [],
    doctor__in: [],
  },
  orderFilterParamsList: {} as OrderFilterParamsType,
  isCollapsedDoctorFilter: true,
  isActivePreloader: false,
};

export const ordersFilterReducer = (
  state: OrdersFilterInitialStateType = initialState,
  action: OrdersFilterActionsType,
): OrdersFilterInitialStateType => {
  switch (action.type) {
    case SET_ORDER_FILTER_PARAMS: {
      return {
        ...state,
        orderFilterParamsList: action.params,
      };
    }

    case ADD_CURRENT_ORDER_FILTER_BRANCH: {
      return {
        ...state,
        currentFilterParams: {
          ...state.currentFilterParams,
          branch_creator__in: [action.params],
        },
      };
    }
    case ADD_ORDER_SEARCH: {
      return {
        ...state,
        currentFilterParams: {
          ...state.currentFilterParams,
          search: [action.params],
        },
      };
    }

    case ADD_CURRENT_ORDER_FILTER_DOCTOR: {
      return {
        ...state,
        currentFilterParams: {
          ...state.currentFilterParams,
          doctor__in: [...state.currentFilterParams.doctor__in, action.params],
        },
      };
    }
    case REMOVE_CURRENT_ORDER_FILTER_DOCTOR: {
      return {
        ...state,
        currentFilterParams: {
          ...state.currentFilterParams,
          doctor__in: state.currentFilterParams.doctor__in.filter(
            (doctor) => doctor !== action.params,
          ),
        },
      };
    }

    case COLLAPSE_DOCTOR_ORDER_FILTER: {
      return {
        ...state,
        isCollapsedDoctorFilter: action.param,
      };
    }
    case SET_PRELOADER_ORDER_FILTER: {
      return {
        ...state,
        isActivePreloader: action.param,
      };
    }

    case CLEAN_ORDERS_FILTER: {
      return {
        ...state,
        currentFilterParams: {
          search: [],
          branch_creator__in: [],
          doctor__in: [],
        },
      };
    }

    case SET_CURRENT_ORDERS_QUERY_STRING:
      return { ...state, currentQueryStringOrdersList: action.query };

    case RESET_REDUCER_GROUP:
      return { ...initialState };

    default:
      return state;
  }
};