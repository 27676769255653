import React, { FC, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Navigate, useSearchParams } from 'react-router-dom';
import { PATH } from '../../routes/Routes';
import { getStatusSelector } from '../../redux/selectors';
import { activationTC } from '../../redux/middlewares';

type SideBarPropsType = {};

export const Activation: FC<SideBarPropsType> = () => {
  const dispatch = useDispatch();
  const [search] = useSearchParams();
  const status = useSelector(getStatusSelector);

  const confirmation_token = search.get('t');
  const idUrl = search.get('id');
  const user_id = Number(idUrl);

  useEffect(() => {
    dispatch(activationTC(user_id, confirmation_token));
  }, []);

  if (status === 'failed') {
    return <Navigate to={PATH.SUCCESS_PAGE_ERROR} />;
  }
  if (status === 'succeeded') {
    return <Navigate to={PATH.SUCCESS_PAGE_CREATE} />;
  }

  return (
    <div
      style={{
        position: 'absolute',
        zIndex: '100000',
        width: '100%',
        height: '100vh',
      }}
    >
    </div>
  );
};