import React, { FC } from 'react';
import { logo } from '../../../assets/img/image';
import { NavLink } from 'react-router-dom';
import { PATH } from '../../../routes/Routes';
import { useTranslation } from 'react-i18next';
import { EmployeeType } from '../../../api/employees-api';
import cx from 'classnames';
import Collapse from '@kunukn/react-collapse';
import { MenuBtn } from '../../../components/MenuBtn/MenuBtn';
import TranslationKeys from '../../../assets/locales/translationKeys/translationKeys';
import './ActionMenuEmployees.scss';

type ActionMenuPropsType = {
  employee: EmployeeType;
  options: boolean;
  onClickOptions: (employeeId: number) => void;
  selectedIndex: number;
  setSelectedIndex: (value: number) => void;
  onLoadHandler: () => void;
  load: boolean;
  setOptions: (value: boolean) => void;
  openAlertHandler: (value: number) => void;
};

export const ActionMenuEmployees: FC<ActionMenuPropsType> = ({
  onClickOptions,
  selectedIndex,
  employee,
  options,
  setSelectedIndex,
  onLoadHandler,
  load,
  setOptions,
  openAlertHandler,
}) => {
  const { t } = useTranslation();

  const onClickOptionHandler = (): void => {
    onClickOptions(employee.id);
  };

  return (
    <>
      <div
        onClick={() => {
          onClickOptions(employee.id);
          setSelectedIndex(employee.id);
        }}
        className={cx('app__toggle', {
          'app__toggle--active': options,
        })}
      >
        <div>
          <MenuBtn
            showSidebar={onClickOptionHandler}
            isOpenMenu={options && selectedIndex === employee.id}
          />
        </div>
        <Collapse
          isOpen={options && selectedIndex === employee.id}
          className={
            'app__collapse app__collapse--gradient ' +
            (options && selectedIndex === employee.id ? 'app__collapse--active' : '')
          }
        >
          <div className="app__content">
            <div className="options">
              <NavLink className={'actions'} to={`${PATH.EMPLOYEES}${employee.id}/`}>
                <img onLoad={onLoadHandler} className={load ? 'img' : 'img'} src={logo.edit} />
                {t(TranslationKeys.edit)}
              </NavLink>

              <span
                className="actions"
                onClick={() => {
                  setOptions(false);
                  openAlertHandler(employee.id);
                }}
              >
                <img onLoad={onLoadHandler} alt="remove" className={'img'} src={logo.trash} />
                {t(TranslationKeys.delete)}
              </span>
            </div>
          </div>
        </Collapse>
      </div>
    </>
  );
};