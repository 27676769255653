import {
  ADD_CURRENT_DOCTOR_FILTER_WAITING,
  ADD_CURRENT_REASON_COLOR_FILTER_WAITING,
  ADD_CURRENT_REASON_FILTER_WAITING,
  ADD_WAITING_SEARCH,
  CLEAN_WAITING_ROOM_FILTER,
  COLLAPSE_FILTER_DOCTOR_FILTER_WAITING,
  COLLAPSE_FILTER_REASON_FILTER_WAITING,
  REMOVE_CURRENT_DOCTOR_FILTER_WAITING,
  REMOVE_CURRENT_REASON_COLOR_FILTER_WAITING,
  REMOVE_CURRENT_REASON_FILTER_WAITING,
  RESET_REDUCER_GROUP,
  SET_CURRENT_PARAM_BRANCH_FILTER_WAITING,
  SET_CURRENT_WAITING_ROOM_QUERY_STRING,
  SET_LIST_OF_BRANCH_FILTER_WAITING,
} from '../actions/constants';
import { WaitingRoomFilterParamsType } from '../../api/waitingRoom-api';
import { WaitingRoomFilterInitialStateType } from './types';
import { WaitingRoomFilterActionsType } from '../actions/types';

const initialState: WaitingRoomFilterInitialStateType = {
  currentQueryStringWaitingRoomList: null,

  currentFilterParams: {
    search: [],
    branch: [],
    reason__in: [],
    color: [],
    doctor__in: [],
  },
  waitingRoomFilterParamsList: {} as WaitingRoomFilterParamsType,
  isCollapsedFilterReason: true,
  isCollapsedFilterDoctor: true,
};

export const waitingRoomFilterReducer = (
  state: WaitingRoomFilterInitialStateType = initialState,
  action: WaitingRoomFilterActionsType,
): WaitingRoomFilterInitialStateType => {
  switch (action.type) {
    case SET_LIST_OF_BRANCH_FILTER_WAITING: {
      return {
        ...state,
        waitingRoomFilterParamsList: action.params,
      };
    }
    case SET_CURRENT_PARAM_BRANCH_FILTER_WAITING: {
      return {
        ...state,
        currentFilterParams: {
          ...state.currentFilterParams,
          branch: [action.params],
        },
      };
    }
    case ADD_CURRENT_REASON_FILTER_WAITING: {
      return {
        ...state,
        currentFilterParams: {
          ...state.currentFilterParams,
          reason__in: [...state.currentFilterParams.reason__in, action.params],
        },
      };
    }
    case REMOVE_CURRENT_REASON_FILTER_WAITING: {
      return {
        ...state,
        currentFilterParams: {
          ...state.currentFilterParams,
          reason__in: state.currentFilterParams.reason__in.filter(
            (reason) => reason !== action.params,
          ),
        },
      };
    }
    case ADD_CURRENT_REASON_COLOR_FILTER_WAITING: {
      return {
        ...state,
        currentFilterParams: {
          ...state.currentFilterParams,
          color: [...state.currentFilterParams.color, action.params],
        },
      };
    }
    case REMOVE_CURRENT_REASON_COLOR_FILTER_WAITING: {
      return {
        ...state,
        currentFilterParams: {
          ...state.currentFilterParams,
          color: state.currentFilterParams.color.filter((reason) => reason !== action.params),
        },
      };
    }
    case ADD_CURRENT_DOCTOR_FILTER_WAITING: {
      return {
        ...state,
        currentFilterParams: {
          ...state.currentFilterParams,
          doctor__in: [...state.currentFilterParams.doctor__in, action.params],
        },
      };
    }
    case REMOVE_CURRENT_DOCTOR_FILTER_WAITING: {
      return {
        ...state,
        currentFilterParams: {
          ...state.currentFilterParams,
          doctor__in: state.currentFilterParams.doctor__in.filter(
            (doctor) => doctor !== action.params,
          ),
        },
      };
    }
    case ADD_WAITING_SEARCH: {
      return {
        ...state,
        currentFilterParams: {
          ...state.currentFilterParams,
          search: [action.params],
        },
      };
    }
    case COLLAPSE_FILTER_REASON_FILTER_WAITING: {
      return {
        ...state,
        isCollapsedFilterReason: action.param,
      };
    }
    case COLLAPSE_FILTER_DOCTOR_FILTER_WAITING: {
      return {
        ...state,
        isCollapsedFilterDoctor: action.param,
      };
    }

    case CLEAN_WAITING_ROOM_FILTER: {
      return {
        ...state,
        currentFilterParams: {
          search: [],
          branch: [],
          reason__in: [],
          color: [],
          doctor__in: [],
        },
      };
    }

    case SET_CURRENT_WAITING_ROOM_QUERY_STRING:
      return { ...state, currentQueryStringWaitingRoomList: action.query };

    case RESET_REDUCER_GROUP:
      return { ...initialState };

    default:
      return state;
  }
};