import { Dispatch } from "redux";
import { AppRootStateType } from "../store";
import {
  addClientSearchParamAC,
  addCurrentClientFilterDoctorAC,
  addCurrentClientsFilterInsuranceAC,
  removeCurrentClientFilterDoctorAC,
  removeCurrentClientsFilterInsuranceAC,
  setActiveArrowFullNameAC,
  setActiveArrowInsuranceAC,
  setAppStatusAC,
  setClientFilterParamsAC,
  setClientsListAC,
  setClientsParamsAC,
  setCurrClientsQueryStringAC,
  setCurrentClientsPageAC,
  setCurrentParamBranchAC,
  setPreloaderClientsFilterAC,
  setSortByFullNameAC,
  setSortByInsuranceAC
} from "../actions";
import { clientsAPI } from "../../api/clients-api";
import { handleServerAppError, handleServerNetworkError } from "../../utils/error-utils";
import { getObjectFromQueryString } from "../../utils/getObjectFromQueryString";
import { getQueryStringFromObject } from "../../utils/getQueryStringFromObject";
import { ClientQueryStringType } from "./types";

export const fetchFilteredClientsParamsListTC =
  () => async (dispatch: Dispatch<any>, getState: () => AppRootStateType) => {
    const branchId = getState().branches.currentBranch;
    dispatch(setAppStatusAC('loading'));
    try {
      const res = await clientsAPI.getFilteredParamsClientsList(branchId);
      dispatch(setClientFilterParamsAC(res.data));
    } catch (error: any) {
      if (error.message === 'Network Error') {
        handleServerNetworkError(error, dispatch);
      } else {
        handleServerAppError(error, dispatch);
      }
    }
    dispatch(setAppStatusAC('idle'));
  };

export const fetchFilteredClientsListOfGeneral =
  (branchId?: string | null, ordering?: string | null, query?: string) =>
    async (dispatch: Dispatch<any>, getState: () => AppRootStateType) => {
      // filter params
      const branches__in = branchId
        ? [branchId]
        : getState().clientsFilter.currentFilterParams.branches__in;
      const insurance_type__in = getState().clientsFilter.currentFilterParams.insurance_type__in;
      const doctors__in = getState().clientsFilter.currentFilterParams.doctors__in;
      const search = getState().clientsFilter.currentFilterParams.search;

      // sort params
      const currentSortParam = getState().clientsFilter.currentSortParams.currentSortParam;
      const isActiveFullName = getState().clientsFilter.currentSortParams.isActiveFullName;
      const isActiveInsurance = getState().clientsFilter.currentSortParams.isActiveInsurance;

      // query string
      const currentQueryParams = getState().clientsFilter.currentQueryStringClientsList;
      const branchFromQueryString = getObjectFromQueryString(currentQueryParams)?.branch;
      const updatedCurrentQueryParams = getObjectFromQueryString(currentQueryParams);

      if (branchFromQueryString !== branches__in[0]) {
        updatedCurrentQueryParams.branches__in = branches__in[0]
      }
      const updatedQueryString = getQueryStringFromObject(updatedCurrentQueryParams)

      const newQueryParams = getQueryStringFromObject({
        branches__in,
        insurance_type__in,
        doctors__in,
        ordering,
        search,
      } as unknown as ClientQueryStringType);

      const queryParams = query ? updatedQueryString : newQueryParams;

      dispatch(setAppStatusAC('loading'));
      await dispatch(setPreloaderClientsFilterAC(true));
      try {
        const res = await clientsAPI.getFilteredClientListOfGeneral(queryParams);
        if (currentSortParam === 'first_name' || currentSortParam === '-first_name') {
          dispatch(setActiveArrowFullNameAC(true));
          dispatch(setActiveArrowInsuranceAC(false));
          dispatch(setSortByFullNameAC(!isActiveFullName));
        }
        if (currentSortParam === 'insurance_type' || currentSortParam === '-insurance_type') {
          dispatch(setActiveArrowInsuranceAC(true));
          dispatch(setActiveArrowFullNameAC(false));
          dispatch(setSortByInsuranceAC(!isActiveInsurance));
        }

        dispatch(setClientsParamsAC(res.data));
        dispatch(setClientsListAC(res.data.results));
        dispatch(setPreloaderClientsFilterAC(false));
        dispatch(setCurrClientsQueryStringAC(queryParams));
        dispatch(setAppStatusAC('succeeded'));
      } catch (error: any) {
        if (error.message === 'Network Error') {
          handleServerNetworkError(error, dispatch);
        } else {
          handleServerAppError(error, dispatch);
        }
      }
      dispatch(setAppStatusAC('idle'));
    };

export const setFilteredClientsOfInsuranceTC =
  (currentInsurance: string) =>
    async (dispatch: Dispatch<any>, getState: () => AppRootStateType) => {
      const currentInsuranceChecked =
        getState().clientsFilter.currentFilterParams.insurance_type__in.find(
          (insurance) => insurance === currentInsurance,
        );
      const currentBranch = getState().branches.currentBranch;
      try {
        if (currentInsuranceChecked) {
          dispatch(removeCurrentClientsFilterInsuranceAC(currentInsurance));
        } else {
          dispatch(addCurrentClientsFilterInsuranceAC(currentInsurance));
        }
        await dispatch(setCurrentParamBranchAC(currentBranch.toString()));
        dispatch(fetchFilteredClientsListOfGeneral());
      } catch (error: any) {
        if (error.message === 'Network Error') {
          handleServerNetworkError(error, dispatch);
        } else {
          handleServerAppError(error, dispatch);
        }
      }
    };

export const fetchFilteredClientsListOfBranchTC =
  (branchId: any) => async (dispatch: Dispatch<any>, getState: () => AppRootStateType) => {
    const insurance_type__in = getState().clientsFilter.currentFilterParams.insurance_type__in;
    const doctors__in = getState().clientsFilter.currentFilterParams.doctors__in;

    dispatch(setAppStatusAC('loading'));
    try {
      if (insurance_type__in) {
        dispatch(removeCurrentClientsFilterInsuranceAC(insurance_type__in[0]));
      }
      if (doctors__in) {
        dispatch(removeCurrentClientFilterDoctorAC(doctors__in[0]));
      }

      dispatch(setCurrentClientsPageAC(null));
      dispatch(setCurrentParamBranchAC(branchId));
      dispatch(fetchFilteredClientsListOfGeneral(branchId, 'first_name'));
      dispatch(setSortByFullNameAC(true));
      dispatch(setActiveArrowFullNameAC(true));
      dispatch(setActiveArrowInsuranceAC(false));
    } catch (error: any) {
      if (error.message === 'Network Error') {
        handleServerNetworkError(error, dispatch);
      } else {
        handleServerAppError(error, dispatch);
      }
    }
    dispatch(setAppStatusAC('idle'));
  };

export const setFilteredClientsSearchTC = (search: string) => async (dispatch: Dispatch<any>) => {
  try {
    dispatch(addClientSearchParamAC(search));
    dispatch(fetchFilteredClientsListOfGeneral());
  } catch (error: any) {
    if (error.message === 'Network Error') {
      handleServerNetworkError(error, dispatch);
    } else {
      handleServerAppError(error, dispatch);
    }
  }
};

export const setFilteredClientOfDoctorTC =
  (currentDoctor: string) => async (dispatch: Dispatch<any>, getState: () => AppRootStateType) => {
    const currentDoctorChecked = getState().clientsFilter.currentFilterParams.doctors__in.find(
      (status) => status === currentDoctor,
    );
    const currentBranch = getState().branches.currentBranch;

    try {
      if (currentDoctorChecked) {
        dispatch(removeCurrentClientFilterDoctorAC(currentDoctor));
      } else {
        dispatch(addCurrentClientFilterDoctorAC(currentDoctor));
      }
      await dispatch(setCurrentParamBranchAC(currentBranch.toString()));
      dispatch(fetchFilteredClientsListOfGeneral());
    } catch (error: any) {
      if (error.message === 'Network Error') {
        handleServerNetworkError(error, dispatch);
      } else {
        handleServerAppError(error, dispatch);
      }
    }
  };