import { OrderFilterParamsType } from '../../api/order-api';
import {
  ADD_CURRENT_ORDER_FILTER_BRANCH,
  ADD_CURRENT_ORDER_FILTER_DOCTOR,
  ADD_ORDER_SEARCH,
  CLEAN_ORDERS_FILTER,
  COLLAPSE_DOCTOR_ORDER_FILTER,
  CURRENT_TITLE_ORDER_FILTER,
  REMOVE_CURRENT_ORDER_FILTER_DOCTOR,
  SET_CURRENT_ORDERS_QUERY_STRING,
  SET_ORDER_FILTER_PARAMS,
  SET_PRELOADER_ORDER_FILTER,
} from './constants';

export const setCurrentTitleOrdersFilterAC = (params: string) =>
  ({ type: CURRENT_TITLE_ORDER_FILTER, params } as const);
export const setOrdersFilterParamsAC = (params: OrderFilterParamsType) =>
  ({ type: SET_ORDER_FILTER_PARAMS, params } as const);
export const addCurrentOrderFilterBranchAC = (params: string) =>
  ({ type: ADD_CURRENT_ORDER_FILTER_BRANCH, params } as const);
export const addCurrentOrderFilterDoctorAC = (params: string) =>
  ({ type: ADD_CURRENT_ORDER_FILTER_DOCTOR, params } as const);
export const removeCurrentOrderFilterDoctorAC = (params: string) =>
  ({ type: REMOVE_CURRENT_ORDER_FILTER_DOCTOR, params } as const);
export const collapseDoctorOrdersFilterAC = (param: boolean) =>
  ({ type: COLLAPSE_DOCTOR_ORDER_FILTER, param } as const);
export const setPreloaderOrdersFilterAC = (param: boolean) =>
  ({ type: SET_PRELOADER_ORDER_FILTER, param } as const);
export const addOrderSearchParamAC = (params: string) =>
  ({ type: ADD_ORDER_SEARCH, params } as const);
export const cleanOrdersFilterAC = () => ({ type: CLEAN_ORDERS_FILTER } as const);
export const setCurrentOrdersQueryStringAC = (query: string | null) =>
  ({
    type: SET_CURRENT_ORDERS_QUERY_STRING,
    query,
  } as const);