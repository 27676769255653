import React, { FC, memo } from 'react';
import { NavLink } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { EventDetailType, EventType, StatusParamsType } from '../../../../api/events-api';
import { logo } from '../../../../assets/img/image';
import { PATH } from '../../../../routes/Routes';
import cx from 'classnames';
import Collapse from '@kunukn/react-collapse';
import { MenuBtn } from '../../../../components/MenuBtn/MenuBtn';
import './ActionMenuEvents.scss';
import { DateListEventType } from '../../../../api/branches-api';

type ActionMenuPropsType = {
  event: EventType;
  options: boolean;
  onClickOptions: (eventId: number) => void;
  selectedIndex: number | null;
  setSelectedIndex: (value: number) => void;
  onLoadHandler: () => void;
  load: boolean;
  onChangeStatus: (eventId: number, currentStatus: string) => void;
  statusFilterParams: StatusParamsType;
  openModalAlertWaitingRoom: () => void;
  openAlertOnCancel: (eventsId: number) => void;
  setCurrentPathForRedirect?: (event: DateListEventType | EventDetailType | null) => void;
};

export const ActionMenuEvents: FC<ActionMenuPropsType> = memo(
  ({
    onClickOptions,
    selectedIndex,
    event,
    options,
    setSelectedIndex,
    onLoadHandler,
    load,
    onChangeStatus,
    statusFilterParams,
    openModalAlertWaitingRoom,
    openAlertOnCancel,
    setCurrentPathForRedirect,
  }) => {
    const { t } = useTranslation();

    const onClickOptionHandler = (): void => {
      onClickOptions(event.id);
    };
    const setCurrentEventFromScheduleHandler = (): void => {
      setCurrentPathForRedirect && setCurrentPathForRedirect(null);
    };

    return (
      <div
        onClick={() => {
          onClickOptions(event.id);
          setSelectedIndex(event.id);
        }}
        className={cx('app__toggle', {
          'app__toggle--active': options,
        })}
      >
        <div>
          <MenuBtn
            showSidebar={onClickOptionHandler}
            isOpenMenu={options && selectedIndex === event.id}
          />
        </div>
        <Collapse
          isOpen={options && selectedIndex === event.id}
          className={
            'app__collapse app__collapse--gradient ' +
            (options && selectedIndex === event.id ? 'app__collapse--active' : '')
          }
        >
          <div className="app__content">
            <div className={'options'}>
              <NavLink className="actions" to={`${PATH.EVENTS}details/${event.id}/`}>
                <img
                  onLoad={onLoadHandler}
                  style={{
                    height: '15px',
                  }}
                  className={'img'}
                  src={logo.info}
                  alt="details"
                />
                {t('details')}
              </NavLink>

              {event.status === 'new' ? (
                <span
                  className="actions"
                  onClick={() => {
                    onChangeStatus(event.id, statusFilterParams.waiting_room);
                    setSelectedIndex(event.id);
                    openModalAlertWaitingRoom();
                  }}
                >
                  <img onLoad={onLoadHandler} alt="Waiting" className={'img'} src={logo.waiting} />
                  {t('waiting')}
                </span>
              ) : (
                ''
              )}
              {event.status === 'waiting_room' ? (
                <>
                  <span
                    className="actions"
                    onClick={() => {
                      onChangeStatus(event.id, statusFilterParams.at_the_doctor);
                      openModalAlertWaitingRoom();
                    }}
                  >
                    <img
                      onLoad={onLoadHandler}
                      className={'img'}
                      // src={logo.check}
                      src={logo.doctor_icon}
                      alt="at_the_doctor"
                    />
                    {t('event_at_the_doctor')}
                  </span>

                  <span
                    className={'actions'}
                    onClick={() => onChangeStatus(event.id, statusFilterParams.did_not_wait)}
                  >
                    <img
                      onLoad={onLoadHandler}
                      className={'img'}
                      src={logo.close}
                      alt="did_not_wait"
                    />
                    {t('event_did_not_wait')}
                  </span>
                </>
              ) : (
                ''
              )}
              {event.status === 'at_the_doctor' ? (
                <span
                  onClick={() => {
                    onChangeStatus(event.id, statusFilterParams.done);
                  }}
                  className="actions"
                >
                  <img
                    onLoad={onLoadHandler}
                    className={load ? 'img' : 'img'}
                    src={logo.done}
                    alt="done"
                  />
                  {t('done')}
                </span>
              ) : (
                ''
              )}
              <NavLink
                className={'actions'}
                onClick={setCurrentEventFromScheduleHandler}
                to={`${PATH.EVENTS}${event.id}/`}
              >
                <img
                  loading="eager"
                  /* onLoad={onLoadHandler} */ className={'img'}
                  src={logo.edit}
                  alt="edit"
                />
                {t('edit')}
              </NavLink>
              <span onClick={() => openAlertOnCancel(event.id)} className="actions">
                <img onLoad={onLoadHandler} className={'img'} src={logo.cancel} alt="cancel" />
                {t('cancel')}
              </span>
            </div>
          </div>
        </Collapse>
      </div>
    );
  },
);