import React, { FC } from 'react';
import { logo } from '../../../assets/img/image';
import './ButtonClose.scss';

type ButtonClosePropsType = {
  onClickBtn?: () => void;
};

export const ButtonClose: FC<ButtonClosePropsType> = ({ onClickBtn }) => {

  const onClickHandler = (): void => {
    onClickBtn && onClickBtn()
  }

  return (
    <button
      className="button-close-component"
      onClick={(e) => {
        e.stopPropagation();
        onClickHandler();
      }}
    >
      <img src={logo.close_gray} alt="close" />
    </button>
  );
};