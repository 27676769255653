import React, { FC, useEffect, useMemo, useState } from 'react';
import { SubmitHandler, useForm } from 'react-hook-form';
import './FormReason.scss';
import '../../../components/Widgets/Button/Button.scss';
import { useDispatch, useSelector } from 'react-redux';
import { Navigate, useParams } from 'react-router-dom';
import { PATH } from '../../../routes/Routes';
import Inputs from '../../../components/Widgets/Inputs';
import {
  defaultChoicesMultiSelect,
  defaultChoicesSelect,
  newOption,
} from '../../../components/Widgets/Select/ReactSelectStyles';
import { ReactSelect } from '../../../components/Widgets/Select/ReactSelect';
import { useErrorHandler } from '../../../hooks/useErrorHandler';
import {
  getErrorStateSelector,
  getFieldsReasonsSelector,
  getIsLoggedInSelector,
  getOpenSidebarAppSelector,
  getReasonsSelector,
  getStatusSelector,
} from '../../../redux/selectors';
import { ErrorMessage } from '../../../components/ErrorMessage/ErrorMessage';
import { useTranslation } from 'react-i18next';
import TranslationKeys from '../../../assets/locales/translationKeys/translationKeys';
import InputsColor from '../../../components/Widgets/InputsColor';
import {
  addReasonTC,
  editReasonTC,
  fetchReasonsList,
  fetchReasonsListFieldsTC,
} from '../../../redux/middlewares';
import CheckboxInput from "../../../components/Widgets/CheckboxInput";

type FormDataType = {
  branches: { value: any; label: any };
  title: string;
  offset_time: { value: any; label: any };
  limit_time: number;
  order: number;
  color: string;
  doctors: { value: any; label: any };
  external: boolean;
};

export const FormReason: FC = () => {
  const dispatch = useDispatch();
  const params = useParams();

  const reasons = useSelector(getReasonsSelector);
  const status = useSelector(getStatusSelector);
  const isLoggedIn = useSelector(getIsLoggedInSelector);
  const fields = useSelector(getFieldsReasonsSelector);
  const errorState = useSelector(getErrorStateSelector);
  const isOpenSidebar = useSelector(getOpenSidebarAppSelector);

  const [valueReason, setValueReason] = useState<boolean>(false);

  //locale
  const { t } = useTranslation();

  //for edit reason
  const idUrl = params.id;
  const reasonId = Number(idUrl);
  const currentReason = reasons.length && reasons.filter((res) => res.id === reasonId)[0];

  const [background, setBackground] = useState<string>(
    currentReason ? currentReason.color : fields.color?.initial_value?.toString(),
  );

  const setColorReason = (color: string): void => {
    setBackground(color);
  };

  //validation
  const formDataArray = [
    'branches',
    'title',
    'offset_time',
    'limit_time',
    'order',
    'color',
    'doctors',
    'external,',
  ];

  const branchesErrorHandler = useErrorHandler('branches', errorState, formDataArray);
  const titleErrorHandler = useErrorHandler('title', errorState, formDataArray);
  const offsetTimeErrorHandler = useErrorHandler('offset_time', errorState, formDataArray);
  const limitTimeErrorHandler = useErrorHandler('limit_time', errorState, formDataArray);
  const orderErrorHandler = useErrorHandler('order', errorState, formDataArray);
  const colorErrorHandler = useErrorHandler('color', errorState, formDataArray);
  const doctorsErrorHandler = useErrorHandler('doctors', errorState, formDataArray);
  // for select
  const choicesBranches = Object.entries(fields.branches.choices).map((br) => br);
  const valueOptionBranches =
    fields && Object.entries(fields.branches.choices).map((b: any) => newOption(b[0], b[1]));
  const labelOptionBranches =
    currentReason && currentReason.branches.map((el: any) => el.id.toString());

  const choicesOffset = Object.entries(fields.branches.choices).map((br) => br);
  const valueOptionOffset =
    fields && Object.entries(fields.offset_time.choices).map((b: any) => newOption(b[0], b[1]));
  const labelOptionOffset = currentReason && currentReason.offset_time.toString();

  const choicesDoctor = Object.entries(fields.doctors.choices).map((br) => br);
  const valueOptionDoctor =
    fields && Object.entries(fields.doctors.choices).map((b: any) => newOption(b[0], b[1]));
  const labelOptionDoctor =
    currentReason && currentReason.doctors.map((el: any) => el.id.toString());

  const reasonData = currentReason
    ? {
        title: currentReason.title,
        offset_time: currentReason
          ? defaultChoicesSelect(valueOptionOffset, labelOptionOffset)[0]
          : '',
        limit_time: currentReason.limit_time,
        order: currentReason.order,
        color: background,
        branches: currentReason
          ? defaultChoicesMultiSelect(valueOptionBranches, labelOptionBranches)
          : '',
        doctors: currentReason
          ? defaultChoicesMultiSelect(valueOptionDoctor, labelOptionDoctor)
          : '',
        external: currentReason.external,
      }
    : {
        title: fields.title.initial_value,
        limit_time: fields.limit_time.initial_value,
        order: fields.order.initial_value,
        color: fields.color.initial_value,
      };

  useEffect(() => {
    dispatch(fetchReasonsListFieldsTC());
    dispatch(fetchReasonsList());
  }, [dispatch]);

  useEffect(() => {
    if (!currentReason) {
      setBackground(fields.color.initial_value);
    }
  }, [currentReason, fields.color]);

  useEffect(() => {
    if (status === 'failed') {
      branchesErrorHandler.setErrorCallback();
      titleErrorHandler.setErrorCallback();
      offsetTimeErrorHandler.setErrorCallback();
      limitTimeErrorHandler.setErrorCallback();
      orderErrorHandler.setErrorCallback();
      colorErrorHandler.setErrorCallback();
      doctorsErrorHandler.setErrorCallback();
    }
  }, [status]);

  const { register, handleSubmit, control, reset } = useForm<FormDataType>({
    mode: 'onBlur',
    defaultValues: useMemo(() => {
      if (reasonData) {
        return reasonData;
      }
    }, [reasonData, fields]),
  });

  useEffect(() => {
    if (reasonData) {
      reset(reasonData);
    }
  }, [currentReason, fields]);
  useEffect(() => {
    document.title = currentReason
      ? t(TranslationKeys.reason_form_edit)
      : t(TranslationKeys.reason_form_add);
  }, []);

  const onSubmit: SubmitHandler<FormDataType> = (data) => {
    const newData = {
      branches: data.branches && Object.values(data.branches).map((br: any) => br.value),
      title: data.title,
      offset_time: data.offset_time && data.offset_time.value,
      limit_time: data.limit_time,
      order: data.order,
      color: background ? background : data.color,
      doctors: data.doctors && Object.values(data.doctors).map((br: any) => br.value),
      external: data.external,
    };

    if (currentReason) {
      dispatch(editReasonTC(reasonId, newData));
    } else {
      dispatch(addReasonTC(newData));
    }
    setValueReason(true);
  };

  if (valueReason && status === 'succeeded') {
    return <Navigate to={PATH.REASONS} />;
  }

  if (!isLoggedIn) {
    return <Navigate to={PATH.LOGIN} />;
  }

  return (
    <>
      <div className={`${isOpenSidebar ? 'add-reason' : 'add-reason full-width'}`}>
        <h1 className="add-reason__title">
          {currentReason ? t(TranslationKeys.reason_form_edit) : t(TranslationKeys.reason_form_add)}
        </h1>
        <form onSubmit={handleSubmit(onSubmit)} className="add-reason__form">
          <label className="add-reason__inputs-label">{t(TranslationKeys.info)}</label>
          <div className="add-reason__inputs-info">
            <Inputs
              error={titleErrorHandler.error}
              help_text={
                fields.title.help_text ||
                (titleErrorHandler.error && titleErrorHandler.errorMessageCurrentField[1])
              }
              onClick={titleErrorHandler.onFieldClick}
              state={'active'}
              register={register}
              input_type={fields.title.input_type}
              name={'title'}
              label={fields.title.label}
              defaultValue={currentReason ? currentReason.title : fields.title.initial_value}
              {...reset}
            />
            <Inputs
              error={orderErrorHandler.error}
              help_text={
                fields.order.help_text ||
                (orderErrorHandler.error && orderErrorHandler.errorMessageCurrentField[1])
              }
              onClick={orderErrorHandler.onFieldClick}
              state={'active'}
              register={register}
              input_type="number"
              name={'order'}
              label={fields.order.label}
              defaultValue={currentReason ? currentReason.order : fields.order.initial_value}
              {...reset}
            />
          </div>
          <div className="add-reason__inputs-setting">
            <ReactSelect
              name="offset_time"
              error={offsetTimeErrorHandler.error}
              help_text={
                fields.offset_time.help_text ||
                (offsetTimeErrorHandler.error && offsetTimeErrorHandler.errorMessageCurrentField[1])
              }
              label={fields.offset_time.label}
              placeholder={fields.offset_time.label}
              onClick={offsetTimeErrorHandler.onFieldClick}
              control={control}
              isMulti={false}
              required={fields.offset_time.required}
              defaultValue={
                currentReason
                  ? defaultChoicesMultiSelect(valueOptionOffset, labelOptionOffset)[0]
                  : ''
              }
              options={
                choicesOffset &&
                Object.entries(fields.offset_time.choices).map((b: any) => newOption(b[0], b[1]))
              }
            />
            <Inputs
              error={limitTimeErrorHandler.error}
              help_text={
                fields.limit_time.help_text ||
                (limitTimeErrorHandler.error && limitTimeErrorHandler.errorMessageCurrentField[1])
              }
              onClick={limitTimeErrorHandler.onFieldClick}
              state={'active'}
              register={register}
              name={'limit_time'}
              input_type="number"
              label={fields.limit_time.label}
              defaultValue={
                currentReason ? currentReason.limit_time : fields.limit_time.initial_value
              }
              {...reset}
            />
            <ReactSelect
              name="doctors"
              error={doctorsErrorHandler.error}
              help_text={
                doctorsErrorHandler.error && doctorsErrorHandler.errorMessageCurrentField[1]
              }
              label={fields.doctors.label}
              placeholder={fields.doctors.label}
              onClick={doctorsErrorHandler.onFieldClick}
              control={control}
              isMulti={true}
              required={fields.doctors.required}
              options={
                choicesDoctor &&
                Object.entries(fields.doctors.choices).map((b: any) => newOption(b[0], b[1]))
              }
            />

            <InputsColor
              error={colorErrorHandler.error}
              help_text={
                fields.color.help_text ||
                (colorErrorHandler.error && colorErrorHandler.errorMessageCurrentField[1])
              }
              onClick={colorErrorHandler.onFieldClick}
              state={'active'}
              register={register}
              input_type={'color'}
              disabled
              name={'color'}
              label={fields.color.label}
              choices={fields.color.choices}
              currentColor={background}
              onChangeText={setColorReason}
              defaultValue={currentReason ? currentReason.color : fields.color.initial_value}
              {...reset}
            />
          </div>

          <label className="add-reason__inputs-label">
            {/*{fields.branches.label}*/}
            {t(TranslationKeys.branch_for_title)}
          </label>
          <div className="add-reason__inputs-branch">
            <ReactSelect
              name="branches"
              error={branchesErrorHandler.error}
              help_text={
                fields.branches.help_text ||
                (branchesErrorHandler.error && branchesErrorHandler.errorMessageCurrentField[1])
              }
              label={fields.branches.label}
              placeholder={fields.branches.label}
              onClick={branchesErrorHandler.onFieldClick}
              control={control}
              isMulti={true}
              required={fields.branches.required}
              options={
                choicesBranches &&
                Object.entries(fields.branches.choices).map((b: any) => newOption(b[0], b[1]))
              }
            />
          </div>

          <CheckboxInput
            id="external"
            state={'square'}
            input_type={fields.external.input_type}
            name={'external'}
            label={fields.external.label}
            defaultChecked={
              currentReason ? currentReason.external : fields.external.initial_value
            }
            help_text={fields.external.help_text}
            register={register}
          />

          <div className="_button-wrapper">
            <button className="button button_size-middle button_position-right button_color-black">
              {t(TranslationKeys.submit)}
            </button>
          </div>

          <ErrorMessage status={status} errorState={errorState} />
        </form>
      </div>
    </>
  );
};
