import { BranchType, ScheduleBranchType } from '../../api/branches-api';
import {
  ADD_BRANCH,
  CHANGE_STATE_CALENDAR_PATH,
  EDIT_BRANCH,
  GET_SCHEDULE_BRANCH,
  REMOVE_BRANCH,
  SET_ACTIVE_CURRENT_WEEK,
  SET_BRANCHES_LIST,
  SET_CURRENT_BRANCH,
  SET_PRELOADER_BRANCHES,
} from './constants';
import { UpdateBranchesModuleType } from './types';

export const setBranchesListAC = (branches: Array<BranchType>) =>
  ({ type: SET_BRANCHES_LIST, branches } as const);
export const addBranchAC = (branch: BranchType) => ({ type: ADD_BRANCH, branch } as const);
export const editBranchAC = (module: UpdateBranchesModuleType) =>
  ({ type: EDIT_BRANCH, module } as const);
export const removeBranchAC = (id: number) => ({ type: REMOVE_BRANCH, id } as const);
export const getScheduleBranchesAC = (scheduleBranch: ScheduleBranchType) =>
  ({ type: GET_SCHEDULE_BRANCH, scheduleBranch } as const);
export const setCurrentBranchesAC = (currentBranchId: number) =>
  ({ type: SET_CURRENT_BRANCH, currentBranchId } as const);
export const setActiveCurrentWeekAC = (activeWeek: string | null) =>
  ({ type: SET_ACTIVE_CURRENT_WEEK, activeWeek } as const);
export const changeStateCalendarAC = (state: boolean) =>
  ({ type: CHANGE_STATE_CALENDAR_PATH, state } as const);
export const setPreloaderAC = (param: boolean) => ({ type: SET_PRELOADER_BRANCHES, param } as const);
