import React, { FC, memo } from 'react';
import { DateOfListType, ScheduleBranchType } from '../../../../api/branches-api';

type NonWorkingHoursSmallPropsType = {
  scheduleBranchDate: Array<DateOfListType>;
  dayIndex: number;
  scheduleBranch: ScheduleBranchType;
  heightHour: string;
};

export const NonWorkingHoursSmall: FC<NonWorkingHoursSmallPropsType> = memo(
  ({ dayIndex, scheduleBranch, scheduleBranchDate, heightHour }) => {
    const CURRENT_DAY = scheduleBranchDate.find(day => day.date_index === dayIndex);

    const HeightNonWorkingStartHours = (): number => {
      const startCurrDayMS = new Date(`${CURRENT_DAY?.date} ${CURRENT_DAY?.start_time_work}`).getTime();
      const startEarlyDayMS = new Date(`${CURRENT_DAY?.date} ${scheduleBranch.day_start}`).getTime();

      return (startCurrDayMS - startEarlyDayMS) / (1000 * 60) / 60;
    };

    const HeightNonWorkingEndHours = (): number => {
      const ednCurrDayMS = new Date(`${CURRENT_DAY?.date} ${CURRENT_DAY?.end_time_work}`).getTime();
      const endEarlyDayMS = new Date(`${CURRENT_DAY?.date} ${scheduleBranch.day_end}`).getTime();

      return (endEarlyDayMS - ednCurrDayMS) / (1000 * 60) / 60;
    };

    const NonWorkingHoursPosition = (): number => {
      const startEarlyDayMS = new Date(`${CURRENT_DAY?.date} ${scheduleBranch.day_start}`).getTime();
      const endEarlyDayMS = new Date(`${CURRENT_DAY?.date} ${scheduleBranch.day_end}`).getTime();
      const heightDay = (endEarlyDayMS - startEarlyDayMS) / (1000 * 60) / 60;

      return heightDay - HeightNonWorkingEndHours();
    };

    const LunchHoursPosition = (): number => {
      const startEarlyDayMS = new Date(`${CURRENT_DAY?.date} ${scheduleBranch.day_start}`).getTime();
      const startLunchMS = new Date(`${CURRENT_DAY?.date} ${CURRENT_DAY?.start_lunch_break}`).getTime();

      return (startLunchMS - startEarlyDayMS) / (1000 * 60) / 60;
    };

    const HeightLunch = (): number => {
      const startLunchMS = new Date(`${CURRENT_DAY?.date} ${CURRENT_DAY?.start_lunch_break}`).getTime();
      const endLunchMS = new Date(`${CURRENT_DAY?.date} ${CURRENT_DAY?.end_lunch_break}`).getTime();

      return (endLunchMS - startLunchMS) / (1000 * 60) / 60;
    };

    return (
      <>
        {CURRENT_DAY && CURRENT_DAY?.start_time_work?.slice(0, 5) !== scheduleBranch?.day_start ? (
          <div
            className="non-working-hours-start"
            title=""
            style={{height: `calc((${heightHour} * ${HeightNonWorkingStartHours()})`}}
          >
          </div>
        ) : (
          ''
        )}

        {CURRENT_DAY && CURRENT_DAY?.lunch_break ? (
          <div
            className="lunch"
            title=""
            style={{
              top: `calc((${heightHour} * ${LunchHoursPosition()} + 60px)`,
              height: `calc((${heightHour} * ${HeightLunch()})`,
            }}
          >
          </div>
        ) : (
          ''
        )}

        {CURRENT_DAY && CURRENT_DAY?.end_time_work?.slice(0, 5) !== scheduleBranch?.day_end ? (
          <div
            className="non-working-hours-end"
            title=""
            style={{
              top: `calc((${heightHour} * ${NonWorkingHoursPosition()} + 60px)`,
              height: `calc((${heightHour} * ${HeightNonWorkingEndHours()})`,
            }}
          >
          </div>
        ) : (
          ''
        )}
      </>
    );
  },
);
