import { Dispatch } from "redux";
import { addContactAC, editContactAC, removeContactAC, setAppStatusAC, setListContactsAC } from "../actions";
import { contactsAPI } from "../../api/contacts-api";
import { handleServerAppError, handleServerNetworkError } from "../../utils/error-utils";

export const fetchContactsList = () => async (dispatch: Dispatch<any>) => {
  dispatch(setAppStatusAC('loading'));
  try {
    let res = await contactsAPI.getList();
    dispatch(setListContactsAC(res.data));
    dispatch(setAppStatusAC('succeeded'));
  } catch (error: any) {
    if (error.message === 'Network Error') {
      handleServerNetworkError(error, dispatch);
    } else {
      handleServerAppError(error, dispatch);
    }
  }
  dispatch(setAppStatusAC('idle'));
};

export const addContactTC = (data: any) => async (dispatch: Dispatch<any>) => {
  dispatch(setAppStatusAC('loading'));
  try {
    let res = await contactsAPI.addContact(data);
    dispatch(addContactAC(res.data));
    dispatch(setAppStatusAC('succeeded'));
  } catch (error: any) {
    if (error.message === 'Network Error') {
      handleServerNetworkError(error, dispatch);
    } else {
      handleServerAppError(error, dispatch);
    }
  }
};

export const editContactTC = (id: number, data: any) => async (dispatch: Dispatch<any>) => {
  dispatch(setAppStatusAC('loading'));
  try {
    let res = await contactsAPI.editContact(data, id);
    dispatch(editContactAC(res.data));
    dispatch(setAppStatusAC('succeeded'));
  } catch (error: any) {
    if (error.message === 'Network Error') {
      handleServerNetworkError(error, dispatch);
    } else {
      handleServerAppError(error, dispatch);
    }
  }
};

export const removeContactTC = (reasonId: number) => async (dispatch: Dispatch<any>) => {
  dispatch(setAppStatusAC('loading'));
  try {
    await contactsAPI.removeContact(reasonId);
    dispatch(removeContactAC(reasonId));
    dispatch(setAppStatusAC('succeeded'));
  } catch (error: any) {
    if (error.message === 'Network Error') {
      handleServerNetworkError(error, dispatch);
    } else {
      handleServerAppError(error, dispatch);
    }
  }
};