import React, { FC, useEffect, useMemo, useState } from 'react';
import './Profile.scss';
import { useDispatch, useSelector } from 'react-redux';
import { Navigate } from 'react-router-dom';
import { SubmitHandler, useForm } from 'react-hook-form';
import { PATH } from '../../routes/Routes';
import Inputs from '../../components/Widgets/Inputs';
import { addPhotoImg, logo, removePhotoImg } from '../../assets/img/image';
import {
  getErrorStateSelector,
  getIsLoggedInSelector,
  getOpenSidebarAppSelector,
  getSStatusProfileRequest,
  getStatusSelector,
  getUserProfileSelector,
} from '../../redux/selectors';
import { ErrorMessage } from '../../components/ErrorMessage/ErrorMessage';
import { useErrorHandler } from '../../hooks/useErrorHandler';
import { useTranslation } from 'react-i18next';
import TranslationKeys from '../../assets/locales/translationKeys/translationKeys';
import { getProfileTC, initializeAppTC, updateProfileTC } from '../../redux/middlewares';
import { changeStatusRequestProfileAC, setAppErrorAC, setAppStatusAC } from '../../redux/actions';

type FormDataType = {
  first_name: string;
  last_name: string;
  email: string;
  phone: string;
  photo?: File;
};

type ProfilePropsType = {};

const baseURL = process.env.REACT_APP_BASE_URL;

export const Profile: FC<ProfilePropsType> = () => {
  const dispatch = useDispatch();

  const errorState = useSelector(getErrorStateSelector);
  const status = useSelector(getStatusSelector);
  const isLoggedIn = useSelector(getIsLoggedInSelector);
  const user = useSelector(getUserProfileSelector);
  const isOpenSidebar = useSelector(getOpenSidebarAppSelector);
  const successProfileRequest = useSelector(getSStatusProfileRequest);

  const [selectedPhoto, setSelectedPhoto] = useState<any>();
  const [isChosenPhoto, setIsChosenPhoto] = useState<boolean>(false);
  const [isRemovedPhoto, setIsRemovedPhoto] = useState<boolean>(false);

  //locale
  const { t } = useTranslation();

  // validation
  const formDataArray = ['first_name', 'last_name', 'email', 'phone', 'photo'];

  const firstNameErrorHandler = useErrorHandler('first_name', errorState, formDataArray);
  const lastNameErrorHandler = useErrorHandler('last_name', errorState, formDataArray);
  const emailErrorHandler = useErrorHandler('email', errorState, formDataArray);
  const phoneErrorHandler = useErrorHandler('phone', errorState, formDataArray);

  const userData = {
    first_name: user.first_name,
    last_name: user.last_name,
    email: user.email,
    phone: user.phone,
  };

  useEffect(() => {
    if (status === 'failed') {
      dispatch(initializeAppTC());
    }
  }, [status]);

  useEffect(() => {
    if (isLoggedIn) {
      dispatch(getProfileTC());
    }
  }, [dispatch]);

  useEffect(() => {
    if (status === 'failed') {
      firstNameErrorHandler.setErrorCallback();
      lastNameErrorHandler.setErrorCallback();
      emailErrorHandler.setErrorCallback();
      phoneErrorHandler.setErrorCallback();
    }
  }, [status]);

  useEffect(() => {
    return () => {
      dispatch(changeStatusRequestProfileAC(null));
    };
  }, []);
  useEffect(() => {
    document.title = t(TranslationKeys.profile_nav);
  }, []);

  const closeAlertStatus = (): void => {
    dispatch(changeStatusRequestProfileAC(null));
  };

  const cleanPhotoHandler = (): void => {
    setIsChosenPhoto(false);
    setIsRemovedPhoto(true);
    setSelectedPhoto(null);
  };

  const userPhoto =
    user.photo !== null && selectedPhoto !== null ? `${baseURL}${user.photo}` : logo.notLogo;
  const userPhotoPreview = isChosenPhoto
    ? URL.createObjectURL(selectedPhoto[0])
    : `${baseURL}${user.photo}`;

  const { register, handleSubmit, reset /*formState: {errors}*/ } = useForm<FormDataType>({
    mode: 'onBlur',

    defaultValues: useMemo(() => {
      return userData;
    }, [userData]),
  });

  useEffect(() => {
    reset(userData);
  }, [user]);

  const onSubmit: SubmitHandler<FormDataType> = (data) => {
    setIsChosenPhoto(false);

    if (isChosenPhoto) {
      setSelectedPhoto(data.photo);
    }

    const notChosen = isRemovedPhoto ? 'del' : 'not';
    const newPhoto = isChosenPhoto ? selectedPhoto : notChosen;

    const newData = {
      first_name: data.first_name,
      last_name: data.last_name,
      email: data.email,
      phone: data.phone,
      photo: newPhoto,
    };

    dispatch(updateProfileTC(newData));
  };

  if (!isLoggedIn) {
    return <Navigate to={PATH.LOGIN} />;
  }

  if (status !== 'failed') {
    dispatch(setAppStatusAC('idle'));
    dispatch(setAppErrorAC(''));
  }

  return (
    <>
      <div
        className={`${isOpenSidebar ? 'profile' : 'profile full-width'}`}
      >
        <h1 className="profile__title">{t(TranslationKeys.profile_nav)}</h1>
        <form onSubmit={handleSubmit(onSubmit)} className="profile__profile-update">
          <div className="upload-avatar">
            <div className="upload-avatar__edit">
              <label htmlFor="fileLoad" className="edit-label" style={addPhotoImg}>
                <input
                  id="fileLoad"
                  className="edit-input"
                  type="file"
                  {...register('photo', {
                    onChange: (e) => {
                      if (e.target.files.length) {
                        setSelectedPhoto(e.target.files);
                        setIsChosenPhoto(true);
                      } else {
                        if (selectedPhoto) {
                          setIsChosenPhoto(true);
                        } else {
                          setIsChosenPhoto(false);
                        }
                      }
                    },
                  })}
                />
              </label>
            </div>
            <div className="upload-avatar__clear">
              <label htmlFor="fileClear" className="clear-label" style={removePhotoImg}>
                <input
                  id="fileClear"
                  className="clear-input"
                  type="checkbox"
                  onClick={cleanPhotoHandler}
                />
              </label>
            </div>
            {isChosenPhoto && (
              <img src={userPhotoPreview} className="upload-avatar__preview" alt="user" />
            )}
            {!isChosenPhoto && (
              <img src={userPhoto} className="upload-avatar__preview" alt="user" />
            )}
          </div>
          <label className="inputs-label">{t(TranslationKeys.profile_personal_info)}</label>
          <div className="inputs-info">
            <Inputs
              state={'active'}
              error={firstNameErrorHandler.error}
              help_text={
                firstNameErrorHandler.error && firstNameErrorHandler.errorMessageCurrentField[1]
              }
              onClick={firstNameErrorHandler.onFieldClick}
              register={register}
              input_type={'text'}
              name={'first_name'}
              label={t(TranslationKeys.profile_first_name)}
              defaultValue={user && user.first_name}
              {...reset}
            />
            <Inputs
              state={'active'}
              error={lastNameErrorHandler.error}
              onClick={lastNameErrorHandler.onFieldClick}
              help_text={
                lastNameErrorHandler.error && lastNameErrorHandler.errorMessageCurrentField[1]
              }
              register={register}
              input_type={'text'}
              name={'last_name'}
              label={t(TranslationKeys.profile_last_name)}
              defaultValue={user && user.last_name}
              {...reset}
            />
          </div>
          <div className="inputs-info">
            <Inputs
              state={'active'}
              error={emailErrorHandler.error}
              onClick={emailErrorHandler.onFieldClick}
              help_text={emailErrorHandler.error && emailErrorHandler.errorMessageCurrentField[1]}
              register={register}
              name={'email'}
              input_type="email"
              label={t(TranslationKeys.email)}
              defaultValue={user && user.email}
              {...reset}
            />
            <Inputs
              state={'active'}
              error={phoneErrorHandler.error}
              onClick={phoneErrorHandler.onFieldClick}
              help_text={phoneErrorHandler.error && phoneErrorHandler.errorMessageCurrentField[1]}
              register={register}
              input_type={'text'}
              name={'phone'}
              placeholder={'+49 (XX) XXX-XX-XX'}
              label={t(TranslationKeys.phone_number)}
              defaultValue={user && user.phone}
              {...reset}
            />
          </div>
          <div>
            <button className="button button_size-middle button_position-right button_color-black">
              {t(TranslationKeys.submit)}
            </button>
          </div>
        </form>

        {successProfileRequest === true && (
          <div className="profile__status success" onClick={closeAlertStatus}>
            <div className="span">{t(TranslationKeys.saved)}</div>
          </div>
        )}
        {successProfileRequest === false && (
          <div className="profile__status failed" onClick={closeAlertStatus}>
            <div className="span">{t(TranslationKeys.failed)}</div>

            <ErrorMessage status={status} errorState={errorState} />
          </div>
        )}
      </div>
    </>
  );
};
