import React, { FC } from 'react';
import { logo } from '../../../assets/img/image';
import { NavLink } from 'react-router-dom';
import { PATH } from '../../../routes/Routes';
import { useTranslation } from 'react-i18next';
import { ClientType } from '../../../api/clients-api';
import cx from "classnames";
import Collapse from "@kunukn/react-collapse";
import { MenuBtn } from '../../../components/MenuBtn/MenuBtn';
import TranslationKeys from '../../../assets/locales/translationKeys/translationKeys';
import './ActionMenuClients.scss'

type ActionMenuPropsType = {
  client: ClientType;
  options: boolean;
  onClickOptions: (clientId: number) => void;
  selectedIndex: number;
  setSelectedIndex: (value: number) => void;
  onLoadHandler: () => void;
  load: boolean;
  setOptions: (value: boolean) => void;
  setNotAppeared: (clientId: number) => void;
};

export const ActionMenuClients: FC<ActionMenuPropsType> = ({
  onClickOptions,
  selectedIndex,
  client,
  options,
  setSelectedIndex,
  onLoadHandler,
  load,
  setOptions,
  setNotAppeared,
}) => {
  const { t } = useTranslation();

  const onClickOptionHandler = (): void => {
    onClickOptions(client.id);
  };

  return (
    <>
      <div
        onClick={() => {
          onClickOptions(client.id);
          setSelectedIndex(client.id);
        }}
        className={cx("app__toggle", {
          "app__toggle--active": options
        })}
      >
        <div>
          <MenuBtn
            showSidebar={onClickOptionHandler}
            isOpenMenu={options && selectedIndex === client.id} />
        </div>
        <Collapse
          isOpen={options && selectedIndex === client.id}
          className={
            "app__collapse app__collapse--gradient " +
            (options && selectedIndex === client.id ? "app__collapse--active" : "")
          }
        >
          <div className="app__content">
            <div className="options">
              <NavLink className={'actions'} to={`${PATH.CLIENTS}${client.id}/`}>
                <img alt="edit" onLoad={onLoadHandler} className={load ? 'img' : 'img'} src={logo.edit} />
                {t(TranslationKeys.edit)}
              </NavLink>

              <span
                className="actions"
                onClick={() => {
                  setOptions(false);
                  setNotAppeared(client.id);
                }}
              >
                <img onLoad={onLoadHandler} className={'img'} src={logo.appeared} />
                {t(TranslationKeys.client_not_appeared_btn)}
              </span>
            </div>
          </div>
        </Collapse>
      </div>
    </>
  );
};