import React, { FC, memo } from 'react';
import { EventSchedule } from './EventSchedule/EventSchedule';
import { DateListEventType, DateOfListType, ScheduleBranchType } from '../../../api/branches-api';
import dayjs from 'dayjs';
import { HolidaySchedule } from './HolidaySchedule/HolidaySchedule';
import { calcTimeForWorkDay } from '../../../utils/schedule/calcTimeForWorkDay';
import { NonWorkingHours } from './NonWorkingHours/NonWorkingHours';
import { CurrentDay } from '../CurrentDay/CurrentDay';
import './DayOfTheWeek.scss';

type DayOfTheWeekPropsType = {
  dayIndex: number;
  dayName: string;
  scheduleBranchDate: Array<DateOfListType>;
  scheduleBranch: ScheduleBranchType;
  onFreeDateClick: (date: string | undefined, time: string) => void;
  setDateForModalAlert: (date: string, date_of_lists: DateOfListType) => void;
  onStatusChangeEventOpenAlert: (eventId: number, status: string) => void;
  onStatusChangeEvent: (eventId: number, status: string) => void;
  calcGrid: {
    timeWorkDayFinally: Array<string>;
    currentHeightLine: (hour: number) => number;
    currentHeightLineStart: (hour: number) => number;
    sumHours: number;
  };
  setCurrentEventFromSchedule: (event: DateListEventType | null) => void
};

export const DayOfTheWeek: FC<DayOfTheWeekPropsType> = memo(
  ({
    scheduleBranchDate,
    scheduleBranch,
    onFreeDateClick,
    dayIndex,
    dayName,
    setDateForModalAlert,
    onStatusChangeEventOpenAlert,
    onStatusChangeEvent,
    calcGrid,
    setCurrentEventFromSchedule,
  }) => {
    const weeklyWorkSchedule = calcGrid.timeWorkDayFinally.map((time) => ({
      time,
      days: scheduleBranchDate,
    }));

    const dayNow = dayjs().format('YYYY-MM-DD');
    const STEP_MINUTES = 5;
    const AVAILABLE_TIME = 60 / STEP_MINUTES;
    const HEIGHT_HOUR = "180px";
    const CURRENT_DATE = scheduleBranchDate.find(day => day.date_index === dayIndex);

    const DayNowBlockStyle =
      CURRENT_DATE && CURRENT_DATE?.date === dayNow
        ? 'day dayNow'
        : 'day';
    const WeekDateNowBlockStyle =
      CURRENT_DATE?.date && CURRENT_DATE?.date === dayNow
        ? 'dayDate dayDateNow'
        : 'dayDate';

    const DayWeekendBlockStyle =
      CURRENT_DATE && CURRENT_DATE?.holidays.length
        ? 'day dayHoliday'
        : 'day';
    const WeekDateWeekendBlockStyle =
      CURRENT_DATE?.holidays && CURRENT_DATE?.holidays.length
        ? 'dayDate dayDateHoliday'
        : 'dayDate';

    const generalDayStyle =
      CURRENT_DATE &&
      CURRENT_DATE?.date === dayNow &&
      CURRENT_DATE?.holidays.length
        ? DayNowBlockStyle
        : CURRENT_DATE?.date === dayNow
        ? DayNowBlockStyle
        : CURRENT_DATE?.holidays.length
        ? DayWeekendBlockStyle
        : 'day';

    const generalDateStyle =
      CURRENT_DATE &&
      CURRENT_DATE?.date === dayNow &&
      CURRENT_DATE?.holidays.length
        ? WeekDateNowBlockStyle
        : CURRENT_DATE?.date === dayNow
        ? WeekDateNowBlockStyle
        : CURRENT_DATE?.holidays.length
        ? WeekDateWeekendBlockStyle
        : 'dayDate';

    const currentHoliday =
      CURRENT_DATE && CURRENT_DATE?.holidays.length
        ? CURRENT_DATE?.holidays[0]?.name
        : '';

    return (
      <div
        className={generalDayStyle}
        title={generalDayStyle === 'day dayHoliday' ? currentHoliday : ''}
      >
        <div className={generalDateStyle}>
          <span className={'dayDate_weekDay'}>{dayName}</span>
          <span className={'dayDate_date'}>
            {CURRENT_DATE && dayjs(CURRENT_DATE.date).format("DD.MM")}
          </span>
        </div>

        <CurrentDay
          scheduleBranchDate={scheduleBranchDate}
          dayIndex={dayIndex}
          scheduleBranch={scheduleBranch}
          heightHour={HEIGHT_HOUR}
          DayNowBlockStyle={DayNowBlockStyle}
        />

        <NonWorkingHours
          scheduleBranchDate={scheduleBranchDate}
          dayIndex={dayIndex}
          scheduleBranch={scheduleBranch}
          />

        <EventSchedule
          events={CURRENT_DATE ? CURRENT_DATE?.events : []}
          dayIndex={dayIndex}
          scheduleBranchDate={scheduleBranchDate}
          timeWorkDayFinally={calcGrid.timeWorkDayFinally}
          setDateForModalAlert={setDateForModalAlert}
          onFreeDateClick={onFreeDateClick}
          onStatusChangeEventOpenAlert={onStatusChangeEventOpenAlert}
          onStatusChangeEvent={onStatusChangeEvent}
          CURRENT_DATE={CURRENT_DATE}
          setCurrentEventFromSchedule={setCurrentEventFromSchedule}
        />

        <HolidaySchedule
          dayIndex={dayIndex}
          scheduleBranchDate={scheduleBranchDate}
          scheduleBranch={scheduleBranch}
          CURRENT_DATE={CURRENT_DATE}
        />

        <div className="dayDate_free-day-block">
          {weeklyWorkSchedule &&
            weeklyWorkSchedule.map((day, index) => (
              <div
                key={index}
                style={
                  index === 0
                    ? {
                        height: `${calcGrid.currentHeightLineStart(
                          Number(day.time.slice(3, 5)),
                        )}px`,
                      }
                    : index === calcGrid.timeWorkDayFinally.lastIndexOf(day.time)
                    ? { height: `${calcGrid.currentHeightLine(Number(day.time.slice(3, 5)))}px` }
                    : { height: `${calcGrid.currentHeightLine(Number(day.time.slice(3, 5)))}px` }
                }
                className="dayDate_free-day-block_free-day"
              >
                <div className="free-time-block">
                  {calcTimeForWorkDay(day.time, scheduleBranch.day_end, STEP_MINUTES).map((time, ind) => (
                    <div
                      key={ind}
                      className="time-elem"
                      style={{ height: `calc(${HEIGHT_HOUR} / ${AVAILABLE_TIME}` }}
                      onClick={() => {
                        setCurrentEventFromSchedule(null);
                        onFreeDateClick(day.days.find(el => el.date_index === dayIndex)?.date, time);
                      }}
                    >
                      <span className="content">
                        {time}
                      </span>
                    </div>
                  ))}
                </div>
              </div>
            ))}
        </div>
      </div>
    );
  },
);
