import { EventFilterParamsType } from "../../api/events-api";
import {
  ADD_CURRENT_EVENT_FILTER_BRANCH,
  ADD_CURRENT_EVENT_FILTER_DOCTOR,
  ADD_CURRENT_EVENT_FILTER_INSURANCE,
  ADD_CURRENT_EVENT_FILTER_REASON,
  ADD_CURRENT_EVENT_FILTER_REASON_COLOR,
  ADD_CURRENT_EVENT_FILTER_STATUS,
  ADD_EVENT_SEARCH,
  CLEAN_EVENTS_FILTER,
  COLLAPSE_FILTER_EVENTS_FILTER,
  COLLAPSE_FILTER_INSURANCE_EVENTS_FILTER,
  COLLAPSE_FILTER_REASON_EVENTS_FILTER,
  COLLAPSE_FILTER_STATUS_EVENTS_FILTER,
  CURRENT_TITLE_EVENTS_FILTER,
  REMOVE_CURRENT_EVENT_FILTER_DOCTOR,
  REMOVE_CURRENT_EVENT_FILTER_INSURANCE,
  REMOVE_CURRENT_EVENT_FILTER_REASON,
  REMOVE_CURRENT_EVENT_FILTER_REASON_COLOR,
  REMOVE_CURRENT_EVENT_FILTER_STATUS, SET_ACTIVE_ARROW_DATE_EVENTS,
  SET_ACTIVE_ARROW_FULL_NAME_EVENTS,
  SET_ACTIVE_ARROW_REASON_EVENTS,
  SET_ACTIVE_ARROW_STATUS_EVENTS, SET_CURRENT_EVENTS_QUERY_STRING,
  SET_CURRENT_SORT_PARAM_EVENTS,
  SET_EVENT_FILTER_PARAMS,
  SET_PRELOADER_EVENTS_FILTER,
  SET_SORT_BY_DATE_EVENTS,
  SET_SORT_BY_FULL_NAME_EVENTS,
  SET_SORT_BY_REASON_EVENTS,
  SET_SORT_BY_STATUS_EVENTS
} from "./constants";

export const setCurrentTitleEventsFilterAC = (params: string) =>
  ({ type: CURRENT_TITLE_EVENTS_FILTER, params } as const);
export const setEventFilterParamsAC = (params: EventFilterParamsType) =>
  ({ type: SET_EVENT_FILTER_PARAMS, params } as const);
export const addCurrentEventFilterInsuranceAC = (params: string) =>
  ({ type: ADD_CURRENT_EVENT_FILTER_INSURANCE, params } as const);
export const removeCurrentEventFilterInsuranceAC = (params: string) =>
  ({ type: REMOVE_CURRENT_EVENT_FILTER_INSURANCE, params } as const);
export const addCurrentEventFilterReasonAC = (params: string) =>
  ({ type: ADD_CURRENT_EVENT_FILTER_REASON, params } as const);
export const removeCurrentEventFilterReasonAC = (params: string) =>
  ({ type: REMOVE_CURRENT_EVENT_FILTER_REASON, params } as const);
export const addCurrentEventFilterReasonColorAC = (params: string) =>
  ({ type: ADD_CURRENT_EVENT_FILTER_REASON_COLOR, params } as const);
export const removeCurrentEventFilterReasonColorAC = (params: string) =>
  ({ type: REMOVE_CURRENT_EVENT_FILTER_REASON_COLOR, params } as const);
export const addCurrentEventFilterStatusAC = (params: string) =>
  ({ type: ADD_CURRENT_EVENT_FILTER_STATUS, params } as const);
export const removeCurrentEventFilterStatusAC = (params: string) =>
  ({ type: REMOVE_CURRENT_EVENT_FILTER_STATUS, params } as const);
export const addCurrentEventFilterBranchAC = (params: string) =>
  ({ type: ADD_CURRENT_EVENT_FILTER_BRANCH, params } as const);
export const addCurrentEventFilterDoctorAC = (params: string) =>
  ({ type: ADD_CURRENT_EVENT_FILTER_DOCTOR, params } as const);
export const removeCurrentEventFilterDoctorAC = (params: string) =>
  ({ type: REMOVE_CURRENT_EVENT_FILTER_DOCTOR, params } as const);
export const collapseFilterEventsFilterAC = (param: boolean) =>
  ({ type: COLLAPSE_FILTER_EVENTS_FILTER, param } as const);
export const collapseInsuranceEventsFilterAC = (param: boolean) =>
  ({ type: COLLAPSE_FILTER_INSURANCE_EVENTS_FILTER, param } as const);
export const collapseReasonEventsFilterAC = (param: boolean) =>
  ({ type: COLLAPSE_FILTER_REASON_EVENTS_FILTER, param } as const);
export const collapseStatusEventsFilterAC = (param: boolean) =>
  ({ type: COLLAPSE_FILTER_STATUS_EVENTS_FILTER, param } as const);
export const setPreloaderEventsFilterAC = (param: boolean) =>
  ({ type: SET_PRELOADER_EVENTS_FILTER, param } as const);
export const addEventSearchParamAC = (params: string) =>
  ({ type: ADD_EVENT_SEARCH, params } as const);
export const cleanEventsFilterAC = () =>
  ({ type: CLEAN_EVENTS_FILTER } as const);

//sort action
export const setCurrentSortParamEventsAC = (value: string) =>
  ({ type: SET_CURRENT_SORT_PARAM_EVENTS, value } as const);
export const setSortByFullNameEventsAC = (value: boolean) =>
  ({ type: SET_SORT_BY_FULL_NAME_EVENTS, value } as const);
export const setSortByReasonEventsAC = (value: boolean) =>
  ({ type: SET_SORT_BY_REASON_EVENTS, value } as const);
export const setSortByStatusEventsAC = (value: boolean) =>
  ({ type: SET_SORT_BY_STATUS_EVENTS, value } as const);
export const setSortByDateEventsAC = (value: boolean) =>
  ({ type: SET_SORT_BY_DATE_EVENTS, value } as const);
export const setActiveArrowFullNameEventsAC = (value: boolean) =>
  ({ type: SET_ACTIVE_ARROW_FULL_NAME_EVENTS, value } as const);
export const setActiveArrowReasonEventsAC = (value: boolean) =>
  ({ type: SET_ACTIVE_ARROW_REASON_EVENTS, value } as const);
export const setActiveArrowStatusEventsAC = (value: boolean) =>
  ({ type: SET_ACTIVE_ARROW_STATUS_EVENTS, value } as const);
export const setActiveArrowDateEventsAC = (value: boolean) =>
  ({ type: SET_ACTIVE_ARROW_DATE_EVENTS, value } as const);
export const setCurrEventsQueryStringAC = (query: string | null) =>
  ({ type: SET_CURRENT_EVENTS_QUERY_STRING, query } as const);