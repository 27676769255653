import { OrderType } from '../../api/order-api';
import {
  ADD_ORDER,
  CHANGE_STATUS_ORDER,
  EDIT_ORDER,
  RESET_REDUCER_GROUP,
  SET_CURRENT_PAGE_ORDERS,
  SET_LIST_ORDER,
} from '../actions/constants';
import { OrdersInitialStateType } from './types';
import { OrdersActionsType } from '../actions/types';

const initialState: OrdersInitialStateType = {
  currentPage: null,
  orders: {
    links: {
      next: null,
      previous: null,
    },
    count: 0,
    current_page: 0,
    lastPage: 0,
    current: 0,
    results: [] as OrderType[],
  },
};

export const orderReducer = (
  state: OrdersInitialStateType = initialState,
  action: OrdersActionsType,
): OrdersInitialStateType => {
  switch (action.type) {
    case SET_LIST_ORDER: {
      return {
        ...state,
        orders: { ...state.orders, ...action.order },
      };
    }
    case ADD_ORDER: {
      return {
        ...state,
        orders: { ...state.orders, results: [...state.orders.results, action.model] },
      };
    }
    case EDIT_ORDER: {
      return {
        ...state,
        orders: {
          ...state.orders,
          results: state.orders.results.map((e) =>
            e.id === action.model.id ? { ...e, ...action.model } : e,
          ),
        },
      };
    }

    case CHANGE_STATUS_ORDER: {
      return {
        ...state,
        orders: {
          ...state.orders,
          results: state.orders.results.map((e) =>
            e.id === action.orderId
              ? {
                  ...e,
                  status: action.order.status,
                  status_display: action.order.status_display,
                }
              : e,
          ),
        },
      };
    }

    case SET_CURRENT_PAGE_ORDERS: {
      return {
        ...state,
        currentPage: action.currentPage,
      };
    }

    case RESET_REDUCER_GROUP:
      return { ...initialState };

    default:
      return state;
  }
};