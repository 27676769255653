import { Dispatch } from 'redux';
import {
  addReasonAC,
  editReasonAC,
  removeReasonAC,
  setAppStatusAC,
  setListReasonsAC,
} from '../actions';
import { reasonsAPI } from '../../api/reasons-api';
import { handleServerAppError, handleServerNetworkError } from '../../utils/error-utils';

export const fetchReasonsList = () => async (dispatch: Dispatch<any>) => {
  dispatch(setAppStatusAC('loading'));
  try {
    let res = await reasonsAPI.getList();
    dispatch(setListReasonsAC(res.data));
    dispatch(setAppStatusAC('succeeded'));
  } catch (error: any) {
    if (error.message === 'Network Error') {
      handleServerNetworkError(error, dispatch);
    } else {
      handleServerAppError(error, dispatch);
    }
  }
  dispatch(setAppStatusAC('idle'));
};

export const fetchReasonsSortList =
  (ordering?: string | null) => async (dispatch: Dispatch<any>) => {
    dispatch(setAppStatusAC('loading'));
    try {
      let res = await reasonsAPI.getSortList(ordering);
      dispatch(setListReasonsAC(res.data));
      dispatch(setAppStatusAC('succeeded'));
    } catch (error: any) {
      if (error.message === 'Network Error') {
        handleServerNetworkError(error, dispatch);
      } else {
        handleServerAppError(error, dispatch);
      }
    }
    dispatch(setAppStatusAC('idle'));
  };

export const addReasonTC = (data: any) => async (dispatch: Dispatch<any>) => {
  dispatch(setAppStatusAC('loading'));
  try {
    let res = await reasonsAPI.addReason(data);
    dispatch(addReasonAC(res.data));
    dispatch(setAppStatusAC('succeeded'));
  } catch (error: any) {
    if (error.message === 'Network Error') {
      handleServerNetworkError(error, dispatch);
    } else {
      handleServerAppError(error, dispatch);
    }
  }
};

export const editReasonTC = (id: number, data: any) => async (dispatch: Dispatch<any>) => {
  dispatch(setAppStatusAC('loading'));
  try {
    let res = await reasonsAPI.editReason(data, id);
    dispatch(editReasonAC(res.data));
    dispatch(setAppStatusAC('succeeded'));
  } catch (error: any) {
    if (error.message === 'Network Error') {
      handleServerNetworkError(error, dispatch);
    } else {
      handleServerAppError(error, dispatch);
    }
  }
};

export const removeReasonTC = (reasonId: number) => async (dispatch: Dispatch<any>) => {
  dispatch(setAppStatusAC('loading'));
  try {
    await reasonsAPI.removeReason(reasonId);
    dispatch(removeReasonAC(reasonId));
    dispatch(setAppStatusAC('succeeded'));
  } catch (error: any) {
    if (error.message === 'Network Error') {
      handleServerNetworkError(error, dispatch);
    } else {
      handleServerAppError(error, dispatch);
    }
  }
};